import { Box, Button, TextField } from "@mui/material";
import styled from "styled-components";
import {
  StyledButton,
  StyledLabel,
  TitleBox,
} from "../../common/MyPageComponents";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API } from "../../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import AgencyProfileBasic from "./AgencyProfileBasic";
import MPAgencyStaff from "./MPAgencyStaff";
import MPAgencyPlayer from "./MPAgencyPlayer";
import heic2any from "heic2any";
import Loading from "../../common/Loading";

const ItemBox = styled(Box)`
  background-color: #fff;
  border: 1px solid rgb(64, 132, 75);
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 0.5rem/0.5rem;
`;
const ProfileLabel = styled("span")`
  display: inline-block;
  font-size: 1.2rem;
  width: 5rem;
`;
const Span = styled("span")`
  display: inline-block;
  font-size: 1.2rem;
`;

const ModifiedLabel = styled("span")`
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  width: 7rem;
`;
const ProfileRow = styled(Box)``;
export default function MPAgentManageAgency() {
  const [agency, setAgency] = useState();
  const [agencyStaffs, setAgencyStaffs] = useState();
  const [agencyPlayers, setAgencyPlayers] = useState();
  const [basicInfo, setBasicInfo] = useState();
  const img_ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isBasicInfoEditState, setIsBasicInfoEditState] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(`${API.MP_MYAGENCY}`);
        console.log("agency data", res);
        const myAgent = res.data.data;
        if (!myAgent) {
          alert(
            "관리할 에이전시가 없습니다. \n 에이전시 신청에서 에이전시 관리 권한을 먼저 등록하세요"
          );
        } else {
          setAgency((prev) => myAgent);
          setBasicInfo((prev) => ({
            name: myAgent.name,
            ceo: myAgent.ceo,
            phoneNumber: myAgent.phoneNumber,
            email: myAgent.email,
            address: myAgent.address,
            logo: myAgent.logo,
          }));
          setAgencyStaffs((prev) => myAgent.staffs);
          setAgencyPlayers((prev) => myAgent.players);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  function MakeRequestData(profileImageId, removeLogo) {
    return {
      name: basicInfo.name,
      ceo: basicInfo.ceo,
      phoneNumber: basicInfo.phoneNumber,
      email: basicInfo.email,
      address: basicInfo.address,
      logoImageId: profileImageId
        ? profileImageId
        : agency.logo
          ? agency.logo.id
          : null,
    };
  }
  async function changeImgText(event) {
    event.preventDefault();
    const formData = new FormData();

    try {
      let uploadFile = event.target.files[0];
      if (uploadFile.name.split(".")[1] === "heic") {
        setLoading((prev) => true);
        let blob = uploadFile;
        const resultBlob = await heic2any({ blob: blob, toType: "image/jpeg" });
        uploadFile = new File(
          [resultBlob],
          uploadFile.name.split(".")[0] + ".jpg",
          {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          }
        );
        setLoading((prev) => false);
      }
      if (uploadFile.size > API.FILE_UPLOAD_MAX_SIZE) {
        alert(
          `변환된 파일크기가 제한 용량(5MB)를 초과합니다\n 파일크기:${Number(uploadFile.size / 1000000).toFixed(2)}MB`
        );
        return;
      }
      formData.append("file", uploadFile);
      const res = await axios.post(`${API.FILE_BASE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setAgency((prev) => ({ ...prev, logo: res.data.data }));
      setBasicInfo((prev) => ({
        ...prev,
        logo: res.data.data,
      }));
      const requestData = MakeRequestData(res.data.data.id);

      const {
        data: { data },
      } = await axios.put(`${API.MP_AGENCY_COMMON}`, requestData);
      console.log("complete", data);
      alert("사진을 등록하였습니다");
    } catch (error) {
      alert("일시적 문제가 발생했습니다");
    }
  }
  async function onRemovePicture() {
    try {
      const requestData = MakeRequestData();
      requestData["logoImageId"] = null;
      const {
        data: { data },
      } = await axios.put(API.MP_AGENCY_COMMON, requestData);
      setBasicInfo((prev) => ({ ...prev, logo: null }));
      setAgency((prev) => ({ ...prev, logo: null }));
      alert("사진을 삭제했습니다");
    } catch (error) {
      alert("사진삭제에 실패했습니다");
    }
  }

  return (
    <Box>
      {agency ? (
        <ItemBox>
          <TitleBox>에이전시 로고</TitleBox>
          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <Box sx={{ margin: "0 1rem 1rem 0" }}>
              <Box
                sx={{
                  width: "13rem",
                  overflow: "hidden",
                  border: "1px solid #eee",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                {agency.logo ? (
                  <img
                    src={`${API.BASE}${agency.logo.fileUrl}`}
                    style={{ width: "100%", objectFit: "contain" }}
                    alt="profile-img"
                  ></img>
                ) : (
                  <FontAwesomeIcon icon={faUser} size="4x" color="#183153" />
                )}
                <Loading loading={loading}></Loading>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box>
                    <StyledLabel sx={{ display: "inline-block" }}>
                      {agency.logo ? "수정" : "등록"}
                      <input
                        type="file"
                        className="file"
                        ref={img_ref}
                        accept="image/*,image/heic"
                        onChange={changeImgText}
                        style={{ display: "none" }}
                      />
                    </StyledLabel>
                  </Box>
                  <Box>
                    <StyledButton onClick={onRemovePicture}>삭제</StyledButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ItemBox>
      ) : null}
      {agency ? (
        <ItemBox>
          <AgencyProfileBasic
            basicInfo={basicInfo}
            setBasicInfo={setBasicInfo}
            isEditState={isBasicInfoEditState}
            setIsEditState={setIsBasicInfoEditState}
          ></AgencyProfileBasic>
        </ItemBox>
      ) : null}
      <ItemBox>
        <TitleBox>직원정보</TitleBox>
        {agencyStaffs ? (
          <MPAgencyStaff
            staffs={agencyStaffs}
            setAgencyStaffs={setAgencyStaffs}
          ></MPAgencyStaff>
        ) : null}
      </ItemBox>
      <ItemBox>
        <TitleBox>선수정보</TitleBox>
        {agencyPlayers ? (
          <MPAgencyPlayer
            players={agencyPlayers}
            setAgencyPlayers={setAgencyPlayers}
          ></MPAgencyPlayer>
        ) : null}
      </ItemBox>
    </Box>
  );
}
