import MainLayout from "../../LayoutContainers/MainLayout";
import PlayerDetail from "../../components/person/PlayerDetail";
import PlayerList from "../../components/person/PlayerList";

export default function PlayerDetailLayout() {
  return (
    <MainLayout mode="light">
      <PlayerDetail mode="light"></PlayerDetail>
    </MainLayout>
  );
}
