import { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Box, DialogContent, DialogContentText } from "@mui/material";
import axios from "axios";
import { API } from "../../../config";
import { useRecoilValue } from "recoil";
import { enumAtom } from "../../../recoil/atoms/applicationEnum";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#F3F6F9",
    cursor: "pointer",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const pageSize = 8;
export default function SelectDialog({
  selectBoxType,
  open,
  setOpen,
  itemSeq,
  setCareer,
}) {
  const [pagingInfo, setPagingInfo] = useState({
    isRequired: false,
    totalPages: 0,
  });
  const applicationEnum = useRecoilValue(enumAtom);
  const searchInput = useRef();
  const manuallyInput = useRef();
  const [searchTeamList, setsearchTeamList] = useState();
  const handleClose = () => {
    setsearchTeamList((prev) => []);
    setPagingInfo({
      isRequired: false,
      totalPages: 0,
    });
    setOpen(false);
  };
  async function findTeamRequest(pageNum) {
    try {
      const requestData = {
        query: searchInput.current.value,
        isActive: null,
      };
      console.log("requestData", requestData);
      const {
        data: { data },
      } = await axios.post(
        `${API.SEARCH_TEAM_BY_NAME}?page=${pageNum}&size=${pageSize}`,
        requestData
      );
      if (data.content.length === 0) {
        setsearchTeamList((prev) => data.content);
        setPagingInfo((prev) => ({
          isRequired: false,
          totalPages: parseInt(data.totalPages),
        }));
        alert("검색 결과가 없습니다.");
      } else {
        console.log("data", data);
        setsearchTeamList((prev) => data.content);
        if (parseInt(data.totalPages) > 1) {
          //pagination이 필요
          setPagingInfo((prev) => ({
            isRequired: true,
            totalPages: parseInt(data.totalPages),
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  function onschBtnClick(event) {
    findTeamRequest(0);
  }

  function onPageChange(event, value) {
    console.log("event", value);
    findTeamRequest(parseInt(value - 1));
  }
  function onDbClick(event) {
    const teamId = Number(event.currentTarget.getAttribute("value"));
    console.log(`teamId:${teamId}`);
    const findObj = searchTeamList.find((item) => item.id === teamId);
    console.log("findObj", findObj);
    console.log("selectBoxType", selectBoxType);
    if (selectBoxType === API.SelectBoxType.MULTIPLE_UPDATE) {
      console.log("multi update");

      setCareer((prev) =>
        prev.map((item) =>
          item.seq === itemSeq ? { ...item, club: findObj } : item
        )
      );
    } else {
      console.log("single update");
      setCareer((prev) => ({ ...prev, club: findObj }));
    }
    setOpen(false);
  }
  function onManuallyWrite() {
    const teamName = manuallyInput.current.value;
    if (selectBoxType === API.SelectBoxType.MULTIPLE_UPDATE) {
      setCareer((prev) =>
        prev.map((c) =>
          c.seq === itemSeq ? { ...c, club: { name: teamName } } : c
        )
      );
    } else {
      setCareer((prev) => ({
        ...prev,
        club: { name: teamName },
      }));
    }
    setOpen(false);
  }
  return (
    <div>
      <Dialog
        className="custom-div"
        open={open}
        onClose={handleClose}
        fullWidth={true}
        PaperProps={{
          sx: {
            maxWidth: "700px",
            maxHeight: { xs: "400px", md: "800px" },
          },
        }}
      >
        <DialogTitle>검색으로 팀선택</DialogTitle>
        <div style={{ marginBottom: "10px", marginTop: "-10px" }}>
          <FontAwesomeIcon
            icon={faCircleInfo}
            color="#1e8ffa"
            style={{ color: "#339AF0", marginLeft: "20px" }}
          />
          <span
            style={{
              color: "#339AF0",
              fontSize: "1rem",
              display: "inline-block",
              width: "70%",
            }}
          >
            검색으로 팀을 선택하면 프로필의 팀정보가 연동됩니다.
          </span>
        </div>
        <div className="dialog-search-wrapper">
          <TextField
            inputRef={searchInput}
            onKeyDown={(e) => {
              if (e.key === "Enter") onschBtnClick();
            }}
            size="small"
            sx={{ width: "15rem" }}
            id="position-input"
            label="팀명"
            variant="outlined"
            inputProps={{ style: { fontSize: "1rem" } }}
          />
          <Button onClick={onschBtnClick} variant="contained">
            검색
          </Button>
        </div>
        <div>
          <DialogContent sx={{ padding: "10px 20px 0" }}>
            <DialogContentText id="alert-dialog-description">
              검색 결과 더블클릭
            </DialogContentText>
          </DialogContent>
        </div>
        <div>
          <TableContainer
            component={Paper}
            fontSize="1rem"
            sx={{ width: "95%", margin: "0.5rem" }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#183153" }}>
                  <TableCell
                    sx={{ color: "#fff", width: "50%" }}
                    align="center"
                  >
                    팀명
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", width: "40%" }}
                    align="center"
                  >
                    지역
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchTeamList
                  ? searchTeamList.map((row) => (
                      <StyledTableRow
                        onDoubleClick={onDbClick}
                        value={row.id}
                        //key={row.club.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">
                          {applicationEnum.regions.find(
                            (item) => item.name === row.region
                          )
                            ? applicationEnum.regions.find(
                                (item) => item.name === row.region
                              ).description
                            : row.region}
                        </TableCell>
                      </StyledTableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
            {pagingInfo.isRequired ? (
              <Stack
                spacing={1}
                sx={{ margin: "1.5rem 1rem", width: "90%" }}
                alignItems="center"
              >
                <Pagination
                  count={pagingInfo.totalPages}
                  color="primary"
                  onChange={onPageChange}
                  siblingCount={0}
                />
              </Stack>
            ) : null}
          </TableContainer>
          <div>
            <DialogTitle>직접입력</DialogTitle>
            <div style={{ marginBottom: "10px", marginTop: "-10px" }}>
              <FontAwesomeIcon
                icon={faCircleInfo}
                color="#1e8ffa"
                style={{ color: "#339AF0", marginLeft: "20px" }}
              />
              <span
                style={{
                  color: "#339AF0",
                  fontSize: "1rem",
                  display: "inline-block",
                  width: "70%",
                }}
              >
                팀이 검색되지 않을 경우, 직접 입력하세요
              </span>
            </div>
            <div
              className="dialog-search-wrapper"
              style={{ marginBottom: "20px" }}
            >
              <TextField
                inputRef={manuallyInput}
                onKeyDown={(e) => {
                  if (e.key == "Enter") onManuallyWrite();
                }}
                size="small"
                sx={{ width: "15rem" }}
                id="position-input"
                label="팀명"
                variant="outlined"
                inputProps={{ style: { fontSize: "1rem" } }}
              />
              <Button onClick={onManuallyWrite} variant="contained">
                입력
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
