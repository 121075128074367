import { Box, Grid, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { API } from "../../config";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { detailPositionSelector } from "../../recoil/atoms/detailPositionSelector";

const StyledDiv = styled("div")({
  margin: "0em 0",
  alignItems: "center",
});
const StyledDiv2 = styled("div")({
  margin: "0em 0",
  display: "flex",
  alignItems: "center",
});
const profileDetail = [
  { property: "birthDate", name: "생년월일" },
  { property: "nationality", name: "국적" },
  { property: "height", name: "신장", unit: "cm" },
  { property: "weight", name: "몸무게", unit: "kg" },
];
export default function MainPlayerProfile({ profile }) {
  const positionDetailEnum = useRecoilValue(detailPositionSelector);
  const theme = useTheme();
  const imgRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      imgRef.current.style.setProperty("opacity", "1");
    }, 0.5);
  });
  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        borderRight: {
          xs: "none",
          sm: "none",
          md: "1px solid #666",
        },
        padding: { xs: "0.5rem 0", sm: "0 0.5rem" },
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {profile ? (
          <Link to={`/player-detail/${profile.id}`}>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: "15rem",
                  lg: "15rem",
                  xl: "17rem",
                  xxl: "20rem",
                },
              }}
            >
              <img
                ref={imgRef}
                style={{
                  width: "100%",
                  opacity: 0.5,
                  transition: "opacity 2ms",
                  border: "1px solid #eee",
                  objectFit: "contain",
                  textAlign: "right",
                }}
                alt="profile-img"
                src={
                  profile.profileImage
                    ? `${API.BASE}/${profile.profileImage.fileUrl}`
                    : "https://cdn-icons-png.flaticon.com/512/6388/6388000.png"
                }
              ></img>
            </Box>
          </Link>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box
          sx={{
            display: { xs: "flex", md: "block" },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <StyledDiv
              style={{
                width: "6rem",
                backgroundColor: "rgb(245,21,29)",
                textAlign: "center",
              }}
            >
              PLAYER
            </StyledDiv>
            <Box>
              <Typography
                variant="mainProfileTeam"
                sx={{
                  borderRight: { xs: "none", sm: "1px solid #999999" },
                  paddingRight: { xs: "1rem" },
                  fontSize: { xs: "1.2rem" },
                }}
              >
                {profile.currentAffiliation.club.name}
              </Typography>
              <Typography
                variant="mainProfileKitNumber"
                sx={{
                  paddingLeft: { xs: "0", sm: "1rem" },
                  fontSize: { xs: "1.2rem" },
                }}
              >
                {profile.currentAffiliation.kitNumber
                  ? `NO.${profile.currentAffiliation.kitNumber || ""}`
                  : ""}
              </Typography>
            </Box>

            <StyledDiv>
              <Typography
                variant="mainProfileName"
                letterSpacing={15}
                sx={{
                  fontSize: { xs: "1.8rem" },
                }}
              >
                {profile.name}
              </Typography>
            </StyledDiv>
          </Box>
          <Box>
            {profileDetail.map((p, index) => (
              <StyledDiv2 key={index}>
                <p style={{ minWidth: "8rem" }}>
                  <Typography variant="mainProfileDetail">{p.name}</Typography>
                </p>
                <Typography variant="mainProfileDetail">
                  {profile[p.property]
                    ? profile[p.property] + (p.unit || "")
                    : ""}
                </Typography>
              </StyledDiv2>
            ))}
            <StyledDiv2>
              <p style={{ minWidth: "8rem" }}>
                <Typography variant="mainProfileDetail">메인포지션</Typography>
              </p>
              <Typography variant="mainProfileDetail">
                {profile.mainPosition ? profile.mainPosition : ""}
                {/* positionDetailEnum.find(
                  (pd) => pd.name === profile.mainPosition
                ).description */}
              </Typography>
            </StyledDiv2>
            <StyledDiv2 style={{ margin: "0 0 1rem 0" }}>
              <p style={{ minWidth: "8rem" }}>
                <Typography variant="mainProfileDetail">
                  세컨드 포지션
                </Typography>
              </p>
              <Typography variant="mainProfileDetail">
                {profile.secondPosition ? profile.secondPosition : ""}
              </Typography>
            </StyledDiv2>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
