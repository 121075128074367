import { styled } from "styled-components";
import PlayerListTable from "./PlayerListTable";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { API } from "../../config";
import { useRecoilState, useRecoilValue } from "recoil";
import { enumAtom } from "../../recoil/atoms/applicationEnum";
import { Box } from "@mui/material";
import { playerListBackAtom } from "../../recoil/atoms/playerListBack";
import Filter from "../common/Filter";
import SearchBox from "../common/SearchBox";
import { useNavigationType } from "react-router";
const position = [
  {
    key: 0,
    name: "ALL",
    description: "ALL",
    include: ["GK", "CB", "LB", "RB", "DMF", "AMF", "LW", "SS", "RW", "CF"],
  },
  {
    key: 1,
    name: "FW",
    description: "FW",
    include: ["LW", "SS", "RW", "CF"],
  },
  { key: 2, name: "MF", description: "MF", include: ["AMF", "DMF"] },
  { key: 3, name: "DF", description: "DF", include: ["LB", "RB", "CB"] },
  { key: 4, name: "GK", description: "GK", include: ["GK"] },
];
const ItemButton = styled.button`
  font-size: 1rem;
  border: none;
  width: 7rem;
  margin: 0.2rem;
  padding: 10px 20px;
  background-color: ${({ "data-active": dataActive }) =>
    dataActive === "true" ? "#0072bc" : "transparent"};
  color: ${({ "data-active": dataActive }) =>
    dataActive === "true" ? "#fff" : "#111"};
  cursor: pointer;
  &:hover {
    border: none;
  }
`;
const SearchButton = styled(ItemButton)`
  background-color: #bb2649;
  border-radius: 20px;
  color: #fff;
  &:hover {
    box-shadow: none;
    color: yellow;
  }
`;
export default function PlayerList() {
  const pageSize = 8;
  const [enumData, setEnumData] = useRecoilState(enumAtom);
  const appEnum = useRecoilValue(enumAtom);
  const [playerListBack, setPlayerListBack] =
    useRecoilState(playerListBackAtom);

  const [isLeagueClicked, setIsLeagueClicked] = useState(
    Array(appEnum.leagues.length + 1).fill(false)
  );
  const [isPositionClicked, setIsPositionClicked] = useState(
    Array(position.length).fill(false)
  );
  const content1Ref = useRef();

  const [players, setplayers] = useState(null);
  const playerSearchNameInput = useRef(null);
  const [pagingInfo, setPagingInfo] = useState({
    isRequired: false,
    totalPages: 0,
  });

  const navigationType = useNavigationType();
  useEffect(() => {
    fetchEnum();
    if (navigationType === "PUSH") {
      //처음 로딩
      console.log("처음 로딩", playerListBack);
      fetchdata(0);
    } else if (navigationType === "POP") {
      console.log("playerListBack", playerListBack);
      if (Object.keys(playerListBack).length === 0) {
        //링크 강제 입력
        console.log("링크 강제 입력");
        fetchdata(0);
      } else {
        console.log("뒤로 가기");
        setplayers((prev) => playerListBack.data.map((tb) => tb));
        setPagingInfo((prev) => ({ ...playerListBack.pagingInfo }));
        setIsLeagueClicked((prev) => playerListBack.filterLeague);
        setIsPositionClicked((prev) => playerListBack.filterPosition);
        setTimeout(
          () =>
            window.scrollTo({
              top: parseInt(playerListBack.scrollY),
              behavior: "instant",
            }),
          0
        );
      }
    }
  }, []);
  function createRequestData() {
    let selectedPositionNames = [];
    let selectedLeagueNames = [];
    selectedPositionNames = isPositionClicked
      .slice(1)
      .map((ipc, index) => (ipc ? appEnum.basePosition[parseInt(index)] : null))
      .filter(Boolean);

    selectedLeagueNames = isLeagueClicked
      .slice(1)
      .map((ilc, index) => (ilc ? appEnum.leagues[index].id : null))
      .flat()
      .filter(Boolean);

    const data = {
      query: playerSearchNameInput.current.value,
      positions: selectedPositionNames,
      leagueIds: selectedLeagueNames,
      regions: null,
    };

    return data;
  }
  async function fetchdata(currentPage) {
    const requestData = createRequestData();
    const requestURL = `${API.PLAYER_SEARCH}?page=${currentPage}&size=${pageSize}`;
    const {
      data: { data },
    } = await axios.post(requestURL, requestData);
    setplayers(data.content);
    setPagingInfo((prev) => ({
      isRequired: data.totalPages <= 1 ? false : true,
      totalPages: parseInt(data.totalPages),
      currentPage: currentPage + 1,
    }));
  }
  async function fetchEnum() {
    try {
      const {
        data: { data },
      } = await axios.get(API.APPLICATION_ENUM);
      setEnumData(data);
    } catch (error) {}
  }
  function scrollScreen() {
    content1Ref.current?.scrollIntoView({ behavior: "auto" });
  }
  return (
    <Box>
      <Box sx={{ paddingBottom: "1rem", borderBottom: "1px solid #ccc" }}>
        {isLeagueClicked ? (
          <Filter
            categoryName="리그"
            filterItem={[
              { name: "ALL", description: "전체", key: 0 },
              ...appEnum.leagues.map((r, index) => ({ ...r, key: index + 1 })),
            ]}
            isClicked={isLeagueClicked}
            setIsClicked={setIsLeagueClicked}
          ></Filter>
        ) : null}
        {isPositionClicked ? (
          <Filter
            categoryName="포지션"
            filterItem={position}
            isClicked={isPositionClicked}
            setIsClicked={setIsPositionClicked}
            widthextend="false"
          ></Filter>
        ) : null}
      </Box>
      <Box>
        <Box ref={content1Ref} sx={{ margin: "1rem 0" }}>
          <SearchBox
            id="player-search-box"
            ref={playerSearchNameInput}
            fetchdata={fetchdata}
            placeHolder="선수명 or 팀명"
          ></SearchBox>
        </Box>
      </Box>

      {players ? (
        <PlayerListTable
          players={players}
          pagingInfo={pagingInfo}
          setPagingInfo={setPagingInfo}
          fetchdata={fetchdata}
          scrollScreen={scrollScreen}
          setPlayerListBack={setPlayerListBack}
          isLeagueClicked={isLeagueClicked}
          isPositionClicked={isPositionClicked}
        ></PlayerListTable>
      ) : null}
    </Box>
  );
}
