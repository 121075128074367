import { Box } from "@mui/material";
import { useRef } from "react";
import { API } from "../../config";

export default function ImageModal({ setModalOpen, imgURL }) {
  const modalBackground = useRef();
  return (
    <Box
      className={"modal-container"}
      ref={modalBackground}
      onClick={(e) => {
        if (e.target === modalBackground.current) {
          setModalOpen(false);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          setModalOpen(false);
        }
      }}
    >
      <Box>
        <Box overflow="hidden">
          <img
            src={`${API.BASE}${imgURL}`}
            alt="team-gallery-img"
            style={{ objectFit: "cover", width: "100%", borderRadius: "10px" }}
          ></img>
        </Box>
      </Box>
    </Box>
  );
}
