import { selector } from "recoil";
import { enumAtom } from "./applicationEnum";

export const detailPositionSelector = selector({
  key: "DetailPositionSelector",
  get: ({ get }) => {
    const data = get(enumAtom);
    let result = [];
    const positionColor = [
      "error.main",
      "primary.main",
      "success.main",
      "warning.main",
      "info.main",
    ];
    const values = data ? Object.values(data.positions) : [];
    for (let i = 0; i < values.length; i++) {
      result = [
        ...result,
        ...values[i].map((item) => ({ ...item, color: positionColor[i] })),
      ];
    }
    return result;
  },
});
