import { useState, useEffect } from "react";

import "../../../../../style/mypage/mypagestaff.css";
import MPStaffProfileBasic from "./MPStaffProfileBasic";

import MPStaffProfilePlayerCareer from "./MPStaffProfilePlayerCareer";
import MPStaffProfileAward from "./MPStaffProfileAward";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";

import { API } from "../../../../../config";
import MPStaffProfilePhilosophy from "./MPStaffProfilePhilosophy";
import MPStaffProfilePositionPrefer from "./MPStaffProfilePositionPrefer";
import MPStaffProfileCert from "./MPStaffProfileCert";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { enumAtom } from "../../../../../recoil/atoms/applicationEnum";
import MPPlayerSNS from "../../player/profile/MPPlayerSNS";
import MPStaffCurrentTeam from "./MPStaffCurrentTeam";
import MPPlayerVideo from "../../player/profile/MPPlayerVideo";
import MPPlayerImages from "../../player/profile/MPPlayerImages";
import styled from "styled-components";
import { Box } from "@mui/material";

const playerPositionText = [
  { key: "CF", text: "CF" },
  { key: "SS", text: "SS" },
  { key: "LWF", text: "LWF" },
  { key: "RWF", text: "RWF" },
  { key: "AM", text: "AM" },
  { key: "LM", text: "LM" },
  { key: "CM", text: "CM" },
  { key: "RM", text: "RM" },
  { key: "DM", text: "DM" },
];

const staffRoleText = [
  { key: "감독", text: "HEAD_COACH" },
  { key: "수석코치", text: "ASSISTANT_COACH" },
  { key: "코치", text: "코치" },
  { key: "GK코치", text: "GK코치" },
  { key: "피지컬코치", text: "피지컬코치" },
  { key: "분석관", text: "분석관" },
];
const ItemBox = styled(Box)`
  background-color: #fff;
  border: 1px solid rgb(64, 132, 75);
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 0.5rem/0.5rem;
`;
function phoneNumberFormat(phoneNumber) {
  const tokens = phoneNumber.split("-");

  if (tokens.length === 1) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7)}`;
  } else {
    return phoneNumber;
  }
}
export default function MPStaffProfile() {
  const [basicInfo, setBasicInfo] = useState();
  const [currentAffiliation, setCurrentAffiliation] = useState();
  const [playerCareer, setPlayerCareer] = useState();
  const [teachCareer, setTeachCareer] = useState();
  const [personalAwardHistories, setPersonalAwardHistories] = useState();
  const [teamAwardHistories, setTeamAwardHistories] = useState();
  const [certifications, setCertifications] = useState();
  const [SNS, setSNS] = useState();
  const [highlightVideos, setHighlightVideos] = useState();
  const [introImages, setIntroImages] = useState();
  const [philosophy, setPhilosophy] = useState();
  const [preferPositionStyles, setPreferPositionStyles] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [lastCRUD, setLastCRUD] = useState("");
  // const [SNSSeq, setSNSSeq] = useState();
  // const [imageSeq, setImageSeq] = useState();
  // const [videoSeq, setVideoSeq] = useState();

  const appEnum = useRecoilValue(enumAtom);
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchdata() {
      try {
        const {
          data: { data },
        } = await axios.get(API.MP_COACHE);
        console.log("coach_mypage", data);
        setBasicInfo({
          name: data.name,
          nationality: data.nationality,
          birthDate: data.birthDate,
          phoneNumber: data.phoneNumber
            ? phoneNumberFormat(data.phoneNumber)
            : "010-1234-5678",
          email: data.email,
          profileImage: data.profileImage,
        });
        setCurrentAffiliation(data.currentAffiliation);
        setPlayerCareer(
          data.playerHistories.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setTeachCareer(
          data.coachHistories.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setPersonalAwardHistories(
          data.personalAwardHistories.map((item, index) => ({
            ...item,
            date: item.date.slice(0, 4),
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setTeamAwardHistories(
          data.teamAwardHistories.map((item, index) => ({
            ...item,
            date: item.date.slice(0, 4),
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setSNS(
          data.snsInformations.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setHighlightVideos(
          data.highlightVideos.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setIntroImages(
          data.introImages.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setCertifications((prev) =>
          data.certifications.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setPhilosophy({ data: data.coachingPhilosophy, isEditState: false });
        setPreferPositionStyles({
          preferPositionStyles: data.preferPositionStyles,
          isEditState: false,
        });
      } catch (error) {
        alert("로그인이 필요합니다");
        const res = await axios.post(API.LOGOUT);
        sessionStorage.clear();
        navigate("/login");
      }
    }
    fetchdata();
  }, []);
  const snackBarClose = (event, reason) => {
    setSnackbarOpen(false);
  };
  return (
    <div>
      <ItemBox>
        {basicInfo ? (
          <MPStaffProfileBasic
            basicInfo={basicInfo}
            setBasicInfo={setBasicInfo}
          ></MPStaffProfileBasic>
        ) : null}
      </ItemBox>
      <ItemBox>
        {currentAffiliation ? (
          <MPStaffCurrentTeam
            comType={API.ComType.PLAYER_HISOTRY}
            roleType={API.RolyType.PLAYER}
            selectBoxType={API.SelectBoxType.SINGLE_UPDATE}
            sectionTitle="현재소속"
            career={currentAffiliation}
            setCareer={setCurrentAffiliation}
            selectBoxText={playerPositionText}
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPStaffCurrentTeam>
        ) : null}
      </ItemBox>
      <ItemBox>
        {playerCareer ? (
          <MPStaffProfilePlayerCareer
            comType={API.ComType.PLAYER_HISOTRY}
            roleType={API.RolyType.COACH}
            selectBoxType={API.SelectBoxType.MULTIPLE_UPDATE}
            sectionTitle="선수경력"
            career={playerCareer}
            setCareer={setPlayerCareer}
            selectBoxText={playerPositionText}
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPStaffProfilePlayerCareer>
        ) : null}
      </ItemBox>
      <ItemBox>
        {teachCareer ? (
          <MPStaffProfilePlayerCareer
            comType={API.ComType.COACH_HISOTRY}
            roleType={API.RolyType.COACH}
            selectBoxType={API.SelectBoxType.MULTIPLE_UPDATE}
            sectionTitle="지도경력"
            career={teachCareer}
            setCareer={setTeachCareer}
            selectBoxText={appEnum.coachPositions}
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPStaffProfilePlayerCareer>
        ) : null}
      </ItemBox>
      <ItemBox>
        {personalAwardHistories ? (
          <MPStaffProfileAward
            comType={API.ComType.COACH_AWARDS_PERSONAL}
            roleType={API.RolyType.COACH}
            sectionTitle="개인수상이력"
            awards={personalAwardHistories}
            setAwards={setPersonalAwardHistories}
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPStaffProfileAward>
        ) : null}
      </ItemBox>
      <ItemBox>
        {teamAwardHistories ? (
          <MPStaffProfileAward
            comType={API.ComType.COACH_AWARDS_TEAM}
            roleType={API.RolyType.COACH}
            sectionTitle="팀수상이력"
            awards={teamAwardHistories}
            setAwards={setTeamAwardHistories}
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPStaffProfileAward>
        ) : null}
      </ItemBox>
      <ItemBox>
        {certifications ? (
          <MPStaffProfileCert
            comType={API.ComType.COACH_CERT}
            roleType={API.RolyType.COACH}
            sectionTitle="자격증"
            rows={certifications}
            setRows={setCertifications}
          ></MPStaffProfileCert>
        ) : null}
      </ItemBox>
      <ItemBox>
        {SNS ? (
          <MPPlayerSNS
            roleType={API.RolyType.COACH}
            comType={API.ComType.COACH_SNS}
            sectionTitle="SNS"
            SNS={SNS}
            setSNS={setSNS}
          ></MPPlayerSNS>
        ) : null}
      </ItemBox>
      <ItemBox>
        {basicInfo ? (
          <MPPlayerVideo
            comType={API.ComType.COACH_VIDEO}
            roleType={API.RolyType.COACH}
            sectionTitle="하이라이트 영상"
            highlightVideos={highlightVideos}
            setHighlightVideos={setHighlightVideos}
            videoType="HIGHLIGHT"
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPPlayerVideo>
        ) : null}
      </ItemBox>
      <ItemBox>
        {basicInfo ? (
          <MPPlayerImages
            comType={API.ComType.COACH_INTRO_IMG}
            roleType={API.RolyType.COACH}
            introImages={introImages}
            setIntroImages={setIntroImages}
          ></MPPlayerImages>
        ) : null}
      </ItemBox>
      <ItemBox>
        <span className="section-title">지도스타일</span>
        {philosophy ? (
          <MPStaffProfilePhilosophy
            comType={API.ComType.COACH_AWARDS_PERSONAL}
            roleType={API.RolyType.COACH}
            subTitle={"축구철학"}
            philosophy={philosophy}
            setPhilosophy={setPhilosophy}
            setLastCRUD={setLastCRUD}
          ></MPStaffProfilePhilosophy>
        ) : null}
        {philosophy ? (
          <MPStaffProfilePositionPrefer
            preferPositionStyles={preferPositionStyles}
            setPreferPositionStyles={setPreferPositionStyles}
            subTitle={"포지션별 선호스타일"}
            philosophy={philosophy}
            setPhilosophy={setPhilosophy}
            setLastCRUD={setLastCRUD}
          ></MPStaffProfilePositionPrefer>
        ) : null}
      </ItemBox>
      <Snackbar
        sx={{ textAlign: "center" }}
        variant={"outlined"}
        size={"sm"}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={snackBarClose}
        message={lastCRUD}
      />
    </div>
  );
}
