import { useRecoilState } from "recoil";
import MainLayout from "../../LayoutContainers/MainLayout";
import RowBox from "../../LayoutContainers/RowBox";
import CoachList from "../../components/person/CoachList";
import { enumAtom } from "../../recoil/atoms/applicationEnum";
import { useEffect } from "react";
import axios from "axios";
import { API } from "../../config";

export default function CoachListLayout() {
  const [enumData, setEnumData] = useRecoilState(enumAtom);
  useEffect(() => {
    async function fetchEnum() {
      try {
        const {
          data: { data },
        } = await axios.get(API.APPLICATION_ENUM);

        setEnumData(data);
      } catch (error) {}
    }
    fetchEnum();
  }, []);
  return (
    <MainLayout mode="light">
      <RowBox
        mode="light"
        bgColor="none"
        padding={"0 0 6rem 0"}
        isBorder={false}
      >
        {Object.keys(enumData).length !== 0 ? <CoachList></CoachList> : null}
      </RowBox>
    </MainLayout>
  );
}
