import {
  Link,
  UNSAFE_NavigationContext,
  useLocation,
  useNavigate,
} from "react-router-dom";

import {
  faAngleDown,
  faIdBadge,
  faPeopleRobbery,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "../../config";
import { useRecoilState, useRecoilValue } from "recoil";
import { enumAtom } from "../../recoil/atoms/applicationEnum";
import { faUniversalAccess } from "@fortawesome/free-solid-svg-icons/faUniversalAccess";
import { faSquareFontAwesomeStroke } from "@fortawesome/free-brands-svg-icons";
import { Box, Pagination, Stack, useTheme } from "@mui/material";
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";
import styled from "styled-components";
import { useContext } from "react";
import { useBackHistoryActions } from "../../BackHistory/UseBackControll";
import StyledTable from "../common/ItemListTable";

const tableHeaderText = [
  { name: "팀", width: "10%" },
  { name: "이름", width: "25%" },
  { name: "리그", width: "10%" },
  { name: "지역", width: "10%" },
];

export default function TeamListTable({
  teams,
  pagingInfo,
  setPagingInfo,
  fetchdata,
  scrollScreen,
  setTeamListBack,
  isLeagueClicked,
  isRegionClicked,
}) {
  const navigate = useNavigate();
  const appEnum = useRecoilValue(enumAtom);
  function onPageChange(event, value) {
    fetchdata(parseInt(value) - 1);
  }
  const handleRowClick = (teamId) => {
    setTeamListBack((prev) => ({
      isFirstLoad: false,
      data: teams,
      pagingInfo: pagingInfo,
      filterLeague: isLeagueClicked,
      filterRegion: isRegionClicked,
      scrollY: window.scrollY,
    }));
    navigate(`/team-detail/${teamId}`);
  };

  return (
    <Box>
      <Box>
        <StyledTable>
          <thead>
            <tr style={{ fontSize: "1.1rem" }}>
              {tableHeaderText.map((item, index) => (
                <th key={index} style={{ width: item.width }}>
                  {item.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {teams.map((team, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(team.id)}
                style={{ fontSize: "1rem" }}
              >
                {team.logo ? (
                  <td>
                    <div
                      style={{
                        width: "3rem",
                        height: "3rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={`${API.BASE}${team.logo.fileUrl}`}
                        alt="profile-img"
                        style={{ objectFit: "cover" }}
                      ></img>
                    </div>
                  </td>
                ) : (
                  <td>
                    <Box>
                      <FontAwesomeIcon
                        icon={faSquareFontAwesomeStroke}
                        size="3x"
                        color="#81cdc6"
                      />
                    </Box>
                  </td>
                )}
                <td>{team.name}</td>
                <td>{team.league.description}</td>
                <td>
                  {team.region
                    ? appEnum.regions.find((r) => r.name === team.region)
                        .description
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </Box>
      <Box>
        <Box>
          {pagingInfo.isRequired ? (
            <Stack
              spacing={1}
              sx={{ margin: "1.5rem 1rem", width: "100%" }}
              alignItems="center"
            >
              <Pagination
                shape="circular"
                size="large"
                count={pagingInfo.totalPages}
                siblingCount={0}
                onChange={onPageChange}
                defaultPage={1}
                page={pagingInfo.currentPage}
                color="info"
              />
            </Stack>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
