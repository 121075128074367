import { Box, Typography } from "@mui/material";

import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Icon = styled(FontAwesomeIcon)`
  color: rgb(64, 132, 75);
  font-size: 1.4rem;
  width: 2rem;
  margin-right: 2rem;
`;
const ContentBox = styled(Box)`
  font-size: 1.2rem;
`;
export default function TeamContact({ team }) {
  return (
    <Box padding="0.5rem 0">
      <Box sx={{ display: "flex", alignItems: "center", margin: "0.5rem 0" }}>
        <ContentBox>
          <Icon icon={faLocationDot} />
        </ContentBox>
        <ContentBox>{team.region}</ContentBox>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", margin: "0.5rem 0" }}>
        <ContentBox>
          <Icon icon={faPhone} color="rgb(64,132,75)" />
        </ContentBox>
        <ContentBox>{team.phoneNumber}</ContentBox>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", margin: "0.5rem 0" }}>
        <ContentBox>
          <Icon icon={faEnvelope} />
        </ContentBox>
        <ContentBox>{team.email}</ContentBox>
      </Box>
    </Box>
  );
}
