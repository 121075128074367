import { Box, Button, TextField } from "@mui/material";
import { StyledButton } from "../mypage/common/MyPageComponents";
import { useRef, useState } from "react";
import { API } from "../../config";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router";

const LabelBox = styled("div")`
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
`;
const DaySelect = styled("select")`
  width: 4rem;
  font-size: 1.1rem;
  color: #444;
  border: 1px solid #ccc;
  border-radius: 5%/10%;
`;
function emailCheck(email_address) {
  const email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
  if (!email_regex.test(email_address)) {
    return false;
  } else {
    return true;
  }
}
function getDayRange(type) {
  let result = null;
  if (type === "YEAR") {
    const endYear = parseInt(new Date().getFullYear());
    result = [...Array(100).keys()].map((key) => endYear - key);
  } else if (type === "MONTH") {
    result = [...Array(12).keys()].map((key) => key + 1);
  } else if (type === "DAY") {
    result = [...Array(31).keys()].map((key) => key + 1);
  }
  return result;
}
export default function FindPassword() {
  const navigate = useNavigate();
  const emailRef = useRef();
  const codeRef = useRef();
  const password1Ref = useRef();
  const password2Ref = useRef();
  const [isAuthCodeRequest, setIsAuthCodeRequest] = useState(false);
  const [emailAuthComplete, setEmailAuthComplete] = useState(false);
  async function onRequestCode() {
    //1. 코드를 이메일로 전달
    const inputEmail = emailRef.current.value.trim();
    if (!emailCheck(inputEmail)) {
      alert("이메일 형식이 올바르지 않습니다");
      return;
    }
    const requestData = {
      email: inputEmail,
    };

    try {
      const res = await axios.post(API.EMAIL_CODE_REQ, requestData, {
        headers: {
          "Content-type": "application/json",
        },
      });

      if (res.data.code === 201000) {
        setIsAuthCodeRequest((prev) => true);
        alert(`${inputEmail}으로 인증코드를 전송했습니다`);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function onRequestAuth() {
    const requestData = {
      email: emailRef.current.value.trim(),
      code: codeRef.current.value.trim(),
    };
    try {
      const res = await axios.post(API.EMAIL_AUTU, requestData);
      if (res.data.code === 200000) {
        alert("인증이 성공하였습니다");
        setEmailAuthComplete((prev) => true);
      } else {
      }
    } catch (error) {
      alert("인증이 실패했습니다. 코드를 확인하시거나 재요청해주세요");
    }

    //2. 받은 이메일 입력
  }
  async function reEnterPassword() {
    const inputEmail = emailRef.current.value.trim();
    const pwd1 = password1Ref.current.value.trim();
    const pwd2 = password2Ref.current.value.trim();

    if (pwd1 !== pwd2) {
      alert("두 비밀번호가 일치하지 않습니다");
      return;
    }

    try {
      const requestData = {
        email: inputEmail,
        password: pwd1,
      };
      const { data } = await axios.put(API.ACCOUNT_FIND_PWD, requestData);
      //setFindIDInfo((prev) => data);
      console.log("after change", data);
      if (data.code === 200000) {
        alert("비밀번호 변경이 완료되었습니다.");
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        maxWidth: "576px",
        margin: "0 auto",
        padding: "2rem",
        borderRadius: "2%/6%",
        boxShadow: "1px 1px 1px 1px #ccc, 2px 2px 2px 2px #eee",
      }}
    >
      <Box>비밀번호를 찾기 위해 이메일 인증이 필요합니다.</Box>
      <Box>
        <StyledButton
          style={{ width: "13rem", borderRadius: "0", padding: "0.5rem 1rem" }}
          onClick={onRequestCode}
        >
          이메일로 인증코드 요청
        </StyledButton>
      </Box>
      <Box>
        <TextField
          id="input_email"
          inputRef={emailRef}
          size="small"
          fullWidth
          sx={{ m: "0 0 0.5rem", width: "100%" }}
          variant="outlined"
          inputProps={{
            style: { fontSize: "1.2rem", height: "1.2rem" },
          }}
          placeholder="회원가입시 사용한 이메일"
        />
      </Box>
      {isAuthCodeRequest ? (
        <Box sx={{ margin: "1rem 0" }}>
          <Box>
            <p
              style={{
                color: "#056186",
                fontWeight: "600",
                fontSize: "1rem",
              }}
            >
              코드입력(시간이 다소 걸릴 수 있습니다)
            </p>
            <Box sx={{ display: "flex" }}>
              <TextField
                id="input_auth_code"
                inputRef={codeRef}
                size="small"
                fullWidth
                sx={{ m: "0 0 0.5rem", width: "100%" }}
                variant="outlined"
                inputProps={{
                  style: { fontSize: "1.2rem", height: "1.2rem" },
                }}
                placeholder="전달받은 인증코드 입력"
              />
              <Button
                variant="contained"
                disabled={!isAuthCodeRequest}
                color="primary"
                sx={{ width: "8rem", padding: "0.2rem", height: "2.2rem" }}
                onClick={onRequestAuth}
              >
                인증시도
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}
      {emailAuthComplete ? (
        <Box
          sx={{
            border: "1px solid #ccc",
            padding: "0.5rem",
            margin: "1rem 0 0 0",
            fontSize: "1.2rem",
          }}
        >
          <Box sx={{ margin: "0 0 0.5rem 0" }}>
            <p
              style={{
                color: "#000",
                fontWeight: "600",
                fontSize: "1.1rem",
                margin: "0 0 1rem 0",
              }}
            >
              비밀번호를 재설정해주세요
            </p>
            <Box>
              <LabelBox>새로운 비밀번호</LabelBox>
              <TextField
                id="input_new_password1"
                type="password"
                inputRef={password1Ref}
                size="small"
                fullWidth
                sx={{ m: "0 0 0.5rem", width: "100%" }}
                variant="outlined"
                inputProps={{
                  style: { fontSize: "1rem", height: "1rem" },
                }}
                placeholder="새로운 비밀번호"
              />
            </Box>
            <Box>
              <LabelBox>비밀번호 재입력</LabelBox>
              <TextField
                id="input_new_password2"
                type="password"
                inputRef={password2Ref}
                size="small"
                fullWidth
                sx={{ m: "0 0 0.5rem", width: "100%" }}
                variant="outlined"
                inputProps={{
                  style: { fontSize: "1rem", height: "1rem" },
                }}
                placeholder="비밀번호 재입력"
              />
            </Box>
          </Box>

          <Box>
            <Button
              variant="contained"
              disabled={!isAuthCodeRequest}
              color="primary"
              sx={{ width: "8rem", padding: "0.2rem", height: "2.2rem" }}
              onClick={reEnterPassword}
            >
              비밀번호재설정
            </Button>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
