import { Box } from "@mui/material";
import boxShadow from "../../assets/theme/functions/boxShadow";
import { API } from "../../config";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

export default function AgencyPlayerCard({ agencyPlayer }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        margin: "0 0 1.5rem",
        borderRadius: "15%/10%",
        flex: "100%",
        boxShadow: "3px 4px 5px 0px rgba(0, 0, 0, 0.38);",
        cursor: "pointer",
      }}
      onClick={() => {
        if (agencyPlayer.playerId) {
          navigate(`/player-detail/${agencyPlayer.playerId}`);
        }
      }}
    >
      <Box
        sx={{
          height: { xs: "200px", lg: "200px" },
          overflow: "hidden",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          borderTopLeftRadius: "15%",
          borderTopRightRadius: "15%",
          backgroundImage: `url(${agencyPlayer.profileImage ? `${API.BASE}/${agencyPlayer.profileImage.fileUrl}` : null})`,
          backgroundPosition: { xs: "center center", sm: "top center" },
          backgroundSize: "cover",
        }}
      >
        {/* <img
          src={agencyPlayer.imgUrl}
          style={{
            width: "200px",
            objectFit: "fill",
            display: "block",
            margin: "0 auto",
          }}
        ></img> */}
      </Box>
      <Box
        sx={{
          backgroundColor: "rgb(64,132,75)",
          alignItems: "center",
          color: "#fff",
          fontSize: "1.2rem",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            width: "100%",
          }}
        >
          {agencyPlayer.clubName ? agencyPlayer.clubName : null}
        </Box>
        <Box
          sx={{ textAlign: "center", width: "100%", fontWeight: "600" }}
        >{`NO.${agencyPlayer.kitNumber}`}</Box>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          padding: "0.7em",
          fontSize: "1.3rem",
          fontWeight: "900",
          letterSpacing: "0.4rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {agencyPlayer.name}
        {agencyPlayer.playerId ? (
          <FontAwesomeIcon
            icon={faHome}
            style={{
              fontSize: "0.7rem",
              color: "rgb(64,132,75)",
              border: "1px solid #d3f8d3",
              padding: "0.3rem",
              borderRadius: "50%",
              backgroundColor: "#e9fce9",
              margin: "0 0 0 -5px",
            }}
          ></FontAwesomeIcon>
        ) : null}
      </Box>
    </Box>
  );
}
