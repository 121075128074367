import { useState } from "react";

import { Box, useTheme, Grid, Drawer, Divider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../config";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { loginStatusAtom } from "../../recoil/atoms/loginStatus";
import { Button } from "@mui/base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClose,
  faEnvelope,
  faFax,
  faHouse,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { color } from "framer-motion";
const roleTypeMyPageURL = {
  ROLE_COACH: "/mypage/coaches/profile",
  ROLE_PLAYER: "/mypage/pro-players",
  ROLE_SCOUTER: "/mypage/scouters",
  ROLE_AGENT: "/mypage/agents/profile",
};

const StyledLink = styled(Link)`
  box-sizing: border-box;
  display: block;
  padding: 0.6em;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s;
  font-size: 1.1rem;
  font-weight: 600;
  color: ${(props) => (props.mode === "dark" ? "#fff" : "#000")};
  &:hover {
    color: rgb(84, 162, 105);
    transition: 0.3s;
  }
`;

const StyledLink2 = styled(Link)`
  box-sizing: border-box;
  display: block;
  text-align: ${(props) => props.textDirection};
  white-space: nowrap;
  overflow: hidden;
  color: ${(props) => (props.mode === "dark" ? "#fff" : "#000")};
  &:hover {
    color: rgb(84, 162, 105);
  }
`;

const MyLink = styled(Link)`
  box-sizing: border-box;
  display: block;
  padding: 0.6em;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s;
  color: ${(props) => (props.mode === "dark" ? "#fff" : "rgb(64,132,75)")};
  &:hover {
    color: rgb(84, 162, 105);
    font-weight: 600;
    transition: 0.3s;
  }
`;
const StyledButton = styled("button")`
  border: ${(props) => props.border};
  padding: 0.5rem 0;
  margin: 0 0.2rem;
  border-radius: 5%/10%;
  width: 6rem;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    cursor: pointer;
  }
`;
const ItemBox = styled(Box)`
  font-size: 1.1rem;
  padding: 0.3rem 0 0.3rem 2rem;
`;
export default function Header({ mode }) {
  const [langDropdown, setLangDropdown] = useState(false);
  const [loginInfo, setLoginInfo] = useRecoilState(loginStatusAtom);
  const navigate = useNavigate();
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <Box>
        <Grid
          container
          alignItems="center"
          justifyContent="space-around"
          sx={{ color: "#333", padding: "1rem" }}
        >
          <Grid item xs={6}>
            <StyledLink2 to="/main" textDirection="left">
              <FontAwesomeIcon
                icon={faHouse}
                textAlign="left"
                fontSize={"1.5rem"}
              ></FontAwesomeIcon>
            </StyledLink2>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <StyledLink2 textDirection="right">
              <FontAwesomeIcon
                icon={faClose}
                fontSize={"1.5rem"}
                onClick={toggleDrawer(false)}
                textAlign="right"
              ></FontAwesomeIcon>
            </StyledLink2>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {sessionStorage.getItem("name") ? (
          <Box>
            <Grid
              container
              padding="0 1rem"
              alignItems="center"
              fontSize="0.8rem"
            >
              <Grid
                item
                sx={{
                  borderRadius: "50%",
                  backgroundColor: "#eee",
                  width: "35px",
                  height: "35px",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faUser}
                  style={{
                    textAlign: "center",
                    fontSize: "2rem",
                  }}
                ></FontAwesomeIcon>
              </Grid>
              <Grid item fontSize="1rem">
                {sessionStorage.getItem("name")
                  ? `${sessionStorage.getItem("name")}님 반갑습니다`
                  : "로그인 후 이용"}
              </Grid>
            </Grid>
            <Grid
              container
              padding="1rem"
              alignItems="center"
              fontSize="0.8rem"
            >
              <Grid item>
                <StyledButton
                  color="#fff"
                  backgroundColor="rgb(64,132,75)"
                  border="none"
                >
                  <Link
                    style={{ color: "#fff" }}
                    to={roleTypeMyPageURL[sessionStorage.getItem("role")]}
                  >
                    마이페이지
                  </Link>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  border="1px solid #aaa"
                  backgroundColor="#fff"
                  onClick={logout}
                >
                  로그아웃
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box>
            <Grid
              container
              padding="0 1rem"
              alignItems="center"
              fontSize="0.8rem"
            >
              <Grid
                item
                sx={{
                  borderRadius: "50%",
                  backgroundColor: "#eee",
                  width: "35px",
                  height: "35px",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faUser}
                  style={{
                    textAlign: "center",
                    fontSize: "2rem",
                  }}
                ></FontAwesomeIcon>
              </Grid>
              <Grid item fontSize="1rem">
                로그인이 필요합니다.
              </Grid>
            </Grid>
            <Grid
              container
              padding="1rem"
              alignItems="center"
              fontSize="0.8rem"
            >
              <Grid item>
                <StyledButton
                  color="#fff"
                  backgroundColor="rgb(64,132,75)"
                  border="none"
                  onClick={() => navigate("/login")}
                >
                  로그인
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  border="1px solid #aaa"
                  backgroundColor="#fff"
                  onClick={() => navigate("/join")}
                >
                  회원가입
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Divider></Divider>
      <Box>
        <ItemBox>
          <StyledLink2 to={"/intro"}>INTRO</StyledLink2>
        </ItemBox>
        <ItemBox>
          <StyledLink2 to={"/players"}>PLAYER</StyledLink2>
        </ItemBox>
        <ItemBox>
          <StyledLink2 to={"/coaches"}>COACH</StyledLink2>
        </ItemBox>
        <ItemBox>
          <StyledLink2 to={"/teams"}>TEAM</StyledLink2>
        </ItemBox>
      </Box>
      <Divider sx={{ margin: "0.3rem 0" }}></Divider>
      <Box>
        <ItemBox>
          <StyledLink2 to={"/main"}>PLAYER HIGHLIGHT</StyledLink2>
        </ItemBox>
        <ItemBox>
          <StyledLink2 to={"/main"}>COACH HIGHLIGHT</StyledLink2>
        </ItemBox>
        <ItemBox>
          <StyledLink2 to={"/main"}>TEAM HIGHLIGHT</StyledLink2>
        </ItemBox>
      </Box>
      <Divider sx={{ margin: "0.3rem 0" }}></Divider>
      <ItemBox>
        <p style={{ fontWeight: "600", color: "rgb(64,132,75)" }} to={"/main"}>
          Contact
        </p>
        <Box>
          <FontAwesomeIcon
            icon={faPhone}
            style={{ marginRight: "0.5rem" }}
          ></FontAwesomeIcon>
          0507-1431-7250
        </Box>

        <Box>
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ marginRight: "0.5rem" }}
          ></FontAwesomeIcon>
          canda.lys76@gmail.com
        </Box>
        <Box>
          <FontAwesomeIcon
            icon={faFax}
            style={{ marginRight: "0.5rem" }}
          ></FontAwesomeIcon>
          0504-467-4939
        </Box>
      </ItemBox>
    </Box>
  );
  function onLangDropClick(e) {
    setLangDropdown((prev) => !prev);
  }
  async function logout() {
    try {
      const res = await axios.post(API.LOGOUT);
      sessionStorage.clear();
      setLoginInfo({});
      alert("로그아웃되었습니다.");
      navigate("/main");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Box
      borderBottom={mode === "dark" ? "none" : "1px solid #ccc"}
      direction={{
        xs: "column",
        md: "row",
      }}
    >
      <Grid container spacing={0} alignItems="center" height="100%">
        <Grid xs={12} item md={2}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={4}
              sx={{ paddingLeft: "1rem", display: { xs: "block", md: "none" } }}
            >
              <StyledLink2>
                <FontAwesomeIcon
                  icon={faBars}
                  style={{
                    backgroundColor: "none",
                    color: mode === "dark" ? "#fff" : "#555",
                    fontSize: "2rem",
                  }}
                  onClick={toggleDrawer(true)}
                ></FontAwesomeIcon>
              </StyledLink2>

              <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
              </Drawer>
            </Grid>
            <Grid item xs={8}>
              <Link to="/main">
                <img
                  src="https://canda23.com/files/header_logo.png"
                  style={{ height: "2.5rem" }}
                  alt="logo"
                ></img>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={0} alignItems="center">
            <Grid item sm={12} md={2}>
              <StyledLink mode={mode} to="/intro">
                CANDA
              </StyledLink>
            </Grid>
            <Grid item sm={12} md={2}>
              <StyledLink mode={mode} to="/players">
                PLAYER
              </StyledLink>
            </Grid>
            <Grid item sm={12} md={2}>
              <StyledLink mode={mode} to="/coaches">
                COACH
              </StyledLink>
            </Grid>
            <Grid item sm={12} md={2}>
              <StyledLink mode={mode} to="/teams">
                TEAM
              </StyledLink>
            </Grid>
            <Grid item sm={12} md={3}>
              <StyledLink mode={mode} to="/scouters">
                SCOUTER/AGENCY
              </StyledLink>
            </Grid>
            {/* <Grid item sm={12} md={1.7}>
              <StyledLink mode={mode} to="/intro">
                구인/구직
              </StyledLink>
            </Grid> */}
            {/* <Grid item sm={12} md={1.7}>
              <StyledLink mode={mode} to="/intro">
                커뮤니티
              </StyledLink>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          display={{
            xs: "none",
            sm: "block",
          }}
        >
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="right"
          >
            {sessionStorage.getItem("name") ? ( //로그인 상태라면
              <Grid container justifyContent="end">
                {/* <Grid item sm={6} md={4}>
                  <StyledLink mode={mode}>
                    {sessionStorage.getItem("name") + "님"}
                  </StyledLink>
                </Grid> */}
                <Grid item sm={6} md={7}>
                  <MyLink
                    mode={mode}
                    to={roleTypeMyPageURL[sessionStorage.getItem("role")]}
                  >
                    마이페이지
                  </MyLink>
                </Grid>
                <Grid item sm={6} md={5} onClick={logout}>
                  {sessionStorage.getItem("name") ? (
                    <MyLink mode={mode}>logout</MyLink>
                  ) : null}
                </Grid>
              </Grid>
            ) : (
              //로그인 상태가 아니라면
              <Grid container justifyContent="end">
                <Grid item sm={6} md={7}>
                  <StyledLink mode={mode} to="/login">
                    Login
                  </StyledLink>
                </Grid>
                <Grid item sm={6} md={5}>
                  <StyledLink mode={mode} to="/join">
                    Join
                  </StyledLink>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
