const BASE_API_URL = process.env.REACT_APP_API_URL;

export const API = {
  FILE_UPLOAD_MAX_SIZE: 5000000,
  BASE: process.env.REACT_APP_BASE_URL,
  API_BASE: process.env.REACT_APP_API_URL,
  FILE_BASE: process.env.REACT_APP_FILE_BASE_URL,

  APPLICATION_ENUM: `${BASE_API_URL}/soccer-properties`,
  RECENT_PLAYER: `${BASE_API_URL}/pro-players/recent`,

  LOGIN: `${BASE_API_URL}/login/canda23`,
  LOGIN_STATUS: `${BASE_API_URL}/accounts/my-info`,
  JOIN_PLAYER: `${BASE_API_URL}/accounts/pro-players`,
  JOIN_COACH: `${BASE_API_URL}/accounts/coaches`,
  JOIN_SCOUTER: `${BASE_API_URL}/accounts/scouters`,
  JOIN_AGENT: `${BASE_API_URL}/accounts/agents`,
  REGISTER_ID_REDUNDANCY_CHECK: `${BASE_API_URL}/auth/loginId`,
  EMAIL_CODE_REQ: `${BASE_API_URL}/auth/email`,
  EMAIL_AUTU: `${BASE_API_URL}/auth/email/valid`,

  ACCOUNT_FIND_ID: `${BASE_API_URL}/accounts/loginId/find`,
  ACCOUNT_FIND_PWD: `${BASE_API_URL}/accounts/password`,

  JOIN_KAKAO: `${BASE_API_URL}/oauth2/authorization/kakao?`,
  JOIN_GOOGLE: `${BASE_API_URL}/oauth2/authorization/google?`,
  JOIN_NAVER: `${BASE_API_URL}/oauth2/authorization/naver?`,

  PLAYER_SEARCH: `${BASE_API_URL}/pro-players/search`,
  PLAYER_DETAIL: `${BASE_API_URL}/pro-players`,

  COACHES: `${BASE_API_URL}/coaches`,
  COACHES_SEARCH: `${BASE_API_URL}/coaches/search`,
  COACHE_DETAIL: `${BASE_API_URL}/coaches`,
  RECENT_COACH: `${BASE_API_URL}/coaches/recent`,

  TEAM_SEARCH: `${BASE_API_URL}/pro-clubs/search`,
  TEAM_DETAIL: `${BASE_API_URL}/pro-clubs`,

  /******************mypage*************************/
  //common
  MP_CONTACT: `${BASE_API_URL}/mypage/contacts`,
  //coach
  MP_COACHE: `${BASE_API_URL}/mypage/coaches`,
  MP_COACHE_INFO: `${BASE_API_URL}/mypage/coaches/common`,
  MP_COACHE_CURRENT_TEAM: `${BASE_API_URL}/mypage/coaches/affiliation`,
  MP_COACHE_PHILOSOPHY: `${BASE_API_URL}/mypage/coaches/philosophy`,
  MP_COACH_PLAYER_HISTORY: `${BASE_API_URL}/mypage/coaches/player-histories`,
  MP_COACH_COACH_HISTORY: `${BASE_API_URL}/mypage/coaches/coach-histories`,
  MP_COACH_AWARDS_PERSONAL: `${BASE_API_URL}/mypage/coaches/awards/personal`,
  MP_COACH_AWARDS_TEAM: `${BASE_API_URL}/mypage/coaches/awards/team`,
  MP_COACH_CERT: `${BASE_API_URL}/mypage/coaches/certifications`,
  MP_COACH_VIDEO: `${BASE_API_URL}/mypage/coaches/videos`,
  MP_COACH_POSITION_PREFER: `${BASE_API_URL}/mypage/coaches/styles`,
  //coach team manage
  MP_COACH_TEAM_ACTIVATE: `${BASE_API_URL}/pro-clubs`,
  MP_COACH_MY_MANAGED_TEAM: `${BASE_API_URL}/mypage/pro-clubs`,
  MP_COACH_MY_TEAM_DETAIL: `${BASE_API_URL}/mypage/pro-clubs`,
  MP_COACH_TEAM_ACTIVATE_HISTORY: `${BASE_API_URL}/mypage/pro-clubs/register-histories`,
  MP_COACH_SNS: `${BASE_API_URL}/mypage/coaches/sns-infos`,
  //player
  MP_PLAYER: `${BASE_API_URL}/mypage/pro-players`, //선수 페이지 조회
  MP_PLAYER_INFO: `${BASE_API_URL}/mypage/pro-players/common`,
  MP_PLAYER_AGENT: `${BASE_API_URL}/mypage/pro-players/agents`,
  MP_PLAYER_CURRENT_TEAM: `${BASE_API_URL}/mypage/pro-players/affiliation`,
  MP_PLAYER_PLAYER_HISTORY: `${BASE_API_URL}/mypage/pro-players/player-histories`, //선수의 선수 경력
  MP_PLAYER_AWARDS_PERSONAL: `${BASE_API_URL}/mypage/pro-players/awards/personal`, //선수의 선수 경력
  MP_PLAYER_AWARDS_TEAM: `${BASE_API_URL}/mypage/pro-players/awards/team`, //선수의 선수 경력
  MP_PLAYER_SNS: `${BASE_API_URL}/mypage/pro-players/sns-infos`, //선수의 SNS
  MP_PLAYER_VIDEO: `${BASE_API_URL}/mypage/pro-players/videos`, //선수의 하이라이트영상

  LOGOUT: `${BASE_API_URL}/logout`,

  //scouter
  MP_SCOUTER: `${BASE_API_URL}/mypage/scouters`,
  MP_SCOUTER_COMMON: `${BASE_API_URL}/mypage/scouters/common`,
  SCOUTER_LIST: `${BASE_API_URL}/scouters`,

  //agent
  MP_AGENT: `${BASE_API_URL}/mypage/agents`,
  MP_AGENT_COMMON: `${BASE_API_URL}/mypage/agents/common`,
  MP_AGENT_AGENCY_SEARCH: `${BASE_API_URL}/agencies`,

  //agency
  AGENCY_LIST: `${BASE_API_URL}/agencies`,
  MP_AGENCY: `${BASE_API_URL}/agencies`,
  MP_MYAGENCY: `${BASE_API_URL}/mypage/agencies`,
  MP_AGENCY_COMMON: `${BASE_API_URL}/mypage/agencies/common`,
  MP_AGENCY_STAFF: `${BASE_API_URL}/mypage/agencies/staffs`,
  MP_AGENCY_PLAYER: `${BASE_API_URL}/mypage/agencies/players`,
  //mypage_common
  SEARCH_TEAM_BY_NAME: `${BASE_API_URL}/pro-clubs/search`,
  RolyType: {
    COACH: "coaches",
    PLAYER: "pro-players",
    TEAM: "teams",
  },
  ComType: {
    COACH_HISOTRY: "coach_history",
    COACH_AWARDS_PERSONAL: "coach_awards_personal",
    COACH_AWARDS_TEAM: "coach_awards_team",
    COACH_SNS: "coach_sns",
    COACH_CERT: "coach_certification",
    COACH_PROFILE: "coach_profile",
    COACH_VIDEO: "coach_video",
    COACH_INTRO_IMG: "coach_intro_image",
    PLAYER_VIDEO: "player_video",
    PLAYER_AWARDS_PERSONAL: "player_awards_personal",
    PLAYER_AWARDS_TEAM: "player_awards_team",
    PLAYER_HISOTRY: "player_history",
    PLAYER_SNS: "player_sns",
    PLAYER_PROFILE: "PLAYER_PROFILE",
    TEAM_PROFILE: "team-videos",
    TEAM_VIDEO: "pro-clubs-videos",
    TEAM_COACH: "pro-clubs-coaches",
    TEAM_PLAYER: "pro-clubs-pro-players",
    TEAM_SNS: "team_sns",
    MAIN_RECENT_PLAYER: "recent_player",
    MAIN_RECENT_COACH: "recent_coach",
  },
  SelectBoxType: {
    SINGLE_UPDATE: "single_update",
    MULTIPLE_UPDATE: "multi_update",
  },
  TeamType: {
    STUDENT: "student",
    PRO: "pro",
  },
};
