import styled from "styled-components";
import GetPositionText from "../mypage/common/GetPositionText";
import { Box, Pagination, Stack } from "@mui/material";
import { useNavigate } from "react-router";
const mainFoot = {
  RIGHT: "오른발",
  LEFT: "왼발",
  BOTH: "양발",
};
const StyledTable = styled.table`
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  caption-side: bottom;
  max-height: 100px;
  td,
  th {
    border: none;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 0.7rem 10px;
    text-align: center;
  }

  tbody > tr {
    border-bottom: 1px solid #bbb;
    :nth-of-type(odd) {
    }
    &:hover {
      background-color: #eee;
    }
  }
  thead > tr {
    background-color: rgb(64, 132, 75);
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
  }
  th {
    padding: 10px 0;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;
export default function TeamPlayerListTable({ hHeaders, data, setTeamBack }) {
  const navigate = useNavigate();
  const handleRowClick = (playerId) => {
    setTeamBack((prev) => ({
      isFirstLoad: false,
      scrollY: window.scrollY,
    }));
    if (playerId) {
      navigate(`/player-detail/${playerId}`);
    }
  };
  return (
    <Box>
      <StyledTable>
        <thead>
          <tr>
            {hHeaders.map((h, hindex) =>
              h === "생년월일" ? (
                window.innerHeight > 600 ? null : (
                  <th key={hindex}>{h}</th>
                )
              ) : (
                <th key={hindex}>{h}</th>
              )
            )}
          </tr>
        </thead>
        {data ? (
          <tbody>
            {data.map((row, rindex) => (
              <tr
                key={rindex}
                onClick={() =>
                  handleRowClick(row.member ? row.member.id : null)
                }
                style={{ cursor: row.member.id ? "pointer" : "none" }}
              >
                <td style={{ fontSize: "1.1rem" }}>{row.kitNumber}</td>
                <td style={{ fontSize: "1.1rem" }}>{row.member.name}</td>
                <td style={{ fontSize: "1.1rem" }}>{row.schoolYear}</td>
                <td style={{ fontSize: "1.1rem" }}>{row.member.height}</td>
                <td style={{ fontSize: "1.1rem" }}>{row.position}</td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </StyledTable>
      {/* <Stack spacing={2} sx={{ margin: "1.5rem 4rem" }} alignItems="center">
        <Pagination
          shape="circular"
          size="large"
          count={3}
          onChange={onPageChange}
          defaultPage={1}
          page={1}
          boundaryCount={4}
          color="info"
        />
      </Stack> */}
    </Box>
  );
}
