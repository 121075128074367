import SNSItem from "./SNSItem";

export default function MPStaffManageTeamSNS({
  roleType,
  comType,
  SNS,
  setSNS,
}) {
  return (
    <div>
      {SNS.map((item) => (
        <SNSItem
          roleType={roleType}
          comType={comType}
          SNS={SNS}
          setSNS={setSNS}
          currentRow={item}
        ></SNSItem>
      ))}
    </div>
  );
}
