import { Box, Button, TextField } from "@mui/material";
import { API } from "../../config";
import axios from "axios";
import { useRef, useState } from "react";
import styled from "styled-components";
import { StyledLabel } from "../mypage/common/MyPageComponents";
import heic2any from "heic2any";
import Loading from "../mypage/common/Loading";

const LabelP = styled("p")`
  display: inline-block;
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
  width: 4rem;
`;

export default function MPImageItem({ images, setImages, currentRow }) {
  const img_ref = useRef(null);
  const title_ref = useRef(null);
  const [loading, setLoading] = useState(false);
  async function changeImg(event) {
    event.preventDefault();
    const formData = new FormData();

    try {
      let uploadFile = event.target.files[0];

      if (uploadFile.name.split(".")[1] === "heic") {
        setLoading((prev) => true);
        let blob = uploadFile;
        const resultBlob = await heic2any({ blob: blob, toType: "image/jpeg" });
        uploadFile = new File(
          [resultBlob],
          uploadFile.name.split(".")[0] + ".jpg",
          {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          }
        );
        setLoading((prev) => false);
      }
      if (uploadFile.size > API.FILE_UPLOAD_MAX_SIZE) {
        alert(
          `변환된 파일크기가 제한 용량(5MB)를 초과합니다\n 파일크기:${Number(uploadFile.size / 1000000).toFixed(2)}MB`
        );
        return;
      }
      formData.append("file", uploadFile);
      var {
        data: { data },
      } = await axios.post(`${API.FILE_BASE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const newObj = { ...currentRow, imageFile: data };

      setImages((prev) =>
        prev.map((item) => (item.seq === currentRow.seq ? newObj : item))
      );

      const imageInfo = {
        imageFileId: data.id,
        title: currentRow.title,
      };
      if (!currentRow.imageFile) {
        //추가라면
        var {
          data: { data },
        } = await axios.post(
          `${API.MP_COACH_MY_MANAGED_TEAM}/${currentRow.teamId}/images`,
          imageInfo
        );
        alert("사진을 등록했습니다.");
        setImages((prev) =>
          data.map((item, index) => ({
            ...item,
            seq: index,
            isFromServer: true,
            isEditState: false,
            teamId: currentRow.teamId,
          }))
        );
      } else {
        //수정이라면
        var {
          data: { data },
        } = await axios.put(
          `${API.MP_COACH_MY_MANAGED_TEAM}/${currentRow.teamId}/images/${currentRow.id}`,
          imageInfo
        );
        alert("사진을 수정했습니다.");
        setImages((prev) =>
          data.map((item, index) => ({
            ...item,
            seq: index,
            isFromServer: true,
            isEditState: false,
            teamId: currentRow.teamId,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }
  function onImageTitleInput(event) {
    const newText = event.target.value;
    console.log(currentRow);
    if (!currentRow.imageFile) {
      alert("사진을 먼저 등록해주세요");
      title_ref.current.value = "";
      return;
    } else {
      setImages((prev) =>
        prev.map((item) =>
          item.seq === currentRow.seq ? { ...item, title: newText } : item
        )
      );
    }
  }
  async function onImageTitleEdit() {
    const teamId = currentRow.teamId;
    const requestData = {
      imageFileId: currentRow.imageFile.id,
      title: currentRow.title,
    };
    if (currentRow.isEditState) {
      const {
        data: { data },
      } = await axios.put(
        `${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/images/${currentRow.id}`,
        requestData
      );
      alert("사진이름을 등록했습니다.");
      setImages((prev) =>
        data.map((item, index) => ({
          ...item,
          seq: index,
          isFromServer: true,
          isEditState: false,
          teamId: currentRow.teamId,
        }))
      );
    } else {
      setImages((prev) =>
        prev.map((item) =>
          item.seq === currentRow.seq ? { ...item, isEditState: true } : item
        )
      );
    }
  }
  async function removeBtnClick() {
    if (window.confirm("사진과 이름을 모두 삭제하시겠습니까?")) {
      if (currentRow.isFromServer) {
        const teamId = currentRow.teamId;
        console.log(11, currentRow);
        try {
          const {
            data: { data },
          } = await axios.delete(
            `${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/images/${currentRow.id}`
          );
          alert("해당 내용을 삭제했습니다.");
          setImages((prev) =>
            data.map((item, index) => ({
              ...item,
              seq: index,
              isFromServer: true,
              isEditState: false,
              teamId: teamId,
            }))
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        setImages((prev) => prev.filter((item) => item.seq != currentRow.seq));
      }
    }
  }
  return (
    <Box
      sx={{
        alignItems: {
          xs: "left",
          md: "center",
        },
        flexDirection: {
          xs: "column",
          md: "row",
        },
        justifyContent: "flex-start",
        border: "1px solid rgb(64,132,75)",
        padding: {
          xs: "0.5rem",
          md: "none",
        },
        margin: {
          xs: "0.5rem",
          md: "none",
        },
        borderRadius: "1%/5%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <LabelP>이름</LabelP>
        <TextField
          id="team-picture-title"
          inputRef={title_ref}
          disabled={!currentRow.isEditState}
          onChange={onImageTitleInput}
          value={currentRow.title}
          size="small"
          variant="outlined"
          sx={{ width: { xs: "95%", md: "50%" } }}
          inputProps={{ style: { fontSize: "1.1rem" } }}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box>
          <Box
            sx={{
              width: { xs: "12rem", md: "13rem" },
              height: { xs: "6rem", md: "7rem" },
              backgroundColor: "#aaa",
              overflow: "hidden",
              margin: "0 0.2rem;",
              position: "relative",
            }}
          >
            {currentRow.imageFile ? (
              <img
                src={`${API.BASE}${currentRow.imageFile.fileUrl}`}
                alt="img"
                style={{ width: "100%", objectFit: "contain" }}
              ></img>
            ) : (
              <div className="image-box"> No Image</div>
            )}
            <Loading loading={loading}></Loading>
          </Box>
          <Box className="file-upload-box" sx={{ textAlign: "left" }}>
            <StyledLabel width="95%" sx={{ display: "inline-block" }}>
              {currentRow.imageFile ? "사진수정" : "사진추가"}
              <input
                type="file"
                className="file"
                ref={img_ref}
                accept="image/*,image/heic"
                style={{ display: "none" }}
                onChange={changeImg}
              />
            </StyledLabel>
          </Box>
        </Box>
        <Box>
          <Box>
            <Button
              value={currentRow.seq}
              onClick={onImageTitleEdit} //onCreateOrUpdate
              variant="contained"
              sx={{
                backgroundColor: currentRow.isEditState
                  ? "info.main"
                  : "success.main",
                ":hover": {
                  backgroundColor: currentRow.isEditState
                    ? "info.main"
                    : "success.main",
                  color: "yellow",
                },
              }}
            >
              {currentRow.isEditState ? "저장" : "수정"}
            </Button>
            <Button
              disabled={!currentRow.isEditState}
              value={currentRow.seq}
              onClick={removeBtnClick} //removeBtnClick
              variant="contained"
              sx={{
                backgroundColor: "#9D0000",
                ":hover": {
                  bgcolor: "#9D0000",
                  color: "yellow",
                },
              }}
            >
              삭제
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
