import MPImageItem from "./MPImageItem";

export default function MPImageList({ comType, roleType, images, setImages }) {
  return (
    <div>
      {images.map((item) => (
        <MPImageItem
          comType={comType}
          roleType={roleType}
          images={images}
          setImages={setImages}
          currentRow={item}
        ></MPImageItem>
      ))}
    </div>
  );
}
