import MainLayout from "../../LayoutContainers/MainLayout";
import RowBox from "../../LayoutContainers/RowBox";
import Intro from "../../components/main/intro/Intro";

export default function IntroLayout() {
  return (
    <MainLayout mode="dark">
      <RowBox mode="dark" bgColor="landingBgDark.light" padding={"0 0 6rem 0"}>
        <Intro></Intro>
      </RowBox>
    </MainLayout>
  );
}
