import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import MPStaffManagePersonList from "./MPStaffManagePersonList";
import MPStaffManagePersonAdd from "./MPStaffManagePersonAdd";
import axios from "axios";
import { API } from "../../../../../config";
import { Box } from "@mui/material";
import { TitleBox } from "../../../common/MyPageComponents";
const ItemBox = styled(Box)`
  background-color: #fff;
  border: 1px solid rgb(64, 132, 75);
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 0.5rem/0.5rem;
`;
export default function MPStaffManageStaff({ currentTeam }) {
  const [coachList, setCoachList] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    status: "ALL",
    position: "ALL",
  });
  const [filteredCoach, setFilteredCoach] = useState();
  function filterCoachList(currentStatus, currentPosition) {
    const status = currentStatus ? currentStatus : filterConditions.status;
    const position = currentPosition
      ? currentPosition
      : filterConditions.position;
    let memberFiltering =
      status === "ALL"
        ? [...coachList]
        : coachList.filter((item) => item.status === status);
    memberFiltering =
      position === "ALL"
        ? [...memberFiltering]
        : memberFiltering.filter((item) => item.position === position);
    console.log("filtering result", memberFiltering);
    setFilteredCoach(memberFiltering);
  }

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { data },
      } = await axios.get(
        `${API.MP_COACH_MY_MANAGED_TEAM}/${currentTeam.id}/coaches`
      );
      console.log("cocah data", data);
      setCoachList((prev) => data);
      setFilteredCoach((prev) => data);
    };
    fetchData();
  }, []);
  return (
    <Box>
      <ItemBox>
        <p className="section-title">지도자현황</p>
        {filteredCoach ? (
          <MPStaffManagePersonList
            comType={API.ComType.TEAM_COACH}
            roleType={API.RolyType.COACH}
            currentTeam={currentTeam}
            members={coachList}
            setMembers={setCoachList}
            filterConditions={filterConditions}
            setFilterConditions={setFilterConditions}
            filteredMember={filteredCoach}
            setFilterMemberAuto={filterCoachList}
            setFilterMemberManually={setFilteredCoach}
          ></MPStaffManagePersonList>
        ) : null}
      </ItemBox>
      <ItemBox>
        <TitleBox>신규등록</TitleBox>
        <MPStaffManagePersonAdd
          comType={API.ComType.TEAM_COACH}
          roleType={API.RolyType.COACH}
          currentTeam={currentTeam}
          members={coachList}
          setMembers={setCoachList}
          filterConditions={filterConditions}
          setFilterConditions={setFilterConditions}
          filteredMember={filteredCoach}
          setFilterMemberAuto={filterCoachList}
          setFilterMemberManually={setFilteredCoach}
        ></MPStaffManagePersonAdd>
      </ItemBox>
    </Box>
  );
}
