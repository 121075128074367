import { useTheme } from "@emotion/react";
import { faAngleRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../style/myswiper.css";
import "../../style/modal.css";
import MainVideoCard from "./MainVideoCard";
import { useState } from "react";
import VideoModal from "../modal/VideoModal";

export default function MainMovieList({
  videoInfos,
  title,
  titleColor,
  btnClassName,
}) {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const [movieIndex, setMovieIndex] = useState(null);
  //const videoRef = useRef();

  return (
    <Box sx={{ borderBottom: "1px solid #555", width: "100%" }}>
      <Box marginBottom={2}>
        <Link>
          <Typography
            variant="mainRowTitle"
            sx={{ fontSize: { xs: "1.3rem", md: "1.5rem" } }}
          >
            {title}
          </Typography>
          <Typography
            variant="mainRowTitle"
            sx={{
              borderRadius: "10%",
              display: "inline-block",
              transition: "0.5s",
              "&:hover": {
                transform: "translateX(10px)",
              },
            }}
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              color={titleColor}
              width="2rem"
            />
          </Typography>
        </Link>
      </Box>
      <Box sx={{ position: "relative" }}>
        <i
          className={`swiper-button-next-i ${btnClassName}-swiper-button-next`}
        >
          &gt;
        </i>
        <i
          className={`swiper-button-prev-i ${btnClassName}-swiper-button-prev`}
        >
          &lt;
        </i>
        <Swiper
          spaceBetween={10}
          breakpoints={{
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
            1920: {
              slidesPerView: 4,
            },
          }}
          navigation={{
            nextEl: `.${btnClassName}-swiper-button-next`,
            prevEl: `.${btnClassName}-swiper-button-prev`,
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {videoInfos.map((info, index) => (
            <SwiperSlide key={index}>
              <Box
                onClick={() => {
                  setMovieIndex((prev) => parseInt(index));
                  setModalOpen(true);
                }}
                display={{
                  xs: "block",
                  md: "block",
                  lg: "block",
                  xl: "block",
                  xxl: "block",
                }}
                sx={{ width: "100%" }}
              >
                <Box sx={{ width: "100%" }}>
                  <MainVideoCard mode="dark" videoInfo={info}></MainVideoCard>
                </Box>
              </Box>
              {/* <Box
                display={{
                  xs: "block",
                  md: "none",
                  lg: "none",
                  xl: "none",
                  xxl: "none",
                }}
              >
                <MainVideoCardPlayer
                  mode="dark"
                  videoInfo={info}
                ></MainVideoCardPlayer>
              </Box> */}
            </SwiperSlide>
          ))}
        </Swiper>
        {modalOpen && (
          <VideoModal
            setModalOpen={setModalOpen}
            videoURL={videoInfos[movieIndex].url}
          ></VideoModal>
        )}
      </Box>
    </Box>
  );
}
