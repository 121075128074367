import { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API } from "../../../config";
import styled from "styled-components";
import { Box, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faRightToBracket } from "@fortawesome/free-solid-svg-icons";

const Input = styled("input")`
  width: 100%;
  height: 30px;
  border: none;
  outline: 1px solid #ccc;
  border-radius: 5px;
  padding: 1em 0.5em;
  id: ${(props) => props.id};
  font-size: 1rem;
  &:focus {
    outline: 2px solid rgb(64, 132, 75);
  }
`;
const Label = styled("p")`
  width: 100%;
  padding: 0;
  font-size: 1rem;
  &:before {
    display: inline-block;
    content: "*";
    color: red;
  }
`;
const LoginBtn = styled("button")`
  width: 100%;
  border: none;
  background-color: rgb(64, 132, 75);
  padding: 0.5em;
  border-radius: 7px/6px;
  font-size: 1rem;
  color: #fff;
  &:hover {
    background-color: rgb(44, 92, 55);
  }
`;
const TypeBtn = styled(Box)`
  border: 1px solid rgb(64, 132, 75);
  background-color: ${(props) =>
    props.selected ? "rgb(64, 132, 75)" : "#fff"};
  color: ${(props) => (props.selected ? "#fff" : "rgb(64, 132, 75)")};
  border-radius: 7px/6px;
  font-size: 1.1rem;
  margin: 0.5rem 1rem 0 0.5rem;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: rgb(64, 132, 75);
    color: #fff;
  }
`;
const DaySelect = styled("select")`
  width: 4rem;
  font-size: 1rem;
  color: #444;
  border: 1px solid #ccc;
  border-radius: 5%/10%;
`;
const JoinA = styled("a")`
  &:visited {
    color: #000;
  }
`;
const rolyType = [
  {
    type: "player",
    description: "PLAYER",
  },
  { type: "coach", description: "COACH" },
  { type: "parent", description: "PARENT" },
  { type: "scouter", description: "SCOUTER" },
  { type: "agent", description: "AGENT" },
];

const ItemBox = styled("div")`
  margin-bottom: 0.5rem;
`;
const StyledButton = styled("button")`
  border: 1px solid #bbb;
  border-radius: 3%/10%;
  font-size: 1rem;
  padding: 0.4rem 1rem;
  cursor: pointer;
`;
export default function PlayerRegister({ role }) {
  const [selectedRole, setSelectedRole] = useState(null);
  const [normalJoin, setNomalJoin] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef([]);
  const [open, setOpen] = useState(false);
  const [agree, setAgree] = useState(false);
  const [emailAuthRequested, setEmailAuthRequested] = useState(false);
  const [emailAuthComplete, setEmailAuthComplete] = useState(false);
  const [idRedundancyCheck, setIdRedundancyCheck] = useState(false);

  async function onCheckIdRedudancy() {
    const inputId = inputRef.current[0].value.trim();
    if (inputId.length < 8) {
      alert("아이디는 최소 8자리입니다");
      return false;
    }
    try {
      const { data } = await axios.post(API.REGISTER_ID_REDUNDANCY_CHECK, {
        loginId: inputId,
      });
      if (data.data.isDuplicated) {
        alert("이미 사용중입니다");
        return;
      } else {
        setIdRedundancyCheck((prev) => true);
      }
    } catch (error) {}
  }
  function onChange() {
    setAgree((prev) => !prev);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event) => {
    const dialogResult = event.target.getAttribute("value");
    if (dialogResult === "move") {
      //로그인 페이지로 이동
      navigate("/login");
    } else {
      //메인페이지로 이동
      navigate("/main");
    }
    setOpen(false);
  };
  function validation() {
    const inputId = inputRef.current[0].value.trim();
    const inputPwd1 = inputRef.current[1].value.trim();
    const inputPwd2 = inputRef.current[2].value.trim();
    const inputName = inputRef.current[3].value.trim();
    const inputPhone = inputRef.current[4].value.trim();
    const inputEmail = inputRef.current[5].value.trim();
    const inputBirthYear = inputRef.current[6].value.trim();
    const inputBirthMonth = inputRef.current[7].value.trim();
    const inputBirthDay = inputRef.current[8].value.trim();
    const inputNationality = inputRef.current[9].value.trim();
    let result = /^(01[016789]{1})-?[0-9]{4}-?[0-9]{4}$/;

    if (inputId.length < 8) {
      alert("아이디는 최소 8자리입니다");
      return false;
    }
    if (inputPwd1.length < 8) {
      alert("비밀번호는 최소 8자리입니다");
      return false;
    }
    if (inputPwd1 !== inputPwd2) {
      alert("비밀번호가 일치하지 않습니다");
      return false;
    }
    if (inputName === "") {
      alert("이름을 입력하세요");
      return false;
    }
    if (!result.test(inputPhone)) {
      alert("전화번호 형식이 올바르지 않습니다.");
      return false;
    }
    if (inputEmail === "") {
      alert("이메일을 올바르게 입력하세요");
      return false;
    }
    if (inputNationality === "") {
      alert("국가를 입력해주세요");
      return false;
    }
    if (!agree) {
      alert("이용약관에 동의해주세요");
      return false;
    }
    return {
      loginId: inputId,
      password: inputPwd1,
      name: inputName,
      birthDate: `${inputBirthYear}-${inputBirthMonth.length === 1 ? `0${inputBirthMonth}` : inputBirthMonth}-${inputBirthDay.length === 1 ? `0${inputBirthDay}` : inputBirthDay}`,
      phoneNumber: inputPhone,
      nationality: inputNationality,
      email: inputEmail,
    };
  }

  function phoneNumberChange() {
    inputRef.current[4].value = inputRef.current[4].value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
  async function onRequestCode() {
    //1. 코드를 이메일로 전달
    if (validation()) {
      const requestData = {
        email: inputRef.current[5].value.trim(),
      };

      try {
        const res = await axios.post(API.EMAIL_CODE_REQ, requestData, {
          headers: {
            "Content-type": "application/json",
          },
        });

        if (res.data.code === 201000) {
          setEmailAuthRequested((prev) => true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  async function onRequestAuth() {
    const requestData = {
      email: inputRef.current[5].value.trim(),
      code: inputRef.current[10].value.trim(),
    };
    try {
      const res = await axios.post(API.EMAIL_AUTU, requestData);
      if (res.data.code === 200000) {
        setEmailAuthComplete((prev) => true);
        alert("인증이 성공하였습니다");
      } else {
      }
    } catch (error) {
      alert("인증이 실패했습니다. 코드를 확인하시거나 재요청해주세요");
    }
    //2. 받은 이메일 입력
  }
  function joinRequestUrlResolver(role) {
    console.log("joinRequestUrlResolver", role);
    if (role === "player") {
      return API.JOIN_PLAYER;
    } else if (role === "coach") {
      return API.JOIN_COACH;
    } else if (role === "scouter") {
      return API.JOIN_SCOUTER;
    } else if (role === "agent") {
      return API.JOIN_AGENT;
    }
  }
  async function onRequestJoin() {
    const loginData = validation();
    const requestUrl = joinRequestUrlResolver(selectedRole);
    console.log(requestUrl);
    if (loginData) {
      try {
        const res = await axios.post(requestUrl, loginData);
        setOpen(true);
      } catch (error) {
        alert(error.response.data.message);
      }
    }
  }

  function getDayRange(type) {
    let result = null;
    if (type === "YEAR") {
      const endYear = parseInt(new Date().getFullYear());
      result = [...Array(100).keys()].map((key) => endYear - key);
    } else if (type === "MONTH") {
      result = [...Array(12).keys()].map((key) => key + 1);
    } else if (type === "DAY") {
      result = [...Array(31).keys()].map((key) => key + 1);
    }
    return result;
  }
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        maxWidth: "576px",
        margin: "0 auto",
        padding: "2rem",
        borderRadius: "3%/3%",
        boxShadow: "1px 1px 1px 1px #ccc, 2px 2px 2px 2px #eee",
      }}
    >
      <p
        style={{
          fontSize: "1.2rem",
          color: "rgb(64,132,75)",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        역할을 선택하세요
      </p>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
        }}
      >
        {rolyType.map((rt, index) => (
          <TypeBtn
            sx={{
              width: { xs: "40%", sm: "45%" },
              padding: { xs: "1rem 0.5rem", sm: "1rem 1rem" },
            }}
            selected={rt.type === selectedRole}
            onClick={() => setSelectedRole(rt.type)}
          >
            {rt.description}
          </TypeBtn>
        ))}
      </Box>
      {selectedRole !== null ? (
        <Box>
          <JoinA href={`${API.JOIN_KAKAO}type=${selectedRole}`}>
            <Box
              sx={{
                backgroundColor: "#fee500",
                borderRadius: "7px",
                padding: "0.3em 2em",
                display: "flex",
                marginTop: "1rem",
                marginBottom: "0.3rem",
              }}
            >
              <div style={{ width: "30px" }}>
                <FontAwesomeIcon icon={faComment}></FontAwesomeIcon>
              </div>
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  flexGrow: 1,
                  fontSize: "1rem",
                }}
              >
                카카오로 가입하기
              </div>
            </Box>
          </JoinA>
          <JoinA href={`${API.JOIN_NAVER}type=${selectedRole}`}>
            <Box
              sx={{
                backgroundColor: "#2db400",
                color: "#fff",
                borderRadius: "7px",
                padding: "0.3em 2em",
                display: "flex",
                marginBottom: "0.3rem",
              }}
            >
              <div style={{ width: "30px" }}>
                <i style={{ fontWeight: 800 }}>N</i>
              </div>
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  flexGrow: 1,
                  fontSize: "1rem",
                }}
              >
                네이버로 가입하기
              </div>
            </Box>
          </JoinA>

          {/* <JoinA href={`${API.JOIN_GOOGLE}type=${selectedRole}`}>
            <Box
              sx={{
                backgroundColor: "#ff4500",
                borderRadius: "7px",
                padding: "0.3em 2em",
                display: "flex",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                "&:hover": {
                  cursor: "poineter",
                },
              }}
            >
              <div style={{ width: "30px" }}>
                <FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon>
              </div>
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  flexGrow: 1,
                  fontSize: "1rem",
                }}
              >
                Google로 가입하기
              </div>
            </Box>
          </JoinA> */}

          <Box
            onClick={() => setNomalJoin(true)}
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              borderRadius: "7px",
              padding: "0.3em 2em",
              display: "flex",
            }}
          >
            <div style={{ width: "30px" }}>
              <FontAwesomeIcon icon={faRightToBracket}></FontAwesomeIcon>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                flexGrow: 1,
                fontSize: "1rem",
              }}
            >
              일반 회원가입
            </div>
          </Box>
        </Box>
      ) : null}
      {normalJoin && (
        <Box
          sx={{ border: "1px solid #ccc", padding: "1rem", marginTop: "1rem" }}
        >
          <Box sx={{ margin: "0 0 1rem 0" }}>
            <Label>로 표시된 항목은 필수입니다</Label>
          </Box>
          <ItemBox>
            <Label>아이디</Label>
            <Box>
              <Input
                id="register-input-id"
                ref={(el) => (inputRef.current[0] = el)}
                placeholder="최소 8자리"
              />
              <button
                style={{
                  border: "none",
                  cursor: "pointer",
                  padding: "0.5rem",
                  margin: "0.2rem 0 0 0",
                  borderRadius: "5%/7%",
                  color: "#000",
                  backgroundColor: "#bbb",
                }}
                onClick={onCheckIdRedudancy}
              >
                중확확인
              </button>
              {idRedundancyCheck && (
                <span
                  style={{
                    fontSize: "1.1rem",
                    color: "#339af0",
                    fontWeight: 600,
                  }}
                >
                  사용 가능 아이디
                </span>
              )}
            </Box>
          </ItemBox>
          <ItemBox>
            <Label>비밀번호</Label>
            <Input
              id="register-input-pwd1"
              type="password"
              ref={(el) => (inputRef.current[1] = el)}
              placeholder="최소 8자리"
            />
          </ItemBox>
          <ItemBox>
            <Label className="label">비밀번호 재확인</Label>
            <Input
              id="register-input-pwd2"
              type="password"
              ref={(el) => (inputRef.current[2] = el)}
              placeholder="최소 8자리"
            />
          </ItemBox>
          <ItemBox>
            <Label className="label">이름</Label>
            <Input
              id="register-input-name"
              ref={(el) => (inputRef.current[3] = el)}
              placeholder="최대 10자리"
            />
          </ItemBox>
          <ItemBox>
            <Label className="label">전화번호</Label>
            <Input
              id="register-input-phoneNumber"
              ref={(el) => (inputRef.current[4] = el)}
              placeholder="하이픈(-)없이"
              onChange={phoneNumberChange}
            />
          </ItemBox>
          <ItemBox>
            <Label className="label">이메일</Label>
            <Input
              id="register-input-email"
              ref={(el) => (inputRef.current[5] = el)}
            />
          </ItemBox>
          <div className="multi-items-row">
            <ItemBox>
              <Label className="label">생년월일</Label>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ fontSize: "1.1rem" }}>
                  <DaySelect
                    name="fruit"
                    ref={(el) => (inputRef.current[6] = el)}
                  >
                    {getDayRange("YEAR").map((yr) => (
                      <option value={yr}>{yr}</option>
                    ))}
                  </DaySelect>
                  년
                </Box>
                <Box sx={{ fontSize: "1.1rem" }}>
                  <DaySelect
                    name="fruit"
                    ref={(el) => (inputRef.current[7] = el)}
                    style={{ width: "3rem" }}
                  >
                    {getDayRange("MONTH").map((yr) => (
                      <option value={yr}>{yr}</option>
                    ))}
                  </DaySelect>
                  월
                </Box>
                <Box sx={{ fontSize: "1.1rem" }}>
                  <DaySelect
                    name="fruit"
                    ref={(el) => (inputRef.current[8] = el)}
                    style={{ width: "3rem" }}
                  >
                    {getDayRange("DAY").map((yr) => (
                      <option value={yr}>{yr}</option>
                    ))}
                  </DaySelect>
                  일
                </Box>
              </Box>
            </ItemBox>

            <ItemBox>
              <Label className="label">국가</Label>
              <Input ref={(el) => (inputRef.current[9] = el)} />
            </ItemBox>
          </div>
          <div className="input-box">
            <FormControlLabel
              onChange={onChange}
              control={<Checkbox style={{ fontSize: "1rem" }} value="agree" />}
              label={
                <span style={{ fontSize: "1rem" }}>
                  <Label>이용약관과 개인정보취급방침에 동의합니다</Label>
                </span>
              }
            />
          </div>
          <div className="input-box">
            <StyledButton className="submit" onClick={onRequestCode}>
              이메일로 인증코드 요청
            </StyledButton>
          </div>
          {emailAuthRequested ? (
            <div>
              <hr></hr>
              <p
                style={{
                  color: "#056186",
                  fontWeight: "600",
                  fontSize: "1.1rem",
                }}
              >
                코드입력(시간이 다소 걸릴 수 있습니다)
              </p>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="email_auth_code"
                    inputRef={(el) => (inputRef.current[10] = el)}
                    size="small"
                    fullWidth
                    sx={{ m: "0 0 0.5rem", width: "100%" }}
                    variant="outlined"
                    inputProps={{
                      style: { fontSize: "1.2rem", height: "1.2rem" },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} mb={"0.5rem"}>
                  <StyledButton
                    style={{ width: "5rem" }}
                    onClick={onRequestAuth}
                  >
                    인증
                  </StyledButton>
                  <StyledButton style={{ width: "10rem" }}>
                    인증코드재요청
                  </StyledButton>
                </Grid>
              </Grid>
            </div>
          ) : null}
          {emailAuthRequested ? (
            <Box>
              <StyledButton
                disabled={!emailAuthComplete}
                onClick={onRequestJoin}
                className={
                  emailAuthComplete
                    ? "complete-btn activated-btn"
                    : "complete-btn inActivated-btn"
                }
              >
                회원가입완료
              </StyledButton>
            </Box>
          ) : null}
        </Box>
      )}

      <Dialog
        open={open}
        onClose={null} //onRequestAuth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p>{"회원가입에 성공하였습니다"}</p>
          <p>{"로그인 페이지로 이동하시겠습니까?"}</p>
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} value="move">
            이동
          </Button>
          <Button onClick={handleClose} value="cancel">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
