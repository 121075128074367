import PreparePage from "./components/common/PreparePage";
import FindPassword from "./components/login/FindPassword";
import LoginForm from "./components/login/LoginForm";
import LoginRedirect from "./components/login/LoginRedirect";
import TeamListLayout from "./layouts/TeamList";
import AgencyDetailLayout from "./layouts/agencydetail";
import CoachListLayout from "./layouts/coachList";
import CoachDetailLayout from "./layouts/coachdetail";
import IntroLayout from "./layouts/intro";
import JoinTypeLayout from "./layouts/join/JoinTypeLayout";
import PlayerRegisterLayout from "./layouts/join/PlayerRegisterLayout";
import LoginLayout from "./layouts/login";
import FindIdLayout from "./layouts/login/FindIdLayout";
import FindPasswordLayout from "./layouts/login/FindPasswordLayout";
import Main from "./layouts/main";
import MyPageAgentLayout from "./layouts/mypage/agent";
import MyPageCoachLayout from "./layouts/mypage/coach";
import MyPageCoachCreateTeamLayout from "./layouts/mypage/coach/MyPageCoachCreateTeamLayout";
import MyPagePlayerLayout from "./layouts/mypage/player";
import MyPageScouterLayout from "./layouts/mypage/scouter";
import PlayerDetailLayout from "./layouts/playerdetail";
import PlayerListLayout from "./layouts/playerlist";
import ScouterListLayout from "./layouts/scouterList";
import TeamDetailLayout from "./layouts/teamdetail";
import StaffWantedLayout from "./layouts/wanted";

const routes = [
  {
    type: "collapse",
    name: "Prepare",
    key: "prepare",
    route: "/prepare",
    component: <PreparePage />,
  },
  {
    type: "collapse",
    name: "Main",
    key: "main",
    route: "/main",
    component: <Main />,
  },
  {
    type: "collapse",
    name: "Intro",
    key: "intro",
    route: "/intro",
    component: <IntroLayout />,
  },
  {
    type: "collapse",
    name: "Register",
    key: "register",
    route: "/join",
    component: <PlayerRegisterLayout />,
  },
  {
    type: "collapse",
    name: "AuthId",
    key: "authid",
    route: "/auth/id",
    component: <FindIdLayout />,
  },
  {
    type: "collapse",
    name: "AuthPassword",
    key: "authpassword",
    route: "/auth/password",
    component: <FindPasswordLayout />,
  },
  {
    type: "collapse",
    name: "Players",
    key: "players",
    route: "/players",
    component: <PlayerListLayout />,
  },
  {
    type: "collapse",
    name: "Player-Detail",
    key: "player-detail",
    route: "/player-detail/:playerId",
    component: <PlayerDetailLayout />,
  },
  {
    type: "collapse",
    name: "Teams",
    key: "teams",
    route: "/teams",
    component: <TeamListLayout />,
  },
  {
    type: "collapse",
    name: "TeamDetail",
    key: "teamtetail",
    route: "/team-detail/:teamId",
    component: <TeamDetailLayout />,
  },
  {
    type: "collapse",
    name: "CoachList",
    key: "coachlist",
    route: "/coaches",
    component: <CoachListLayout />,
  },
  {
    type: "collapse",
    name: "CoachDetail",
    key: "coachdetail",
    route: "/coach-detail/:coachId",
    component: <CoachDetailLayout />,
  },
  {
    type: "collapse",
    name: "ScouterList",
    key: "scouterlist",
    route: "/scouters",
    component: <ScouterListLayout />,
  },
  {
    type: "collapse",
    name: "AgencyDetail",
    key: "agencydetail",
    route: "/agency-detail/:agencyId",
    component: <AgencyDetailLayout />,
  },
  {
    type: "collapse",
    name: "Login",
    key: "login",
    route: "/login",
    component: <LoginLayout />,
  },
  {
    type: "collapse",
    name: "Login",
    key: "login",
    route: "/login/oauth/processing",
    component: <LoginRedirect />,
  },

  {
    type: "collapse",
    name: "StaffWanted",
    key: "staffwanted",
    route: "/straff-wanted",
    component: <StaffWantedLayout />,
  },
  //mypage
  {
    type: "collapse",
    name: "MyCoaches",
    key: "mycoaches",
    route: "/mypage/coaches/:menuType",
    component: <MyPageCoachLayout />,
  },
  {
    type: "collapse",
    name: "MyPlayers",
    key: "myplayers",
    route: "/mypage/pro-players",
    component: <MyPagePlayerLayout />,
  },
  {
    type: "collapse",
    name: "MyScouters",
    key: "myscouters",
    route: "/mypage/scouters",
    component: <MyPageScouterLayout />,
  },
  {
    type: "collapse",
    name: "MyScouters",
    key: "myscouters",
    route: "/mypage/agents/:menuType",
    component: <MyPageAgentLayout />,
  },
];

export default routes;
