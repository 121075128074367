import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import SelectDialog from "../../../common/SelectDialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import dayjs from "dayjs";
import { faL } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API } from "../../../../../config";
import { useRecoilValue } from "recoil";
import { enumAtom } from "../../../../../recoil/atoms/applicationEnum";

export default function MPStaffCurrentTeam({
  comType,
  roleType,
  sectionTitle,
  selectBoxType,
  career,
  setCareer,
}) {
  const positionEnum = useRecoilValue(enumAtom);
  const [teamSelectOpen, setTeamSelectOpen] = useState(false);
  function setStartDate(e) {
    setCareer((prev) => ({ ...prev, joinedAt: e.$d }));
  }
  function inputValidation() {
    if (!career.club.name) {
      alert("팀명을 입력하세요");
      return false;
    }
    if (!career.joinedAt) {
      alert("시작일을 입력하세요");
      return false;
    }
    return true;
  }
  const changePosition = (e) => {
    const newPosition = e.target.value;
    console.log("newPosition", newPosition);
    setCareer((prev) => ({ ...prev, position: newPosition }));
  };
  async function onSave() {
    if (career.isEditState) {
      if (inputValidation()) {
        const requestData = {
          clubId: career.club.id || null,
          position: career.position,
          joinedAt: career.joinedAt,
          etcClubName: career.club.id ? "" : career.club.name,
        };
        console.log("requestData", requestData);
        try {
          const {
            data: { data },
          } = await axios.put(API.MP_COACHE_CURRENT_TEAM, requestData);
          console.log(data);
          console.log(positionEnum.coachPositions);
          setCareer((prev) => data);
          alert("현재소속을 저장하였습니다.");
        } catch (error) {
          alert("현재소속 저장 중 문제가 발생했습니다.");
        }
      }
    } else {
      setCareer((prev) => ({ ...prev, isEditState: true }));
    }
    console.log("career", career);
  }
  function findTeam() {
    setTeamSelectOpen(true);
  }
  return (
    <Box>
      <Box>
        <Box className="section-title">{sectionTitle}</Box>
        <Box
          sx={{
            display: "flex",
            alignItems: {
              xs: "left",
              md: "center",
            },
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "flex-start",
            border: {
              xs: "1px solid rgb(64,132,75)",
              md: "none",
            },
            padding: {
              xs: "0.5rem 0.5rem",
              md: "0",
            },
            margin: {
              xs: "0.5rem",
              md: "0",
            },
            borderRadius: "3%",
          }}
        >
          <TextField
            disabled={!career.isEditState}
            onClick={findTeam}
            size="small"
            sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
            id="position-input"
            variant="outlined"
            value={career.club ? career.club.name : ""}
            defaultValue={career.club ? career.club.name : ""}
            inputProps={{ style: { fontSize: "1.2rem" } }}
            //defaultValue={currentCareer.club ? currentCareer.club.name : ""}
          />
          <FormControl
            sx={{ width: { xs: "95%", md: "17rem" }, ml: 1, mr: 1 }}
            size="small"
          >
            <InputLabel id="demo-simple-select-label">포지션</InputLabel>
            <Select
              disabled={!career.isEditState}
              defaultValue={career.position}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Position"
              value={career.position}
              onChange={changePosition}
              sx={{
                "& .MuiOutlinedInput-input": {
                  fontSize: "1.2rem",
                },
              }}
              MenuProps={{
                style: {
                  maxHeight: 250,
                },
              }}
            >
              {positionEnum.coachPositions.map((item) => (
                <MenuItem sx={{ fontSize: "1rem" }} value={item.name}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
            <MobileDatePicker
              disabled={!career.isEditState}
              localeText={"ko"}
              defaultValue={dayjs(career.joinedAt)}
              value={dayjs(career.joinedAt)}
              onChange={setStartDate}
              sx={{
                m: 1,
                width: { xs: "95%", md: "17rem" }, //sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
                "& .MuiOutlinedInput-input": {
                  border: 0,
                  borderRadius: 3,
                  fontSize: "1.2rem",
                },
              }}
              slotProps={{ textField: { size: "small" } }}
              views={["year", "month"]}
              format={"YYYY-MM"}
              label={"시작일"}
              data-pickerType="startDate"
            />
          </LocalizationProvider>

          <Button
            onClick={onSave}
            variant="contained"
            sx={{
              backgroundColor: career.isEditState
                ? "info.main"
                : "success.main",
              ":hover": {
                backgroundColor: career.isEditState
                  ? "info.main"
                  : "success.main",
                color: "yellow",
              },
            }}
          >
            {career.isEditState ? "저장" : "수정"}
          </Button>
        </Box>
      </Box>
      <SelectDialog
        selectBoxType={selectBoxType}
        open={teamSelectOpen}
        setOpen={setTeamSelectOpen}
        itemSeq={null}
        setCareer={setCareer}
      />
    </Box>
  );
}
