import { Box, Button } from "@mui/material";
import { TextField } from "@mui/material";
import { useState } from "react";
import RemoveDialog from "./RemoveDialog";
import { API } from "../../../config";
import axios from "axios";
import URLCreator from "./URLParser";
import ExtractYouTubeRUL from "../../common/ExtractYouTubeURL";

export default function MPVideoListItem({
  comType,
  roleType,
  videoType,
  highlightVideos,
  setHighlightVideos,
  currentVideo,
  setSnackbarOpen,
  setLastCRUD,
}) {
  const [urlState, setUrlState] = useState(currentVideo.url);
  const [removeOpen, setRemoveOpen] = useState(false);

  function onTextChange(e) {
    const inputType = e.currentTarget.name;
    if (inputType === "video-title") {
      const inputTitle = e.currentTarget.value;
      setHighlightVideos((prev) =>
        prev.map((item) =>
          item.seq === currentVideo.seq ? { ...item, title: inputTitle } : item
        )
      );
    } else {
      const inputUrl = e.currentTarget.value.trim();
      setUrlState((prev) => inputUrl);
      setHighlightVideos((prev) =>
        prev.map((item) =>
          item.seq === currentVideo.seq ? { ...item, url: inputUrl } : item
        )
      );
    }
  }
  function inputValidation() {
    if (currentVideo.title.trim().length < 3) {
      alert("영상제목을 최소 세글자 입력하세요");
      return false;
    }
    if (!currentVideo.url) {
      alert("영상주소를 입력해주세요");
      return false;
    }
    if (!currentVideo.url.trim().includes("youtube")) {
      alert("영상주소에 Youtube주소가 올바르게 입력되었는지 확인해주세요");
      return false;
    }
    return true;
  }
  async function requestUpdate() {
    const teamId = currentVideo.teamId;
    console.log("teamId", teamId);
    const requestURL = `${URLCreator(roleType, comType, teamId, "PUT")}/${currentVideo.id}`;

    console.log(requestURL);

    const data = {
      title: currentVideo.title.trim(),
      url: currentVideo.url,
      type: videoType,
    };
    try {
      const res = await axios.put(requestURL, data);
      console.log(res);
      const newData = res.data.data.map((item, index) => ({
        ...item,
        teamId: teamId,
        seq: index,
        fromServer: true,
        isEditState: false,
      }));
      alert("수정하였습니다.");
      setHighlightVideos((prev) => newData);
    } catch (error) {
      console.log(error);
    }
  }
  async function requestCreate() {
    if (!inputValidation()) {
      return;
    }
    const teamId =
      comType === API.ComType.TEAM_VIDEO ? currentVideo.teamId : null;
    const requestURL = URLCreator(roleType, comType, teamId);
    console.log("roleType", roleType);
    console.log("comType", comType);
    console.log("requestURL", requestURL);
    const data = {
      title: currentVideo.title.trim(),
      url: currentVideo.url,
      type: videoType,
    };
    try {
      const res = await axios.post(requestURL, data);
      const newData = res.data.data.map((item, index) => ({
        ...item,
        teamId: teamId,
        seq: index,
        fromServer: true,
        isEditState: false,
      }));
      alert("영상을 추가했습니다.");
      setHighlightVideos((prev) => newData);
      console.log("teamId after add", teamId);
    } catch (error) {
      console.log(error);
    }
  }
  function removeBtnClick(e) {
    if (currentVideo.fromServer) {
      setRemoveOpen(true);
    } else {
      console.log("remove row");
      console.log(highlightVideos);
      setHighlightVideos((prev) =>
        prev.filter((item) => item.seq !== currentVideo.seq)
      );
    }
  }
  function onBtnClick() {
    if (currentVideo.isEditState === true) {
      if (currentVideo.fromServer === true) {
        //수정
        requestUpdate();
      } else {
        //생성
        requestCreate();
      }
    } else {
      setHighlightVideos((prev) =>
        prev.map((item) =>
          item.seq === currentVideo.seq ? { ...item, isEditState: true } : item
        )
      );
    }
  }

  return (
    <Box
      sx={{
        alignItems: {
          xs: "left",
          md: "center",
        },
        flexDirection: {
          xs: "column",
          md: "row",
        },
        justifyContent: "flex-start",
        border: "1px solid rgb(64,132,75)",
        padding: {
          xs: "0.5rem",
          md: "none",
        },
        margin: {
          xs: "0.5rem",
          md: "none",
        },
        borderRadius: "1%/5%",
      }}
    >
      <Box>
        <TextField
          disabled={!currentVideo.isEditState}
          size="small"
          sx={{ m: 1 }}
          style={{ width: "95%" }}
          id="outlined-multiline-flexible"
          value={currentVideo.url}
          label={"영상주소"}
          onChange={onTextChange}
          placeholder="유뷰트 영상의 주소를 입력해주세요"
          name="video-url"
          inputProps={{ style: { fontSize: "1.1rem", height: "1.1rem" } }}
        />
        <TextField
          disabled={!currentVideo.isEditState}
          size="small"
          id="outlined-multiline-flexible"
          sx={{ m: 1 }}
          value={currentVideo.title}
          label={"영상제목"}
          onChange={onTextChange}
          name="video-title"
          style={{ width: "95%" }}
          inputProps={{ style: { fontSize: "1.1rem", height: "1.1rem" } }}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            backgroundColor: "#aaa",
            width: "150px",
            height: "120px",
            overflow: "hidden",
          }}
        >
          {urlState ? (
            <img
              src={ExtractYouTubeRUL(urlState)}
              alt="thumbnail-img"
              style={{ width: "100%", objectFit: "contain" }}
            ></img>
          ) : null}
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{
              width: "70px",
              backgroundColor: currentVideo.isEditState
                ? "info.main"
                : "success.main",
              ":hover": {
                backgroundColor: currentVideo.isEditState
                  ? "info.main"
                  : "success.main",
                color: "yellow",
              },
            }}
            onClick={onBtnClick}
          >
            {currentVideo.isEditState ? "저장" : "수정"}
          </Button>

          <Button
            disabled={!currentVideo.isEditState}
            value={currentVideo.seq}
            onClick={removeBtnClick}
            variant="contained"
            sx={{
              width: "70px",
              backgroundColor: "#9D0000",
              ":hover": {
                bgcolor: "#9D0000",
                color: "yellow",
              },
            }}
          >
            삭제
          </Button>
        </Box>
      </Box>

      <RemoveDialog
        comType={comType}
        roleType={roleType}
        open={removeOpen}
        setOpen={setRemoveOpen}
        itemSeq={currentVideo.seq}
        teamId={currentVideo.teamId || null}
        rows={highlightVideos}
        setRows={setHighlightVideos}
        setSnackbarOpen={setSnackbarOpen}
        setLastCRUD={setLastCRUD}
      />
    </Box>
  );
}
