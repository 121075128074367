import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";

export default function ImageAndText({ text, margin, isBottom }) {
  return (
    <Box>
      <Box
        sx={{
          height: "auto", //height
          margin: margin,
          padding: isBottom ? { sm: "none", md: "13rem 0 0 0" } : "none",
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgb(64,132,75)",
            color: "#fff",
            textAlign: "center",
            height: { sm: "auto", md: "10.625rem" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "auto",
          }}
        >
          <Box>
            <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>
          </Box>
          <Box sx={{ width: "100%" }}>
            <pre
              style={{
                fontSize: "1.2rem",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {text.mainTitle}
            </pre>
          </Box>
          <Box>
            <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
          </Box>
        </Box>
        <Box sx={{ padding: "2rem" }}>
          {text.items.map((it, index) => (
            <Box key={index} sx={{ marginBottom: "1rem", color: "#fff" }}>
              <Box
                sx={{
                  marginBottom: "0.5em",
                  color: "rgb(84,152,95)",
                  fontSize: "1.5rem",
                  width: "90%",
                }}
              >
                {it.title}
              </Box>
              <Box
                sx={{
                  color: "#adb5bd",
                  fontSize: "1.2rem",
                  width: "auto",
                }}
              >
                {it.content}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
