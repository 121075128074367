import "../../style/team.css";
import Paper from "@mui/material/Paper";
import BasicCarousel from "../common/BasicCarousel";
import { API } from "../../config";
import { useRecoilValue } from "recoil";
import { enumAtom } from "../../recoil/atoms/applicationEnum";
import { Box, Grid, Typography } from "@mui/material";
import DetailProfile from "../person/DetailProfile";
import ImageSwiper from "../ImageSwiper/ImageSwiper";
import DetailTeamProfile from "./DetailTeamProfile";
import CareerTable from "../common/CareerTable";
import CoachListLinkTable from "../common/CoachListLinkTable";
const imageURLs = [
  "https://img.kbs.co.kr/kbs/620/news.kbs.co.kr/data/fckeditor/new/image/2024/04/01/303281711953532160.jpeg",
  "https://res.heraldm.com/content/image/2024/03/07/20240307050575_0.jpg",
  "https://file2.nocutnews.co.kr/newsroom/image/2022/09/27/202209271039137444_0.jpg",
];
const headers = ["이름", "생년월일", "직함"];
export default function TeamProfile({ team }) {
  function ListItemTranslate() {
    return [
      { title: "창단일", content: team.foundedDate },
      { title: "대표이사", content: team.chiefOfficer },
      { title: "단장", content: team.chiefManager },
    ];
  }

  return (
    <Box>
      <Grid container alignItems="center">
        <Grid item xs={12} md={7} sx={{ padding: "3rem 0" }}>
          <DetailTeamProfile
            data={team}
            profileItem={ListItemTranslate()}
            comType={API.ComType.TEAM_PLAYER}
            profileTypeText="TEAM"
          ></DetailTeamProfile>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            textAlign: "center",
            padding: "1rem",
          }}
        >
          <Box
            sx={{
              textAlign: "left",
              fontSize: "1.4rem",
              fontWeight: "600",
              color: "#333",
            }}
          >
            코칭스태프
          </Box>
          <CoachListLinkTable
            hHeaders={headers}
            data={team.affiliatingCoaches}
          ></CoachListLinkTable>
        </Grid>
      </Grid>
    </Box>
  );
}
