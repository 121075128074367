import { Box, Button } from "@mui/material";
import { TextField } from "@mui/material";
import axios from "axios";
import { API } from "../../../config";
import { faL } from "@fortawesome/free-solid-svg-icons";

export default function MultiLineText({ text, setText }) {
  function onTextChange(e) {
    setText((prev) => ({ ...prev, data: e.target.value }));
  }
  async function requestUpdate() {
    const data = {
      coachingPhilosophy: text.data,
    };
    try {
      const res = await axios.put(API.MP_COACHE_PHILOSOPHY, data);
      alert("수정하였습니다.");
      setText((prev) => ({
        isEditState: false,
        coachingPhilosophy: res.data.data.coachingPhilosophy,
      }));
    } catch (error) {
      console.log(error);
    }
  }
  function onBtnClick() {
    if (text.isEditState === true) {
      requestUpdate();
    } else {
      setText((prev) => ({ ...prev, isEditState: true }));
    }
  }
  return (
    <Box>
      <TextField
        disabled={!text.isEditState}
        size="small"
        id="outlined-multiline-flexible"
        sx={{ width: "90%" }}
        value={text.data}
        onChange={onTextChange}
        inputProps={{ style: { fontSize: "1.1rem" } }}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: text.isEditState ? "info.main" : "success.main",
          ":hover": {
            backgroundColor: text.isEditState ? "info.main" : "success.main",
            color: "yellow",
          },
        }}
        onClick={onBtnClick}
      >
        {text.isEditState ? "저장" : "수정"}
      </Button>
    </Box>
  );
}
