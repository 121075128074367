import { Fragment, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { DialogActions, DialogContent, DialogContentText } from "@mui/material";

import axios from "axios";
import { API } from "../../../config";
import { COM_TYPE } from "../../../enum";
import URLCreator from "./URLParser";
export default function RemoveDialog(props) {
  const {
    comType,
    roleType,
    open,
    setOpen,
    itemSeq,
    teamId,
    rows,
    setRows,
    setSnackbarOpen,
    setLastCRUD,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  async function removeItem() {
    //find remove item id
    const removeId = rows[itemSeq].id;
    try {
      console.log(roleType, comType);
      const url = `${URLCreator(roleType, comType, teamId, "DELETE")}/${removeId}`;
      console.log(`url:${removeId}`);
      const response = await axios.delete(url);
      //setLastCRUD("삭제하였습니다.")
      //setSnackbarOpen(true)
      const newList = response.data.data;
      console.log(3, newList);
      if (comType === API.ComType.PLAYER_VIDEO) {
        setRows((prev) =>
          newList.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
      } else if (comType === API.ComType.TEAM_VIDEO) {
        console.log("try team video remove");
        console.log(response);
        setRows((prev) =>
          newList.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
            teamId: teamId,
          }))
        );
      }

      console.log(4, setRows);
    } catch (err) {
      console.log("Error >>", err);
    }
    handleClose();
  }
  return (
    <div>
      <Dialog
        className="custom-div"
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"} //xs, sm, md, lg
      >
        <DialogTitle color={"blue"}>삭제</DialogTitle>
        <div>
          <DialogContent sx={{ padding: "10px 20px 0" }}>
            <DialogContentText id="alert-dialog-description">
              정말로 삭제하시겠습니까?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={removeItem}
              sx={{ width: "100px", border: "1px solid #1976D2" }}
            >
              삭제
            </Button>
            <Button
              onClick={handleClose}
              autoFocus
              sx={{ width: "100px", border: "1px solid #1976D2" }}
            >
              취소
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
