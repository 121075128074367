import { Box, Button, TextField } from "@mui/material";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {
  StyledButton,
  StyledLabel,
  TitleBox,
} from "../../common/MyPageComponents";
import { useEffect, useRef, useState } from "react";
import { API } from "../../../../config";
import axios from "axios";

const ProfileLabel = styled("span")`
  display: inline-block;
  font-size: 1.1rem;
  width: 5rem;
`;
const Span = styled("span")`
  display: inline-block;
  font-size: 1.1rem;
`;

const ModifiedLable = styled("span")`
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  width: 7rem;
`;
const ItemBox = styled(Box)`
  background-color: #fff;
  border: 1px solid rgb(64, 132, 75);
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 0.5rem/0.5rem;
`;
const ProfileRow = styled(Box)``;
export default function MPAgentProfile() {
  const [isEditState, setIsEditState] = useState(false);
  const img_ref = useRef(null);
  const inputRef = useRef([]);
  const [agent, setAgent] = useState();
  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`${API.MP_AGENT}`);
      console.log("agentdata", data);
      if (data.code === 200000) {
        setAgent(data.data);
      }
      try {
      } catch (error) {}
    }
    fetchData();
  }, []);
  function changeTextField(event) {
    const { name, value } = event.target;
    if (name === "scouter-input-phoneNumber") {
      setAgent((prev) => ({ ...prev, contactPhoneNumber: value }));
    } else if (name === "scouter-input-email") {
      setAgent((prev) => ({ ...prev, contactEmail: value }));
    }
  }
  function MakeRequestData(profileImageId) {
    console.log(agent);
    return {
      contactEmail: agent.contactEmail,
      contactPhoneNumber: agent.contactPhoneNumber,

      profileImageId: profileImageId
        ? profileImageId
        : agent.profileImage
          ? agent.profileImage.id
          : null,
    };
  }
  async function requestCoachInfoUpdate() {
    try {
      const requestData = MakeRequestData();
      const {
        data: { data },
      } = await axios.put(API.MP_AGENT_COMMON, requestData);
      setAgent((prev) => data);
      setIsEditState((prev) => false);
      alert("수정성공");
    } catch (error) {
      alert("수정에 실패했습니다");
    }
  }
  async function changeImgText(event) {
    event.preventDefault();
    console.log("changed");
    const formData = new FormData();
    const uploadFile = event.target.files[0];
    formData.append("file", uploadFile);

    try {
      const res = await axios.post(`${API.FILE_BASE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setAgent((prev) => ({
        ...prev,
        profileImage: { id: res.data.data.id, fileUrl: res.data.data.fileUrl },
      }));
      const requestData = MakeRequestData(res.data.data.id);
      const {
        data: { data },
      } = await axios.put(`${API.MP_AGENT_COMMON}`, requestData);
      alert("사진을 등록하였습니다.");
    } catch (error) {
      console.log(error);
    }
  }
  function onEditBtnClick() {
    if (isEditState) {
      requestCoachInfoUpdate();
    } else {
      setIsEditState((prev) => !prev);
    }
  }

  async function onRemovePicture() {
    try {
      const requestData = MakeRequestData();
      requestData["profileImageId"] = null;
      const {
        data: { data },
      } = await axios.put(API.MP_AGENT_COMMON, requestData);
      setAgent((prev) => data);
      setIsEditState((prev) => false);
      alert("사진을 삭제했습니다");
    } catch (error) {
      alert("사진삭제에 실패했습니다");
    }
  }
  return agent ? (
    <Box>
      <ItemBox>
        <TitleBox>프로필</TitleBox>
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
        >
          <Box sx={{ margin: "0 1rem 1rem 0" }}>
            <Box
              sx={{
                width: "13rem",
                overflow: "hidden",
                border: "1px solid #eee",
                textAlign: "center",
              }}
            >
              {agent.profileImage ? (
                <img
                  src={`${API.BASE}${agent.profileImage.fileUrl}`}
                  style={{ width: "100%", objectFit: "contain" }}
                  alt="profile-img"
                ></img>
              ) : (
                <FontAwesomeIcon icon={faUser} size="4x" color="#183153" />
              )}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box>
                  <StyledLabel sx={{ display: "inline-block" }}>
                    {agent.profileImage ? "수정" : "등록"}
                    <input
                      type="file"
                      className="file"
                      ref={img_ref}
                      accept="image/*"
                      onChange={changeImgText}
                      style={{ display: "none" }}
                    />
                  </StyledLabel>
                </Box>
                <Box>
                  <StyledButton onClick={onRemovePicture}>삭제</StyledButton>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ margin: "0 1rem 1rem 0" }}>
            <ProfileRow>
              <ProfileLabel>이름</ProfileLabel>
              <Span>{agent.name}</Span>
            </ProfileRow>
            <ProfileRow>
              <ProfileLabel>연락처 </ProfileLabel>
              <Span>{agent.phoneNumber}</Span>
            </ProfileRow>
            <ProfileRow>
              <ProfileLabel>이메일 </ProfileLabel>
              <Span>{agent.email}</Span>
            </ProfileRow>
          </Box>
        </Box>
        <Box sx={{ border: "1px solid #eee", padding: "0.5rem" }}>
          <TitleBox>개인정보수정</TitleBox>
          <Button
            onClick={onEditBtnClick} //onEditBtnClick
            variant="contained"
            sx={{
              backgroundColor: isEditState ? "info.main" : "success.main",
              ":hover": {
                backgroundColor: isEditState ? "info.main" : "success.main",
                color: "yellow",
              },
            }}
          >
            {isEditState ? "저장" : "수정"}
          </Button>
          <Box sx={{ padding: "0.5rem" }}>
            <Box sx={{ maxWidth: "30rem" }}>
              <ModifiedLable>공개연락처</ModifiedLable>
              <TextField
                value={agent.contactPhoneNumber}
                size="small"
                variant="outlined"
                disabled={!isEditState}
                name="scouter-input-phoneNumber"
                onChange={changeTextField}
                sx={{ m: "0.5rem 0", width: { xs: "95%" } }}
                inputProps={{ style: { fontSize: "1rem", height: "1rem" } }}
              />
            </Box>
            <Box sx={{ maxWidth: "30rem" }}>
              <ModifiedLable>공개이메일</ModifiedLable>

              <TextField
                value={agent.contactEmail}
                size="small"
                variant="outlined"
                disabled={!isEditState}
                name="scouter-input-email"
                onChange={changeTextField}
                sx={{ m: "0.5rem 0", width: { xs: "95%" } }}
                inputProps={{ style: { fontSize: "1rem", height: "1rem" } }}
              />
            </Box>
          </Box>
        </Box>
      </ItemBox>
    </Box>
  ) : null;
}
