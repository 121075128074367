import MainLayout from "../../LayoutContainers/MainLayout";
import RowBox from "../../LayoutContainers/RowBox";
import FindId from "../../components/login/FindId";
import { useParams } from "react-router-dom";

export default function FindIdLayout() {
  let { role } = useParams();
  return (
    <MainLayout mode="light">
      <RowBox
        mode="light"
        bgColor="none"
        padding={"0 0 6rem 0"}
        isBorder={false}
      >
        <FindId></FindId>
      </RowBox>
    </MainLayout>
  );
}
