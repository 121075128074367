import { useEffect, useState } from "react";
import { API } from "../../../../../config";
import axios from "axios";
import MPStaffManagePersonList from "./MPStaffManagePersonList";
import MPStaffManagePersonAdd from "./MPStaffManagePersonAdd";

export default function MPStaffManagePlayer({
  comType,
  roleType,
  currentTeam,
  teamType,
}) {
  const [playerList, setPlayerList] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    status: "ALL",
    position: "ALL",
  });
  const [filteredPlayer, setFilteredPlayer] = useState();
  function filterPlayerList(currentStatus, currentPosition) {
    const status = currentStatus ? currentStatus : filterConditions.status;
    const position = currentPosition
      ? currentPosition
      : filterConditions.position;

    let memberFiltering =
      status === "ALL"
        ? [...playerList]
        : playerList.filter((item) => item.status === status);
    memberFiltering =
      position === "ALL"
        ? [...memberFiltering]
        : memberFiltering.filter((item) => item.position === position);
    console.log("filtering result", memberFiltering);
    setFilteredPlayer(memberFiltering);
  }
  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { data },
      } = await axios.get(
        `${API.MP_COACH_MY_MANAGED_TEAM}/${currentTeam.id}/pro-players`
      );
      console.log("get player list", data);
      setPlayerList((prev) => data);
      setFilteredPlayer((prev) => data);
    };
    fetchData();
  }, []);
  return (
    <div>
      <div className="mypage-body-item-wrapper">
        <p className="section-title">선수현황</p>
        <MPStaffManagePersonList
          comType={comType}
          roleType={roleType}
          currentTeam={currentTeam}
          members={playerList}
          setMembers={setPlayerList}
          filterConditions={filterConditions}
          setFilterConditions={setFilterConditions}
          filteredMember={filteredPlayer}
          setFilterMemberAuto={filterPlayerList}
          setFilterMemberManually={setFilteredPlayer}
        ></MPStaffManagePersonList>
      </div>
      <div className="mypage-body-item-wrapper">
        <p className="section-title">신규등록</p>
        <MPStaffManagePersonAdd
          comType={API.ComType.TEAM_PLAYER}
          roleType={API.RolyType.PLAYER}
          currentTeam={currentTeam}
          members={playerList}
          setMembers={setPlayerList}
          filterConditions={filterConditions}
          setFilterConditions={setFilterConditions}
          filteredMember={filteredPlayer}
          setFilterMemberAuto={filterPlayerList}
          setFilterMemberManually={setFilteredPlayer}
        ></MPStaffManagePersonAdd>
      </div>
    </div>
  );
}
