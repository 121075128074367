import { Box, TextField, Button } from "@mui/material";
import AgencyStaffItem from "./AgencyStaffItem";
import { useRef } from "react";
import { width } from "@fortawesome/free-solid-svg-icons/faUniversalAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API } from "../../../../config";
import styled from "styled-components";

const Input = styled("input")`
  width: 45%;
  padding: 0.5rem;

  border: none;
  outline: none;
  margin: 0 0.1rem 0;
  font-size: 1.1rem;
`;
export default function MPAgencyStaff({ staffs, setAgencyStaffs }) {
  const inputRef = useRef([]);
  async function onAddStaff() {
    const position = inputRef.current[0].value.trim();
    const name = inputRef.current[1].value.trim();
    if (position.length === 0) {
      alert("직함을 입력하세요");
      return;
    }
    if (name.length === 0) {
      alert("사원명을 입력하세요");
      return;
    }
    const requestData = {
      position: position,
      name: name,
    };
    const {
      data: { data },
    } = await axios.post(API.MP_AGENCY_STAFF, requestData);
    setAgencyStaffs((prev) => data);
    alert("직원을 추가했습니다");
    inputRef.current[0].value = "";
    inputRef.current[1].value = "";
  }
  return (
    <Box sx={{ width: { xs: "100", md: "50%" } }}>
      <Box
        sx={{
          display: "flex",
          padding: "1rem",
          backgroundColor: "rgb(64,132,75)",
        }}
      >
        <Box sx={{ width: "95%" }}>
          <Input
            id="agent_staff_input"
            ref={(el) => (inputRef.current[0] = el)}
            size="small"
            placeholder="직함"
          />
          <Input
            id="agent_staff_input"
            ref={(el) => (inputRef.current[1] = el)}
            placeholder="이름"
            style={{ width: "50%", display: "inline-block" }}
            variant="outlined"
          />
        </Box>

        <Box>
          <FontAwesomeIcon
            icon={faAdd}
            onClick={onAddStaff}
            style={{
              padding: "0.5rem",
              backgroundColor: "#eee",
              color: "#555",
              cursor: "pointer",
            }}
          ></FontAwesomeIcon>
        </Box>
      </Box>
      <Box sx={{ border: "1px solid #eee", padding: "0.5rem" }}>
        {staffs.map((st) => (
          <AgencyStaffItem
            staff={st}
            setAgencyStaffs={setAgencyStaffs}
          ></AgencyStaffItem>
        ))}
      </Box>
    </Box>
  );
}
