import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loginStatusAtom } from "../../../recoil/atoms/loginStatus";
import { API } from "../../../config";
import axios from "axios";
import { Box, Grid, Hidden } from "@mui/material";

export default function MyPageHeader() {
  const [loginInfo, setLoginInfo] = useRecoilState(loginStatusAtom);
  const navigate = useNavigate();
  async function logout() {
    console.log("try logout");
    try {
      const res = await axios.post(API.LOGOUT);
      sessionStorage.clear();
      setLoginInfo({});
      navigate("/main");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Grid container sx={{ alignItems: "center" }}>
      <Grid item>
        <Link to="/main">
          <Box sx={{ width: "10rem", overflow: "hidden" }}>
            <img
              src="https://canda23.com/files/canda_logo.png"
              style={{ width: "100%", objectFit: "contain" }}
            ></img>
          </Box>
        </Link>
      </Grid>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box className="member-info">
          {sessionStorage.getItem("name") + "님"}
        </Box>
        <Box className="member-info">
          <button onClick={logout}>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </button>
        </Box>
      </Box>
    </Grid>
  );
}
