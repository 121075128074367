import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Button,
} from "@mui/material";

import { useRef } from "react";
import axios from "axios";
import { API } from "../../../config";
const positionKorean = {
  DF: "수비수",
  FW: "공격수",
  GK: "골키퍼",
  MF: "미드필더",
};
const defaultPrefer = {
  mainFoot: "RIGHT",
  height: "170cm 이상",
};
export default function PositionPreferTable({
  preferPositionStyles,
  setPreferPositionStyles,
}) {
  function onInputChange(e) {
    const changedPosition = e.target.getAttribute("name");
    const changedText = e.target.value;
    const newPositionPrefer = preferPositionStyles.preferPositionStyles.map(
      (item) =>
        item.position === changedPosition
          ? { ...item, abilities: changedText }
          : item
    );
    setPreferPositionStyles((prev) => ({
      ...prev,
      preferPositionStyles: newPositionPrefer,
    }));
  }
  async function onBtnClick() {
    if (preferPositionStyles.isEditState) {
      try {
        const preferStyles = preferPositionStyles.preferPositionStyles.map(
          (item, idx) => ({
            id: item.id,
            mainFoot: item.mainFoot,
            height: item.height,
            abilities: item.abilities,
          })
        );

        const fillPreferStyles = preferStyles.map((item) => ({
          ...item,
          mainFoot: item.mainFoot || defaultPrefer.mainFoot,
          height: item.height || defaultPrefer.height,
        }));
        const data = { preferStyles: fillPreferStyles };
        const res = await axios.put(API.MP_COACH_POSITION_PREFER, data);
        alert("수정하였습니다.");
        setPreferPositionStyles((prev) => ({
          preferPositionStyles: res.data.data,
          isEditState: false,
        }));
      } catch (error) {
        console.log(error);
      }
    } else {
      setPreferPositionStyles((prev) => ({
        ...prev,
        isEditState: !prev.isEditState,
      }));
    }
  }
  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
      <Box sx={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ widht: "100%" }} size="small" aria-label="a dense table">
            <TableBody>
              {preferPositionStyles.preferPositionStyles.map((item, idx) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    <Box>
                      <Box sx={{ color: "rgb(64,132,75)", fontWeight: "600" }}>
                        {positionKorean[item.position]}
                      </Box>
                      <TextField
                        disabled={!preferPositionStyles.isEditState}
                        size="small"
                        id="outlined-multiline-flexible"
                        sx={{ width: "100%" }}
                        onChange={onInputChange}
                        inputProps={{ style: { fontSize: "1.1rem" } }}
                        defaultValue={item.abilities}
                        placeholder="예) 공중장악, 순간스피드"
                        name={item.position}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Button
          variant="contained"
          onClick={onBtnClick}
          sx={{
            backgroundColor: preferPositionStyles.isEditState
              ? "info.main"
              : "success.main",
            ":hover": {
              backgroundColor: preferPositionStyles.isEditState
                ? "info.main"
                : "success.main",
              color: "yellow",
            },
          }}
        >
          {preferPositionStyles.isEditState ? "저장" : "수정"}
        </Button>
      </Box>
    </Box>
  );
}
