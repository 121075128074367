import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

const FilterCategory = styled(Box)`
  width: 16%;
  border-radius: 6px;
  background-color: rgb(64, 132, 75);
  color: #eee;
  padding: 0.5rem;
  text-align: center;
`;

const FilterBtn = styled(FilterCategory)`
  word-break: keep-all;
  background-color: ${(props) => (props.selected ? "#333" : "#eee")};
  color: ${(props) => (props.selected ? "#eee" : "#333")};
  cursor: pointer;
  border: 1px solid #ccc;
  margin-right: ${(props) => (props.mobile === "true" ? "0.1rem" : "0.5rem")};
  &:hover {
    color: rgb(64, 132, 75);
  }
`;

export default function Filter({
  categoryName,
  filterType,
  filterItem,
  isClicked,
  setIsClicked,
  dataFiltering,
  itemBtnWidth,
}) {
  function onClickFilterItem(e) {
    const selectedIndex = parseInt(e.target.dataset.index);
    let newIsClicked = isClicked;
    if (selectedIndex === 0) {
      if (isClicked.slice(1).includes(true)) {
        //이미 하나라도 선택된 아이템이 있다면 모두 클리어
        //setIsClicked((prev) => prev.map((s) => false));
        newIsClicked = isClicked.map((ic) => false);
      } else {
        //이미 선택된 아이템이 하나도 없다면 모두 선택
        //setIsClicked((prev) => prev.map((s) => true));
        newIsClicked = isClicked.map((ic) => true);
      }
    } else {
      newIsClicked = isClicked.map((c, index) =>
        index === selectedIndex ? !c : c
      );
      if (
        newIsClicked.slice(1).filter((nc) => nc === true).length ===
        isClicked.length - 1
      ) {
        newIsClicked[0] = true;
      } else {
        newIsClicked[0] = false;
      }
    }
    setIsClicked((prev) => newIsClicked);
    if (dataFiltering) {
      dataFiltering(filterType, newIsClicked);
    }
  }
  return (
    <Box>
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
          fontSize: "1.1rem",
          fontWeight: "600",
          color: "rgb(64, 132, 75)",
        }}
      >
        {categoryName}
      </Box>
      <Box sx={{ display: "flex", margin: "0 0 0.5rem 0" }}>
        <FilterCategory
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: { xs: "0.9rem", sm: "1rem" },
            width: "15%",
          }}
        >
          {categoryName}
        </FilterCategory>
        <Box sx={{ display: "flex", width: "100%" }}>
          <FilterBtn
            onClick={onClickFilterItem}
            data-index={filterItem[0].key}
            key={filterItem[0].key}
            selected={isClicked[0]}
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              fontSize: "1rem",
              margin: "0 0.1rem 0 0 ",
            }}
          >
            {filterItem[0].description}
          </FilterBtn>
          <Box
            sx={{
              display: "flex",
              width: { xs: "100%", sm: "60%" },
              flexWrap: "wrap",
            }}
          >
            {filterItem.slice(1).map((fi, index) => (
              <FilterBtn
                key={fi.key}
                onClick={onClickFilterItem}
                data-index={fi.key}
                selected={isClicked[fi.key]}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  width: itemBtnWidth || "24%",
                  margin: "0.1rem 0",
                  fontSize: { xs: "0.9rem", sm: "1rem" },
                }}
              >
                {fi.description}
              </FilterBtn>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
