import { Box } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function AgentSidebar({ selectIndex }) {
  const [selectedMenu, setSelectedMenu] = useState(selectIndex);

  return (
    <Box sx={{ display: "flex", "& a:visited": { color: "#000" } }}>
      <Box sx={{ color: "#000" }}>
        <Link to="/mypage/agents/profile">
          <Box
            sx={{ fontSize: { xs: "1rem", sm: "1.2rem" } }}
            onClick={() => {
              setSelectedMenu((prev) => 0);
            }}
            className={
              selectedMenu === 0 ? "sidebar-menu selected-menu" : "sidebar-menu"
            }
          >
            프로필
          </Box>
        </Link>
      </Box>
      <Box>
        <Link to="/mypage/agents/createAgency">
          <Box
            sx={{ fontSize: { xs: "1rem", sm: "1.2rem" } }}
            onClick={() => {
              setSelectedMenu((prev) => 1);
            }}
            className={
              selectedMenu === 1 ? "sidebar-menu selected-menu" : "sidebar-menu"
            }
          >
            에이전시신청
          </Box>
        </Link>
      </Box>
      <Box>
        <Link to="/mypage/agents/agencyManage">
          <Box
            sx={{ fontSize: { xs: "1rem", sm: "1.2rem" } }}
            onClick={() => {
              setSelectedMenu((prev) => 2);
            }}
            className={
              selectedMenu === 2 ? "sidebar-menu selected-menu" : "sidebar-menu"
            }
          >
            에이전시관리
          </Box>
        </Link>
      </Box>
    </Box>
  );
}
