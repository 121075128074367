import { Box, Paper, Typography } from "@mui/material";

import PlayerVideoCard from "./PlayerVideoCard";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../style/myswiper.css";

import MainVideoCard from "../main/MainVideoCard";
import { Navigation } from "swiper/modules";
import { faAngleRight, faPlay } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import VideoModal from "../modal/VideoModal";
export default function PlayerVideo({ playerVideo, btnClassName }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [movieIndex, setMovieIndex] = useState(null);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ position: "relative" }}>
        <i
          className={`swiper-button-next-i ${btnClassName}-swiper-button-next`}
        >
          &gt;
        </i>
        <i
          className={`swiper-button-prev-i ${btnClassName}-swiper-button-prev`}
        >
          &lt;
        </i>
        <Swiper
          spaceBetween={10}
          breakpoints={{
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
            1920: {
              slidesPerView: 4,
            },
          }}
          navigation={{
            nextEl: `.${btnClassName}-swiper-button-next`,
            prevEl: `.${btnClassName}-swiper-button-prev`,
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {playerVideo.map((info, index) => (
            <SwiperSlide key={index}>
              <Box
                onClick={() => {
                  setMovieIndex((prev) => parseInt(index));
                  setModalOpen(true);
                }}
                display={{
                  xs: "block",
                  md: "block",
                  lg: "block",
                  xl: "block",
                  xxl: "block",
                }}
                sx={{ width: "100%" }}
              >
                <Box sx={{ width: "100%" }}>
                  <MainVideoCard mode="light" videoInfo={info}></MainVideoCard>
                </Box>
              </Box>
              {/* <Box
                display={{
                  xs: "block",
                  md: "none",
                  lg: "none",
                  xl: "none",
                  xxl: "none",
                }}
              >
                <MainVideoCardPlayer
                  mode="dark"
                  videoInfo={info}
                ></MainVideoCardPlayer>
              </Box> */}
            </SwiperSlide>
          ))}
        </Swiper>
        {modalOpen && (
          <VideoModal
            setModalOpen={setModalOpen}
            videoURL={playerVideo[movieIndex].url}
          ></VideoModal>
        )}
      </Box>
    </Box>
  );
}
