import { Box } from "@mui/material";
import MyPageMainLayout from "../../../LayoutContainers/MyPageLayout";
import MPPlayerProfile from "../../../components/mypage/body/player/profile/MPPlayerProfile";
export default function MyPagePlayerLayout() {
  return (
    <MyPageMainLayout>
      <Box>
        <MPPlayerProfile></MPPlayerProfile>
      </Box>
    </MyPageMainLayout>
  );
}
