import Button from "@mui/material/Button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import YearTitleList from "../../../common/YearTitleList";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { TitleBox } from "../../../common/MyPageComponents";

export default function MPStaffProfileAward({
  comType,
  roleType,
  awards,
  setAwards,
  sectionTitle,
  setSnackbarOpen,
  setLastCRUD,
}) {
  const [seq, setSeq] = useState(awards.length);
  function addBtnClick() {
    const data = {
      id: null,
      date: String(new Date().getFullYear()).slice(0, 4),
      name: null,
      seq: seq,
      fromServer: false,
      isEditState: true,
    };
    setAwards((prev) => [...prev, data]);
    setSeq((prev) => prev + 1);
  }
  return (
    <Box>
      <TitleBox>
        {sectionTitle}
        <Button
          id="player-career-add-btn"
          variant="text"
          sx={{ fontSize: "1rem" }}
          onClick={addBtnClick}
        >
          <FontAwesomeIcon
            className="add-btn-icon"
            color="#1976D2"
            icon={faPlus}
          />
          추가하기
        </Button>
      </TitleBox>

      <Box>
        {awards ? (
          <YearTitleList
            comType={comType}
            roleType={roleType}
            rows={awards}
            setRows={setAwards}
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></YearTitleList>
        ) : null}
      </Box>
    </Box>
  );
}
