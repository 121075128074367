import "../../style/staff.css";
import Paper from "@mui/material/Paper";
import { API } from "../../config";
import { Box, Grid, Typography } from "@mui/material";
import DetailProfile from "./DetailProfile";
import ImageSwiper from "../ImageSwiper/ImageSwiper";
import { coach_kor_title } from "../../recoil/commonEnum";
const profileItem = [
  { property: "birthDate", name: "생년월일" },
  { property: "height", name: "현 소속" },
  { property: "weight", name: "직함" },
  { property: "mainPosition", name: "자격증" },
];
const imageURLs = [
  "https://img.kbs.co.kr/kbs/620/news.kbs.co.kr/data/fckeditor/new/image/2024/04/01/303281711953532160.jpeg",
  "https://res.heraldm.com/content/image/2024/03/07/20240307050575_0.jpg",
  "https://file2.nocutnews.co.kr/newsroom/image/2022/09/27/202209271039137444_0.jpg",
];
export default function CoachProfile({ coach }) {
  function ListItemTranslate() {
    return [
      { title: "국적", content: "대한민국" },
      { title: "생년월일", content: coach.birthDate },
      {
        title: "현 소속",
        content: coach.currentAffiliation
          ? coach.currentAffiliation.club.name
          : "null",
      },
      {
        title: "직함",
        content: coach_kor_title[coach.currentAffiliation.position],
      },
      {
        title: "자격증",
        content: coach.certifications[0] ? coach.certifications[0].name : "",
      },
    ];
  }
  return (
    <Box>
      {coach ? (
        <Grid container alignItems="center">
          <Grid item xs={12} md={7} sx={{ padding: "3rem 0" }}>
            <DetailProfile
              data={coach}
              profileItem={ListItemTranslate()}
              comType={API.ComType.COACH_PROFILE}
              profileTypeText="COACH"
            ></DetailProfile>
          </Grid>
          {/* <Grid
            item
            xs={12}
            md={5}
            sx={{
              textAlign: "center",
              padding: "3rem 0",
            }}
          >
            <ImageSwiper
              btnClassName="coach-image-swiper"
              imgURL={imageURLs}
            ></ImageSwiper>
          </Grid> */}
        </Grid>
      ) : null}
    </Box>
  );
}
