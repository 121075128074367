import { faEnvelope, faPhone, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Hidden } from "@mui/material";
import styled from "styled-components";
import { API } from "../../config";

const ScouterLabel = styled("span")`
  display: inline-block;
  width: 3rem;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
`;
const ScouterContent = styled("span")`
  display: inline-block;
  font-size: 1rem;
  color: #fff;
`;
const defaultProfileUrl =
  "https://careerboss.co.uk/wp-content/uploads/2018/06/football-scout-taking-notes.jpg";
export default function ScouterCard({ scouter }) {
  return (
    <Box sx={{ borderRadius: "0.5rem", border: "1px solid #ccc" }}>
      <Box>
        <Box
          sx={{
            margin: "0.5rem auto",
            width: "10rem",
            height: "10rem",
            overflow: "hidden",
          }}
        >
          <img
            src={
              scouter.profileImage
                ? `${API.BASE}/${scouter.profileImage.fileUrl}`
                : defaultProfileUrl
            }
            style={{
              width: "10rem",
              height: "10rem",
              objectFit: "cover",
              borderRadius: "100%",
            }}
          ></img>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "rgb(64,132,75)",
          borderRadius: "0 0 0.5rem 0.5rem",
        }}
      >
        <Box sx={{ padding: "0.3rem 0.6rem" }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <ScouterLabel>이름</ScouterLabel>
            </Box>
            <Box>
              <ScouterContent>{scouter.name}</ScouterContent>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <ScouterLabel>소속</ScouterLabel>
            </Box>
            <Box>
              <ScouterContent>{scouter.company}</ScouterContent>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <ScouterLabel>직함</ScouterLabel>
            </Box>
            <Box>
              <ScouterContent>{scouter.companyPosition}</ScouterContent>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <ScouterLabel>
                <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
              </ScouterLabel>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <ScouterContent>{scouter.contactPhoneNumber}</ScouterContent>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <ScouterLabel>
                <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
              </ScouterLabel>
            </Box>
            <Box>
              <ScouterContent>{scouter.contactEmail}</ScouterContent>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: "0.3rem 0.6rem", textAlign: "right" }}>
          <ScouterLabel>
            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
          </ScouterLabel>
        </Box>
      </Box>
    </Box>
  );
}
