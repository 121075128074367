import { Box, Grid, Typography } from "@mui/material";
import ImageAndText from "./ImageAndText";

const texts = [
  {
    mainTitle: '"서로의 니즈를 발견하고\n 수정시키며 발전한다"',
    imgUrl: "https://canda23.com/files/page2.jpg",
    items: [
      {
        title: "선수에게는",
        content:
          "국내외 학생 선수부터 프로선수까지 원하는 팀의 진학/입단/이적을 위한 \n팀 정보 및 팀 현황과 지도자 정보를 확인 할 수 있는 서비스를 제공합니다. \n또한 자신을 필요로 하는 팀과 지도자를 확인 할 수 있습니다.",
      },
      {
        title: "지도자에게는",
        content:
          "팀에 필요한 포지션 별, 신체 특성별, 플레이 스타일의 선수를 \n성공적으로 선발 할 수 있도록 돕습니다. \n지도자에게 필요한 교육 자료(심리, 해부학, 코칭학등)를 제공합니다.",
      },
      {
        title: "구단(팀)에게는",
        content:
          "팀의 유능한 인재를 채용 할 수 있도록 인재 정보를 제공합니다. \n구단의 맞춤 컨텐츠 개발 및 마케팅을 통해 구단의 홍보, 가치를 \n밸류 업 시킬 수 있도록 돕습니다.",
      },
    ],
  },
  {
    mainTitle:
      '"이제 선수와 지도자를 포함한\n 모든 축구 종사자는 \n1인 브랜드 시대입니다"',
    imgUrl: "https://canda23.com/files/page3.jpg",
    items: [
      {
        title: "",
        content:
          "축구적 능력을 필요로 하는 클라이언트들에게 \n자신의 개성과 능력을 어필하여 시장에서의 가치를 확인 시켜야 합니다.",
      },
      {
        title: "",
        content:
          "선수로서 내 자신을 어필할 수 있는 공간. 지도자로서 \n보다 선수 선발을 잘 할 수 있는 가이드 라인 및 스타일을 알 수 있습니다.",
      },
      {
        title: "",
        content:
          "학생선수부터 프로선수까지 자신의 리포트를 통해 능력을 수치화하여 \n팀 지도자 및 스카우팅을 원하는 클라이언트들에게 잘 정리 되어있는 \n사실적 정보를 노출 및 어필 할 기회를 얻고 성공적인 미래를 함께 합니다.",
      },
    ],
  },
  {
    mainTitle:
      '"팀과 지도자, 스카우터에게\n 포지션별 맞춤형 선수의 \n 디테일한 정보와 연결 방법을 제공합니다."',
    imgUrl: "https://canda23.com/files/page4.jpg",
    items: [
      {
        title: "선수 정보",
        content:
          "정리 된 프로필(신장, 수상이력, 트렌스퍼 스토리, 가족관계 등)\n능력 디테일 수치화(경기 당 퍼포먼스)\n스포츠 mbti\n플레이 스타일\n훈련 영상/하일라이트 영상/경기 풀 영상\n",
      },
      {
        title: "팀 정보",
        content:
          "팀 운영자 및 지도자 구성\n선수구성(포지션별, 학년 별)\n팀 운영비 및 기타 비용 오픈\n연도별 입상실적\n팀 진학, 채용 성과 및 대표선수 배출",
      },
    ],
  },
  {
    mainTitle: '"선한영향력은 \n우리의 핵심 사명 입니다."',
    imgUrl: "https://canda23.com/files/page5.jpg",
    items: [
      {
        title: "",
        content:
          "모든 축구 종사자들이 목표를 위해 달려 나아갈때 \n우리는 이들에게 실질적 도움을 줄 수 있는 시스템 구축을 강화합니다.",
      },
      {
        title: "",
        content:
          "축구시장을 성장, 확장화 시키는 일은 곧 축구 종사자들에게\n보다 많은 일자리 제공과 선한 영향력을 전파하여 축구 전체시장의\n질적, 양적 발전을 함께 이루고자 함과 결국 축구를 통한 행복한\n세상만들기를 구현 할 수 있습니다.",
      },
      {
        title: "",
        content:
          "축구로 경제적 이득을 취함은 곧 사회의 환원으로 선한 역할을 할 것입니다.",
      },
    ],
  },
];

export default function Intro() {
  return (
    <Box sx={{ margin: "0 auto" }}>
      <Box
        sx={{
          backgroundImage: `url("https://canda23.com/files/canda_cate_intro.jpg")`,
          backgroundPosition: "center",
          width: { xs: "100%", md: "90%" },
          height: { xs: "20rem", md: "62.5rem" },
          margin: {
            xs: "-20rem auto 0",
            sm: "-30.25rem auto 0",
            md: "-41.25rem auto 0",
          },
          borderRadius: { xs: "0 0 50% 50%" },
        }}
      ></Box>
      {/* <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Box
          sx={{
            backgroundImage: `url("https://canda23.com/files/bee_house.png")`,
            width: "100%",
            height: "37.5rem",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            margin: "-31.25rem -56.25rem",
            opacity: 0.5,
          }}
        ></Box>
      </Box> */}
      <Box sx={{ display: "flex", justifyContent: "center", margin: "5rem 0" }}>
        <Typography
          variant="introTitle1"
          style={{ display: "inline-block", marginRight: "1rem" }}
        >
          ABOUT
        </Typography>
        <Typography variant="introTitle2">CAN-DA</Typography>
      </Box>
      <Box sx={{ position: "relative", margin: "0 auto", width: "90%" }}>
        <Grid container>
          {texts.map((text, index) => (
            <Grid item sm={12} md={6} padding={"1rem"} key={index}>
              <ImageAndText text={text} isBottom={index > 1}></ImageAndText>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        >
          <Grid
            container
            sx={{
              width: "80%",
              height: "37.5rem",
              margin: "0 auto",
              position: "absolute",
              top: "35%",
              left: "10%",
            }}
          >
            <Grid
              item
              xs={6}
              xxl={6}
              sx={{
                borderRight: "1px solid #495057",
                borderBottom: "1px solid #495057",
                height: "18rem",
              }}
            ></Grid>
            <Grid
              item
              xs={6}
              xxl={6}
              sx={{
                borderBottom: "2px solid #495057",

                height: "18rem",
              }}
            ></Grid>
            <Grid
              item
              xs={6}
              xxl={6}
              sx={{
                borderRight: "2px solid #495057",
                height: "18rem",
              }}
            ></Grid>
            <Grid
              item
              xs={6}
              xxl={6}
              sx={{
                height: "18rem",
              }}
            ></Grid>
            <Box
              sx={{
                width: "15.625rem",
                height: "15.625rem",
                border: "1px solid #495057",
                padding: "1rem",
                borderRadius: "50%",
                position: "absolute",
                top: "27%",
                left: "41%",
                backgroundColor: "rgb(25,25,25)",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <img
                style={{ width: "100%" }}
                src="https://canda23.com/files/ball.png"
                alt="ball-img"
              ></img>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
