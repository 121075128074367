import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PlayerProfile from "./PlayerProfile";
import "../../style/player.css";
import PlayerCarrer from "./PlayerCarrer";
import PlayerVideo from "./PlayerVideo";
import axios from "axios";

import { API } from "../../config";

import { Box, Typography } from "@mui/material";
import RowBox from "../../LayoutContainers/RowBox";
import TitleBox from "../../LayoutContainers/TitleBox";
import TitleRowBox from "../../LayoutContainers/TitleRowBox";
import ItemBox from "./ItemBox";
import { enumAtom } from "../../recoil/atoms/applicationEnum";
import { useRecoilState } from "recoil";

export default function PlayerDetail() {
  const [player, setPlayer] = useState(null);
  const [enumData, setEnumData] = useRecoilState(enumAtom);
  const { playerId } = useParams();
  useEffect(() => {
    async function fetchEnum() {
      try {
        const {
          data: { data },
        } = await axios.get(API.APPLICATION_ENUM);
        console.log("enum data", data);
        setEnumData(data);
      } catch (error) {}
    }
    async function fetchdata() {
      try {
        const { data } = await axios.get(`${API.PLAYER_DETAIL}/${playerId}`);
        console.log("player detail data", data);
        setPlayer(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchEnum();
    fetchdata();
  }, []);
  return (
    <Box>
      {player ? (
        <Box>
          <TitleBox title="PLAYER"></TitleBox>
          <RowBox padding="0 0 3rem" mode="light" isBorder={true}>
            <PlayerProfile player={player}></PlayerProfile>
          </RowBox>

          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              선수커리어
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="0"
            margin="0 0 1rem 0"
            mode="light"
            isBorder={true}
          >
            <PlayerCarrer
              player={player}
              margin={{ xs: "0.5rem", md: "1.5rem" }}
            ></PlayerCarrer>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              에이전시
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="2rem"
            margin="0 0 1rem 0"
            mode="light"
            isBorder={true}
          >
            {player.agent.name ? (
              <ItemBox
                data={[
                  { title: "회사명", content: player.agent.name },
                  { title: "연락처", content: player.agent.phoneNumber },
                ]}
              ></ItemBox>
            ) : null}
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              개인 수상
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="2rem"
            margin="0 0 1rem 0"
            mode="light"
            isBorder={true}
          >
            <ItemBox
              data={player.personalAwardHistories.map((pa) => ({
                title: pa.date ? pa.date.substring(0, 4) : "",
                content: pa.name,
              }))}
            ></ItemBox>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              팀 수상
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="2rem"
            margin="0 0 1rem 0"
            mode="light"
            isBorder={true}
          >
            <ItemBox
              data={player.teamAwardHistories.map((pa) => ({
                title: pa.date ? pa.date.substring(0, 4) : "",
                content: pa.name,
              }))}
            ></ItemBox>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              하이라이트
            </Typography>
          </TitleRowBox>
          <RowBox mode="light" isBorder={true}>
            <PlayerVideo
              playerVideo={player.highlightVideos}
              btnClassName={"player-highlight"}
            ></PlayerVideo>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              풀영상
            </Typography>
          </TitleRowBox>
          <RowBox mode="light" isBorder={true}>
            <PlayerVideo
              playerVideo={player.fullVideos}
              btnClassName={"player-highlight"}
            ></PlayerVideo>
          </RowBox>
        </Box>
      ) : null}
    </Box>
  );
}
