import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import ExtractYouTubeURL from "../common/ExtractYouTubeURL";
const ReSizeImg = styled.img`
  display: block;
  object-fit: contain;
  border-radius: 5px;
  margin: 0 auto;
  transition: 500ms;
  width: 100%;
  height: auto;
`;
const ListDiv = styled.div`
  margin: 0.8rem 0;
`;
export default function MainVideoCard({ mode, videoInfo }) {
  return (
    <Box sx={{ cursor: "pointer", width: "100%" }}>
      <Box>
        <ReSizeImg
          src={videoInfo ? ExtractYouTubeURL(videoInfo.url) : ""}
        ></ReSizeImg>
        <FontAwesomeIcon
          icon={faCirclePlay}
          size="2x"
          style={{
            color: "fff",
            position: "absolute",
            right: "20%",
            top: "30%",
            opacity: 0.7,
          }}
        ></FontAwesomeIcon>
      </Box>
      <Box>
        <Box
          sx={{
            width: "100%",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            "-webkit-box-orient": "vertical",
            color: mode === "dark" ? "#fff" : "#000",
            fontSize: { xs: "1.1rem", sm: "1.2rem" },
          }}
        >
          {videoInfo ? videoInfo.title : ""}
        </Box>
        <ListDiv>
          {videoInfo ? (
            videoInfo.subTitle ? (
              <Typography
                variant="teamProfileDetail"
                style={{ color: mode === "dark" ? "#fff" : "#000" }}
              >
                {videoInfo.subTitle}
              </Typography>
            ) : null
          ) : null}
        </ListDiv>
      </Box>
    </Box>
  );
}
