import { Box, Grid, Stack } from "@mui/material";

import { useRecoilValue } from "recoil";
import { detailPositionSelector } from "../../recoil/atoms/detailPositionSelector";
import CarrerTable from "../common/CareerTable";
export default function CoachPositionPreference({ coach, margin }) {
  const headers = ["포지션", "주요 능력"];
  const data = coach.preferPositionStyles.map((ca) => [
    ca.position,
    ca.abilities,
  ]);
  return (
    //coach.preferPositionStyles
    <Box margin={margin ? margin : "1rem"} sx={{ width: "100%" }}>
      <table style={{ width: "95%" }}>
        {coach.preferPositionStyles.map((pps) => (
          <tr style={{ border: "1px solid #aaa", fontSize: "1.2rem" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                lg={3}
                sx={{
                  backgroundColor: "rgb(64,132,75)",
                  textAlign: "center",
                }}
              >
                {pps.position}
              </Grid>
              <Grid
                item
                xs={12}
                lg={9}
                sx={{ textAlign: "center" }}
                padding="0.5rem"
              >
                {pps.abilities}
              </Grid>
            </Grid>
          </tr>
        ))}
      </table>
    </Box>
  );
}
