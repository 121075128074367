import { styled } from "styled-components";
import { useState } from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ItemButton = styled.button`
  border: 1px solid #eee;
  width: 6rem;
  margin: 0 0.2rem;  
  padding: 10px 20px;
  background-color: ${({ "data-active": dataActive }) =>
    dataActive === "true" ? "#0072bc" : "transparent"};
  color: ${({ "data-active": dataActive }) =>
    dataActive === "true" ? "#fff" : "#111"};    
 cursor: pointer;
 &:hover{
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.25), 0 4px 4px rgba(0,0,0,0.22);    
 }
`;
const SearchButton = styled(ItemButton)`
background-color: #008B8B;
color:#fff;
&:hover{
    box-shadow: none;
    color:yellow;
    font-size:1.05rem;
 }
`;
const region = [{"id":0, "name":"전국"},
    {"id":1, "name":"경기도"} ,
    {"id":2, "name":"강원도"} , 
    {"id":3, "name":"충청북도"} , 
    {"id":4, "name":"충청남도"} 
]
const leagueType = [{"id":0, "name":"리그전체"},
    {"id":1, "name":"초등"},
    {"id":2, "name":"중등"} ,
    {"id":3, "name":"고등"} , 
    {"id":4, "name":"대학"} , 
    {"id":5, "name":"K리그"},
    {"id":6, "name":"해외리그"}
]
const jobType = [{"id":0, "name":"모든직군"},
    {"id":1, "name":"감독"},
    {"id":2, "name":"코치"},
    {"id":3, "name":"스카우터"},
    {"id":4, "name":"전력분석"}, 
    {"id":5, "name":"행정"},         
]
export default function StaffWantedFilter() {
  
  const [activeRegion, setActiveRegion] = useState(region.map((item)=>false));
  const [activeLeague, setActiveLeague] = useState(leagueType.map((item)=>false));
  const [activeJob, setActiveJob] = useState(jobType.map((item)=>false));
  
  function regionClickHandler(e){    
    let updatedArray = null
    if(e.currentTarget.value==='0'){
        if(activeRegion[0]==true){//all btn set to false
            updatedArray = activeRegion.map((item) => (false));    
        }else{
            updatedArray = activeRegion.map((item) => (true));    
        }
    }else{
        updatedArray = activeRegion.map((item,index) => (index === parseInt(e.currentTarget.value) ? !item : item));
        if(activeRegion[0]===true){
            updatedArray[0]=false
        }
    }    
    setActiveRegion(updatedArray);    
  }  
  function leagueClickHandler(e){        
    let updatedArray = null
    if(e.currentTarget.value==='0'){
        if(activeLeague[0]==true){//all btn set to false
            updatedArray = activeLeague.map((item) => (false));    
        }else{
            updatedArray = activeLeague.map((item) => (true));    
        }
    }else{
        updatedArray = activeLeague.map((item,index) => (index === parseInt(e.currentTarget.value) ? !item : item));
        if(activeLeague[0]===true){
            updatedArray[0]=false
        }
    }    
    setActiveLeague(updatedArray);    
  }
  function jobClickHandler(e){        
    let updatedArray = null
    if(e.currentTarget.value==='0'){
        if(activeJob[0]==true){//all btn set to false
            updatedArray = activeJob.map((item) => (false));    
        }else{
            updatedArray = activeJob.map((item) => (true));    
        }
    }else{
        updatedArray = activeJob.map((item,index) => (index === parseInt(e.currentTarget.value) ? !item : item));
        if(activeJob[0]===true){
            updatedArray[0]=false
        }
    }    
    setActiveJob(updatedArray);    
  }
  
  return (    
    <div className="filter-wrapper">
        <div className="filter-contition-row">
            <div className="filter-type">
                지역
            </div>
            <div className="filter-items">
                {region.map((item)=>(<ItemButton
                 key={item.id}
                 value={item.id}
                 data-active={activeRegion[item.id] ? "true" : "false"}
                 onClick={regionClickHandler}
                >{item.name}</ItemButton>))}
            </div>
        </div>
        
        <div className="filter-contition-row">
            <div className="filter-type">
                리그
            </div>
            <div className="filter-items">
                {leagueType.map((item)=>(<ItemButton
                    key={item.id}
                    value={item.id}
                    data-active={activeLeague[item.id] ? "true" : "false"}
                    onClick={leagueClickHandler}
                    >{item.name}</ItemButton>))}
            </div>
        </div>

        <div className="filter-contition-row">
            <div className="filter-type">
                구분
            </div>
            <div className="filter-items">
                {jobType.map((item)=>(<ItemButton
                    key={item.id}
                    value={item.id}
                    data-active={activeJob[item.id] ? "true" : "false"}
                    onClick={jobClickHandler}
                    >{item.name}</ItemButton>))}
                    <SearchButton><FontAwesomeIcon icon={faMagnifyingGlass} />검색</SearchButton>
            </div>
            
        </div>    
        
    </div>
  );
};

