import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { API } from "../../../../../config";
import axios from "axios";
import { Box, Grid } from "@mui/material";
import {
  LabelBox,
  StyledButton,
  StyledLabel,
  TitleBox,
} from "../../../common/MyPageComponents";
import styled from "styled-components";
import { width } from "@fortawesome/free-solid-svg-icons/faUniversalAccess";
import Loading from "../../../common/Loading";
import heic2any from "heic2any";
const ItemBox = styled("Box")`
  font-size: 1.1rem;
`;
export default function MPStaffProfileBasic({ basicInfo, setBasicInfo }) {
  const img_ref = useRef(null);
  const [loading, setLoading] = useState(false);

  async function changeImg(event) {
    event.preventDefault();
    const formData = new FormData();

    try {
      let uploadFile = event.target.files[0];
      if (uploadFile.name.split(".")[1] === "heic") {
        setLoading((prev) => true);
        let blob = uploadFile;
        const resultBlob = await heic2any({ blob: blob, toType: "image/jpeg" });
        uploadFile = new File(
          [resultBlob],
          uploadFile.name.split(".")[0] + ".jpg",
          {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          }
        );
        setLoading((prev) => false);
      }
      if (uploadFile.size > API.FILE_UPLOAD_MAX_SIZE) {
        alert(
          `변환된 파일크기가 제한 용량(5MB)를 초과합니다\n 파일크기:${Number(uploadFile.size / 1000000).toFixed(2)}MB`
        );
        return;
      }
      formData.append("file", uploadFile);
      const res = await axios.post(`${API.FILE_BASE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setBasicInfo((prev) => ({
        ...prev,
        profileImage: { id: res.data.data.id, fileUrl: res.data.data.fileUrl },
      }));

      const newData = { ...basicInfo, profileImageId: res.data.data.id };
      delete newData["profileImage"];

      const commonUpdateRes = await axios.put(`${API.MP_COACHE_INFO}`, newData);
      alert("사진이 등록되었습니다.");
    } catch (error) {
      console.log(error);
    }
  }
  async function onRemovePicture() {
    const requestData = { ...basicInfo, profileImageId: null };
    const commonUpdateRes = await axios.put(
      `${API.MP_COACHE_INFO}`,
      requestData
    );

    setBasicInfo((prev) => ({ ...prev, profileImage: null }));
    alert("사진이 삭제되었습니다.");
  }
  return (
    <Box>
      <TitleBox>프로필</TitleBox>
      <Grid
        container
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "left", md: "center" }}
      >
        <Grid item xs={12} lg={4} sx={{ textAlign: "center" }}>
          <Box
            sx={{
              width: "12rem",
              margin: "0 auto",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {basicInfo.profileImage ? (
              <img
                src={`${API.BASE}${basicInfo.profileImage.fileUrl}`}
                alt="profile-img"
                style={{ width: "100%", objectFit: "contain" }}
              ></img>
            ) : (
              <FontAwesomeIcon icon={faUser} size="4x" color="#183153" />
            )}
            {/* <Loading loading={loading}></Loading> */}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ textAlign: "center" }}>
              <StyledLabel sx={{ display: "inline-block" }}>
                {basicInfo.profileImage ? "수정" : "등록"}
                <input
                  type="file"
                  className="file"
                  ref={img_ref}
                  accept="image/*,image/heic"
                  style={{ display: "none" }}
                  onChange={changeImg}
                />
              </StyledLabel>
            </Box>
            <Box>
              <StyledButton onClick={onRemovePicture}>삭제</StyledButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box sx={{ display: "flex" }}>
            <LabelBox>이 름</LabelBox> <ItemBox>{basicInfo.name}</ItemBox>
          </Box>
          <Box sx={{ display: "flex" }}>
            <LabelBox>국 적</LabelBox>
            <ItemBox>{basicInfo.nationality || ""}</ItemBox>
          </Box>
          <Box sx={{ display: "flex" }}>
            <LabelBox>생년월일</LabelBox>
            <ItemBox>{basicInfo.birthDate || ""}</ItemBox>
          </Box>
          <Box sx={{ display: "flex" }}>
            <LabelBox>연락처</LabelBox>
            <ItemBox>{basicInfo.phoneNumber || ""}</ItemBox>
          </Box>
          <Box sx={{ display: "flex" }}>
            <LabelBox>이메일</LabelBox>
            <ItemBox>{basicInfo.email || ""}</ItemBox>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
