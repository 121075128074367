import axios from "axios";
import { useEffect, useState } from "react";
import { API } from "../../../../../config";
import MPPlayerProfileBasic from "./MPPlayerProfileBasic";
import MPPlayerVideo from "./MPPlayerVideo";
import MPPlayerProfilePlayerCareer from "./MPPlayerPlayerCareer";
import MPStaffProfileAward from "../../staff/profile/MPStaffProfileAward";
import { Link, useNavigate } from "react-router-dom";
import MPPlayerCurrentTeam from "./MPPlayerCurrentTeam";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import MPPlayerSNS from "./MPPlayerSNS";
import MPPlayerAgent from "./MPPlayerAgent";
import styled from "styled-components";

const playerPositionText = [
  { key: "CF", text: "CF" },
  { key: "SS", text: "SS" },
  { key: "LWF", text: "LWF" },
  { key: "RWF", text: "RWF" },
  { key: "AM", text: "AM" },
  { key: "LM", text: "LM" },
  { key: "CM", text: "CM" },
  { key: "RM", text: "RM" },
  { key: "DM", text: "DM" },
];
const ItemBox = styled(Box)`
  background-color: #fff;
  border: 1px solid rgb(64, 132, 75);
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 0.5rem/0.5rem;
`;
export default function MPPlayerProfile() {
  const navigate = useNavigate();
  const [basicInfo, setBasicInfo] = useState();
  const [agent, setAgent] = useState();
  const [currentAffiliation, setCurrentAffiliation] = useState();
  const [affiliationHistory, setAffiliationHistory] = useState();
  const [personalAwardHistories, setPersonalAwardHistories] = useState();
  const [teamAwardHistories, setTeamAwardHistories] = useState();
  const [highlightVideos, setHighlightVideos] = useState();
  const [fullVideos, setFullVideos] = useState();
  const [SNS, setSNS] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [lastCRUD, setLastCRUD] = useState("");
  useEffect(() => {
    async function fetchdata() {
      try {
        const res = await axios.get(API.MP_PLAYER);
        const data = res.data.data;
        setBasicInfo({
          name: data.name,
          height: data.height,
          weight: data.weight,
          nationality: data.nationality,
          birthDate: data.birthDate,
          phoneNumber: data.phoneNumber,
          email: data.email,
          profileImage: data.profileImage,
          mainPosition: data.mainPosition,
          secondPosition: data.secondPosition,
          strengths:
            data.strengths.length < 3
              ? [
                  ...data.strengths,
                  ...Array(3 - data.strengths.length).fill("NotDefine"),
                ]
              : data.strengths,
          leagueName: data.leagueName,
          mainFoot: data.mainFoot,
          isEditState: false,
        });
        setAgent((prev) => ({
          ...data.agent,
          isEditState: false,
        }));
        setCurrentAffiliation((prev) => ({
          ...data.currentAffiliation,
          isEditState: false,
        }));
        setAffiliationHistory(
          data.affiliationHistory.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setPersonalAwardHistories(
          data.personalAwardHistories.map((item, index) => ({
            ...item,
            date: item.date.slice(0, 4),
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setTeamAwardHistories(
          data.teamAwardHistories.map((item, index) => ({
            ...item,
            date: item.date.slice(0, 4),
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setSNS(
          data.snsInformations.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setHighlightVideos(
          data.highlightVideos.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
        setFullVideos(
          data.fullVideos.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
          }))
        );
      } catch (error) {
        alert("로그인이 필요합니다.");
        const res = await axios.post(API.LOGOUT);
        sessionStorage.clear();
        navigate("/login");
      }
    }
    if (!sessionStorage.getItem("name")) {
      alert("로그인이 필요합니다");
      sessionStorage.clear();
      navigate("/login");
    } else {
      fetchdata();
    }
  }, []);
  const snackBarClose = (event, reason) => {
    setSnackbarOpen(false);
  };
  return (
    <Box
      sx={{
        fontSize: "1.3rem",
        width: { sm: "auto", xl: "1200px" },
        margin: { sm: "0", xl: "0 auto" },
      }}
    >
      <ItemBox>
        {basicInfo ? (
          <MPPlayerProfileBasic
            basicInfo={basicInfo}
            setBasicInfo={setBasicInfo}
          ></MPPlayerProfileBasic>
        ) : null}
      </ItemBox>
      <ItemBox>
        {agent ? (
          <MPPlayerAgent
            comType={API.ComType.PLAYER_HISOTRY}
            roleType={API.RolyType.PLAYER}
            sectionTitle="에이전트정보"
            agent={agent}
            setAgent={setAgent}
          ></MPPlayerAgent>
        ) : null}
      </ItemBox>
      <ItemBox>
        {currentAffiliation ? (
          <MPPlayerCurrentTeam
            comType={API.ComType.PLAYER_HISOTRY}
            roleType={API.RolyType.PLAYER}
            selectBoxType={API.SelectBoxType.SINGLE_UPDATE}
            sectionTitle="현재소속"
            career={currentAffiliation}
            setCareer={setCurrentAffiliation}
            selectBoxText={playerPositionText}
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPPlayerCurrentTeam>
        ) : null}
      </ItemBox>

      <ItemBox>
        {affiliationHistory ? (
          <MPPlayerProfilePlayerCareer
            comType={API.ComType.PLAYER_HISOTRY}
            roleType={API.RolyType.PLAYER}
            selectBoxType={API.SelectBoxType.MULTIPLE_UPDATE}
            sectionTitle="선수경력"
            career={affiliationHistory}
            setCareer={setAffiliationHistory}
            selectBoxText={playerPositionText}
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPPlayerProfilePlayerCareer>
        ) : null}
      </ItemBox>

      <ItemBox>
        {personalAwardHistories ? (
          <MPStaffProfileAward
            comType={API.ComType.PLAYER_AWARDS_PERSONAL}
            roleType={API.RolyType.PLAYER}
            sectionTitle="개인수상이력"
            awards={personalAwardHistories}
            setAwards={setPersonalAwardHistories}
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPStaffProfileAward>
        ) : null}
      </ItemBox>
      <ItemBox>
        {teamAwardHistories ? (
          <MPStaffProfileAward
            comType={API.ComType.PLAYER_AWARDS_TEAM}
            roleType={API.RolyType.PLAYER}
            sectionTitle="팀수상이력"
            awards={teamAwardHistories}
            setAwards={setTeamAwardHistories}
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPStaffProfileAward>
        ) : null}
      </ItemBox>
      <ItemBox>
        {SNS ? (
          <MPPlayerSNS
            roleType={API.RolyType.PLAYER}
            comType={API.ComType.PLAYER_SNS}
            sectionTitle="SNS"
            SNS={SNS}
            setSNS={setSNS}
          ></MPPlayerSNS>
        ) : null}
      </ItemBox>
      <ItemBox>
        {basicInfo ? (
          <MPPlayerVideo
            comType={API.ComType.PLAYER_VIDEO}
            roleType={API.RolyType.PLAYER}
            highlightVideos={highlightVideos}
            setHighlightVideos={setHighlightVideos}
            videoType="HIGHLIGHT"
            sectionTitle="하이라이트"
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPPlayerVideo>
        ) : null}
      </ItemBox>
      <ItemBox>
        {basicInfo ? (
          <MPPlayerVideo
            comType={API.ComType.PLAYER_VIDEO}
            roleType={API.RolyType.PLAYER}
            highlightVideos={fullVideos}
            setHighlightVideos={setFullVideos}
            videoType="FULL"
            sectionTitle="풀영상"
            setSnackbarOpen={setSnackbarOpen}
            setLastCRUD={setLastCRUD}
          ></MPPlayerVideo>
        ) : null}
      </ItemBox>
    </Box>
  );
}
