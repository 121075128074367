import { Box, Paper } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Bar = styled.span`
  display: inline-block;
  width: 5px;
  height: 1.2rem;
  background-color: rgb(153, 153, 153);
  margin-right: 1em;
`;
const BarTitle = styled.span`
  display: inline-block;
  width: ${(props) => props.width || "5rem"};
  color: rgb(43, 43, 43);
  font-size: 1.2rem;
`;
const BarContent = styled.span`
  display: inline-block;
  color: rgb(43, 43, 43);
  font-size: 1.2rem;
`;
const TextContent = styled.span`
  display: inline-block;
  color: rgb(43, 43, 43);
  font-size: 1.2rem;
  padding: 1rem;
`;
export default function ItemBox({ data, singleText, labelWidth, twoLine }) {
  return (
    <Box>
      {singleText ? (
        <TextContent>{singleText}</TextContent>
      ) : data ? (
        data.map((d, index) => (
          <Box
            key={index}
            sx={{
              display: { xs: "block", sm: "flex" },
              alignItems: "center",
              margin: "1rem 0",
            }}
          >
            <Box>
              <Bar></Bar>
              <BarTitle width={labelWidth}>{d.title}</BarTitle>
            </Box>
            <Box padding={{ xs: "0 0 0 1.4rem", sm: "" }}>
              <BarContent>{d.content}</BarContent>
            </Box>
          </Box>
        ))
      ) : null}
    </Box>
  );
}

{
  /* <Paper className="player-profile-container player-info-section">
            <div className="player-agent-container">
                <span className="section-title">에이전트 소속사</span>
                <div className="contact-info-container">
                    <div className="agent-img-box">
                    <img src="https://cdn.imweb.me/upload/S2023082021715987e22a1/fb228a29c8442.png"></img>
                    </div>
                    <div className="agent-info-box">
                        <span className="agent-info-name">Dami sports agency</span>
                        <div className="contact-info-row">
                            <div className="contact-info-icon-box"><FontAwesomeIcon icon={faLocationDot} color="#183153"/></div>
                            <div className="contact-info-txt">경북 구미시 봉곡로 23길</div>
                        </div>
                        <div className="contact-info-row">
                            <div className="contact-info-icon-box"><FontAwesomeIcon icon={faPhone} color="#183153"/></div>
                            <div className="contact-info-txt">+82-54-478-7549</div>                
                        </div>
                        <div className="contact-info-row">
                            <div className="contact-info-icon-box"><FontAwesomeIcon icon={faFax} color="#183153"/></div>
                            <div className="contact-info-txt">+82)0504 467 4939</div>                
                        </div>
                        <div className="contact-info-row">
                            <div className="contact-info-icon-box"><FontAwesomeIcon icon={faEnvelope} color="#183153"/></div>
                            <div className="contact-info-txt">sungryul@kumoh.ac.kr</div>                
                        </div>
                    </div>
                    
                </div>
            </div>            
            
        </Paper>     */
}
