import MainLayout from "../../LayoutContainers/MainLayout";
import CoachList from "../../components/person/CoachList";
import StaffDetail from "../../components/person/StaffDetail";

export default function CoachDetailLayout() {
  return (
    <MainLayout>
      <StaffDetail></StaffDetail>
    </MainLayout>
  );
}
