import MPVideoListItem from "../../../common/MPVideoListItem";
const testVideos = [
  {
    id: 1,
    title: "12세 선수들의 드리블",
    url: "https://www.youtube.com/watch?v=kNEPTIw-1j4",
  },
  {
    id: 2,
    title: "4개국 출전 유소년 대회",
    url: "https://www.youtube.com/watch?v=ZFNfYgJ8iQE",
  },
];
export default function MPStaffManageTeamVideos({
  comType,
  roleType,
  videos,
  setVideos,
  videoType,
}) {
  return (
    <div>
      {videos.map((item) => (
        <MPVideoListItem
          comType={comType}
          roleType={roleType}
          highlightVideos={videos}
          setHighlightVideos={setVideos}
          currentVideo={item}
          videoType={videoType}
          //setSnackbarOpen={setSnackbarOpen}
          //setLastCRUD={setLastCRUD}
        ></MPVideoListItem>
      ))}
    </div>
  );
}
