import { useParams } from "react-router";
import MyPageMainLayout from "../../../LayoutContainers/MyPageLayout";
import MPStaffProfile from "../../../components/mypage/body/staff/profile/MPStaffProfile";
import StaffSidebar from "../../../components/mypage/sidebar/StaffSidebar";
import MPStaffCreateTeam from "../../../components/mypage/body/staff/createteam/MPStaffCreateTeam";
import MPStaffManageTeam from "../../../components/mypage/body/staff/teamManage/MPStaffManageTeam";
import { Box, Divider } from "@mui/material";

const menuInfo = {
  profile: { index: 0, component: <MPStaffProfile /> },
  createTeam: { index: 1, component: <MPStaffCreateTeam /> },
  teamManage: { index: 2, component: <MPStaffManageTeam /> },
  wanted: 3,
};
export default function MyPageCoachLayout() {
  const { menuType } = useParams();
  return (
    <MyPageMainLayout>
      <Box
        sx={{
          fontSize: "1.3rem",
          width: { sm: "auto", xl: "1200px" },
          margin: { sm: "0", xl: "0 auto" },
        }}
      >
        <Box>
          <StaffSidebar selectIndex={menuInfo[menuType].index}></StaffSidebar>
        </Box>
        <Divider sx={{ margin: "0rem 0 0.5rem" }}></Divider>
        <Box>{menuInfo[menuType].component}</Box>
      </Box>
    </MyPageMainLayout>
  );
}
