import { useEffect, useState } from "react";

import MyTab from "../../../common/MyTab";
import MPStaffManageTeamProfile from "./MPStaffManageTeamProfile";

import MPStaffManageStaff from "./MPStaffManageStaff";
import axios from "axios";
import { API } from "../../../../../config";
import MPStaffManagePlayer from "./MPStaffManagePlayer";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";

export default function MPStaffManageTeam() {
  const navigate = useNavigate();
  const [currentTeam, setCurrentTeam] = useState({}); //{id: lastUsedTeam.id,name: lastUsedTeam.name}
  const [managedTeamList, setManagedTeamList] = useState([]);
  const [teamRequiredInfo, setTeamRequiredInfo] = useState();
  const [teamCommonInfo, setTeamCommonInfo] = useState();
  const [fee, setFee] = useState();
  const [SNS, setSNS] = useState();
  const [images, setImages] = useState();
  const [videos, setVideos] = useState();

  const menuArr = [
    {
      name: "팀정보",
      component:
        teamRequiredInfo && teamCommonInfo ? (
          <MPStaffManageTeamProfile
            currentTeam={currentTeam}
            teamRequiredInfo={teamRequiredInfo}
            setTeamRequiredInfo={setTeamRequiredInfo}
            teamCommonInfo={teamCommonInfo}
            setTeamCommonInfo={setTeamCommonInfo}
            fee={fee}
            setFee={setFee}
            SNS={SNS}
            setSNS={setSNS}
            images={images}
            setImages={setImages}
            videos={videos}
            setVideos={setVideos}
            videoType="HIGHLIGHT"
          ></MPStaffManageTeamProfile>
        ) : null,
    },
    {
      name: "지도자",
      component: (
        <MPStaffManageStaff
          comType={API.ComType.TEAM_COACH}
          roleType={API.RolyType.COACH}
          currentTeam={currentTeam}
        ></MPStaffManageStaff>
      ),
    },
    {
      name: "선수",
      component: (
        <MPStaffManagePlayer
          comType={API.ComType.TEAM_PLAYER}
          roleType={API.RolyType.COACH}
          currentTeam={currentTeam}
          teamType={API.TeamType.STUDENT}
        ></MPStaffManagePlayer>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      var {
        data: { data },
      } = await axios.get(API.MP_COACH_MY_MANAGED_TEAM);
      console.log("MP_COACH_MY_MANAGED_TEAM", data);
      if (data.length === 0) {
        alert("관리할 팀이 없습니다\n팀관리권한을 먼저 신청해주세요");
        navigate("/mypage/coaches/createTeam");
        return;
      }
      console.log("teamList", data);

      setManagedTeamList((prev) => data);
      // const lastUsedTeam = data.reduce(function (prev, curr) {
      //   return prev.lastUsedAt > curr.lastUsedAt ? prev : curr;
      // });
      const lastUsedTeam = data[0];
      setCurrentTeam((prev) => ({
        id: lastUsedTeam.id,
        name: lastUsedTeam.name,
      }));
      console.log(1, lastUsedTeam);

      var {
        data: { data },
      } = await axios.get(`${API.MP_COACH_MY_TEAM_DETAIL}/${lastUsedTeam.id}`);
      console.log(data);
      setTeamRequiredInfo((prev) => ({
        teamId: data.id,
        name: data.name,
        league: data.league ? data.league : null,
        region: data.region,
        logo: data.logo,
        isEditState: false,
      }));
      setTeamCommonInfo((prev) => ({
        teamId: data.id,
        foundedDate: data.foundedDate,
        chiefOfficer: data.chiefOfficer,
        chiefManager: data.chiefManager,
        phoneNumber: data.phoneNumber,
        email: data.email,
        isEditState: false,
      }));
      setFee((prev) => ({
        teamId: data.id,
        educationFee: data.proClubFee.educationFee,
        gameFee: data.proClubFee.gameFee,
        uniformFee: data.proClubFee.uniformFee,
        summerTrainingFee: data.proClubFee.summerTrainingFee,
        winterTrainingFee: data.proClubFee.winterTrainingFee,
        etcFee: data.proClubFee.etcFee,
        isEditState: false,
      }));
      setSNS((prev) =>
        data.snsInformations.map((item, index) => ({
          ...item,
          seq: index,
          fromServer: true,
          isEditState: false,
          teamId: data.id,
        }))
      );
      setImages((prev) =>
        data.images.map((item, index) => ({
          ...item,
          seq: index,
          isFromServer: true,
          isEditState: false,
          teamId: data.id,
        }))
      );

      setVideos((prev) =>
        data.highlightVideos.map((item, index) => ({
          ...item,
          seq: index,
          fromServer: true,
          isEditState: false,
          teamId: data.id,
        }))
      );
    };
    fetchData();
  }, []);
  function selectBoxClick() {
    console.log(currentTeam);
  }
  return (
    <Box>
      {managedTeamList[0] ? (
        <Box>
          <Box
            sx={{
              backgroundColor: "#fff",
              fontSize: "1.2rem",
              fontWeight: "700",
              padding: "0.5rem",
            }}
          >
            {`관리팀: ${managedTeamList[0].name}`}
            {/* <select
          className="my-select-box"
          defaultValue={currentTeam.name}
          value={currentTeam.name}
          name="displayyn"
          onChange={selectBoxClick}
        >
          {managedTeamList.map((item) => (
            <option value={item.name}>{item.name}</option>
          ))}
        </select> */}
          </Box>
          <Box>
            <MyTab tabs={menuArr}></MyTab>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
