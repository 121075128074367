import MainLayout from "../../LayoutContainers/MainLayout";
import RowBox from "../../LayoutContainers/RowBox";
import LoginForm from "../../components/login/LoginForm";

export default function LoginLayout() {
  return (
    <MainLayout mode="light">
      <RowBox
        mode="light"
        bgColor="none"
        padding={"0 0 6rem 0"}
        isBorder={false}
      >
        <LoginForm></LoginForm>
      </RowBox>
    </MainLayout>
  );
}
