import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import TitleBox from "../../LayoutContainers/TitleBox";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import RowBox from "../../LayoutContainers/RowBox";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faEnvelope,
  faLocation,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import "./PlayerSwiper.css";
import AgencyPlayerCard from "./AgencyPlayerCard";
import axios from "axios";
import { API } from "../../config";

const ItemBox = styled(Box)`
  margin: 0 0 1rem 0;
`;
const AgencyLabel = styled("span")`
  display: ${(props) => props.display};
  color: rgb(64, 132, 75);
  font-weight: 600;
  font-size: 1.2rem;
  width: ${(props) => props.width};
`;
const AgencyContent = styled("span")`
  display: ${(props) => props.display};
  font-size: 1.2rem;
`;
const AgencyItemLabel = styled("span")`
  display: inline-block;
  width: 8rem;
  font-size: 1.2rem;
  color: #06402b;
`;
const AgencyItemContent = styled("span")`
  display: inline-block;
  font-size: 1.2rem;
`;

const agencyPlayer = {
  id: 1,
  name: "최성범",
  affliation: "FC안양",
  kitNumber: 19,
  imgUrl:
    "https://www.fc-anyang.com/images/mgrPic/TPlayer/TPlayer-240227145612-77-1.png",
};
const agencyPlayer2 = {
  id: 1,
  name: "최성범",
  affliation: "FC안양",
  kitNumber: 19,
  imgUrl:
    "https://i.namu.wiki/i/faL8lxAsCPVrsnd5QR-T83pZBNC4oXdbceZblKC8c7Kr1JMwWNt0lPIUxpgrvpGDS7YBkMx44mu-N9KIDg5FTA.webp",
};
const agencyPlayerList = [
  agencyPlayer,
  agencyPlayer2,
  agencyPlayer,
  agencyPlayer,
  agencyPlayer,
  agencyPlayer,
  agencyPlayer,
];
export default function AgencyDetail() {
  const { agencyId } = useParams();
  const [agency, setAgency] = useState();
  useEffect(() => {
    async function fetchData() {
      const {
        data: { data },
      } = await axios.get(`${API.AGENCY_LIST}/${agencyId}`);

      setAgency((prev) => data);
    }
    fetchData();
  }, []);
  return (
    <Box sx={{ padding: "0.7rem" }}>
      {agency ? (
        <Box sx={{ paddingBottom: "1rem", borderBottom: "1px solid #ccc" }}>
          <TitleBox title="AGENCY"></TitleBox>
          <RowBox padding="0 0 3rem" mode="light" isBorder={false}>
            <Box sx={{ margin: "0 0 1rem 0" }}>
              <Box
                sx={{
                  width: "12rem",
                  border: "1px solid #ccc",
                  padding: "0.3rem 0.5rem",
                }}
              >
                <img
                  src="https://canda23.com/files/header_logo.png"
                  style={{ width: "100%", objectFit: "contain" }}
                ></img>
              </Box>
              <Box
                sx={{
                  fontSize: "2.2rem",
                  fontWeight: "800",
                  color: "rgb(64,132,75)",
                }}
              >
                CANDA
              </Box>
            </Box>
            <Grid
              container
              sx={{ fontSize: "1.3rem" }}
              justifyContent="space-between"
            >
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  width: "20rem",

                  padding: "0.5rem",
                }}
              >
                <Box>
                  <ItemBox>
                    <AgencyLabel display="block">대표</AgencyLabel>
                    <AgencyContent>{agency.ceo}</AgencyContent>
                  </ItemBox>
                  <ItemBox>
                    <AgencyLabel display="block">직원</AgencyLabel>
                    {agency.staffs.map((st) => (
                      <Box>
                        <AgencyItemLabel>{st.position}</AgencyItemLabel>
                        <AgencyItemContent>{st.name}</AgencyItemContent>
                      </Box>
                    ))}
                  </ItemBox>
                  <Divider></Divider>
                  <Box sx={{ margin: "1rem 0" }}>
                    <AgencyLabel>Contact</AgencyLabel>
                    <ItemBox>
                      <Box>
                        <AgencyLabel display="inline-block" width="2rem">
                          <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                        </AgencyLabel>
                        <AgencyContent>{agency.phoneNumber}</AgencyContent>
                      </Box>
                      <Box>
                        <AgencyLabel display="inline-block" width="2rem">
                          <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                        </AgencyLabel>
                        <AgencyContent>{agency.email}</AgencyContent>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <AgencyLabel display="inline-block" width="2.5rem">
                          <FontAwesomeIcon
                            icon={faLocationDot}
                          ></FontAwesomeIcon>
                        </AgencyLabel>
                        <AgencyContent>{agency.address}</AgencyContent>
                      </Box>
                    </ItemBox>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={7}>
                <Grid container>
                  <Box sx={{ margin: "0 0 1rem" }}>
                    <Typography
                      variant="mainRowTitle"
                      style={{ color: "#555", fontWeight: "600" }}
                    >
                      PLAYER
                    </Typography>
                    <Typography
                      variant="mainRowTitle"
                      sx={{
                        borderRadius: "10%",
                        display: "inline-block",
                        width: "10rem",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        color="rgb(64,132,75)"
                        width="2rem"
                      />
                    </Typography>
                  </Box>
                  <Box width={"100%"} sx={{ position: "relative" }}>
                    <Box sx={{ display: { xs: "none", md: "block" } }}>
                      <i className="swiper-button-next-i player-card-swiper-button-next">
                        &gt;
                      </i>
                      <i className="swiper-button-prev-i player-card-swiper-button-prev">
                        &lt;
                      </i>
                    </Box>
                    <Swiper
                      scrollbar={{
                        el: ".swiper-scrollbar",
                        hide: false,
                      }}
                      spaceBetween={10}
                      slidesPerView={2}
                      breakpoints={{
                        480: {
                          slidesPerView: 3,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                        1024: {
                          slidesPerView: 3,
                        },
                        1200: {
                          slidesPerView: 3,
                        },
                        1920: {
                          slidesPerView: 3,
                        },
                      }}
                      navigation={{
                        nextEl: `.player-card-swiper-button-next`,
                        prevEl: `.player-card-swiper-button-prev`,
                      }}
                      modules={[Scrollbar, Navigation]}
                      className="mySwiper"
                    >
                      {agency.players.map((agp) => (
                        <SwiperSlide>
                          <AgencyPlayerCard
                            agencyPlayer={agp}
                          ></AgencyPlayerCard>
                        </SwiperSlide>
                      ))}
                      <div
                        class="swiper-scrollbar"
                        style={{ backgroundColor: "rgba(64, 132, 75, 0.5)" }}
                      ></div>
                    </Swiper>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </RowBox>
        </Box>
      ) : null}
    </Box>
  );
}
