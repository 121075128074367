import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'
import MyPageHeader from '../../components/mypage/header/MyPageHeader'
import '../../style/main.css'
import '../../style/mypage/mypagecommon.css'
function MyPageMainLayout({ children }) {
  return (
    <div>
      <MyPageHeader></MyPageHeader>      
      {children}
      <Footer></Footer>
    </div>
    
  )
}
export default MyPageMainLayout
