import PlayerCard from "./PlayerCard";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Grid, Typography, useTheme } from "@mui/material";
import { API } from "../../config";
import MainPlayerProfile from "./MainPlayerProfile";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { detailPositionSelector } from "../../recoil/atoms/detailPositionSelector";
import { useRecoilValue } from "recoil";
const center = "38%";
const left = "8%";
const right = "68%";
const positionLoc = [
  { postion: "CF", top: "10%", left: center },
  { postion: "SS", top: "20%", left: center },
  { postion: "AMF", top: "35%", left: center },
  { postion: "DMF", top: "45%", left: center },
  { postion: "LW", top: "20%", left: left },
  { postion: "RW", top: "20%", left: right },
  { postion: "LB", top: "60%", left: left },
  { postion: "CB", top: "70%", left: "25%" },
  { postion: "CB", top: "70%", left: "55%" },
  { postion: "RB", top: "60%", left: right },
  { postion: "GK", top: "85%", left: center },
];
const ScrollDiv = styled.div`
  height: 35rem;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(220, 20, 60); /* 스크롤바 색상 */
    border-radius: 0 10px 10px 0; /* 스크롤바 둥근 테두리 */
    height: 30%;
  }
  &::-webkit-scrollbar-track {
    background: rgba(220, 20, 60, 0.1);
  }
`;
const ReSizeImg = styled.img`
  display: block;
  object-fit: contain;
  border-radius: 5px;
  margin: 0 auto;
`;

export default function MainPlayerList({ recentPlayer }) {
  const positionDetailEnum = useRecoilValue(detailPositionSelector);

  const theme = useTheme();
  const [selectedPlayer, setSelectedPlayer] = useState(recentPlayer[0]);
  // useEffect(() => {
  //   console.log("selectedPlayer", selectedPlayer);
  // }, []);
  return (
    <Box bgcolor="landingBgDark.light">
      <Box>
        <Box marginBottom={2}>
          <Link to={"/players"}>
            <Typography variant="mainRowTitle">PLAYER</Typography>
            <Typography
              variant="mainRowTitle"
              sx={{
                borderRadius: "10%",
                display: "inline-block",
                width: "10rem",
                transition: "0.5s",
                "&:hover": {
                  transform: "translateX(10px)",
                },
              }}
            >
              <FontAwesomeIcon icon={faAngleRight} color="red" width="2rem" />
            </Typography>
          </Link>
        </Box>
        <Box
          sx={{
            border: "1px solid red",
            borderRadius: "10px",
            bgcolor: "landingBgDark.deep",
          }}
        >
          <Grid
            container
            spacing={0}
            alignItems="center"
            height="100%"
            color="white.main"
          >
            <Grid
              item
              sm={12}
              md={7}
              lg={6}
              padding={{
                xs: "1rem 1rem 0 1rem",
                xxl: "1rem 1rem 0 1rem",
              }}
            >
              <MainPlayerProfile profile={selectedPlayer}></MainPlayerProfile>
            </Grid>
            <Grid
              item
              sm={12}
              md={5}
              lg={6}
              sx={{
                maxHeight: "100%",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sx={{
                    textAlign: "center",
                    padding: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: { xs: "0", md: "5rem 0 0 0" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTop: { xs: "1px solid #555", md: "none" },
                      padding: { xs: "0.5rem", sm: "none" },
                      width: { xs: "100%", sm: "70%", md: "90%", lg: "100%" },
                    }}
                  >
                    <Typography color="rgb(245,21,29)">
                      MAIN POSITION
                    </Typography>
                    <Typography>
                      -
                      {positionDetailEnum.find(
                        (pd) => pd.name === selectedPlayer.mainPosition
                      )
                        ? positionDetailEnum.find(
                            (pd) => pd.name === selectedPlayer.mainPosition
                          ).engDescription
                        : ""}
                      -
                    </Typography>
                    <Box
                      sx={{
                        position: "relative",

                        margin: "20px auto",
                      }}
                    >
                      {/* <img
                        src="https://canda23.com/files/field_board.jpg"
                        style={{ width: "100%", objectFit: "contain" }}
                      ></img> */}
                      <img
                        src={
                          selectedPlayer.mainPosition
                            ? `/img/bk_position_${selectedPlayer.mainPosition}.png`
                            : `/img/bk_position.png`
                        }
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                      ></img>
                      {/* {positionLoc.map((pl, index) => (
                        <div
                          key={index}
                          style={{
                            width: "4rem",
                            padding: "0.1rem 1rem",
                            textAlign: "center",
                            position: "absolute",
                            top: pl.top,
                            left: pl.left,

                            fontSize:
                              pl.postion === selectedPlayer.mainPosition
                                ? "1rem"
                                : "0.9rem",
                            background:
                              pl.postion === selectedPlayer.mainPosition
                                ? "red"
                                : "#000",
                            opacity:
                              pl.postion === selectedPlayer.mainPosition
                                ? 1
                                : "0.6",
                            borderRadius: "50%",
                          }}
                        >
                          {pl.postion}
                        </div>
                      ))} */}
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "none",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  <ScrollDiv style={{ height: "35rem", overflow: "auto" }}>
                    {recentPlayer.map((rp, index) => (
                      <PlayerCard
                        key={rp.id}
                        comType={API.ComType.MAIN_RECENT_PLAYER}
                        data={rp}
                        isSelected={rp.id === selectedPlayer.id ? true : false}
                        setSelectedPlayer={setSelectedPlayer}
                      ></PlayerCard>
                    ))}
                  </ScrollDiv>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
