import { useEffect, useRef, useState } from "react";
import TeamListTable from "./TeamListTable";
import axios from "axios";
import { API } from "../../config";
import { useRecoilState, useRecoilValue } from "recoil";
import { enumAtom } from "../../recoil/atoms/applicationEnum";
import { Box } from "@mui/material";

import Filter from "../common/Filter";
import SearchBox from "../common/SearchBox";
import { useNavigationType } from "react-router";
import { useBackHistoryValue } from "../../BackHistory/UseBackControll";
import { teamListBackAtom } from "../../recoil/atoms/teamListBack";

export default function TeamList() {
  const pageSize = 10;
  const [enumData, setEnumData] = useRecoilState(enumAtom);
  const appEnum = useRecoilValue(enumAtom);
  const [teamListBack, setTeamListBack] = useRecoilState(teamListBackAtom);
  const [isLeagueClicked, setIsLeagueClicked] = useState(
    Array(appEnum.leagues.length + 1).fill(false)
  );
  const [isRegionClicked, setIsRegionClicked] = useState(
    Array(appEnum.regions.length + 1).fill(false)
  );

  const [teams, setTeams] = useState(null);
  const teamSearchNameInput = useRef(null);
  const content1Ref = useRef();
  const [pagingInfo, setPagingInfo] = useState({
    isRequired: false,
    totalPages: 0,
  });
  const navigationType = useNavigationType();
  useEffect(() => {
    if (navigationType === "PUSH") {
      //처음 로딩
      fetchdata(0);
    } else if (navigationType === "POP") {
      if (Object.keys(teamListBack).length === 0) {
        //링크 강제 입력
        fetchdata(0);
      } else {
        console.log("teamListBack".teamListBack);

        setTeams((prev) => teamListBack.data.map((tb) => tb));
        setPagingInfo((prev) => ({ ...teamListBack.pagingInfo }));
        setIsLeagueClicked((prev) => teamListBack.filterLeague);
        setIsRegionClicked((prev) => teamListBack.filterRegion);
        setTimeout(
          () =>
            window.scrollTo({
              top: parseInt(teamListBack.scrollY),
              behavior: "instant",
            }),
          0
        );
      }
    }
  }, []);

  function createRequestData() {
    let selectedLeagueIds = [];
    selectedLeagueIds = isLeagueClicked
      .slice(1)
      .map((ilc, index) => (ilc ? appEnum.leagues[index].id : null))
      .flat()
      .filter(Boolean);

    const selectedRegions = appEnum.regions
      .filter((ar, index) => isRegionClicked[index + 1])
      .map((re) => re.name);
    return {
      query: teamSearchNameInput.current.value,
      isActive: null,
      leagueIds: selectedLeagueIds,
      regions: selectedRegions,
    };
  }

  async function fetchdata(currentPage) {
    const requestData = createRequestData();
    const requestURL = `${API.TEAM_SEARCH}?page=${currentPage}&size=${pageSize}`;
    const {
      data: { data },
    } = await axios.post(requestURL, requestData);
    setTeams((prev) =>
      data.content.map((item, index) => ({ ...item, seq: index }))
    );
    setPagingInfo((prev) => ({
      isRequired: data.totalPages <= 1 ? false : true,
      totalPages: parseInt(data.totalPages),
      currentPage: currentPage + 1,
    }));
  }
  async function fetchEnum() {
    try {
      const {
        data: { data },
      } = await axios.get(API.APPLICATION_ENUM);
      console.log("enum data", data);
      setEnumData(data);
    } catch (error) {}
  }

  function scrollScreen() {
    content1Ref.current?.scrollIntoView({ behavior: "auto" });
  }
  return (
    <Box>
      <Box sx={{ paddingBottom: "1rem", borderBottom: "1px solid #ccc" }}>
        {isLeagueClicked ? (
          <Filter
            categoryName="리그"
            filterItem={[
              { name: "ALL", description: "전체", key: 0 },
              ...appEnum.leagues.map((r, index) => ({ ...r, key: index + 1 })),
            ]}
            isClicked={isLeagueClicked}
            setIsClicked={setIsLeagueClicked}
          ></Filter>
        ) : null}
      </Box>
      <Box sx={{ paddingTop: "1rem" }}>
        {isRegionClicked ? (
          <Filter
            categoryName="지역"
            filterItem={[
              { name: "ALL", description: "전체", key: 0 },
              ...appEnum.regions.map((r, index) => ({ ...r, key: index + 1 })),
            ]}
            isClicked={isRegionClicked}
            setIsClicked={setIsRegionClicked}
          ></Filter>
        ) : null}
      </Box>

      <Box>
        <Box ref={content1Ref} sx={{ margin: "1rem 0" }}>
          <SearchBox
            ref={teamSearchNameInput}
            fetchdata={fetchdata}
            placeHolder="팀명"
          ></SearchBox>
        </Box>
      </Box>

      {teams ? (
        <TeamListTable
          teams={teams}
          pagingInfo={pagingInfo}
          setPagingInfo={setPagingInfo}
          fetchdata={fetchdata}
          scrollScreen={scrollScreen}
          setTeamListBack={setTeamListBack}
          isLeagueClicked={isLeagueClicked}
          isRegionClicked={isRegionClicked}
        ></TeamListTable>
      ) : null}
    </Box>
  );
}
