import { Box, Button, Drawer, useTheme } from "@mui/material";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import RowBox from "../RowBox";
import { useState } from "react";
import { Link } from "react-router-dom";
function MainLayout({ children, mode }) {
  const theme = useTheme();
  return (
    <Box>
      <Box>
        <RowBox
          mode={mode}
          bgColor={mode === "dark" ? "landingBgDark.light" : "white"}
          padding={{ xs: "1rem 1rem 2rem 1rem", sm: "1rem 1rem 4rem 1rem" }}
        >
          <Header mode={mode} />
        </RowBox>

        {children}
        <RowBox
          mode={mode}
          bgColor="landingBgDark.lighter"
          padding={"1rem 1rem 4rem 1rem"}
        >
          {mode === "dark" ? (
            <Footer />
          ) : window.innerWidth > 600 ? (
            <Footer />
          ) : null}
        </RowBox>
      </Box>
    </Box>
  );
}
export default MainLayout;
