import { Box, Divider } from "@mui/material";
import MyPageMainLayout from "../../../LayoutContainers/MyPageLayout";
import MPAgentMain from "../../../components/mypage/body/agent/MPAgentMain";
import MPScouterProfile from "../../../components/mypage/body/scouter/MPScouterProfile";
import StaffSidebar from "../../../components/mypage/sidebar/StaffSidebar";
import AgentSidebar from "../../../components/mypage/sidebar/AgentSidebar";
import { useParams } from "react-router";
import MPAgentProfile from "../../../components/mypage/body/agent/MPAgentProfile";
import styled from "styled-components";
import MPAgentCreateAgency from "../../../components/mypage/body/agent/MPAgentCreateAgency";
import MPAgentManageAgency from "../../../components/mypage/body/agent/MPAgentManageAgency";
const menuInfo = {
  profile: { index: 0, component: <MPAgentProfile /> },
  createAgency: { index: 1, component: <MPAgentCreateAgency /> },
  agencyManage: { index: 2, component: <MPAgentManageAgency /> },
  wanted: 3,
};

export default function MyPageAgentLayout() {
  const { menuType } = useParams();
  return (
    <MyPageMainLayout>
      <Box
        sx={{
          fontSize: "1.3rem",
          width: { sm: "auto", xl: "1200px" },
          margin: { sm: "0", xl: "0 auto" },
        }}
      >
        <Box>
          <AgentSidebar selectIndex={menuInfo[menuType].index}></AgentSidebar>
        </Box>
        <Box>{menuInfo[menuType].component}</Box>
      </Box>
    </MyPageMainLayout>
  );
}
