import "./App.css";
import "./style/common.css";
import "./style/table.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import theme from "./assets/theme";
import { deDE as coreDeDE } from "@mui/material/locale";
import ScrollToTop from "./components/router/ScrollToTop";
import { RecoilRoot } from "recoil";
import routes from "./routes";
import axios from "axios";
import { CssBaseline } from "@mui/material";
axios.defaults.withCredentials = true;

function App({ children }) {
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <RecoilRoot>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/main" />} />
            </Routes>
          </BrowserRouter>
        </RecoilRoot>
      </div>
    </ThemeProvider>
  );
}

export default App;
