import WantedCard from "./WantedCard";
import "../../style/wanted.css"
import StaffWantedFilter from "./StaffWantedFilter";
import { faCrown, faMedal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

const temp = Array.apply(null, Array(10)).map((x,i)=>i)
export default function StaffWantedList(){
    
    return(        
        <div>            
            <div className="staff-wanted-filter-wrapper">                
                <StaffWantedFilter></StaffWantedFilter>
            </div>            
            <div className="staff-wanted-cards-wapper">
                <FontAwesomeIcon icon={faCrown} color="#ffd43b"/> 프리미엄
                <ul className="staff-wanted-cards-ul">
                    {
                        temp.map(()=>(<li className="staff-wanted-cards-li">
                                <Link to="/wanted-detail"><WantedCard></WantedCard></Link>
                            </li>))
                    }
                </ul>
            </div>
            <div className="staff-wanted-cards-wapper">
                <FontAwesomeIcon icon={faMedal} color="#ffd43b"/> 일반
                <ul className="staff-wanted-cards-ul">
                    {
                        temp.map(()=>(<li className="staff-wanted-cards-li">
                                <WantedCard></WantedCard>
                            </li>))
                    }                    
                </ul>                
            </div>
        </div>        
    )
}