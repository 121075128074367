import { Box, Button, TextField } from "@mui/material";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API } from "../../../../../config";
import styled from "styled-components";

const LabelBox = styled("div")`
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
`;
const feeType = [
  { keyType: "educationFee", text: "월교육비" },
  { keyType: "gameFee", text: "대회비(1일)" },
  { keyType: "uniformFee", text: "피복비(동하계)" },
  { keyType: "summerTrainingFee", text: "하계훈련비(1일)" },
  { keyType: "winterTrainingFee", text: "동계훈련비(1일)" },
  { keyType: "etcFee", text: "기타비용" },
];
export default function TuitionFee({ fee, setFee }) {
  async function onEditClick() {
    if (fee.isEditState) {
      const teamId = fee.teamId;
      const updateData = fee;

      delete updateData.isEditState;
      delete updateData.teamId;
      console.log(updateData);
      console.log(`${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/fees`);
      try {
        const res = await axios.put(
          `${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/fees`,
          updateData
        );
        alert("수정하였습니다.");
        setFee((prev) => ({
          ...res.data.data,
          teamId: teamId,
          isEditState: false,
        }));
      } catch (error) {
        console.log(error);
      }
    } else {
      setFee((prev) => ({ ...prev, isEditState: true }));
    }
  }
  function onTextChange(event) {
    const inputType = event.target.name;
    const inputValue = event.target.value;

    setFee((prev) => ({ ...prev, [inputType]: inputValue }));
  }
  return (
    <Box>
      <div className="staff-serach-filter-item">
        <Button
          onClick={onEditClick}
          variant="contained"
          sx={{
            backgroundColor: fee.isEditState ? "info.main" : "success.main",
            ":hover": {
              backgroundColor: fee.isEditState ? "info.main" : "success.main",
              color: "yellow",
            },
          }}
        >
          {fee.isEditState ? "저장" : "수정"}
        </Button>
      </div>
      <Box sx={{ fontSize: "1rem" }}>
        <FontAwesomeIcon icon={faCircleInfo} color="#1e8ffa" />
        <span>단위:만원</span>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {feeType.map((item) => (
          <Box sx={{ width: { xs: "48%", md: "16%" } }}>
            <LabelBox>{item.text}</LabelBox>
            <TextField
              type="text"
              size="small"
              variant="outlined"
              disabled={!fee.isEditState}
              name={item.keyType}
              value={fee[item.keyType]}
              onChange={onTextChange}
              sx={{ width: "98%" }}
              inputProps={{ style: { fontSize: "1.1rem" } }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
