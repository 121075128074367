import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";

export default function TitleBox({ title }) {
  const theme = useTheme();
  return (
    <Box sx={{ textAlign: "center", padding: "0 0 4rem 0" }}>
      <Typography variant="mainDetailTitle">{title}</Typography>
    </Box>
  );
}
