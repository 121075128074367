import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { TitleBox } from "../../common/MyPageComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import axios from "axios";
import { API } from "../../../../config";
import FileDrapBox from "./FileDrapBox";

const SearchResultTableHeader = ["이름", "대표자", "연락처"];
const requestStatusTableHeader = ["상태", "신청일", "회사명", "기타"];

const ItemBox = styled(Box)`
  background-color: #fff;
  border: 1px solid rgb(64, 132, 75);
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 0.5rem/0.5rem;
`;

const Label = styled(Box)`
  font-size: 1.1rem;
  color: #001c48;
`;
const Button = styled("button")`
  background-color: ${(props) => (props.fade === "true" ? "#777" : "#1a73e8")};
  border: none;
  width: 4.5rem;
  padding: 0.5rem;
  border-radius: 5%/9%;
  fontsize: 0.85rem;
  color: #fff;
  margin: 0.1rem;
  &:hover {
    background-color: ${(props) => (props.fade === "true" ? "" : "#1068e0")};
    cursor: ${(props) => (props.fade === "true" ? "" : "pointer")};
  }
`;

export default function MPAgentCreateAgency() {
  const inputRef = useRef([]);
  const mandataryInputRef = useRef([]);
  const [searchAgentList, setSearchAgentList] = useState([]);
  const [mandataryWrite, setMandataryWrite] = useState(false);
  const [uploadedInfo, setUploadedInfo] = useState(null);
  const [clickedAgency, setClickedAgency] = useState();
  async function onschBtnClick(event) {
    const searchKeyword = inputRef.current[0].value;

    try {
      const res = await axios.get(
        `${API.MP_AGENT_AGENCY_SEARCH}?page=0&size=20`
      );
      const findAgent = res.data.data.content;
      if (findAgent.length === 0) {
        alert(
          "조회한 에이전시가 없습니다. \n 다시 검색하거나 아래의 에이전시 생성 요청을 이용하세요."
        );
      }
      setSearchAgentList((prev) => findAgent);
    } catch (error) {
      alert("에이전시 조회에 일시적 문제가 발생했습니다");
    }
  }
  function onClickInput() {
    setMandataryWrite((prev) => true);
  }
  async function onClickMandataryCreateRequest() {
    if (mandataryInputRef.current[0].value.trim().length === 0) {
      alert("회사명을 입력해주세요");
      return;
    }
    if (!uploadedInfo) {
      alert("사업자등록증을 입력해주세요");
      return;
    }
    if (mandataryInputRef.current[1].value.trim().length === 0) {
      alert("연락할 전화번호를 입력해주세요");
      return;
    }
    if (mandataryInputRef.current[2].value.trim().length === 0) {
      alert("연락할 이메일을 입력해주세요");
      return;
    }
    try {
      const formData = new FormData();
      const uploadFile = uploadedInfo.file;
      formData.append("file", uploadFile);
      const res = await axios.post(`${API.FILE_BASE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const requestData = {
        agencyName: mandataryInputRef.current[0].value,
        publisherPhoneNumber: mandataryInputRef.current[1].value,
        publisherEmail: mandataryInputRef.current[2].value,
        businessLicenseFileId: res.data.data.id,
      };

      const {
        data: { data },
      } = await axios.post(`${API.MP_AGENCY}`, requestData);
      alert("에이전시 생성을 신청하였습니다");
    } catch (error) {}
  }
  async function onClickExistCreateRequest() {
    if (!clickedAgency) {
      alert("에이전시를 먼저 선택하세요");
      return;
    }
    const publisherPhoneNumber = inputRef.current[1].value.trim();
    const publisherEmail = inputRef.current[2].value.trim();
    if (publisherPhoneNumber.length === 0) {
      alert("연락할 전화번호를 입력해주세요");
      return;
    }
    if (publisherEmail.length === 0) {
      alert("연락할 이메일을 입력해주세요");
      return;
    }
    try {
      const requestData = {
        publisherEmail: publisherEmail,
        publisherPhoneNumber: publisherPhoneNumber,
      };
      const {
        data: { data },
      } = await axios.post(
        `${API.MP_AGENCY}/${clickedAgency.id}/admin-requests`,
        requestData
      );
      alert("에이전시 관리권한을 신청하였습니다");
    } catch (error) {
      alert(error.response.data.message || "관리자에게 문의하세요");
    }
  }
  function onListClick(event) {
    const clickedAgencyId = parseInt(event.currentTarget.getAttribute("value"));

    const findAgency = searchAgentList.find(
      (item) => item.id === clickedAgencyId
    );
    setClickedAgency(findAgency);
  }
  function phoneNumberChange() {
    inputRef.current[1].value = inputRef.current[1].value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
  return (
    <Box>
      <ItemBox>
        <TitleBox>등록된 에이전시 관리권한 신청일자</TitleBox>
        <Box>
          <Box>
            <Box>
              <FontAwesomeIcon icon={faCircleInfo} color="#1e8ffa" />
              <span style={{ fontSize: "1rem" }}>
                에이전시 정보 및 직원, 소속선수 등 에이전시 관리를 위해 권한을
                신청해주세요
              </span>
            </Box>
            <p
              style={{
                fontSize: "1.1rem",
                fontWeight: "600",
              }}
            >
              등록된 에이전시를 조회해보세요
            </p>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box className="search-input">
              <TextField
                inputRef={(el) => (inputRef.current[0] = el)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") onschBtnClick();
                }}
                size="small"
                variant="outlined"
                placeholder="agency"
                inputProps={{ style: { fontSize: "1.1rem", height: "1.1rem" } }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="search-icon"
              />
            </Box>
            <Box>
              <Button variant="contained" onClick={onschBtnClick}>
                검색
              </Button>
            </Box>
          </Box>
          <Box>
            <TableContainer
              component={Paper}
              sx={{
                margin: "1rem 0",
                maxHeight: 250,
                width: "100%",
              }}
            >
              <Table
                sx={{ width: "90%", margin: "1rem", border: "1px solid #eee" }}
              >
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#eee" }}>
                    {SearchResultTableHeader.map((fath) => (
                      <TableCell
                        sx={{ textAlign: "center", padding: "0.5rem 0" }}
                      >
                        {fath}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchAgentList
                    ? searchAgentList.map((row) => (
                        <TableRow
                          onClick={onListClick}
                          value={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:hover": { backgroundColor: "#eee" },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell
                            sx={{ textAlign: "center", padding: "0.5rem 0" }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", padding: "0" }}>
                            {row.ceo}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", padding: "0" }}>
                            {row.phoneNumber}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{ width: "49%", border: "1px solid #eee", padding: "0.5rem" }}
            >
              <Box
                sx={{
                  fontSize: "1.2rem",
                  color: "001c48",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                에이전시 정보
              </Box>
              <Box>
                <Label>회사명</Label>
                <TextField
                  type="text"
                  disabled={true}
                  value={clickedAgency ? clickedAgency.name : ""}
                  size="small"
                  variant="outlined"
                  placeholder="회사명"
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
              <Box>
                <Label>대표자</Label>
                <TextField
                  type="text"
                  disabled={true}
                  value={clickedAgency ? clickedAgency.ceo : ""}
                  size="small"
                  variant="outlined"
                  placeholder="대표자"
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
              <Box>
                <Label>연락처</Label>
                <TextField
                  type="text"
                  disabled={true}
                  value={clickedAgency ? clickedAgency.phoneNumber : ""}
                  size="small"
                  variant="outlined"
                  placeholder="연락처"
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
            </Box>
            <Box
              sx={{ width: "49%", border: "1px solid #eee", padding: "0.5rem" }}
            >
              <Box
                sx={{
                  fontSize: "1.2rem",
                  color: "001c48",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                신청자 정보
              </Box>
              <Box sx={{ marginBottom: "0.5rem", fontSize: "1rem" }}>
                <FontAwesomeIcon icon={faCircleInfo} color="#1e8ffa" />
                신청자 확인을 위한 연락처가 필요합니다
              </Box>

              <Box>
                <Label>연락처</Label>
                <TextField
                  type="text"
                  inputRef={(el) => (inputRef.current[1] = el)}
                  size="small"
                  variant="outlined"
                  placeholder="-없이 숫자만 입력"
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                  onChange={phoneNumberChange}
                />
              </Box>
              <Box>
                <Label>이메일</Label>
                <TextField
                  type="text"
                  inputRef={(el) => (inputRef.current[2] = el)}
                  size="small"
                  variant="outlined"
                  placeholder="이메일"
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="info"
                  onClick={onClickExistCreateRequest}
                >
                  신청
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </ItemBox>
      <ItemBox>
        <TitleBox>에이전시 생성 요청</TitleBox>
        <Box>
          <Box>
            <Box>
              <FontAwesomeIcon icon={faCircleInfo} color="#1e8ffa" />
              <span style={{ fontSize: "1rem" }}>
                관리할 에이전시가 검색되지 않을 경우, 직접 에이전시를 생성하세요
              </span>
            </Box>
            <Box>
              <Button
                fade={mandataryWrite ? "true" : "false"}
                onClick={onClickInput}
              >
                입력
              </Button>

              <Button
                fade={mandataryWrite ? "false" : "true"}
                onClick={onClickMandataryCreateRequest}
                disabled={!mandataryWrite}
              >
                신청
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{ width: "49%", border: "1px solid #eee", padding: "0.5rem" }}
            >
              <Box
                sx={{
                  fontSize: "1.2rem",
                  color: "001c48",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                에이전시 정보
              </Box>
              <Box>
                <Label>회사명</Label>
                <TextField
                  type="text"
                  disabled={!mandataryWrite}
                  inputRef={(el) => (mandataryInputRef.current[0] = el)}
                  size="small"
                  variant="outlined"
                  placeholder="회사명"
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
              <Box>
                <Label>사업자등록증</Label>
                <FileDrapBox
                  uploadedInfo={uploadedInfo}
                  setUploadedInfo={setUploadedInfo}
                  mandataryWrite={mandataryWrite}
                ></FileDrapBox>
              </Box>
            </Box>
            <Box
              sx={{ width: "49%", border: "1px solid #eee", padding: "0.5rem" }}
            >
              <Box
                sx={{
                  fontSize: "1.2rem",
                  color: "001c48",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                신청자 정보
              </Box>
              <Box sx={{ marginBottom: "0.5rem", fontSize: "1rem" }}>
                <FontAwesomeIcon icon={faCircleInfo} color="#1e8ffa" />
                생성 요청시 시, 신청자 확인을 위한 연락처가 필요합니다
              </Box>

              <Box>
                <Label>연락처</Label>
                <TextField
                  type="text"
                  inputRef={(el) => (mandataryInputRef.current[1] = el)}
                  size="small"
                  variant="outlined"
                  placeholder="-없이 숫자만 입력"
                  disabled={!mandataryWrite}
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
              <Box>
                <Label>이메일</Label>
                <TextField
                  type="text"
                  inputRef={(el) => (mandataryInputRef.current[2] = el)}
                  size="small"
                  variant="outlined"
                  placeholder="이메일"
                  disabled={!mandataryWrite}
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </ItemBox>
      <ItemBox>
        <TitleBox>에이전시 관리 요청 현황</TitleBox>
        <Box>
          <TableContainer
            component={Paper}
            sx={{ margin: "1rem 0", maxHeight: 250, width: "100%" }}
          >
            <Table sx={{ width: "90%", margin: "1rem" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#eee" }}>
                  {requestStatusTableHeader.map((fath) => (
                    <TableCell
                      sx={{ textAlign: "center", padding: "0.5rem 0" }}
                    >
                      {fath}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {searchAgentList
                  ? searchAgentList.map((row) => (
                      <TableRow
                        value={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{ textAlign: "center", padding: "0.5rem 0" }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", padding: "0" }}>
                          abc
                        </TableCell>
                      </TableRow>
                    ))
                  : null} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ItemBox>
    </Box>
  );
}
