import styled from "styled-components";

export const LabelBox = styled("Box")`
  color: #777;
  width: ${(props) => (props.width ? props.width : "7rem")};
`;
export const TitleBox = styled("p")`
  font-size: 1.5rem;
  color: #001c48;
  margin-bottom: 1rem;
  &:before {
    display: inline-block;
    content: "";
    width: 0.3rem;
    height: 1.4rem;
    background-color: #001c48;
    margin-right: 0.5rem;
  }
`;
export const SubTitleBox = styled("p")`
  font-size: 1.4rem;
  color: #001c48;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  &:before {
    display: inline-block;
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    background-color: #001c48;
    margin-right: 0.5rem;
    border-radius: 50%;
  }
`;
export const StyledButton = styled("div")`
  background-color: rgb(64, 132, 75);
  text-align: center;
  width: 5rem;
  padding: 0.3rem;
  font-size: 1rem;
  color: #eee;
  border-radius: 5%/10%;
  margin: 0.2rem;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`;
export const StyledLabel = styled("label")`
  display: inline-block;
  background-color: rgb(64, 132, 75);
  text-align: center;
  width: ${(props) => props.width || "5rem"};
  padding: 0.3rem;
  font-size: 1rem;
  color: #eee;
  border-radius: 5%/10%;
  margin: 0.2rem;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`;
