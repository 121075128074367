import { Box, Divider, Grid, Typography } from "@mui/material";
import TitleBox from "../../LayoutContainers/TitleBox";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ScouterCard from "./ScouterCard";
import AgencyCard from "./AgencyCard";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../config";
const personal = {
  name: "이범호",
  imgUrl:
    "https://careerboss.co.uk/wp-content/uploads/2018/06/football-scout-taking-notes.jpg",
  affliation: "CANDA Agency",
  position: "스카우터",
  phone: "031-467-4939",
  email: "canda.lys76@gmail.com",
};
const agency = {
  name: "CANDA",
  imgUrl: "https://canda23.com/files/header_logo.png",
};
const scouterData = [
  personal,
  personal,
  personal,
  personal,
  personal,
  personal,
  personal,
  personal,
];
const agenyData = [
  agency,
  agency,
  agency,
  agency,
  agency,
  agency,
  agency,
  agency,
];
export default function ScouterList() {
  const [scouterList, setScouterList] = useState(null);
  const [agentList, setAgentList] = useState(null);
  useEffect(() => {
    async function fetchScouter() {
      const {
        data: {
          data: { content },
        },
      } = await axios.get(`${API.SCOUTER_LIST}?page=0&size=10`);
      setScouterList((prev) => content);
    }
    async function fetchAgency() {
      const {
        data: {
          data: { content },
        },
      } = await axios.get(`${API.AGENCY_LIST}?page=0&size=10`);

      setAgentList((prev) => content);
    }
    fetchScouter();
    fetchAgency();
  }, []);

  return (
    <Box sx={{ padding: "0.7rem" }}>
      <Box sx={{ paddingBottom: "1rem", borderBottom: "1px solid #ccc" }}>
        <TitleBox title="SCOUTER/AGENCY"></TitleBox>
      </Box>
      <Box
        sx={{
          margin: "2rem 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            variant="mainRowTitle"
            style={{ color: "#555", fontWeight: "600" }}
          >
            SCOUTER
          </Typography>
          <Typography
            variant="mainRowTitle"
            sx={{
              borderRadius: "10%",
              display: "inline-block",
              width: "10rem",
            }}
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              color="rgb(64,132,75)"
              width="2rem"
            />
          </Typography>
        </Box>
        <Box
          sx={{
            fontSize: "1.1rem",
            color: "rgb(64,132,75)",
            cursor: "pointer",
          }}
        >
          더보기+
        </Box>
      </Box>
      {scouterList ? (
        <Box sx={{ margin: "0 0 2rem 0" }}>
          <Grid container spacing={1}>
            {scouterList.map((sc) => (
              <Grid item xs={6} md={3}>
                <ScouterCard scouter={sc}></ScouterCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}

      <Divider></Divider>
      <Box
        sx={{
          margin: "2rem 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            variant="mainRowTitle"
            style={{ color: "#555", fontWeight: "600" }}
          >
            AGENCY
          </Typography>
          <Typography
            variant="mainRowTitle"
            sx={{
              borderRadius: "10%",
              display: "inline-block",
              width: "10rem",
            }}
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              color="rgb(64,132,75)"
              width="2rem"
            />
          </Typography>
        </Box>
        <Box
          sx={{
            fontSize: "1.1rem",
            color: "rgb(64,132,75)",
            cursor: "pointer",
          }}
        >
          더보기+
        </Box>
      </Box>
      <Box sx={{ margin: "2rem 0 2rem 0" }}>
        {agentList ? (
          <Grid container spacing={1}>
            {agentList.map((ag) => (
              <Grid item xs={6} md={3}>
                <AgencyCard agency={ag}></AgencyCard>
              </Grid>
            ))}
          </Grid>
        ) : null}
      </Box>
    </Box>
  );
}
