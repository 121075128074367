import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MPImageList from "../../../../common/MPImageList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Button } from "@mui/material";

export default function MPPlayerImages({
  comType,
  roleType,
  introImages,
  setIntroImages,
}) {
  const [seq, setSeq] = useState(introImages.length);
  function onAddIntroImages() {
    setIntroImages((prev) => [
      ...prev,
      {
        fromServer: false,
        id: null,
        type: "INSTAGRAM",
        url: null,
        isEditState: true,
        seq: seq,
      },
    ]);
    setSeq((prev) => prev + 1);
  }
  return (
    <div className="profile-wrapper">
      <span className="section-title">사진</span>
      <Button
        id="player-career-add-btn"
        variant="text"
        sx={{ fontSize: "1rem" }}
        onClick={onAddIntroImages} //onAddImage
      >
        <FontAwesomeIcon
          className="add-btn-icon"
          color="#1976D2"
          icon={faPlus}
        />
        추가하기
      </Button>
      <MPImageList
        comType={comType}
        roleType={roleType}
        images={introImages}
        setImages={setIntroImages}
      ></MPImageList>
    </div>
  );
}
