import { Box, Button, Grid, TextField } from "@mui/material";
import {
  StyledButton,
  StyledLabel,
  TitleBox,
} from "../../common/MyPageComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { API } from "../../../../config";
import styled from "styled-components";
import { useRef, useState } from "react";
import axios from "axios";
import Loading from "../../common/Loading";
import heic2any from "heic2any";

const ProfileLabel = styled("span")`
  display: inline-block;
  font-size: 1.2rem;
  width: 5rem;
`;
const Span = styled("span")`
  display: inline-block;
  font-size: 1.2rem;
`;

const ModifiedLable = styled("span")`
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  width: 7rem;
`;

const ProfileRow = styled(Box)``;

export default function MPScouterProfile({ basicInfo, setBasicInfo }) {
  const [isEditState, setIsEditState] = useState(false);
  const img_ref = useRef(null);
  const [loading, setLoading] = useState(false);
  async function changeImgText(event) {
    event.preventDefault();
    const formData = new FormData();
    try {
      let uploadFile = event.target.files[0];
      if (uploadFile.name.split(".")[1] === "heic") {
        setLoading((prev) => true);
        let blob = uploadFile;
        const resultBlob = await heic2any({ blob: blob, toType: "image/jpeg" });
        uploadFile = new File(
          [resultBlob],
          uploadFile.name.split(".")[0] + ".jpg",
          {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          }
        );
        setLoading((prev) => false);
      }
      if (uploadFile.size > API.FILE_UPLOAD_MAX_SIZE) {
        alert(
          `변환된 파일크기가 제한 용량(5MB)를 초과합니다\n 파일크기:${Number(uploadFile.size / 1000000).toFixed(2)}MB`
        );
        return;
      }
      formData.append("file", uploadFile);
      const res = await axios.post(`${API.FILE_BASE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setBasicInfo((prev) => ({
        ...prev,
        profileImage: { id: res.data.data.id, fileUrl: res.data.data.fileUrl },
      }));
      const requestData = MakeRequestData(res.data.data.id);
      const {
        data: { data },
      } = await axios.put(`${API.MP_SCOUTER_COMMON}`, requestData);
      alert("사진을 등록하였습니다.");
    } catch (error) {
      console.log(error);
    }
  }
  async function onRemovePicture() {
    try {
      const requestData = MakeRequestData();
      requestData["profileImageId"] = null;
      const {
        data: { data },
      } = await axios.put(API.MP_SCOUTER_COMMON, requestData);
      setBasicInfo((prev) => data);
      setIsEditState((prev) => false);
      alert("사진을 삭제했습니다");
    } catch (error) {
      alert("사진삭제에 실패했습니다");
    }
  }
  function MakeRequestData(profileImageId) {
    return {
      contactEmail: basicInfo.contactEmail,
      contactPhoneNumber: basicInfo.contactPhoneNumber,
      company: basicInfo.company,
      companyPosition: basicInfo.companyPosition,
      profileImageId: profileImageId
        ? profileImageId
        : basicInfo.profileImage
          ? basicInfo.profileImage.id
          : null,
    };
  }
  async function requestCoachInfoUpdate() {
    try {
      const requestData = MakeRequestData();
      const {
        data: { data },
      } = await axios.put(API.MP_SCOUTER_COMMON, requestData);
      setBasicInfo((prev) => data);
      setIsEditState((prev) => false);
      alert("수정성공");
    } catch (error) {
      alert("수정에 실패했습니다");
    }
  }
  function changeTextField(event) {
    const { name, value } = event.target;
    if (name === "scouter-input-phoneNumber") {
      setBasicInfo((prev) => ({ ...prev, contactPhoneNumber: value }));
    } else if (name === "scouter-input-email") {
      setBasicInfo((prev) => ({ ...prev, contactEmail: value }));
    } else if (name === "scouter-input-company") {
      setBasicInfo((prev) => ({ ...prev, company: value }));
    } else if (name === "scouter-input-position") {
      setBasicInfo((prev) => ({ ...prev, companyPosition: value }));
    }
  }
  function onEditBtnClick() {
    if (isEditState) {
      //수정요청
      requestCoachInfoUpdate();
    } else {
      setIsEditState((prev) => !prev);
    }
  }

  return (
    <Box>
      <TitleBox>프로필</TitleBox>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
        <Box sx={{ margin: "0 1rem 1rem 0" }}>
          <Box
            sx={{
              width: "13rem",
              overflow: "hidden",
              border: "1px solid #eee",
              textAlign: "center",
              position: "relative",
            }}
          >
            {basicInfo.profileImage ? (
              <img
                src={`${API.BASE}${basicInfo.profileImage.fileUrl}`}
                style={{ width: "100%", objectFit: "contain" }}
                alt="profile-img"
              ></img>
            ) : (
              <FontAwesomeIcon icon={faUser} size="4x" color="#183153" />
            )}
            <Loading loading={loading}></Loading>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Box>
                <StyledLabel sx={{ display: "inline-block" }}>
                  {basicInfo.profileImage ? "수정" : "등록"}
                  <input
                    type="file"
                    className="file"
                    ref={img_ref}
                    accept="image/*,image/heic"
                    onChange={changeImgText}
                    style={{ display: "none" }}
                  />
                </StyledLabel>
              </Box>
              <Box>
                <StyledButton onClick={onRemovePicture}>삭제</StyledButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ margin: "0 1rem 1rem 0" }}>
          <ProfileRow>
            <ProfileLabel>이름</ProfileLabel>
            <Span>{basicInfo.name}</Span>
          </ProfileRow>
          <ProfileRow>
            <ProfileLabel>연락처 </ProfileLabel>
            <Span>{basicInfo.phoneNumber}</Span>
          </ProfileRow>
          <ProfileRow>
            <ProfileLabel>이메일 </ProfileLabel>
            <Span>{basicInfo.email}</Span>
          </ProfileRow>
        </Box>
      </Box>
      <Box sx={{ border: "1px solid #eee", padding: "0.5rem" }}>
        <TitleBox>개인정보수정</TitleBox>
        <Button
          onClick={onEditBtnClick} //onEditBtnClick
          variant="contained"
          sx={{
            backgroundColor: isEditState ? "info.main" : "success.main",
            ":hover": {
              backgroundColor: isEditState ? "info.main" : "success.main",
              color: "yellow",
            },
          }}
        >
          {isEditState ? "저장" : "수정"}
        </Button>
        <Box sx={{ padding: "0.5rem" }}>
          <Box sx={{ maxWidth: "30rem" }}>
            <ModifiedLable>공개연락처</ModifiedLable>
            <TextField
              value={basicInfo.contactPhoneNumber}
              size="small"
              variant="outlined"
              disabled={!isEditState}
              name="scouter-input-phoneNumber"
              onChange={changeTextField}
              sx={{ m: "0.5rem 0", width: { xs: "95%" } }}
              inputProps={{ style: { fontSize: "1.2rem", height: "1.2rem" } }}
            />
          </Box>
          <Box sx={{ maxWidth: "30rem" }}>
            <ModifiedLable>공개이메일</ModifiedLable>

            <TextField
              value={basicInfo.contactEmail}
              size="small"
              variant="outlined"
              disabled={!isEditState}
              name="scouter-input-email"
              onChange={changeTextField}
              sx={{ m: "0.5rem 0", width: { xs: "95%" } }}
              inputProps={{ style: { fontSize: "1.2rem", height: "1.2rem" } }}
            />
          </Box>
          <Box sx={{ maxWidth: "30rem" }}>
            <ModifiedLable>소속</ModifiedLable>
            <TextField
              value={basicInfo.company}
              size="small"
              variant="outlined"
              disabled={!isEditState}
              name="scouter-input-company"
              onChange={changeTextField}
              sx={{ m: "0.5rem 0", width: { xs: "95%" } }}
              inputProps={{ style: { fontSize: "1.2rem", height: "1.2rem" } }}
            />
          </Box>
          <Box sx={{ maxWidth: "30rem" }}>
            <ModifiedLable>직함</ModifiedLable>
            <TextField
              value={basicInfo.companyPosition}
              size="small"
              variant="outlined"
              disabled={!isEditState}
              name="scouter-input-position"
              onChange={changeTextField}
              sx={{ m: "0.5rem 0", width: { xs: "95%" } }}
              inputProps={{ style: { fontSize: "1.2rem", height: "1.2rem" } }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
