import { Box } from "@mui/material";
import { useRef, useState } from "react";
import "./filebox.css";

export default function FileDrapBox({
  uploadedInfo,
  setUploadedInfo,
  mandataryWrite,
}) {
  const img_ref = useRef(null);
  const Logo = () => (
    <svg className="icon" x="0px" y="0px" viewBox="0 0 24 24">
      <path fill="transparent" d="M0,0h24v24H0V0z" />
      <path
        fill="#000"
        d="M20.5,5.2l-1.4-1.7C18.9,3.2,18.5,3,18,3H6C5.5,3,5.1,3.2,4.8,3.5L3.5,5.2C3.2,5.6,3,6,3,6.5V19  c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V6.5C21,6,20.8,5.6,20.5,5.2z M12,17.5L6.5,12H10v-2h4v2h3.5L12,17.5z M5.1,5l0.8-1h12l0.9,1  H5.1z"
      />
    </svg>
  );
  const handleDrop = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    console.log(file);
    setActive(false);

    // 드롭된 파일 핸들링
    // ...
  };
  const UploadBox = () => {
    const setFileInfo = (file) => {
      const { name, size: byteSize, type } = file;
      const size = (byteSize / (1024 * 1024)).toFixed(2) + "mb";
      setUploadedInfo({ file: file, name: name }); // name, size, type 정보를 uploadedInfo에 저장
    };

    const handleDrop = (event) => {
      event.preventDefault();
      setActive(false);

      const file = event.dataTransfer.files[0];
      console.log("file", file);
      setFileInfo(file); // 코드 추가
    };

    const handleUpload = ({ target }) => {
      const file = target.files[0];
      setFileInfo(file); // 코드 추가
    };

    const handleDragOver = (event) => {
      event.preventDefault(); // 필수 1
    };
    const removeFile = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setUploadedInfo((prev) => null);
    };
    return (
      <label
        className={`preview${isActive ? " active" : ""}`}
        onDragEnter={handleDragStart}
        onDragOver={handleDragOver}
        onDragLeave={handleDragEnd}
        onDrop={handleDrop}
      >
        <input
          type="file"
          className="file"
          ref={img_ref}
          onChange={handleUpload}
          disabled={!mandataryWrite}
        />
        {uploadedInfo ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "90%",
              border: "1px solid #eee",
              padding: { xs: "0.1rem", sm: "0.5rem 2rem" },
            }}
          >
            <Box>{<span className="info_value">{uploadedInfo.name}</span>}</Box>
            <Box
              onClick={removeFile}
              sx={{
                padding: "0 1rem",
                "&:hover": { backgroundColor: "#eee" },
              }}
            >
              x
            </Box>
          </Box>
        ) : (
          <Box sx={{ fontSize: "1.1rem", width: "100%" }}>
            <p className="preview_msg">클릭 or 파일 드롭</p>
            <p className="preview_desc">pdf파일만</p>
          </Box>
        )}
      </label>
    );
  };

  const [isActive, setActive] = useState(false);
  const handleDragStart = () => setActive(true);
  const handleDragEnd = () => setActive(false);
  return <Box>{UploadBox()}</Box>;
}
