import { Box } from "@mui/material";

import { useRecoilValue } from "recoil";
import { detailPositionSelector } from "../../recoil/atoms/detailPositionSelector";
import CarrerTable from "../common/CareerTable";
export default function PlayerCarrer({ player, margin }) {
  const headers = ["연도", "소속", "포지션"];
  const data = player.affiliationHistory
    ? player.affiliationHistory.map((pa) => [
        `${pa.joinedAt.substring(0, 4)}-${pa.leftAt.substring(0, 4)}`,
        pa.club.name,
        pa.position,
      ])
    : null;
  return (
    <Box margin={margin ? margin : "1rem"}>
      <CarrerTable hHeaders={headers} data={data}></CarrerTable>
    </Box>
  );
}
