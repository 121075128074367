import { faLocationDot, faPhone, faEnvelope, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function WantedCard(){
    return(
        <div className="wanted-card-wrapper">            
            <div className="logo-wrapper">
                <div>
                    <img src="https://webpds.saramin.co.kr/pds/united_company/logo/19929_logo.gif"></img>
                </div>                
                <div className="job-dday-wrapper">
                    <span class="dday">3월 20일</span>
                </div>
            </div>
            <div className="content-wrapper">
                <span class="title">2024다미스포츠 코치 모집</span>
                <span class="position">코치 채용(경력무관) 인원 ~3</span>
                <div class="wanted-detail">
                    <span class="detail">대한민국</span>
                    <span class="detail">대구/경북</span>
                    <span class="detail">경력무관</span>                    
                </div>
                <div class="wanted-job-type">
                    <span class="job-chip">초등</span>
                    <span class="job-chip">코치</span>
                    <span class="job-chip">계약</span>
                </div>
            </div>
            
        </div>
    )
}