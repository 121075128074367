import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useRef, useState } from "react";
import { Box, TextField, Button, InputLabel, Switch } from "@mui/material";
import { useRecoilValue } from "recoil";
import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios, { HttpStatusCode } from "axios";
import URLCreator from "../../../common/URLParser";
import { enumAtom } from "../../../../../recoil/atoms/applicationEnum";
import { API } from "../../../../../config";
import { detailPositionSelector } from "../../../../../recoil/atoms/detailPositionSelector";
import { faL } from "@fortawesome/free-solid-svg-icons";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#F3F6F9",
    cursor: "pointer",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const LabelBox = styled("div")`
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
`;
const LabelP = styled("p")`
  display: inline-block;
  font-size: 1.2rem;
  padding: 0 0.5rem 0 0;
  font-weight: 600;
  width: 4.5rem;
`;
const playerListTableHeader = [
  "상태",
  "이름",
  "생년월일",
  "학년",
  "포지션",
  "등번호",
  "시작일",
  "종료일",
];

const staffListTableHeader = [
  "상태",
  "이름",
  "생년월일",
  "포지션",
  "시작일",
  "종료일",
];

export default function MPStaffManagePersonList({
  comType,
  roleType,
  currentTeam,
  members,
  setMembers,
  filterConditions,
  setFilterConditions,
  filteredMember,
  setFilterMemberAuto,
  setFilterMemberManually,
}) {
  const positionEnum = useRecoilValue(enumAtom);
  const positionDetailEnum = useRecoilValue(detailPositionSelector);
  const [clickedMember, setClickedMember] = useState();
  const [isEditState, setIsEditState] = useState(false);
  function headerSelector(comType) {
    if (comType === API.ComType.TEAM_PLAYER) {
      return playerListTableHeader;
    } else if (comType === API.ComType.TEAM_COACH) {
      return staffListTableHeader;
    }
  }
  function requestDataCreate(comType) {
    if (comType === API.ComType.TEAM_PLAYER) {
      return {
        position: clickedMember.position,
        joinedAt: clickedMember.joinedAt
          ? dayjs(clickedMember.joinedAt).format("YYYY-MM-DD")
          : null,
        leftAt: clickedMember.leftAt
          ? dayjs(clickedMember.leftAt).format("YYYY-MM-DD")
          : null,
        schoolYear: clickedMember.schoolYear || null,
        kitNumber: clickedMember.kitNumber || null,
      };
    } else if (comType === API.ComType.TEAM_COACH) {
      return {
        position: clickedMember.position,
        joinedAt: clickedMember.joinedAt
          ? dayjs(clickedMember.joinedAt).format("YYYY-MM-DD")
          : null,
        leftAt: clickedMember.leftAt
          ? dayjs(clickedMember.leftAt).format("YYYY-MM-DD")
          : null,
      };
    }
  }
  const onFilterChange = (event) => {
    const filterType = event.target.name;
    const filterValue = event.target.value;
    console.log("positionDetailEnum", positionDetailEnum);
    if (filterType === "status-radio") {
      //전체, 현재소속, 종료 중 선택
      setFilterConditions((prev) => ({
        ...prev,
        status: filterValue,
      }));
      setFilterMemberAuto(filterValue, null);
    } else if (filterType === "position-select") {
      setFilterConditions((prev) => ({
        ...prev,
        position: filterValue,
      }));
      setFilterMemberAuto(null, filterValue);
    }
  };
  function changeTextField(event) {
    const { name, value } = event.target;
    console.log("changeTextField", event.target.value);
    if (name === "player-input-schoolYear") {
      setClickedMember((prev) => ({ ...prev, schoolYear: value }));
    } else if (name === "player-input-kitNumber") {
      setClickedMember((prev) => ({ ...prev, kitNumber: value }));
    }
  }
  function onListClick(event) {
    const clickedMemberId = parseInt(event.currentTarget.getAttribute("value"));
    console.log("clickedMemberId", clickedMemberId);
    const findMember = members.find((item) => item.id === clickedMemberId);
    setClickedMember(findMember);
  }
  async function requestCoachInfoUpdate() {
    const requestUrl = `${URLCreator(roleType, comType, currentTeam.id, "PUT")}/${clickedMember.id}`;
    console.log("roleType", roleType);
    console.log("comType", comType);
    console.log("requestUrl", requestUrl);
    const requestData = requestDataCreate(comType);

    const {
      data: { data },
    } = await axios.put(requestUrl, requestData);
    console.log("res for update", data);
    setMembers((prev) => data);
    setFilterMemberManually((prev) => data);
    setIsEditState((prev) => !prev);
    setClickedMember(null);
    alert("수정했습니다.");
  }
  function onEditBtnClick() {
    console.log("clickedMember", clickedMember);
    if (isEditState) {
      //수정요청
      requestCoachInfoUpdate();
    } else {
      if (clickedMember) {
        setIsEditState((prev) => !prev);
      } else {
        alert("수정할 대상을 선택 후 클릭하세요");
      }
    }
  }
  async function onRemoveBtnClick() {
    if (window.confirm("삭제하시겠습니까?")) {
      const requestUrl = `${URLCreator(roleType, comType, currentTeam.id, "DELETE")}/${clickedMember.id}`;
      try {
        const {
          data: { data },
        } = await axios.delete(requestUrl);

        setMembers((prev) => data);
        setFilterConditions((prev) => ({
          status: "ALL",
          position: "ALL",
        }));
        setFilterMemberManually((prev) => data);
        setClickedMember((prev) => null);
        setIsEditState((prev) => false);
        alert("삭제가 성공하였습니다.");
      } catch (error) {
        console.log(error);
      }
    }
  }
  function setStartDate(e) {
    setClickedMember((prev) => ({ ...prev, joinedAt: e }));
  }
  function setEndDate(e) {
    setClickedMember((prev) => ({ ...prev, leftAt: e }));
  }
  const changePosition = (e) => {
    setClickedMember((prev) => ({ ...prev, position: e.target.value }));
  };
  function onChangeStatus(e) {
    console.log(e.target.value);
    if (!clickedMember) {
      alert("탈퇴한 멤버가 선택되지 않았습니다");
      return;
    }
    if (clickedMember.leftAt) {
      setClickedMember((prev) => ({ ...prev, leftAt: null }));
    } else {
      alert("선택한 멤버는 소속된 상태입니다.");
    }
  }
  return (
    <Box>
      <Box sx={{ padding: "0 0 0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: 600,
            fontSize: "1.3rem",
            color: "#001c48",
          }}
        >
          <span
            style={{
              width: "0.4rem",
              height: "1.3rem",
              backgroundColor: "#001c48",
              display: "block",
              margin: "0 0.2rem 0 0 ",
            }}
          ></span>
          목 록
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LabelP>상 태</LabelP>
          <Box>
            <FormControl>
              <RadioGroup
                row
                defaultValue="ALL"
                name="status-radio"
                value={filterConditions.status}
                onChange={onFilterChange}
              >
                <FormControlLabel
                  value="ALL"
                  control={<Radio />}
                  label="전체"
                  sx={{
                    fontSize: "1rem",
                    "& .MuiSvgIcon-root": {
                      fontSize: "1.3rem",
                      padding: 0,
                      margin: 0,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "1.2rem",
                    },
                  }}
                  inputProps={{ style: { fontSize: "1.2rem" } }}
                />
                <FormControlLabel
                  value="AFFILIATED"
                  control={<Radio />}
                  label="소속"
                  sx={{
                    fontSize: "1rem",
                    "& .MuiSvgIcon-root": {
                      fontSize: "1.3rem",
                      padding: 0,
                      margin: 0,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "1.2rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="LEFT"
                  control={<Radio />}
                  label="종료"
                  sx={{
                    fontSize: "1rem",
                    "& .MuiSvgIcon-root": {
                      fontSize: "1.3rem",
                      padding: 0,
                      margin: 0,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "1.2rem",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LabelP>포지션</LabelP>
          <div>
            <Select
              defaultValue={"ALL"}
              value={filterConditions.position}
              size="small"
              sx={{
                width: { xs: "12rem", md: "20rem" },
                "& .MuiOutlinedInput-input": {
                  fontSize: "1rem",
                },
              }}
              MenuProps={{
                style: {
                  maxHeight: 250,
                },
              }}
              onChange={onFilterChange}
              name="position-select"
            >
              <MenuItem sx={{ fontSize: "1rem" }} value={"ALL"}>
                {"전체"}
              </MenuItem>
              {comType === API.ComType.TEAM_COACH
                ? positionEnum.coachPositions.map((item) => (
                    <MenuItem sx={{ fontSize: "1rem" }} value={item.name}>
                      {item.description}
                    </MenuItem>
                  ))
                : positionDetailEnum.map((item) => (
                    <MenuItem
                      sx={{ fontSize: "1rem", color: item.color }}
                      value={item.name}
                    >
                      {item.description}
                    </MenuItem>
                  ))}
            </Select>
            {/* <Button variant="contained" onClick={onShowBtnClick}>
                  보기
                </Button> */}
          </div>
        </Box>
        <Box>
          <div>
            <TableContainer
              component={Paper}
              sx={{ margin: "1rem 0", maxHeight: 500 }}
            >
              <Table
                sx={{ width: "90%", margin: "1rem" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#eee" }}>
                    {headerSelector(comType).map((item) => (
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0.2rem 0",
                          display:
                            item === "시작일" || item === "종료일"
                              ? { xs: "none", md: "table-cell" }
                              : "",
                        }}
                      >
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredMember
                    ? filteredMember.map((row) => (
                        <StyledTableRow
                          onClick={onListClick}
                          value={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ padding: "0.5rem 0", textAlign: "center" }}
                          >
                            {row.status === "AFFILIATED" ? "소속" : "종료"}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "0.5rem 0", textAlign: "center" }}
                          >
                            {row.member.name}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "0.5rem 0", textAlign: "center" }}
                          >
                            {row.member.birthDate}
                          </TableCell>
                          {comType === API.ComType.TEAM_PLAYER ? (
                            <TableCell
                              sx={{ padding: "0.5rem 0", textAlign: "center" }}
                            >
                              {row.schoolYear}
                            </TableCell>
                          ) : null}
                          <TableCell
                            sx={{ padding: "0.5rem 0", textAlign: "center" }}
                          >
                            {comType === API.ComType.TEAM_COACH
                              ? row.position
                                ? positionEnum.coachPositions.find(
                                    (item) => item.name === row.position
                                  ).description || "-"
                                : "-"
                              : row.position
                                ? positionDetailEnum.find(
                                    (item) => item.name === row.position
                                  ).description || "-"
                                : "-"}
                          </TableCell>
                          {comType === API.ComType.TEAM_PLAYER ? (
                            <TableCell
                              sx={{ padding: "0.5rem 0", textAlign: "center" }}
                            >
                              {row.kitNumber}
                            </TableCell>
                          ) : null}

                          <TableCell
                            sx={{
                              padding: "0.5rem 0",
                              textAlign: "center",
                              display: { xs: "none", md: "table-cell" },
                            }}
                          >
                            {row.joinedAt || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem 0",
                              textAlign: "center",
                              display: { xs: "none", md: "table-cell" },
                            }}
                          >
                            {row.leftAt || "-"}
                          </TableCell>
                        </StyledTableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
      </Box>

      <Box sx={{ padding: "0 0 0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: 600,
            fontSize: "1.3rem",
            color: "#001c48",
            margin: "0 0 0.2rem 0",
          }}
        >
          <span
            style={{
              width: "0.4rem",
              height: "1.3rem",
              backgroundColor: "#001c48",
              display: "block",
              margin: "0 0.2rem 0 0 ",
            }}
          ></span>
          상세보기
        </Box>
        <Box>
          <Button
            onClick={onEditBtnClick}
            variant="contained"
            sx={{
              backgroundColor: isEditState ? "info.main" : "success.main",
              ":hover": {
                backgroundColor: isEditState ? "info.main" : "success.main",
                color: "yellow",
              },
            }}
          >
            {isEditState ? "저장" : "수정"}
          </Button>
          <Button
            disabled={!isEditState}
            onClick={onRemoveBtnClick}
            variant="contained"
            sx={{
              backgroundColor: "error.main",
              ":hover": {
                backgroundColor: "error.main",
                color: "yellow",
              },
            }}
          >
            삭제
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ border: "1px solid #eee", width: "48%" }}>
            <Box>
              <LabelBox>이름</LabelBox>
              <TextField
                value={clickedMember ? clickedMember.member.name : ""}
                size="small"
                variant="outlined"
                disabled
                sx={{ m: "0.5rem", width: { xs: "95%" } }}
                inputProps={{ style: { fontSize: "1.2rem", height: "1.2rem" } }}
              />
            </Box>
            <Box>
              <LabelBox>생년월일</LabelBox>
              <TextField
                value={clickedMember ? clickedMember.member.birthDate : ""}
                size="small"
                variant="outlined"
                disabled
                sx={{ m: "0.5rem", width: { xs: "95%" } }}
                inputProps={{ style: { fontSize: "1.2rem", height: "1.2rem" } }}
              />
            </Box>
            <Box>
              <LabelBox>이메일</LabelBox>
              <TextField
                value={clickedMember ? clickedMember.member.email : ""}
                size="small"
                variant="outlined"
                disabled
                sx={{ m: "0.5rem", width: { xs: "95%" } }}
                inputProps={{ style: { fontSize: "1.2rem", height: "1.2rem" } }}
              />
            </Box>
            <Box>
              <LabelBox>연락처</LabelBox>
              <TextField
                value={clickedMember ? clickedMember.member.phoneNumber : ""}
                size="small"
                variant="outlined"
                disabled
                sx={{ m: "0.5rem", width: { xs: "95%" } }}
                inputProps={{ style: { fontSize: "1.2rem", height: "1.2rem" } }}
              />
            </Box>
          </Box>
          <Box sx={{ border: "1px solid #eee", width: "48%" }}>
            <Box>
              {comType === API.ComType.TEAM_PLAYER ? (
                <Box>
                  <LabelBox>학년</LabelBox>
                  <TextField
                    disabled={!isEditState}
                    defaultValue={clickedMember ? clickedMember.schoolYear : ""}
                    value={clickedMember ? clickedMember.schoolYear : ""}
                    size="small"
                    sx={{ m: "0.5rem", width: { xs: "95%" } }}
                    inputProps={{
                      style: { fontSize: "1.2rem", height: "1.2rem" },
                    }}
                    name="player-input-schoolYear"
                    onChange={changeTextField}
                  ></TextField>
                </Box>
              ) : null}
            </Box>
            <Box>
              {comType === API.ComType.TEAM_PLAYER ? (
                <Box>
                  <LabelBox>등번호</LabelBox>
                  <TextField
                    disabled={!isEditState}
                    defaultValue={clickedMember ? clickedMember.kitNumber : ""}
                    value={clickedMember ? clickedMember.kitNumber : ""}
                    size="small"
                    sx={{ m: "0.5rem", width: { xs: "95%" } }}
                    inputProps={{
                      style: { fontSize: "1.2rem", height: "1.2rem" },
                    }}
                    name="player-input-kitNumber"
                    onChange={changeTextField}
                  ></TextField>
                </Box>
              ) : null}
            </Box>
            <Box>
              <LabelBox>포지션</LabelBox>
              <FormControl sx={{ width: "98%" }} size="small">
                <Select
                  disabled={!isEditState}
                  defaultValue={clickedMember ? clickedMember.position : null}
                  value={clickedMember ? clickedMember.position : null}
                  onChange={changePosition}
                  sx={{
                    m: "0.5rem",
                    height: "2.3rem",
                    "& .MuiOutlinedInput-input": {
                      fontSize: "1.2rem",
                    },
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 250,
                    },
                  }}
                >
                  <MenuItem sx={{ fontSize: "1.1rem" }} value={"ALL"}>
                    {"전체"}
                  </MenuItem>
                  {comType === API.ComType.TEAM_COACH
                    ? positionEnum.coachPositions.map((item) => (
                        <MenuItem sx={{ fontSize: "1.1rem" }} value={item.name}>
                          {item.description}
                        </MenuItem>
                      ))
                    : positionDetailEnum.map((item) => (
                        <MenuItem sx={{ color: item.color }} value={item.name}>
                          {item.description}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <LabelBox>시작일</LabelBox>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="ko"
              >
                <MobileDatePicker
                  inputProps={{
                    name: "MyName",
                    "data-role": "role",
                  }}
                  disabled={!isEditState}
                  defaultValue={dayjs(
                    clickedMember ? clickedMember.joinedAt : null
                  )} //
                  value={dayjs(clickedMember ? clickedMember.joinedAt : null)} //dayjs(currentCareer.leftAt)
                  onChange={setStartDate}
                  sx={{
                    m: 0.5,
                    width: { xs: "95%" }, //sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
                    "& .MuiOutlinedInput-input": {
                      border: 0,
                      borderRadius: 3,
                      fontSize: "1.2rem",
                      height: "1.2rem",
                    },
                  }}
                  slotProps={{ textField: { size: "small" } }}
                  views={["year", "month", "day"]}
                  format={"YYYY-MM-DD"}
                  data-pickerType="startDate"
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <LabelBox>종료일</LabelBox>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="ko"
              >
                <MobileDatePicker
                  inputProps={{
                    name: "yourName",
                    "data-role": "role",
                  }}
                  disabled={!isEditState}
                  defaultValue={dayjs(
                    clickedMember ? clickedMember.leftAt : null
                  )}
                  value={dayjs(clickedMember ? clickedMember.leftAt : null)}
                  onChange={setEndDate}
                  sx={{
                    m: 0.5,
                    width: { xs: "95%" },
                    "& .MuiOutlinedInput-input": {
                      border: 0,
                      borderRadius: 3,
                      fontSize: "1.2rem",
                      height: "1.2rem",
                    },
                  }}
                  slotProps={{ textField: { size: "small" } }}
                  views={["year", "month", "day"]}
                  format={"YYYY-MM-DD"}
                  data-pickerType="endDate"
                />
              </LocalizationProvider>
              <Box>
                <Button
                  variant="contained"
                  color="success"
                  disabled={
                    clickedMember
                      ? clickedMember.status === "AFFILIATED" || !isEditState
                        ? true
                        : false
                      : true
                  }
                  sx={{
                    backgroundColor: "success.main",
                    color: "#fff",
                    marginLeft: "0.3rem",
                    fontSize: "1rem",
                  }}
                  onClick={onChangeStatus}
                >
                  탈퇴 멤버를 팀으로 소속
                </Button>
              </Box>
            </Box>
          </Box>
          <Box></Box>
        </Box>
      </Box>
    </Box>
  );
}
