import styled from "styled-components";

const StyledTable = styled.table`
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  caption-side: bottom;

  td,
  th {
    border: none;
  }
  /* td,
  th {
    border: 1px solid;
    display:block;
    text-align: center;
  } */
  td {
    padding: 0.2rem 0.2rem;
    text-align: center;
  }

  tbody tr:hover {
    background-color: rgb(230, 240, 230);
    cursor: pointer;
  }
  tbody tr td {
    border-bottom: 1px solid #ddd;
    :nth-of-type(odd) {
    }
    vertical-align: middle;
  }
  thead > tr {
    background-color: rgb(64, 132, 75);
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
  }
  th {
    padding: 10px 0;
  }

  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
  td img,
  td div {
    border: 1px solid #eee;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
  }
`;
export default StyledTable;
