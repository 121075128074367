import MainLayout from "../../LayoutContainers/MainLayout";
import AgencyDetail from "../../components/Scouter/AgencyDetail";

export default function AgencyDetailLayout() {
  return (
    <MainLayout>
      <AgencyDetail></AgencyDetail>
    </MainLayout>
  );
}
