import { Box, Button } from "@mui/material";
import { forwardRef, useRef } from "react";
import styled from "styled-components";

const Input = styled("input")`
  width: 100%;
  height: 2.8rem;
  background-color: #f5f5f5;
  outline: none;
  border: 1px solid #aaa;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
  &:focus {
    border: 1px solid #40844b;
  }
`;
const SearchButton = styled("button")`
  width: 6rem;
  height: 2.5rem;
  padding: 0.5em;
  margin-left: 1rem;
  font-size: 1.1rem;
  line-height: 1.2rem;
  border: none;
  border-radius: 10%/20%;
  background-color: #1a73e8;
  color: #eee;
  &:hover {
    background-color: #0a53c8;
    color: #fff;
    cursor: pointer;
  }
`;
const SearchBox = forwardRef((props, ref) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "31.25rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Input
          id={props.id || ""}
          type="text"
          name="searchQueryInput"
          placeholder={props.placeHolder || ""}
          ref={ref}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              props.fetchdata(0);
            }
          }}
        />
        <button
          id="searchQuerySubmit"
          type="submit"
          name="searchQuerySubmit"
          style={{
            width: "3.5rem",
            height: "2.8rem",
            marginLeft: "-3.5rem",
            background: "none",
            border: "none",
          }}
        >
          <svg style={{ width: "24px", height: "24px", viewBox: "0 0 24 24" }}>
            <path
              fill="#666666"
              d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
            />
          </svg>
        </button>
      </Box>
      <SearchButton
        onClick={() => {
          props.fetchdata(0);
        }}
      >
        검색
      </SearchButton>
    </Box>
  );
});
export default SearchBox;
