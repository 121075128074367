import axios from "axios";
import { useEffect } from "react";
import { API } from "../../config";
import { useRecoilState } from "recoil";
import { loginStatusAtom } from "../../recoil/atoms/loginStatus";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export default function LoginRedirect() {
  const [loginInfo, setLoginInfo] = useRecoilState(loginStatusAtom);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    async function LoginStatus() {
      const loginStatus = searchParams.get("status");
      if (loginStatus === "fail") {
        const errorCode = parseInt(searchParams.get("error"));
        if (errorCode === 401001) {
          alert("회원가입을 먼저 진해하세요");
          navigate("/join");
        }
      } else if (loginStatus === "success") {
        try {
          const res = await axios.get(API.LOGIN_STATUS);
          console.log(res);
          if (res.data.code === 200000) {
            console.log(res.data.data.name);
            console.log(res.data.data.role);
            sessionStorage.removeItem("name");
            sessionStorage.removeItem("role");
            sessionStorage.setItem("name", res.data.data.name);
            sessionStorage.setItem("role", res.data.data.role);
            setLoginInfo(res.data.data);
            navigate("/main");
          } else {
            console.log("계정을 확인해보세요");

            navigate("/login");
          }
        } catch (error) {
          console.log("login error", error);
        }
      }
    }
    LoginStatus();
  }, []);
  return <div></div>;
}
