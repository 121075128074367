import axios from "axios";
import MainLayout from "../../LayoutContainers/MainLayout";
import MainMovieList from "../../components/main/MainMovieList";
import MainPlayerList from "../../components/main/MainPlayerList";

import { API } from "../../config";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { enumAtom } from "../../recoil/atoms/applicationEnum";
import MainCoachList from "../../components/main/MainCoachList";
import { Box, useTheme } from "@mui/material";
import RowBox from "../../LayoutContainers/RowBox";

const urlPrefix = "https://img.youtube.com/vi/";
const playerVideoInfos = [
  {
    thumbnailURL: `${urlPrefix}OAwFAgu6uxA/0.jpg`,
    url: "https://youtu.be/OAwFAgu6uxA?si=6stvtDq7rUrala3x",
    title: "경남FC U-18[진주고] No.1 장영웅 2024시즌",
  },
  {
    thumbnailURL: `${urlPrefix}xojfcf3MSLQ/0.jpg`,
    url: "https://youtu.be/xojfcf3MSLQ?si=RR0Yc9Y3mvu9b6jn",
    title: "인천남고등학교 No.8 조현수 2024시즌",
  },
  {
    thumbnailURL: `${urlPrefix}_f6D-Sztfjk/0.jpg`,
    url: "https://youtu.be/_f6D-Sztfjk?si=ULCjByBUUO-xLwkF",
    title: "천안축구센터 U-18 No.21 임용민 2024시즌",
  },
  {
    thumbnailURL: `${urlPrefix}JK3KTuJ1JlU/0.jpg`,
    url: "https://youtu.be/JK3KTuJ1JlU?si=wFCHmiGB21Id6KP5",
    title: "서울 보인고 No.1 권능 2023시즌",
  },
  {
    thumbnailURL: `${urlPrefix}mjmsT0YX4do/0.jpg`,
    url: "https://youtu.be/mjmsT0YX4do?si=s4ElXJcNkB1z9U4P",
    title: "서울 대동세무고 No.10 장민석 2023시즌",
  },
];

const coachVideoInfos = [
  {
    thumbnailURL: `${urlPrefix}xsKlwDZeVkI/0.jpg`,
    url: "https://youtu.be/xsKlwDZeVkI?si=PYcRR8DPl0omRGbl",
    title: "고민 끝에 내린 결정 “초심으로 새롭게 다시 도전",
    subTitle: "조성환 부산 아이파크 감독",
  },
  {
    thumbnailURL: `${urlPrefix}BRNgkNWhjTc/0.jpg`,
    url: "https://youtu.be/BRNgkNWhjTc?si=Z2B2QV6ZnxdNNFut",
    title: "김도훈 감독이 직접 본 대표팀 분위기",
    subTitle: "동시대에 살아 행복합니다",
  },
  {
    thumbnailURL: `${urlPrefix}CtX9ymW4GC0/0.jpg`,
    url: "https://youtu.be/CtX9ymW4GC0?si=xgA7NkFVbb_Ypso6",
    title: "저의 실수로..",
    subTitle: "7분의 격정 토로 뒤 고개 숙인 홍명보",
  },
  {
    thumbnailURL: `${urlPrefix}ubDK0q__kqA/0.jpg`,
    url: "https://youtu.be/ubDK0q__kqA?si=gZbn2Viq4vSkhMC1",
    title: "광주FC, 코리아컵 첫 4강 진출",
    subTitle: "마른행주를 쥐어짜 내는 것 같다",
  },
  {
    thumbnailURL: `${urlPrefix}iD9jm7Y8RB8/0.jpg`,
    url: "https://youtu.be/iD9jm7Y8RB8?si=saVJkrNZt8GWqDBE",
    title: "인도네시아를 좋은 팀으로 바꿔놨다",
    subTitle: "'여우' 신태용 감독 경계하는 황선홍 감독",
  },
];
const teamVideoInfos = [
  {
    thumbnailURL: `${urlPrefix}JGPpHl49gLg/0.jpg`,
    url: "https://youtu.be/JGPpHl49gLg?si=XdZG3jxbM0Il33gX",
    title: "[9분 하이라이트] 대동세무고 vs. 영등포공고",
    subTitle: "2024 전국 고등 축구리그 | 2024.06.29",
  },
  {
    thumbnailURL: `${urlPrefix}ALx6Nn49QFE/0.jpg`,
    url: "https://youtu.be/ALx6Nn49QFE?si=hD_RviurId2RuMlQ",
    title: "[7분 하이라이트] 대동세무고 vs. 중대부고",
    subTitle: "2024 전국 고등 축구리그 | 2024.06.21",
  },
  {
    thumbnailURL: `${urlPrefix}MgZsN8qOJGA/0.jpg`,
    url: "https://youtu.be/MgZsN8qOJGA?si=lBnZT5Q3ZAptx581",
    title: "[고교 결승전] 서울 영등포공고 vs. 서울 상문고",
    subTitle: "2024 금강대기 전국 고등학교 축구대회 | 2024.06.14",
  },
  {
    thumbnailURL: `${urlPrefix}LnsyLbBn20A/0.jpg`,
    url: "https://youtu.be/LnsyLbBn20A?si=CM2rWFmDc8OGFXfD",
    title: "[고교 준결승전] 서울 상문고 vs. 서울 중경고",
    subTitle: "2024 금강대기 전국 고등학교 축구대회 | 2024.06.12",
  },
  {
    thumbnailURL: `${urlPrefix}g874TF3adEU/0.jpg`,
    url: "https://youtu.be/g874TF3adEU",
    title: "[8분 하이라이트] 신평고 vs. 영덕고",
    subTitle: "제29회 무학기 전국고등학교 축구대회 | 2024.06.05",
  },
];

export default function Main() {
  const [enumData, setEnumData] = useRecoilState(enumAtom);
  const [recentPlayer, setRecentPlayer] = useState();
  const [recentCoach, setRecentCoach] = useState();
  const timeout = 5000;
  const theme = useTheme();
  useEffect(() => {
    async function fetchEnum() {
      localStorage.clear();
      try {
        const {
          data: { data },
        } = await axios.get(API.APPLICATION_ENUM, { timeout });
        setEnumData(data);
      } catch (error) {
        alert("서버에 일시적인 문제가 발생했습니다.");
      }
    }
    async function fetchRecentPlayer() {
      try {
        const res = await axios.get(API.RECENT_PLAYER);
        let recentPlayers = res.data.data;
        recentPlayers = recentPlayers.map((rp) => ({
          ...rp,
          birthDate: rp.birthDate.replace(/-/g, "."),
        }));
        setRecentPlayer((prev) => recentPlayers);
      } catch (error) {
        alert("서버에 일시적인 문제가 발생했습니다.");
      }
    }
    async function fetchRecentCoach() {
      try {
        const res = await axios.get(API.RECENT_COACH);
        setRecentCoach((prev) => res.data.data);
      } catch (error) {
        alert("서버에 일시적인 문제가 발생했습니다.");
      }
    }
    fetchEnum();
    fetchRecentPlayer();
    fetchRecentCoach();
  }, []);

  return (
    <MainLayout mode="dark">
      <RowBox
        mode="dark"
        bgColor="landingBgDark.light"
        padding={"0 2rem 5rem 2rem"}
      >
        {recentPlayer ? (
          <MainPlayerList recentPlayer={recentPlayer}></MainPlayerList>
        ) : null}
      </RowBox>

      <RowBox
        mode="dark"
        bgColor="landingBgDark.light"
        padding={"0 2rem 4rem 2rem"}
      >
        {recentCoach ? (
          <MainCoachList
            recentCoach={recentCoach}
            title="COACH"
          ></MainCoachList>
        ) : null}
      </RowBox>

      <RowBox
        mode="dark"
        bgColor="landingBgDark.deep"
        padding={"2rem 2rem 3rem 2rem"}
      >
        {recentCoach ? (
          <MainMovieList
            videoInfos={playerVideoInfos}
            title="PLAYER HIGHLIGHT"
            titleColor="rgb(245,21,29)"
            btnClassName="player"
          ></MainMovieList>
        ) : null}
      </RowBox>
      <RowBox
        mode="dark"
        bgColor="landingBgDark.deep"
        padding={"0rem 2rem 0 2rem"}
      >
        {recentCoach ? (
          <MainMovieList
            videoInfos={coachVideoInfos}
            title="COACH HIGHLIGHT"
            titleColor="rgb(245,21,29)"
            btnClassName="coach"
          ></MainMovieList>
        ) : null}
      </RowBox>
      <RowBox
        mode="dark"
        bgColor="landingBgDark.deep"
        padding={"3rem 2rem 0 2rem"}
      >
        {recentCoach ? (
          <MainMovieList
            videoInfos={teamVideoInfos}
            title="TEAM HIGHLIGHT"
            titleColor="rgb(245,21,29)"
            btnClassName="team"
          ></MainMovieList>
        ) : null}
      </RowBox>
    </MainLayout>
  );
}
