import { useRef } from "react";
import { API } from "../../config";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loginStatusAtom } from "../../recoil/atoms/loginStatus";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const Input = styled("input")`
  width: 100%;
  height: 30px;
  border: none;
  outline: 1px solid #ccc;
  border-radius: 5px;
  padding: 1em 0.5em;
  font-size: 1rem;
  &:focus {
    outline: 2px solid rgb(64, 132, 75);
  }
`;
const Label = styled("p")`
  width: 100%;
  padding: 0;
  font-size: 1rem;
`;
const LoginBtn = styled("button")`
  width: 100%;
  border: none;
  background-color: rgb(64, 132, 75);
  padding: 0.8rem;
  border-radius: 7px/6px;
  font-size: 1rem;
  color: #fff;
  &:hover {
    background-color: rgb(44, 92, 55);
  }
`;
export default function LoginForm() {
  const idInput = useRef();
  const pwInput = useRef();
  const navigate = useNavigate();

  const [loginInfo, setLoginInfo] = useRecoilState(loginStatusAtom);
  async function onLogin() {
    const loginInfo = {
      loginId: idInput.current.value,
      password: pwInput.current.value,
    };
    try {
      const res = await axios.post(API.LOGIN, loginInfo, {
        withCredentials: true,
      });
      console.log(res);
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("role");
      sessionStorage.setItem("name", res.data.data.name);
      sessionStorage.setItem("role", res.data.data.role);
      setLoginInfo(res.data.data);

      navigate("/main");
    } catch (e) {
      alert("아이디 혹은 비밀번호를 확인해주세요");
      console.log(e);
    }
  }
  return (
    <Box
      sx={{
        width: { xs: "100%", md: "576px" },
        margin: "0 auto",
        border: "1px solid rgb(64,132,75)",
        borderRadius: { xs: "5%/3%", sm: "4%/3%" },
        padding: { xs: "1rem 0.5rem", md: "3rem 5rem" },
        backgroundColor: "#fff",
      }}
    >
      <Box sx={{ marginBottom: "2rem" }}>
        <p
          style={{
            fontSize: "1.8rem",
            fontWeight: "600",
            color: "rgb(64,132,75)",
          }}
        >
          LOGIN
        </p>
      </Box>
      <Box sx={{ borderBottom: "1px solid #ccc", paddingBottom: "3rem" }}>
        <Box mb={2}>
          <Box>
            <Label>아이디</Label>
          </Box>
          <Box>
            <Input ref={idInput}></Input>
          </Box>
        </Box>
        <Box mb={2}>
          <Box>
            <Label>비밀번호</Label>
          </Box>
          <Box>
            <Input
              type="password"
              ref={pwInput}
              onKeyDown={(e) => {
                if (e.key === "Enter") onLogin();
              }}
            ></Input>
          </Box>
        </Box>
        <Box>
          <LoginBtn style={{}} onClick={onLogin}>
            로그인
          </LoginBtn>
        </Box>
        <Box sx={{ fontSize: "1rem", textAlign: "right", marginTop: "1rem" }}>
          계정이 없으신가요?
          <Box>
            <Link to="/join" style={{ color: "#339af0" }}>
              회원가입
            </Link>
          </Box>
        </Box>
        <Box sx={{ fontSize: "1rem", textAlign: "right", marginTop: "1rem" }}>
          아이디 or 비밀번호를 분실했나요?
          <Box>
            <Link
              to="/auth/id"
              style={{ color: "#339af0", margin: "0 1rem 0 0" }}
            >
              아이디 찾기
            </Link>
            <Link to="/auth/password" style={{ color: "#339af0" }}>
              비밀번호 재설정
            </Link>
          </Box>
        </Box>
      </Box>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            marginTop: "-15px",
            textAlign: "center",
            fontSize: "1rem",
            color: "#aaa",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ backgroundColor: "#fff", padding: "0 1em" }}>또는</Box>
        </Box>
        <Box>
          <a href={API.JOIN_KAKAO}>
            <Box
              sx={{
                backgroundColor: "#fee500",
                borderRadius: "7px",
                padding: "0.3em 2em",
                display: "flex",
                marginTop: "3rem",
                marginBottom: "0.3rem",
                alignItems: "center",
              }}
            >
              <div style={{ width: "30px" }}>
                <FontAwesomeIcon
                  icon={faComment}
                  color="#000"
                ></FontAwesomeIcon>
              </div>

              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  flexGrow: 1,
                  fontSize: "1rem",
                  margin: "0 auto",
                  color: "#000",
                }}
              >
                카카오 로그인
              </div>
            </Box>
          </a>
        </Box>

        <Box>
          <a href={API.JOIN_NAVER}>
            <Box
              sx={{
                backgroundColor: "#09AA5C",
                borderRadius: "7px",
                padding: "0.3em 2em",
                display: "flex",
                marginTop: "0.5rem",
                marginBottom: "0.3rem",
                alignItems: "center",
              }}
            >
              <div style={{ width: "30px", color: "#fff", fontWeight: "600" }}>
                N
              </div>

              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  flexGrow: 1,
                  fontSize: "1rem",
                  margin: "0 auto",
                  color: "#000",
                }}
              >
                네이버 로그인
              </div>
            </Box>
          </a>
        </Box>
      </Box>
    </Box>
  );
}
