import { Box } from "@mui/material";
import MultiLineText from "../../../common/MultiLineText";
import PositionPreferTable from "../../../common/PositionPreferTable";
import { SubTitleBox, TitleBox } from "../../../common/MyPageComponents";
export default function MPStaffProfilePositionPrefer({
  comType,
  roleType,
  subTitle,
  preferPositionStyles,
  setPreferPositionStyles,
}) {
  return (
    <Box margin="1.5rem 0 0 0">
      <SubTitleBox>{subTitle}</SubTitleBox>
      <PositionPreferTable
        preferPositionStyles={preferPositionStyles}
        setPreferPositionStyles={setPreferPositionStyles}
      ></PositionPreferTable>
    </Box>
  );
}
