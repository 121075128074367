import MainLayout from '../../LayoutContainers/MainLayout'
import MainWanted from '../../components/main/MainWanted'
import StaffWantedList from '../../components/wanted/StaffWantedList'

export default function StaffWantedLayout() {
  return (
    <MainLayout>
      <div className="body-container">
      <div className="main-row">
        <StaffWantedList></StaffWantedList>
      </div>
      </div>
    </MainLayout>
  )
}
