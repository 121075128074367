import { Link, useNavigate } from "react-router-dom";
import { faAngleDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "../../config";
import { Box, Pagination, Stack } from "@mui/material";
import { useRecoilValue } from "recoil";
import { enumAtom } from "../../recoil/atoms/applicationEnum";
import StyledTable from "../common/ItemListTable";
import { faSquareFontAwesomeStroke } from "@fortawesome/free-brands-svg-icons";

const tableHeaderText = [
  { name: "사진", width: "10%" },
  { name: "이름", width: "22%" },
  { name: "현재팀", width: "22%" },
  { name: "직책", width: "22%" },
];

export default function CoachListTable({
  coaches,
  pagingInfo,
  setPagingInfo,
  fetchdata,
  scrollScreen,
  setCoachListBack,
  isLeagueClicked,
  isPositionClicked,
}) {
  const appEnum = useRecoilValue(enumAtom);
  const navigate = useNavigate();
  function onPageChange(event, value) {
    fetchdata(parseInt(value - 1));
  }
  const handleRowClick = (coachId) => {
    setCoachListBack((prev) => ({
      isFirstLoad: false,
      data: coaches,
      pagingInfo: pagingInfo,
      scrollY: window.scrollY,
      filterLeague: isLeagueClicked,
      filterPosition: isPositionClicked,
    }));
    navigate(`/coach-detail/${coachId}`);
  };
  return (
    <Box>
      <Box>
        <StyledTable>
          <thead>
            <tr style={{ fontSize: "1.1rem" }}>
              {tableHeaderText.map((item, index) => (
                <th key={index} style={{ width: item.width }}>
                  {item.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {coaches.map((coach, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(coach.id)}
                style={{ fontSize: "1rem" }}
              >
                {coach.profileImage ? (
                  <td>
                    <div
                      style={{
                        width: "4rem",
                        height: "4rem",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        src={`${API.BASE}/${coach.profileImage.fileUrl}`}
                        alt="profile-img"
                        style={{ objectFit: "cover" }}
                      ></img>
                    </div>
                  </td>
                ) : (
                  <td>
                    <div
                      style={{
                        width: "4rem",
                        height: "4rem",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ display: "inline-block", margin: "0 auto" }}
                        icon={faUser}
                        size="3x"
                        color="#81cdc6"
                      />
                    </div>
                  </td>
                )}
                <td>{coach.name}</td>
                <td>
                  {coach.currentAffiliation
                    ? coach.currentAffiliation.club.name
                    : "-"}
                </td>
                <td>
                  {coach.currentAffiliation.position
                    ? appEnum.coachPositions.find(
                        (cp) => cp.name === coach.currentAffiliation.position
                      ).description
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </Box>
      <Box>
        <Box>
          {pagingInfo.isRequired ? (
            <Stack
              spacing={1}
              sx={{ margin: "1.5rem 1rem", width: "100%" }}
              alignItems="center"
            >
              <Pagination
                shape="circular"
                size="large"
                count={pagingInfo.totalPages}
                siblingCount={0}
                onChange={onPageChange}
                defaultPage={1}
                page={pagingInfo.currentPage}
                color="info"
              />
            </Stack>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
