import { useTheme } from "@emotion/react";
import { Box, Grid, Hidden, Typography } from "@mui/material";
import { API } from "../../../config";
import styled from "styled-components";
import { coach_kor_title } from "../../../recoil/commonEnum";
const ReSizeImg = styled.img`
  display: block;
  object-fit: cover;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
`;
const StyledDiv = styled("div")({
  display: "flex",
  margin: "0.5em 0",
  alignItems: "center",
});
const StyledDiv2 = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: { xs: "column", sm: "column", md: "row" },
});
const BulletSpan = styled("span")({
  display: "inline-block",
  width: "10px",
  height: "10px",
  backgroundColor: "#fff",
  borderRadius: "50%",
  marginRight: "1em",
});
export default function CoachCard({ profile }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "mainGreen.deep",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        padding: "1rem",
      }}
    >
      <Grid container alignItems={"center"} justifyContent="center">
        <Grid item xs={12} xxl={4}>
          <Box
            sx={{
              overflow: "hidden",
              marginBottom: { xs: "1rem", md: "0" },
            }}
          >
            <ReSizeImg
              src={
                profile.profileImage
                  ? `${API.BASE}/${profile.profileImage.fileUrl}`
                  : null
              }
              alt="profile-img"
            ></ReSizeImg>
          </Box>
        </Grid>
        <Grid item xs={12} xxl={8}>
          <div
            style={{
              width: "6rem",
              backgroundColor: "#ffffff",
              textAlign: "center",
              color: "#000",
            }}
          >
            COACH
          </div>
          <StyledDiv>
            <Typography variant="mainCoachName" letterSpacing={5}>
              {profile.name}
            </Typography>
            <Typography
              variant="mainCoachNationality"
              borderLeft={"1px solid #eee"}
              letterSpacing={2}
              pl={2}
            >
              {profile.nationality}
            </Typography>
          </StyledDiv>
          <Grid container sx={{ margin: "0 0 0.8rem 0" }}>
            <Grid item xs={12} md={4}>
              <BulletSpan></BulletSpan>
              <Typography
                variant="mainCoachDetailTitle"
                sx={{
                  display: "inline-block",
                  width: "4rem", //{ xs: "auto", sm: "2rem", md: "4.5rem" }
                  textAlign: "justify",
                  fontSize: { xs: "1.1rem", md: "1.2rem" },
                  fontWeight: "600",
                }}
              >
                소속팀
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ padding: { xs: "0 0 0 2rem", md: "0" } }}
            >
              <Typography
                variant="mainCoachDetailContent"
                fontSize={{ xs: "1rem", md: "1.1rem" }}
              >
                {profile.currentAffiliation
                  ? profile.currentAffiliation.club.name
                  : null}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ margin: "0 0 0.8rem 0" }}>
            <Grid item xs={12} md={4}>
              <BulletSpan></BulletSpan>
              <Typography
                variant="mainCoachDetailTitle"
                sx={{
                  display: "inline-block",
                  width: "4rem",
                  textAlign: "justify",
                  fontSize: { xs: "1.1rem", md: "1.2rem" },
                  fontWeight: "600",
                }}
              >
                직함
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ padding: { xs: "0 0 0 2rem", md: "0" } }}
            >
              <Typography
                variant="mainCoachDetailContent"
                fontSize={{ xs: "1rem", md: "1.1rem" }}
              >
                {coach_kor_title[profile.currentAffiliation.position]}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4}>
              <BulletSpan></BulletSpan>
              <Typography
                variant="mainCoachDetailTitle"
                sx={{
                  display: "inline-block",
                  width: "4rem",
                  fontSize: { xs: "1.1rem", md: "1.2rem" },
                  fontWeight: "600",
                }}
              >
                자격증
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ padding: { xs: "0 0 0 2rem", md: "0" } }}
            >
              <Typography
                variant="mainCoachDetailContent"
                fontSize={{ xs: "1rem", md: "1.1rem" }}
              >
                {profile.certification}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
