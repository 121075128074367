import MainLayout from "../../LayoutContainers/MainLayout";
import RowBox from "../../LayoutContainers/RowBox";
import PlayerRegister from "../../components/main/join/PlayerRegister";
import { Routes, Route, useParams } from "react-router-dom";

export default function PlayerRegisterLayout() {
  let { role } = useParams();
  return (
    <MainLayout mode="light">
      <RowBox
        mode="light"
        bgColor="none"
        padding={"0 0 6rem 0"}
        isBorder={false}
      >
        <PlayerRegister></PlayerRegister>
      </RowBox>
    </MainLayout>
  );
}
