import React, { useRef, useState } from "react";
//import DatePicker, {registerLocale} from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
//registerLocale("el", el);
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { koKR } from "@mui/x-date-pickers/locales";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import SelectDialog from "./SelectDialog";
import dayjs from "dayjs";
import RemoveDialog from "./RemoveDialog";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { API } from "../../../config";
import URLCreator from "./URLParser";
import { useRecoilValue } from "recoil";
import {
  enumAtom,
  positionEnumAtom,
} from "../../../recoil/atoms/applicationEnum";
import { detailPositionSelector } from "../../../recoil/atoms/detailPositionSelector";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/material";
export default function CareerListItem({
  comType,
  roleType,
  selectBoxType,
  currentCareer,
  career,
  setCareer,
  roleText,
  setSnackbarOpen,
  setLastCRUD,
}) {
  const [teamSelectOpen, setTeamSelectOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);

  const positionEnum = useRecoilValue(enumAtom);
  const positionDetailEnum = useRecoilValue(detailPositionSelector);

  function setStartDate(e) {
    setCareer((prev) =>
      prev.map((item) =>
        item.seq === currentCareer.seq ? { ...item, joinedAt: e.$d } : item
      )
    );
  }
  function setEndDate(e) {
    setCareer((prev) =>
      prev.map((item) =>
        item.seq === currentCareer.seq ? { ...item, leftAt: e.$d } : item
      )
    );
  }
  const changePosition = (e) => {
    const newPosition = e.target.value;

    setCareer((prev) =>
      prev.map((item) =>
        item.seq === currentCareer.seq
          ? { ...item, position: newPosition }
          : item
      )
    );
  };
  function inputValidation(item) {
    if (!item.joinedAt) {
      alert("시작일을 입력하세요");
      return false;
    }
    if (!item.leftAt) {
      alert("종료일 입력하세요");
      return false;
    }
    if (item.joinedAt >= item.leftAt) {
      alert("시작일이 종료일보다 크거나 같습니다.");
      return false;
    }
    if (!item.club) {
      alert("팀을 선택해주세요");
      return false;
    }
    if (!item.position) {
      alert("포지션을 선택해주세요");
      return false;
    }
    return true;
  }
  async function requestAddCareer(item) {
    if (!inputValidation(item)) {
      return;
    }
    const requestData = {
      clubId: item.club.id || null,
      position: item.position,
      kitNumber: 10, //item.kitNumber현재는 kitNumber입력창이 없음
      joinedAt: item.joinedAt,
      leftAt: item.leftAt,
      etcClubName: item.club.id ? null : item.club.name,
    };
    console.log(requestData);
    try {
      const url = URLCreator(roleType, comType);
      const {
        data: { data },
      } = await axios.post(url, requestData);
      console.log(12, data);
      setCareer((prev) =>
        data.map((item, index) => ({
          ...item,
          seq: index,
          fromServer: true,
          isEditState: false,
        }))
      );
      console.log(13, career);
      alert("성공적으로 저장하였습니다.");
      //setLastCRUD("성공적으로 저장하였습니다.")
      //setSnackbarOpen(true)
    } catch (e) {
      console.log(e);
    }
  }
  async function requestUpdateCareer(item) {
    console.log(item);
    if (!inputValidation(item)) {
      return;
    }
    const requestData = {
      clubId: item.club.id || null,
      position: item.position,
      kitNumber: 10, //item.kitNumber현재는 kitNumber입력창이 없음
      joinedAt: item.joinedAt,
      leftAt: item.leftAt,
      etcClubName: item.club.id ? null : item.club.name,
    };
    console.log("carrer update data", requestData);
    try {
      const url = `${URLCreator(roleType, comType)}/${item.id}`;
      const {
        data: { data },
      } = await axios.put(url, requestData);
      console.log("after update", data);
      setCareer((prev) =>
        data.map((item, index) => ({
          ...item,
          seq: index,
          fromServer: true,
          isEditState: false,
        }))
      );
      alert("성공적으로 수정하였습니다.");
      //setLastCRUD("성공적으로 수정하였습니다.");
      //setSnackbarOpen(true);
    } catch (e) {
      console.log(e);
    }
  }
  function onCreateOrUpdate(e) {
    if (currentCareer.isEditState === true) {
      //버튼의 텍스트가 저장상태라면 해당내용을 서버에 저장
      //fromServer데이터라면 수정
      if (currentCareer.fromServer === true) {
        requestUpdateCareer(currentCareer);
      } else {
        //fromServer데이터가 아니라면 생성
        requestAddCareer(currentCareer);
      }
    } else {
      //버튼의 텍스트가 수정상태라면 수정상태로만 변경
      setCareer((prev) =>
        prev.map((item) =>
          item.seq === currentCareer.seq ? { ...item, isEditState: true } : item
        )
      );
    }
  }
  async function removeBtnClick(e) {
    if (currentCareer.fromServer) {
      if (window.confirm("삭제하시겠습니까?")) {
        try {
          const url = `${URLCreator(roleType, comType, null, "DELETE")}/${currentCareer.id}`;
          const {
            data: { data },
          } = await axios.delete(url);
          setCareer((prev) =>
            data.map((item, index) => ({
              ...item,
              seq: index,
              fromServer: true,
              isEditState: false,
            }))
          );
          alert("삭제완료");
        } catch (error) {
          console.log(error);
        }
      } else {
        alert("삭제취소");
      }
    } else {
      setCareer((prev) =>
        prev.filter((item) => item.seq !== currentCareer.seq)
      );
    }
  }
  function findTeam() {
    setTeamSelectOpen(true);
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: {
          xs: "left",
          md: "center",
        },
        flexDirection: {
          xs: "column",
          md: "row",
        },
        justifyContent: "flex-start",
        border: {
          xs: "1px solid rgb(64,132,75)",
          md: "none",
        },
        padding: {
          xs: "0.5rem 0.5rem",
          md: "0",
        },
        margin: {
          xs: "0.5rem",
          md: "0",
        },
        borderRadius: "3%",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
        <MobileDatePicker
          disabled={!currentCareer.isEditState}
          localeText={"ko"}
          defaultValue={dayjs(currentCareer.joinedAt)}
          value={dayjs(currentCareer.joinedAt)}
          onChange={setStartDate}
          sx={{
            m: 1,
            width: { xs: "95%", md: "17rem" }, //sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
            "& .MuiOutlinedInput-input": {
              border: 0,
              borderRadius: 3,
              fontSize: "1.2rem",
            },
          }}
          slotProps={{ textField: { size: "small" } }}
          views={["year", "month"]}
          format={"YYYY-MM"}
          label={"시작일"}
          data-pickerType="startDate"
          inputProps={{ style: { fontSize: "1.2rem" } }}
        />
      </LocalizationProvider>

      <Box display={{ xs: "none", sm: "block" }}>&nbsp;&nbsp;~&nbsp;&nbsp;</Box>

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
        <MobileDatePicker
          disabled={!currentCareer.isEditState}
          defaultValue={dayjs(currentCareer.leftAt)}
          value={dayjs(currentCareer.leftAt)}
          onChange={setEndDate}
          slotProps={{ textField: { size: "small" } }}
          views={["year", "month"]}
          format={"YYYY-MM"}
          label={"종료일"}
          data-pickerType="endDate"
          sx={{
            m: 1,
            width: { xs: "95%", md: "17rem" },
            "& .MuiOutlinedInput-input": {
              border: 0,
              borderRadius: 3,
              fontSize: "1.2rem",
            },
          }}
          inputProps={{ style: { fontSize: "1.2rem" } }}
        />
      </LocalizationProvider>
      <TextField
        disabled={!currentCareer.isEditState}
        onClick={findTeam}
        size="small"
        sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
        id="position-input"
        label={"팀명"}
        variant="outlined"
        value={currentCareer.club ? currentCareer.club.name : ""}
        defaultValue={currentCareer.club ? currentCareer.club.name : ""}
        inputProps={{ style: { fontSize: "1.2rem" } }}
      />
      <FormControl
        sx={{ width: { xs: "95%", md: "17rem" }, m: 1 }}
        size="small"
      >
        <InputLabel id="demo-simple-select-label">포지션</InputLabel>
        <Select
          disabled={!currentCareer.isEditState}
          defaultValue={currentCareer.position}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Position"
          value={currentCareer.position}
          onChange={changePosition}
          sx={{
            "& .MuiOutlinedInput-input": {
              fontSize: "1.2rem",
            },
          }}
          MenuProps={{
            style: {
              maxHeight: 250,
            },
          }}
        >
          {comType === API.ComType.PLAYER_HISOTRY
            ? positionDetailEnum.map((item) => (
                <MenuItem
                  sx={{ color: item.color, fontSize: "1rem" }}
                  value={item.name}
                >
                  {item.description}
                </MenuItem>
              ))
            : positionEnum.coachPositions.map((item) => (
                <MenuItem value={item.name}>{item.description}</MenuItem>
              ))}
        </Select>
      </FormControl>

      <Box sx={{ display: "flex" }}>
        <Button
          value={currentCareer.seq}
          onClick={onCreateOrUpdate}
          variant="contained"
          sx={{
            backgroundColor: currentCareer.isEditState
              ? "info.main"
              : "success.main",
            ":hover": {
              backgroundColor: currentCareer.isEditState
                ? "info.main"
                : "success.main",
              color: "yellow",
            },
          }}
        >
          {currentCareer.isEditState ? "저장" : "수정"}
        </Button>
        <Tooltip
          title={currentCareer.isEditState ? "" : "수정버튼을 먼저 클릭하세요"}
        >
          <Button
            disabled={!currentCareer.isEditState}
            value={currentCareer.seq}
            onClick={removeBtnClick}
            variant="contained"
            sx={{
              backgroundColor: "#9D0000",
              ":hover": {
                bgcolor: "#9D0000",
                color: "yellow",
              },
            }}
          >
            삭제
          </Button>
        </Tooltip>
      </Box>

      <SelectDialog
        selectBoxType={selectBoxType}
        open={teamSelectOpen}
        setOpen={setTeamSelectOpen}
        itemSeq={currentCareer.seq}
        setCareer={setCareer}
      />
      <RemoveDialog
        comType={comType}
        roleType={roleType}
        open={removeOpen}
        setOpen={setRemoveOpen}
        itemSeq={currentCareer.seq}
        rows={career}
        setRows={setCareer}
        setSnackbarOpen={setSnackbarOpen}
        setLastCRUD={setLastCRUD}
      />
    </Box>
  );
}
