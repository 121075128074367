import axios from "axios";
import CoachListTable from "./CoachListTable";
import { useState, useEffect, useRef } from "react";
import { API } from "../../config";
import { Box } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { enumAtom } from "../../recoil/atoms/applicationEnum";
import { useNavigationType } from "react-router";
import Filter from "../common/Filter";
import SearchBox from "../common/SearchBox";
import { coachListBackAtom } from "../../recoil/atoms/coachListBack";

export default function CoachList() {
  const pageSize = 8;
  const appEnum = useRecoilValue(enumAtom);
  const [coachListBack, setCoachListBack] = useRecoilState(coachListBackAtom);
  const [coaches, setCoaches] = useState(null);
  const coachSearchNameInput = useRef(null);
  const content1Ref = useRef();
  const [isLeagueClicked, setIsLeagueClicked] = useState(
    Array(appEnum.leagues.length + 1).fill(false)
  );
  const [isPositionClicked, setIsPositionClicked] = useState(
    Array(appEnum.coachPositions.length + 1).fill(false)
  );

  const [pagingInfo, setPagingInfo] = useState({
    isRequired: false,
    totalPages: 1,
    currentPage: 1,
  });
  const navigationType = useNavigationType();
  function createRequestData() {
    let selectedPositionNames = [];
    let selectedLeagueIds = [];

    selectedLeagueIds = isLeagueClicked
      .slice(1)
      .map((ilc, index) => (ilc ? appEnum.leagues[index].id : null))
      .flat()
      .filter(Boolean);
    selectedPositionNames = isPositionClicked
      .slice(1)
      .map((ipc, index) =>
        ipc ? appEnum.coachPositions[parseInt(index)].name : null
      )
      .flat()
      .filter(Boolean);

    const data = {
      query: coachSearchNameInput.current.value,
      leagueIds: selectedLeagueIds, //selectedLeagueNames
      positions: selectedPositionNames, //selectedPositionNames
      regions: null,
    };
    return data;
  }

  async function fetchdata(currentPage) {
    const requestData = createRequestData();
    const requestURL = `${API.COACHES_SEARCH}?page=${currentPage}&size=${pageSize}`;

    const {
      data: { data },
    } = await axios.post(requestURL, requestData);
    setCoaches(data.content);
    setPagingInfo((prev) => ({
      isRequired: data.totalPages <= 1 ? false : true,
      totalPages: parseInt(data.totalPages),
      currentPage: currentPage + 1,
    }));
  }
  function scrollScreen() {
    content1Ref.current?.scrollIntoView({ behavior: "auto" });
  }
  useEffect(() => {
    if (navigationType === "PUSH") {
      //처음 로딩
      fetchdata(0);
    } else if (navigationType === "POP") {
      if (Object.keys(coachListBack).length === 0) {
        //링크 강제 입력
        fetchdata(0);
      } else {
        setCoaches((prev) => coachListBack.data.map((tb) => tb));
        setPagingInfo((prev) => ({ ...coachListBack.pagingInfo }));
        setIsLeagueClicked((prev) => coachListBack.filterLeague);
        setIsPositionClicked((prev) => coachListBack.filterPosition);
        setTimeout(
          () =>
            window.scrollTo({
              top: parseInt(coachListBack.scrollY),
              behavior: "instant",
            }),
          0
        );
      }
    }
  }, []);

  return (
    <Box>
      <Box sx={{ paddingBottom: "1rem", borderBottom: "1px solid #ccc" }}>
        {isLeagueClicked ? (
          <Filter
            categoryName="리그"
            filterItem={[
              { name: "ALL", description: "전체", key: 0 },
              ...appEnum.leagues.map((r, index) => ({ ...r, key: index + 1 })),
            ]}
            isClicked={isLeagueClicked}
            setIsClicked={setIsLeagueClicked}
          ></Filter>
        ) : null}
        {isPositionClicked ? (
          <Filter
            categoryName="포지션"
            filterItem={[
              { name: "ALL", description: "전체", key: 0 },
              ...appEnum.coachPositions.map((r, index) => ({
                ...r,
                key: index + 1,
              })),
            ]}
            isClicked={isPositionClicked}
            setIsClicked={setIsPositionClicked}
            itemBtnWidth="32%"
          ></Filter>
        ) : null}
      </Box>

      <Box>
        <Box ref={content1Ref} sx={{ margin: "1rem 0" }}>
          <SearchBox
            id="coach-search-box"
            ref={coachSearchNameInput}
            fetchdata={fetchdata}
            placeHolder="지도자명 or 팀명"
          ></SearchBox>
        </Box>
      </Box>

      {coaches ? (
        <CoachListTable
          coaches={coaches}
          pagingInfo={pagingInfo}
          setPagingInfo={setPagingInfo}
          fetchdata={fetchdata}
          scrollScreen={scrollScreen}
          setCoachListBack={setCoachListBack}
          isLeagueClicked={isLeagueClicked}
          isPositionClicked={isPositionClicked}
        ></CoachListTable>
      ) : null}
    </Box>
  );
}
