import { Box, Button, TextField } from "@mui/material";
import { StyledButton } from "../mypage/common/MyPageComponents";
import { useRef, useState } from "react";
import { API } from "../../config";
import axios from "axios";
import styled from "styled-components";

const LabelBox = styled("div")`
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
`;
const DaySelect = styled("select")`
  width: 4rem;
  font-size: 1.1rem;
  color: #444;
  border: 1px solid #ccc;
  border-radius: 5%/10%;
`;
function emailCheck(email_address) {
  const email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
  if (!email_regex.test(email_address)) {
    return false;
  } else {
    return true;
  }
}
function getDayRange(type) {
  let result = null;
  if (type === "YEAR") {
    const endYear = parseInt(new Date().getFullYear());
    result = [...Array(100).keys()].map((key) => endYear - key);
  } else if (type === "MONTH") {
    result = [...Array(12).keys()].map((key) => key + 1);
  } else if (type === "DAY") {
    result = [...Array(31).keys()].map((key) => key + 1);
  }
  return result;
}
export default function FindId() {
  const emailRef = useRef();
  const codeRef = useRef();
  const birthRef = useRef([]);
  const nameRef = useRef();
  const [isAuthCodeRequest, setIsAuthCodeRequest] = useState(false);
  const [emailAuthComplete, setEmailAuthComplete] = useState(false);
  const [findIDInfo, setFindIDInfo] = useState(false);
  async function onRequestCode() {
    //1. 코드를 이메일로 전달
    const inputEmail = emailRef.current.value.trim();
    if (!emailCheck(inputEmail)) {
      alert("이메일 형식이 올바르지 않습니다");
      return;
    }
    const requestData = {
      email: inputEmail,
    };

    try {
      const res = await axios.post(API.EMAIL_CODE_REQ, requestData, {
        headers: {
          "Content-type": "application/json",
        },
      });

      if (res.data.code === 201000) {
        setIsAuthCodeRequest((prev) => true);
        alert(`${inputEmail}으로 인증코드를 전송했습니다`);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function onRequestAuth() {
    const requestData = {
      email: emailRef.current.value.trim(),
      code: codeRef.current.value.trim(),
    };
    try {
      const res = await axios.post(API.EMAIL_AUTU, requestData);
      if (res.data.code === 200000) {
        alert("인증이 성공하였습니다");
        setEmailAuthComplete((prev) => true);
      } else {
      }
    } catch (error) {
      alert("인증이 실패했습니다. 코드를 확인하시거나 재요청해주세요");
    }

    //2. 받은 이메일 입력
  }
  async function findId() {
    const inputBirthYear = birthRef.current[0].value.trim();
    const inputBirthMonth = birthRef.current[1].value.trim();
    const inputBirthDay = birthRef.current[2].value.trim();
    const inputEmail = emailRef.current.value.trim();
    const inputName = nameRef.current.value.trim();

    try {
      const requestData = {
        email: inputEmail,
        name: inputName,
        birthDate: `${inputBirthYear}-${inputBirthMonth.length === 1 ? `0${inputBirthMonth}` : inputBirthMonth}-${inputBirthDay.length === 1 ? `0${inputBirthDay}` : inputBirthDay}`,
      };
      console.log("requestData", requestData);
      const {
        data: { data },
      } = await axios.post(API.ACCOUNT_FIND_ID, requestData);
      setFindIDInfo((prev) => data);
    } catch (error) {
      alert(error.response.data.message);
    }
  }
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        maxWidth: "576px",
        margin: "0 auto",
        padding: "2rem",
        borderRadius: "2%/6%",
        boxShadow: "1px 1px 1px 1px #ccc, 2px 2px 2px 2px #eee",
      }}
    >
      <Box>아이디를 찾기 위해 이메일 인증이 필요합니다.</Box>
      <Box>
        <StyledButton
          style={{ width: "13rem", borderRadius: "0", padding: "0.5rem 1rem" }}
          onClick={onRequestCode}
        >
          이메일로 인증코드 요청
        </StyledButton>
      </Box>
      <Box>
        <TextField
          id="email_auth_code"
          inputRef={emailRef}
          size="small"
          fullWidth
          sx={{ m: "0 0 0.5rem", width: "100%" }}
          variant="outlined"
          inputProps={{
            style: { fontSize: "1.2rem", height: "1.2rem" },
          }}
          placeholder="회원 가입시 사용한 이메일 입력"
        />
      </Box>
      {isAuthCodeRequest ? (
        <Box sx={{ margin: "1rem 0" }}>
          <Box>
            <p
              style={{
                color: "#056186",
                fontWeight: "600",
                fontSize: "1rem",
              }}
            >
              코드입력(시간이 다소 걸릴 수 있습니다)
            </p>
            <Box sx={{ display: "flex" }}>
              <TextField
                id="email_auth_code"
                inputRef={codeRef}
                size="small"
                fullWidth
                sx={{ m: "0 0 0.5rem", width: "100%" }}
                variant="outlined"
                inputProps={{
                  style: { fontSize: "1.2rem", height: "1.2rem" },
                }}
                placeholder="전달받은 인증코드 입력"
              />
              <Button
                variant="contained"
                disabled={!isAuthCodeRequest}
                color="primary"
                sx={{ width: "8rem", padding: "0.2rem", height: "2.2rem" }}
                onClick={onRequestAuth}
              >
                인증시도
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}
      {emailAuthComplete ? (
        <Box
          sx={{
            border: "1px solid #ccc",
            padding: "0.5rem",
            margin: "1rem 0 0 0",
            fontSize: "1.2rem",
          }}
        >
          <Box sx={{ margin: "0 0 0.5rem 0" }}>
            <p
              style={{
                color: "#000",
                fontWeight: "600",
                fontSize: "1.1rem",
                margin: "0 0 1rem 0",
              }}
            >
              회원가입시 사용한 이름과 생년월일
            </p>
            <LabelBox>이름</LabelBox>
            <TextField
              id="find_id_name"
              inputRef={nameRef}
              size="small"
              fullWidth
              sx={{ m: "0 0 0.5rem", width: "100%" }}
              variant="outlined"
              inputProps={{
                style: { fontSize: "1rem", height: "1rem" },
              }}
              placeholder="이름"
            />
          </Box>
          <Box sx={{ margin: "0 0 0.5rem 0" }}>
            <LabelBox>생년월일</LabelBox>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  fontSize: "1.1rem",
                  margin: "0 1rem 0 0",
                  height: "20px",
                  overflowY: "visible",
                }}
              >
                <DaySelect
                  name="dayselect-year"
                  ref={(el) => (birthRef.current[0] = el)}
                  style={{ width: "5rem", minHeight: "20px" }}
                >
                  {getDayRange("YEAR").map((yr) => (
                    <option value={yr}>{yr}</option>
                  ))}
                </DaySelect>
                년
              </Box>
              <Box sx={{ fontSize: "1.1rem", margin: "0 1rem 0 0" }}>
                <DaySelect
                  name="dayselect-month"
                  ref={(el) => (birthRef.current[1] = el)}
                  style={{ width: "3rem", minHeight: "20px" }}
                >
                  {getDayRange("MONTH").map((yr) => (
                    <option value={yr}>{yr}</option>
                  ))}
                </DaySelect>
                월
              </Box>
              <Box sx={{ fontSize: "1.1rem" }}>
                <DaySelect
                  name="dayselect-day"
                  ref={(el) => (birthRef.current[2] = el)}
                  style={{ width: "3rem" }}
                >
                  {getDayRange("DAY").map((yr) => (
                    <option value={yr}>{yr}</option>
                  ))}
                </DaySelect>
                일
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              disabled={!isAuthCodeRequest}
              color="primary"
              sx={{ width: "8rem", padding: "0.2rem", height: "2.2rem" }}
              onClick={findId}
            >
              아이디찾기
            </Button>
          </Box>
        </Box>
      ) : null}
      {findIDInfo ? (
        <Box sx={{ fontSize: "1.1rem" }}>아이디: {findIDInfo.loginId}</Box>
      ) : null}
    </Box>
  );
}
