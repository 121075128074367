import { Box, Divider, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { API } from "../../config";

export default function AgencyCard({ agency }) {
  const navigate = useNavigate();
  const handleAgencyCardClick = (event) => {
    navigate(`/agency-detail/${agency.id}`);
  };
  return (
    <Box
      onClick={handleAgencyCardClick}
      sx={{
        borderRadius: "0.5rem 0.3rem",
        border: "1px solid rgb(64,132,75)",
        padding: "1.5rem 1rem",
        cursor: "pointer",
      }}
    >
      <Box>
        <Box sx={{ width: "80%", overflow: "hidden" }}>
          <img
            src={`${API.BASE}/${agency.logo.fileUrl}`}
            style={{ width: "100%", objectFit: "contain" }}
          ></img>
        </Box>
        <Divider></Divider>
        <Box sx={{ fontSize: "1.2rem", margin: "0.5rem 0 0 0" }}>
          {agency.name}
        </Box>
      </Box>
    </Box>
  );
}
