import { Box, Button, TextField } from "@mui/material";
import { TitleBox } from "../../common/MyPageComponents";
import styled from "styled-components";
import { useRef } from "react";
import axios from "axios";
import { API } from "../../../../config";

const ModifiedLabel = styled("span")`
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  width: 7rem;
`;

export default function AgencyProfileBasic({
  basicInfo,
  setBasicInfo,
  isEditState,
  setIsEditState,
}) {
  const inputRef = useRef([]);
  async function requestAgencyInfoUpdate() {
    const newData = {
      name: inputRef.current[0].value,
      ceo: inputRef.current[1].value,
      phoneNumber: inputRef.current[2].value,
      email: inputRef.current[3].value,
      address: inputRef.current[4].value,
      logoImageId: basicInfo.logo ? basicInfo.logo.id : null,
    };
    try {
      const { data } = await axios.put(API.MP_AGENCY_COMMON, newData);
      console.log(data);
      setBasicInfo((prev) => data);
      setIsEditState((prev) => false);
      alert("에이전시 정보를 수정했습니다");
    } catch (error) {}
  }
  function phoneNumberChange() {
    inputRef.current[2].value = inputRef.current[2].value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
  function onEditBtnClick() {
    if (isEditState) {
      requestAgencyInfoUpdate();
    } else {
      setIsEditState((prev) => !prev);
    }
  }
  function onEditCancleBtnClick() {
    console.log("수정취소");
    console.log("basicInfo", basicInfo);
    inputRef.current[0].value = basicInfo.name;
    inputRef.current[1].value = basicInfo.ceo;
    inputRef.current[2].value = basicInfo.phoneNumber;
    inputRef.current[3].value = basicInfo.email;
    inputRef.current[4].value = basicInfo.address;
  }

  return (
    <Box>
      <TitleBox>에이전시 정보수정</TitleBox>
      <Button
        onClick={onEditBtnClick} //onEditBtnClick
        variant="contained"
        sx={{
          backgroundColor: isEditState ? "info.main" : "success.main",
          ":hover": {
            backgroundColor: isEditState ? "info.main" : "success.main",
            color: "yellow",
          },
        }}
      >
        {isEditState ? "저장" : "수정"}
      </Button>
      <Button
        onClick={onEditCancleBtnClick} //onEditBtnClick
        variant="contained"
        sx={{
          backgroundColor: isEditState ? "info.main" : "#777",
          ":hover": {
            backgroundColor: isEditState ? "info.main" : "success.main",
            color: "yellow",
          },
          margin: "0 0 0 0.1rem",
        }}
      >
        취소
      </Button>
      <Box sx={{ padding: "0.5rem" }}>
        <Box sx={{ maxWidth: "30rem" }}>
          <ModifiedLabel>이름</ModifiedLabel>
          <TextField
            id="agency-comnapy-name"
            defaultValue={basicInfo.name}
            inputRef={(el) => (inputRef.current[0] = el)}
            size="small"
            variant="outlined"
            disabled={!isEditState}
            name="scouter-input-phoneNumber"
            sx={{ m: "0.5rem 0", width: { xs: "95%" } }}
            inputProps={{
              style: { fontSize: "1.2rem", height: "1.2rem" },
            }}
          />
        </Box>
        <Box sx={{ maxWidth: "30rem" }}>
          <ModifiedLabel>대표자</ModifiedLabel>
          <TextField
            id="agency-ceo"
            defaultValue={basicInfo.ceo}
            inputRef={(el) => (inputRef.current[1] = el)}
            size="small"
            variant="outlined"
            disabled={!isEditState}
            name="scouter-input-phoneNumber"
            sx={{ m: "0.5rem 0", width: { xs: "95%" } }}
            inputProps={{
              style: { fontSize: "1.2rem", height: "1.2rem" },
            }}
          />
        </Box>
        <Box sx={{ maxWidth: "30rem" }}>
          <ModifiedLabel>공개연락처</ModifiedLabel>
          <TextField
            id="agency-phone"
            defaultValue={basicInfo.phoneNumber}
            inputRef={(el) => (inputRef.current[2] = el)}
            size="small"
            variant="outlined"
            disabled={!isEditState}
            name="scouter-input-phoneNumber"
            placeholder="하이픈(-)없이"
            sx={{ m: "0.5rem 0", width: { xs: "95%" } }}
            onChange={phoneNumberChange}
            inputProps={{
              style: { fontSize: "1.2rem", height: "1.2rem" },
            }}
          />
        </Box>
        <Box sx={{ maxWidth: "30rem" }}>
          <ModifiedLabel>공개이메일</ModifiedLabel>

          <TextField
            id="agency-email"
            defaultValue={basicInfo.email}
            inputRef={(el) => (inputRef.current[3] = el)}
            size="small"
            variant="outlined"
            disabled={!isEditState}
            name="scouter-input-email"
            sx={{ m: "0.5rem 0", width: { xs: "95%" } }}
            inputProps={{
              style: { fontSize: "1.2rem", height: "1.2rem" },
            }}
          />
        </Box>
        <Box sx={{ maxWidth: "30rem" }}>
          <ModifiedLabel>주소</ModifiedLabel>
          <TextField
            id="agency-address"
            defaultValue={basicInfo.address}
            inputRef={(el) => (inputRef.current[4] = el)}
            size="small"
            variant="outlined"
            disabled={!isEditState}
            name="scouter-input-phoneNumber"
            sx={{ m: "0.5rem 0", width: { xs: "95%" } }}
            inputProps={{
              style: { fontSize: "1.2rem", height: "1.2rem" },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
