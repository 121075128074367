import React from "react";
import { GridLoader } from "react-spinners";

// 술사진 로딩 페이지
const override = {
  span: "10px",
  color: "#fff",
  size: "10",
  position: "absolute",
  top: "30%",
  right: "30%",
};

const Loading = ({ loading }) => {
  return (
    <div>
      <GridLoader
        loading={loading}
        cssOverride={override}
        speedMultiplier={0.8}
        color="#fff"
        margin={5}
      />
    </div>
  );
};

export default Loading;
