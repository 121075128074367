import { useNavigate } from "react-router-dom";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "../../config";
import { Box, Pagination, Stack } from "@mui/material";
import StyledTable from "../common/ItemListTable";

const tableHeaderText = [
  { name: "선수", width: "10%" },
  { name: "이름", width: "10%" },
  { name: "팀", width: "20%" },
  { name: "리그", width: "15%" },
  { name: "No", width: "10%" },
  { name: "포지션", width: "20%" },
];

export default function PlayerListTable({
  players,
  pagingInfo,
  setPagingInfo,
  fetchdata,
  scrollScreen,
  setPlayerListBack,
  isLeagueClicked,
  isPositionClicked,
}) {
  function onPageChange(event, value) {
    fetchdata(parseInt(value - 1));
  }
  const navigate = useNavigate();
  const handleRowClick = (playerId) => {
    
    setPlayerListBack((prev) => ({
      isFirstLoad: false,
      data: players,
      pagingInfo: pagingInfo,
      scrollY: window.scrollY,
      filterLeague: isLeagueClicked,
      filterPosition: isPositionClicked,
    }));
    navigate(`/player-detail/${playerId}`);
  };
  return (
    <Box>
      <Box>
        <StyledTable>
          <thead>
            <tr style={{ fontSize: "1.1rem" }}>
              {tableHeaderText.map((item, index) =>
                item.name === "이름" || item.name === "리그" ? (
                  window.innerWidth > 600 ? (
                    <th key={index} style={{ width: item.width }}>
                      {item.name}
                    </th>
                  ) : null
                ) : (
                  <th key={index} style={{ width: item.width }}>
                    {item.name}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {players.map((player, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(player.id)}
                style={{ fontSize: "1rem" }}
              >
                {player.profileImage ? (
                  <td>
                    <div
                      style={{
                        width: "3.5rem",
                        height: "3.5rem",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        src={`${API.BASE}/${player.profileImage.fileUrl}`}
                        alt="profile-img"
                        style={{ objectFit: "cover" }}
                      ></img>
                    </div>
                    {window.innerWidth > 600 ? null : <div>{player.name}</div>}
                  </td>
                ) : (
                  <td>
                    <div
                      style={{
                        width: "4rem",
                        height: "4rem",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ display: "inline-block", margin: "0 auto" }}
                        icon={faUser}
                        size="3x"
                        color="#81cdc6"
                      />
                    </div>
                    {window.innerWidth > 600 ? null : <div>{player.name}</div>}
                  </td>
                )}
                {window.innerWidth > 600 ? <td>{player.name}</td> : null}

                <td style={{ width: "45%" }}>{player.club.name}</td>
                {window.innerWidth > 600 ? (
                  <td>{player.league ? player.league.description : ""}</td>
                ) : null}
                <td>{player.club ? player.kitNumber : "-"}</td>
                <td>
                  {player.mainPosition ? player.mainPosition : ""}
                  {/* positionDetailEnum.find(
                        (pd) => pd.name === player.mainPosition
                      ).description */}
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </Box>
      <Box>
        <Box>
          {pagingInfo.isRequired ? (
            <Stack
              spacing={1}
              sx={{ margin: "1.5rem 1rem", width: "100%" }}
              alignItems="center"
            >
              <Pagination
                shape="circular"
                size="large"
                count={pagingInfo.totalPages}
                siblingCount={0}
                onChange={onPageChange}
                defaultPage={1}
                page={pagingInfo.currentPage}
                color="info"
              />
            </Stack>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
