import { Box } from "@mui/material";
import styled from "styled-components";

const StyledTable = styled.table`
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  caption-side: bottom;

  td,
  th {
    border: none;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 1rem 10px;
    text-align: center;
  }

  tbody tr {
    border-bottom: 1px solid #bbb;
    :nth-of-type(odd) {
    }
    // :hover {
    //   background-color: lightpink;
    // }
  }
  thead > tr {
    background-color: rgb(64, 132, 75);
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
  }
  th {
    padding: 10px 0;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;
const StyledTable2 = styled.table`
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  caption-side: bottom;

  td,
  th {
    border: none;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 1rem 10px;
    text-align: center;
    font-size: 1rem;
  }

  th {
    padding: 10px 0;
    background-color: rgb(64, 132, 75);
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
  }
`;

export default function CareerTable({ hHeaders, data }) {
  return (
    <Box>
      <StyledTable>
        <thead>
          <tr>
            {hHeaders.map((h, index) => (
              <th key={index}>{h}</th>
            ))}
          </tr>
        </thead>
        {data ? (
          <tbody style={{ fontSize: "1.1rem" }}>
            {data.map((row, rindex) => (
              <tr key={rindex}>
                {row.map((data, dindex) => (
                  <td key={dindex}>{data}</td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : null}
      </StyledTable>
    </Box>
  );
}
