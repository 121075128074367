import { Box } from "@mui/material";
import CareerListItem from "./CareerListItem";

export default function CareerList({
  comType,
  roleType,
  selectBoxType,
  career,
  setCareer,
  roleText,
  setSnackbarOpen,
  setLastCRUD,
}) {
  return (
    <Box>
      {career.map((item) => (
        <CareerListItem
          comType={comType}
          roleType={roleType}
          selectBoxType={selectBoxType}
          currentCareer={item}
          career={career}
          setCareer={setCareer}
          roleText={roleText}
          setSnackbarOpen={setSnackbarOpen}
          setLastCRUD={setLastCRUD}
        ></CareerListItem>
      ))}
    </Box>
  );
}
