import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import Button from "@mui/material/Button";
import RemoveDialog from "./RemoveDialog";
import Tooltip from "@mui/material/Tooltip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import URLCreator from "./URLParser";
import axios from "axios";
import { Box } from "@mui/material";

export default function YearTitleListItem({
  roleType,
  comType,
  row,
  rows,
  setRows,
  setLastCRUD,
  setSnackbarOpen,
}) {
  const currentYear = parseInt(String(new Date().getFullYear()).slice(0, 4));
  const howMany = 50;
  const baseYear = currentYear - howMany;
  const yearOption = Array.from(new Array(howMany), (x, i) =>
    String(i + baseYear + 1)
  );

  const [removeOpen, setRemoveOpen] = useState(false);

  function selectYear(e) {
    setRows((prev) =>
      prev.map((item) =>
        item.seq === row.seq ? { ...item, date: e.target.value } : item
      )
    );
  }
  function nameOnChange(e) {
    const newText = e.target.value;
    const seq = parseInt(row.seq);
    setRows((prev) =>
      prev.map((item) => (item.seq === seq ? { ...item, name: newText } : item))
    );
  }
  function inputValidation(item) {
    if (item.name.length < 3) {
      alert("세 글자 이상으로 입력해주세요");
      return false;
    }
    return true;
  }
  async function requestUpdateAwards(item) {
    if (!inputValidation(item)) {
      return;
    }
    const data = {
      date: item.date + "-10-10",
      name: item.name,
    };
    try {
      const url = `${URLCreator(roleType, comType)}/${item.id}`;
      const response = await axios.put(url, data);
      alert("성공적으로 수정하였습니다.");
      //setLastCRUD("성공적으로 수정하였습니다.")
      //setSnackbarOpen(true)
      setRows((prev) =>
        response.data.data.map((item, index) => ({
          ...item,
          date: item.date.slice(0, 4),
          seq: index,
          fromServer: true,
          isEditState: false,
        }))
      );
    } catch (e) {
      console.log(e);
    }
  }
  async function requestAddAwards(item) {
    if (!inputValidation(item)) {
      return;
    }

    const data = {
      date: `${row.date}-01-01`,
      name: row.name,
    };
    try {
      const url = URLCreator(roleType, comType);
      const response = await axios.post(url, data);
      alert("성공적으로 저장하였습니다.");
      //setLastCRUD("성공적으로 저장하였습니다.")
      //setSnackbarOpen(true)
      setRows((prev) =>
        response.data.data.map((item, index) => ({
          ...item,
          date: item.date.slice(0, 4),
          seq: index,
          fromServer: true,
          isEditState: false,
        }))
      );
    } catch (e) {
      console.log(e);
    }
  }
  function onCreateOrUpdate(e) {
    const editSeq = parseInt(e.currentTarget.getAttribute("value"));

    const findObj = rows.find((item) => item.seq === editSeq);
    console.log(findObj);
    if (findObj.isEditState === true) {
      //버튼의 텍스트가 저장상태라면 해당내용을 서버에 저장

      if (findObj.fromServer === true) {
        requestUpdateAwards(findObj);
      } else {
        //fromServer데이터가 아니라면 생성
        requestAddAwards(findObj);
      }
    }
    setRows((prev) =>
      prev.map((item) =>
        item.seq === editSeq ? { ...item, isEditState: true } : item
      )
    );
  }
  async function onRemove(e) {
    if (row.fromServer) {
      if (window.confirm("삭제하시겠습니까?")) {
        const url = `${URLCreator(roleType, comType, null, "DELETE")}/${row.id}`;
        try {
          const {
            data: { data },
          } = await axios.delete(url);
          alert("삭제하였습니다.");
          setRows((prev) =>
            data.map((item, index) => ({
              ...item,
              date: item.date.slice(0, 4),
              seq: index,
              fromServer: true,
              isEditState: false,
            }))
          );
        } catch (error) {
          console.log(error);
        }
      } else {
      }
    } else {
      setRows((prev) => prev.filter((item) => item.seq != row.seq));
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: {
          xs: "left",
          md: "center",
        },
        flexDirection: {
          xs: "column",
          md: "row",
        },
        justifyContent: "flex-start",
        border: {
          xs: "1px solid rgb(64,132,75)",
          md: "none",
        },
        padding: {
          xs: "0.5rem",
          md: "0",
        },
        margin: {
          xs: "0.5rem",
          md: "0",
        },
        borderRadius: "3%",
      }}
    >
      <FormControl
        sx={{ width: { xs: "95%", md: "17rem" }, m: 1 }}
        size="small"
      >
        <InputLabel>수상연도</InputLabel>
        <Select
          disabled={!row.isEditState}
          onChange={selectYear}
          label="연도"
          size="small"
          sx={{
            "& .MuiOutlinedInput-input": {
              fontSize: "1.2rem",
            },
          }}
          MenuProps={{
            style: {
              maxHeight: 250,
            },
          }}
          value={row.date}
        >
          {yearOption.map((item) => (
            <MenuItem sx={{ fontSize: "1rem" }} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id="year-title-title-input"
        disabled={!row.isEditState}
        onChange={nameOnChange}
        size="small"
        sx={{ m: 1, width: { xs: "95%", md: "20rem" } }}
        label={"수상명"}
        variant="outlined"
        value={row.name}
        inputProps={{ style: { fontSize: "1.2rem" } }}
      />
      <Box sx={{ display: "flex" }}>
        <Button
          value={row.seq}
          onClick={onCreateOrUpdate}
          variant="contained"
          sx={{
            backgroundColor: row.isEditState ? "info.main" : "success.main",
            ":hover": {
              backgroundColor: row.isEditState ? "info.main" : "success.main",
              color: "yellow",
            },
          }}
        >
          {row.isEditState ? "저장" : "수정"}
        </Button>
        <Tooltip title={row.isEditState ? "" : "수정버튼을 먼저 클릭하세요"}>
          <Button
            disabled={!row.isEditState}
            value={row.seq}
            onClick={onRemove}
            variant="contained"
            sx={{
              backgroundColor: "#9D0000",
              ":hover": {
                bgcolor: "#9D0000",
                color: "yellow",
              },
            }}
          >
            삭제
          </Button>
        </Tooltip>
      </Box>

      <RemoveDialog
        comType={comType}
        roleType={roleType}
        open={removeOpen}
        setOpen={setRemoveOpen}
        itemSeq={row.seq}
        rows={rows}
        setRows={setRows}
        setItems={setRows}
        setSnackbarOpen={setSnackbarOpen}
        setLastCRUD={setLastCRUD}
      />
    </Box>
  );
}
