import {
  faCircleInfo,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useRef, useState } from "react";
import { API } from "../../../../../config";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { detailPositionSelector } from "../../../../../recoil/atoms/detailPositionSelector";
import { enumAtom } from "../../../../../recoil/atoms/applicationEnum";
import TitleBox from "../../../../../LayoutContainers/TitleBox";
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#F3F6F9",
    cursor: "pointer",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const LabelBox = styled("div")`
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
`;
const staffTableHeader = ["이름", "생년월일"];
export default function MPStaffManagePersonAdd({
  comType,
  roleType,
  currentTeam,
  members,
  setMembers,
  setFilterConditions,
  setFilterMemberManually,
}) {
  const positionEnum = useRecoilValue(enumAtom);
  const playerPositionEnum = useRecoilValue(detailPositionSelector);
  const inputRef = useRef();
  const [findMember, setFindMember] = useState();
  const [clickedMember, setClickedMember] = useState();
  function onListClick(event) {
    const selectedMemberId = parseInt(
      event.currentTarget.getAttribute("value")
    );
    console.log("selectedMemberId", selectedMemberId);
    const selectedMember = findMember.find(
      (item) => item.id === selectedMemberId
    );
    console.log(selectedMember);
    setClickedMember((prev) => ({
      ...selectedMember,
      position: "ALL",
      joinAt: dayjs(new Date()),
    }));
  }
  async function onschBtnClick(event) {
    const searchKeyword = inputRef.current.value.trim();
    console.log(1, searchKeyword);
    if (searchKeyword.length <= 1) {
      alert("최소 두글자 검색어가 필요합니다");
      return;
    }
    try {
      const requestUrl =
        comType === API.ComType.TEAM_COACH
          ? API.COACHES_SEARCH
          : API.PLAYER_SEARCH;
      console.log(requestUrl);
      const requestData =
        comType === API.ComType.TEAM_COACH
          ? {
              query: searchKeyword,
            }
          : {
              query: searchKeyword,
              position: null,
              strength: null,
              mainFoot: null,
              league: null,
              address: {
                province: null,
                city: null,
              },
              page: 0,
              size: 10,
            };
      console.log("requestData", requestData);
      const {
        data: {
          data: { content },
        },
      } = await axios.post(requestUrl, requestData);

      const findTeam = content;
      if (findTeam.length === 0) {
        alert(
          `조회한 ${comType === API.ComType.TEAM_COACH ? "지도자" : "선수"}가 없습니다. 회원가입이 필요합니다.`
        );
      }
      setFindMember(content);

      console.log(content);
    } catch (error) {
      console.log(error);
    }
  }
  function onChangePosition(event) {
    setClickedMember((prev) => ({ ...prev, position: event.target.value }));
  }
  function onChangeSchoolYear(event) {
    setClickedMember((prev) => ({ ...prev, schoolYear: event.target.value }));
  }
  function onChangeKitNumber(event) {
    setClickedMember((prev) => ({ ...prev, kitNumber: event.target.value }));
  }
  async function onAddNewMember() {
    if (clickedMember.position === "ALL") {
      alert("포지션을 선택해주세요");
      return;
    }
    if (
      members.find(
        (item) =>
          item.member.name === clickedMember.name &&
          item.member.birthDate === clickedMember.birthDate
      ) !== undefined //누군가 있다
    ) {
      alert("이름과 생년월일이 동일한 멤버가 존재합니다.");

      return;
    }

    const updateRole =
      comType === API.ComType.TEAM_COACH ? "coaches" : "pro-players";
    const requestUrl = `${API.MP_COACH_MY_MANAGED_TEAM}/${currentTeam.id}/${updateRole}`;
    const requestData =
      comType === API.ComType.TEAM_COACH
        ? {
            coachId: clickedMember.id,
            position: clickedMember.position,
            joinedAt: dayjs(clickedMember.joinAt).add(1, "day"),
          }
        : {
            proPlayerId: clickedMember.id,
            position: clickedMember.position,
            joinedAt: dayjs(clickedMember.joinAt).add(1, "day"),
            schoolYear: clickedMember.schoolYear,
            kitNumber: clickedMember.kitNumber,
          };
    console.log(requestData);
    try {
      const {
        data: { data },
      } = await axios.post(requestUrl, requestData);
      alert("등록이 성공하였습니다.");
      setMembers((prev) => data);
      setClickedMember((prev) => null);
      setFilterConditions((prev) => ({
        status: "ALL",
        position: "ALL",
      }));
      setFilterMemberManually((prev) => data);
      setClickedMember((prev) => null);
      setFindMember((prev) => null);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Box sx={{ padding: "0.2rem" }}>
      <Box sx={{ fontSize: "1rem" }}>
        <FontAwesomeIcon icon={faCircleInfo} color="#1e8ffa" />
        <span>{`팀에 등록할 ${roleType === API.RolyType.COACH ? "지도자" : "선수"}를 검색`}</span>
      </Box>
      <Box
        sx={{
          width: {
            xs: "95%",
            md: "20rem",
          },
        }}
      >
        {/* <TextField
          inputRef={inputRef}
          onKeyDown={(e) => {
            if (e.key === "Enter") onschBtnClick();
          }}
          size="small"
          variant="outlined"
          placeholder="이름"
          sx={{ m: 0.5, width: { xs: "95%", md: "20rem" } }}
          inputProps={{ style: { fontSize: "1.1rem" } }}
        />
        <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" /> */}

        <input
          type="search"
          ref={inputRef}
          style={{
            width: "100%",
            lineHeight: "2rem",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") onschBtnClick();
          }}
          placeholder="검색후 enter"
        ></input>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5rem",
        }}
      >
        <Box sx={{ width: "49%", border: "1px solid #eee" }}>
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table sx={{ width: "85%", margin: "1rem" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#eee" }}>
                  {staffTableHeader.map((item) => (
                    <TableCell sx={{ padding: "0.4em", textAlign: "center" }}>
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {findMember
                  ? findMember.map((row) => (
                      <StyledTableRow
                        onClick={onListClick}
                        value={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{ textAlign: "center", padding: "0.5rem 0" }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", padding: "0.5rem 0" }}
                        >
                          {row.birthDate}
                        </TableCell>
                      </StyledTableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ width: "49%", border: "1px solid #eee", padding: "0.5rem" }}>
          <Box>
            <LabelBox>이름</LabelBox>
            <TextField
              disabled
              size="small"
              variant="outlined"
              value={clickedMember ? clickedMember.name : null}
              sx={{ m: 0.5, width: "95%" }}
              inputProps={{ style: { fontSize: "1.2rem", height: "1.2rem" } }}
            />
          </Box>
          <Box>
            <LabelBox>생년월일</LabelBox>
            <TextField
              disabled
              size="small"
              variant="outlined"
              value={clickedMember ? clickedMember.birthDate : null}
              sx={{ m: 0.5, width: "95%" }}
              inputProps={{ style: { fontSize: "1.2rem", height: "1.2rem" } }}
            />
          </Box>
          <Box>
            {comType === API.ComType.TEAM_PLAYER ? (
              <Box>
                <LabelBox>학년(성인팀 생략)</LabelBox>
                <TextField
                  id="new-player-schoolYear"
                  value={clickedMember ? clickedMember.schoolYear : ""}
                  defaultValue={clickedMember ? clickedMember.schoolYear : ""}
                  onChange={onChangeSchoolYear}
                  size="small"
                  placeholder="숫자만"
                  sx={{ m: 0.5, width: "95%" }}
                  inputProps={{
                    style: { fontSize: "1.2rem", height: "1.2rem" },
                  }}
                ></TextField>
              </Box>
            ) : null}
          </Box>
          <Box>
            {comType === API.ComType.TEAM_PLAYER ? (
              <Box>
                <LabelBox>등번호</LabelBox>
                <TextField
                  id="new-player-kitNum"
                  value={clickedMember ? clickedMember.kitNumber : ""}
                  defaultValue={clickedMember ? clickedMember.kitNumber : ""}
                  onChange={onChangeKitNumber}
                  size="small"
                  sx={{ m: 0.5, width: "95%" }}
                  inputProps={{
                    style: { fontSize: "1.2rem", height: "1.2rem" },
                  }}
                ></TextField>
              </Box>
            ) : null}
          </Box>
          <Box>
            <LabelBox>포지션</LabelBox>
            <FormControl sx={{ width: "95%", m: 0.5 }} size="small">
              <Select
                defaultValue={"ALL"}
                value={clickedMember ? clickedMember.position : "ALL"}
                size="small"
                onChange={onChangePosition}
                name="position-select"
                sx={{
                  height: "2.3rem",
                  "& .MuiOutlinedInput-input": {
                    fontSize: "1.2rem",
                  },
                }}
                MenuProps={{
                  style: {
                    maxHeight: 250,
                  },
                }}
              >
                {comType === API.ComType.TEAM_COACH ? (
                  <MenuItem sx={{ fontSize: "1.1rem" }} value={"ALL"}>
                    {"전체"}
                  </MenuItem>
                ) : null}
                {comType === API.ComType.TEAM_COACH
                  ? positionEnum.coachPositions.map((item) => (
                      <MenuItem value={item.name}>{item.description}</MenuItem>
                    ))
                  : playerPositionEnum.map((item) => (
                      <MenuItem
                        sx={{ color: item.color, fontSize: "1.1rem" }}
                        value={item.name}
                      >
                        {item.description}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <LabelBox>시작일</LabelBox>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
              <MobileDatePicker
                value={clickedMember ? dayjs(clickedMember.joinAt) : null}
                size="small"
                slotProps={{ textField: { size: "small" } }}
                views={["year", "month", "day"]}
                format={"YYYY-MM-DD"}
                sx={{
                  m: 0.5,
                  width: { xs: "95%" }, //sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
                  "& .MuiOutlinedInput-input": {
                    border: 0,
                    borderRadius: 3,
                    fontSize: "1.2rem",
                    height: "1.2rem",
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <Button
              disabled={!clickedMember}
              onClick={onAddNewMember}
              variant="contained"
              sx={{
                backgroundColor: clickedMember ? "info.main" : "success.main",
                ":hover": {
                  backgroundColor: clickedMember ? "info.main" : "success.main",
                  color: "yellow",
                },
              }}
            >
              등록
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
