import {
  faEdit,
  faRemove,
  faSave,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, TextField } from "@mui/material";
import axios from "axios";
import { useRef, useState } from "react";
import { API } from "../../../../config";

export default function AgencyStaffItem({ staff, setAgencyStaffs }) {
  const inputRef = useRef([]);
  const [isEdit, setIsEdit] = useState(false);
  async function onEditComplete() {
    const position = inputRef.current[0].value.trim();
    const name = inputRef.current[1].value.trim();
    if (position.length === 0) {
      alert("직함을 입력하세요");
    }
    if (name.length === 0) {
      alert("이름을 입력하세요");
    }
    try {
      const requestData = {
        position: position,
        name: name,
      };
      const { data } = await axios.put(
        `${API.MP_AGENCY_STAFF}/${staff.id}`,
        requestData
      );
      console.log(data);
      setAgencyStaffs((prev) => data.data);
      alert("정보를 수정했습니다");
      setIsEdit((prev) => false);
    } catch (error) {}
  }
  async function onDelete() {
    if (window.confirm("해당 직원을 삭제하시겠습니까?")) {
      try {
        const { data } = await axios.delete(
          `${API.MP_AGENCY_STAFF}/${staff.id}`
        );
        setAgencyStaffs((prev) => data.data);
        alert("직원 정보를 삭제했습니다");
      } catch (error) {}
    }
  }
  return (
    <Grid sx={{ display: "flex", alignItems: "center" }}>
      <TextField
        id="email_auth_code"
        defaultValue={staff.position}
        inputRef={(el) => (inputRef.current[0] = el)}
        size="small"
        disabled={!isEdit}
        placeholder="직함"
        sx={{ m: "0 0 0.5rem", width: "100%", display: "inline-block" }}
        variant="outlined"
        inputProps={{
          style: { fontSize: "1.2rem", height: "1.2rem" },
        }}
      />
      <TextField
        id="email_auth_code"
        defaultValue={staff.name}
        inputRef={(el) => (inputRef.current[1] = el)}
        size="small"
        disabled={!isEdit}
        placeholder="직원명"
        sx={{ m: "0 0 0.5rem", width: "100%", display: "inline-block" }}
        variant="outlined"
        inputProps={{
          style: { fontSize: "1.2rem", height: "1.2rem" },
        }}
      />
      <Box>
        <FontAwesomeIcon
          icon={faEdit}
          style={{
            padding: "0.5rem",
            border: "1px solid #eee",
            color: "#555",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsEdit((prev) => !prev);
          }}
        ></FontAwesomeIcon>
      </Box>
      {isEdit ? (
        <Box>
          <FontAwesomeIcon
            icon={faSquareCheck}
            style={{
              padding: "0.5rem",
              border: "1px solid #eee",
              cursor: "pointer",
              color: "rgb(64,132,75)",
            }}
            onClick={onEditComplete}
          ></FontAwesomeIcon>
        </Box>
      ) : null}
      <Box>
        <FontAwesomeIcon
          icon={faRemove}
          style={{
            padding: "0.5rem",
            border: "1px solid #eee",
            color: "#555",
            cursor: "pointer",
          }}
          onClick={onDelete}
        ></FontAwesomeIcon>
      </Box>
    </Grid>
  );
}
