import { Button } from "@mui/material";
import YearTitleFileItem from "../../../common/YearTitleFileItem";
import YearTitleListItem from "../../../common/YearTitleListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function MPStaffProfileCert({
  comType,
  roleType,
  sectionTitle,
  rows,
  setRows,
}) {
  const [seq, setSeq] = useState(rows.length);
  function addBtnClick() {
    const data = {
      id: null,
      date: String(new Date().getFullYear()).slice(0, 4),
      name: null,
      seq: seq,
      fromServer: false,
      isEditState: true,
    };
    setRows((prev) => [...prev, data]);
    setSeq((prev) => prev + 1);
  }
  return (
    <div>
      <div className="mypage-body-item-title-wrapper">
        <div>
          <span className="section-title">{sectionTitle}</span>
          <Button
            id="player-career-add-btn"
            variant="text"
            sx={{ fontSize: "1rem" }}
            onClick={addBtnClick}
          >
            <FontAwesomeIcon
              className="add-btn-icon"
              color="#1976D2"
              icon={faPlus}
            />
            추가하기
          </Button>
          {rows.map((item, index) => (
            <YearTitleFileItem
              roleType={roleType}
              comType={comType}
              row={item}
              rows={rows}
              setRows={setRows}
            ></YearTitleFileItem>
          ))}
        </div>
      </div>
    </div>
  );
}
