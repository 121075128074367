import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { API } from "../../../../../config";
import axios from "axios";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { detailPositionSelector } from "../../../../../recoil/atoms/detailPositionSelector";
import { enumAtom } from "../../../../../recoil/atoms/applicationEnum";
import styled from "styled-components";
import {
  LabelBox,
  StyledButton,
  StyledLabel,
  TitleBox,
} from "../../../common/MyPageComponents";
import heic2any from "heic2any";
import { GridLoader } from "react-spinners";
import Loading from "../../../common/Loading";
const indexArr = [0, 1, 2];
const mainFoot = [
  {
    name: "RIGHT",
    description: "오른발",
  },
  {
    name: "LEFT",
    description: "왼발",
  },
  {
    name: "BOTH",
    description: "양발",
  },
];

const ItemBox = styled("Box")``;
export default function MPPlayerProfileBasic({
  comType,
  basicInfo,
  setBasicInfo,
}) {
  const positionDetailEnum = useRecoilValue(detailPositionSelector);
  const appEnum = useRecoilValue(enumAtom);
  const img_ref = useRef(null);
  const [loading, setLoading] = useState(false);
  function makeBasicInfoRequestData(profileImageId) {
    console.log("leagueName", basicInfo.leagueName);
    const leagueId = basicInfo.leagueName
      ? appEnum.leagues.find((league) => league.name === basicInfo.leagueName)
          .id
      : null;
    const requestData = {
      ...basicInfo,
      mainFoot: basicInfo.mainFoot === "NotDefine" ? null : basicInfo.mainFoot,
      mainPosition:
        basicInfo.mainPosition === "NotDefine" ? null : basicInfo.mainPosition,
      secondPosition:
        basicInfo.secondPosition === "NotDefine"
          ? null
          : basicInfo.secondPosition,
      leagueId: leagueId,
      email: basicInfo.email || "test@canda23.com",
      profileImageId: profileImageId
        ? profileImageId
        : basicInfo.profileImage
          ? basicInfo.profileImage.id
          : null,
    };
    delete requestData["leagueName"];
    delete requestData["profileImage"];
    delete requestData["isEditState"];

    requestData.strengths = requestData.strengths.filter(
      (item) => item.trim().length !== 0 && item.trim() !== "NotDefine"
    );
    return requestData;
  }
  async function onClickBasicInfoEdit() {
    if (basicInfo.isEditState) {
      const requestData = makeBasicInfoRequestData();
      console.log("request update data", requestData);
      const {
        data: { data },
      } = await axios.put(`${API.MP_PLAYER_INFO}`, requestData);
      const newInfo = { ...data, isEditState: false };
      console.log("changed info", newInfo);
      newInfo["strengths"] =
        data.strengths.length < 3
          ? [
              ...data.strengths,
              ...Array(3 - data.strengths.length).fill("NotDefine"),
            ]
          : data.strengths;

      alert("정보를 성공적으로 등록하였습니다.");
      console.log("newInfo", newInfo);
      setBasicInfo((prev) => newInfo);
    } else {
      setBasicInfo((prev) => ({ ...prev, isEditState: true }));
    }
  }
  function onChangeSelect(event) {
    console.log("positionDetailEnum", positionDetailEnum);
    console.log("basicInfo.mainPosition", basicInfo.mainPosition);
    const currentTargetName = event.target.name;
    const currentValue = event.target.value;
    if (currentTargetName.includes("mainFoot")) {
      setBasicInfo((prev) => ({
        ...prev,
        mainFoot: event.target.value,
      }));
    } else if (currentTargetName.includes("preferPosition")) {
      if (currentTargetName.includes("main")) {
        setBasicInfo((prev) => ({
          ...prev,
          mainPosition: currentValue,
        }));
      } else if (currentTargetName.includes("second")) {
        setBasicInfo((prev) => ({
          ...prev,
          secondPosition: currentValue,
        }));
      }
    } else if (currentTargetName.includes("strength")) {
      if (
        currentValue !== "NotDefine" &&
        basicInfo.strengths.includes(currentValue)
      ) {
        alert("이미 해당 장점 설정이 존재합니다.");
        return;
      }
      const targetIndex = parseInt(currentTargetName.slice(-1));
      setBasicInfo((prev) => ({
        ...prev,
        strengths: prev.strengths.map((item, index) =>
          index === targetIndex ? currentValue : item
        ),
      }));
    } else if (currentTargetName.includes("interesting-league")) {
      console.log("currentValue", currentValue);
      setBasicInfo((prev) => ({ ...prev, leagueName: currentValue }));
    }

    //mainFoot
    //preferPosition
    //strength
    console.log(event.target.name);
  }
  async function changeImgText(event) {
    event.preventDefault();
    const formData = new FormData();

    try {
      let uploadFile = event.target.files[0];

      if (uploadFile.name.split(".")[1] === "heic") {
        setLoading((prev) => true);
        let blob = uploadFile;
        const resultBlob = await heic2any({ blob: blob, toType: "image/jpeg" });
        uploadFile = new File(
          [resultBlob],
          uploadFile.name.split(".")[0] + ".jpg",
          {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          }
        );
        setLoading((prev) => false);
      }
      if (uploadFile.size > API.FILE_UPLOAD_MAX_SIZE) {
        alert(
          `변환된 파일크기가 제한 용량(5MB)를 초과합니다\n 파일크기:${Number(uploadFile.size / 1000000).toFixed(2)}MB`
        );
        return;
      }
      formData.append("file", uploadFile);
      const res = await axios.post(`${API.FILE_BASE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("respose for image upload", res.data.data);
      setBasicInfo((prev) => ({
        ...prev,
        profileImage: res.data.data,
      }));

      const requestData = makeBasicInfoRequestData(res.data.data.id);
      console.log("requestData for image linking", requestData);
      const {
        data: { data },
      } = await axios.put(`${API.MP_PLAYER_INFO}`, requestData);

      console.log("respose for image linking", data);
      const newInfo = { ...data, isEditState: false };
      // newInfo["preferencePositions"] =
      //   data.preferencePositions.length < 3
      //     ? [
      //         ...data.preferencePositions,
      //         ...Array(3 - data.preferencePositions.length).fill("NotDefine"),
      //       ]
      //     : data.preferencePositions;
      // newInfo["strengths"] =
      //   data.strengths.length < 3
      //     ? [
      //         ...data.strengths,
      //         ...Array(3 - data.strengths.length).fill("NotDefine"),
      //       ]
      //     : data.strengths;
      setBasicInfo((prev) => newInfo);
      alert("사진을 등록하였습니다.");
      console.log(3, data);
    } catch (error) {
      console.log(error);
    }
  }
  async function onRemovePicture() {
    if (!basicInfo.profileImage) {
      alert("등록된 사진이 없습니다");
      return;
    }
    const requestData = makeBasicInfoRequestData(null);
    requestData.profileImageId = null; //null을 전달해도 기본값이 채워지므로 명시적으로 ImageId비움
    console.log("makeBasicInfoRequestData", requestData);
    try {
      const {
        data: { data },
      } = await axios.put(`${API.MP_PLAYER_INFO}`, requestData);
      const newInfo = { ...data, isEditState: false };
      // newInfo["preferencePositions"] =
      //   data.preferencePositions.length < 3
      //     ? [
      //         ...data.preferencePositions,
      //         ...Array(3 - data.preferencePositions.length).fill("NotDefine"),
      //       ]
      //     : data.preferencePositions;
      newInfo["strengths"] =
        data.strengths.length < 3
          ? [
              ...data.strengths,
              ...Array(3 - data.strengths.length).fill("NotDefine"),
            ]
          : data.strengths;
      setBasicInfo((prev) => newInfo);
      alert("사진을 삭제했습니다.");
    } catch (error) {}
  }
  function onChangeWH(e) {
    const inputType = e.target.name;
    const inputValue = e.target.value;

    if (inputType === "height") {
      setBasicInfo((prev) => ({ ...prev, height: inputValue }));
      console.log("height");
    } else if (inputType === "weight") {
      setBasicInfo((prev) => ({ ...prev, weight: inputValue }));
      console.log("weight");
    }
  }
  function onChangeStrenth(e) {
    const targetIndex = parseInt(e.target.name);
    const value = e.target.value;
    setBasicInfo((prev) => ({
      ...prev,
      strengths: prev.strengths.map((s, index) =>
        index === targetIndex ? value : s
      ),
    }));
  }
  return (
    <Box>
      <TitleBox>프로필</TitleBox>
      <Grid
        container
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "left", md: "center" }}
      >
        <Grid item xs={12} lg={4} sx={{ textAlign: "center" }}>
          <Box
            sx={{
              width: "12rem",
              margin: "0 auto",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {basicInfo.profileImage ? (
              <img
                src={`${API.BASE}${basicInfo.profileImage.fileUrl}`}
                alt="profile-img"
                style={{ width: "100%" }}
              ></img>
            ) : (
              <FontAwesomeIcon icon={faUser} size="4x" color="#183153" />
            )}
            {/* <Loading loading={loading}></Loading> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <StyledLabel sx={{ display: "inline-block" }}>
                {basicInfo.profileImage ? "수정" : "등록"}
                <input
                  type="file"
                  className="file"
                  ref={img_ref}
                  accept="image/*,image/heic"
                  onChange={changeImgText}
                  style={{ display: "none" }}
                />
              </StyledLabel>
            </Box>
            <Box>
              <StyledButton onClick={onRemovePicture}>삭제</StyledButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box sx={{ display: "flex" }}>
            <LabelBox>이 름</LabelBox> <ItemBox>{basicInfo.name}</ItemBox>
          </Box>
          <Box sx={{ display: "flex" }}>
            <LabelBox>국 적</LabelBox>
            <ItemBox>{basicInfo.nationality || ""}</ItemBox>
          </Box>
          <Box sx={{ display: "flex" }}>
            <LabelBox>생년월일</LabelBox>
            <ItemBox>{basicInfo.birthDate || ""}</ItemBox>
          </Box>
          <Box sx={{ display: "flex" }}>
            <LabelBox>연락처</LabelBox>
            <ItemBox>{basicInfo.phoneNumber || ""}</ItemBox>
          </Box>
          <Box sx={{ display: "flex" }}>
            <LabelBox>이메일</LabelBox>
            <ItemBox>{basicInfo.email || ""}</ItemBox>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ margin: "1rem 0" }}></Divider>
      <Box>
        <Box>
          <TitleBox>기본정보</TitleBox>
          <StyledButton
            value={basicInfo.seq}
            onClick={onClickBasicInfoEdit}
            variant="contained"
            sx={{
              backgroundColor: basicInfo.isEditState
                ? "info.main"
                : "success.main",
              ":hover": {
                backgroundColor: basicInfo.isEditState
                  ? "info.main"
                  : "success.main",
                color: "yellow",
              },
            }}
          >
            {basicInfo.isEditState ? "저장" : "입력"}
          </StyledButton>
        </Box>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LabelBox width={"7rem"}>키</LabelBox>
            <FormControl sx={{ width: "10rem", height: "3rem" }} size="small">
              <TextField
                disabled={!basicInfo.isEditState}
                size="small"
                sx={{ mr: 1 }}
                id="height-input"
                variant="outlined"
                name="height"
                value={basicInfo.height ? basicInfo.height : ""}
                defaultValue={basicInfo.height ? basicInfo.height : ""}
                onChange={onChangeWH}
                inputProps={{ style: { fontSize: "1rem" } }}
              />
            </FormControl>
            <Box sx={{ fontSize: "1rem" }}>cm</Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LabelBox width={"7rem"}>몸무게</LabelBox>
            <FormControl sx={{ width: "10rem" }} size="small">
              <TextField
                disabled={!basicInfo.isEditState}
                size="small"
                sx={{ mr: 1 }}
                id="weight-input"
                variant="outlined"
                name="weight"
                value={basicInfo.weight ? basicInfo.weight : ""}
                defaultValue={basicInfo.weight ? basicInfo.weight : ""}
                onChange={onChangeWH}
                inputProps={{ style: { fontSize: "1rem" } }}
              />
            </FormControl>
            <Box sx={{ fontSize: "1rem" }}>kg</Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LabelBox width={"7rem"}>주발</LabelBox>
            <FormControl sx={{ width: "9.5rem", mr: 1 }} size="small">
              <Select
                disabled={!basicInfo.isEditState}
                defaultValue={
                  basicInfo.mainFoot ? basicInfo.mainFoot : "NotDefine"
                }
                id="player-prefer-position"
                value={basicInfo.mainFoot ? basicInfo.mainFoot : "NotDefine"}
                name="mainFoot"
                onChange={onChangeSelect}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    fontSize: "1rem",
                  },
                }}
                MenuProps={{
                  style: {
                    maxHeight: 250,
                  },
                }}
              >
                <MenuItem sx={{ fontSize: "1rem" }} value={"NotDefine"}>
                  {"선택안함"}
                </MenuItem>
                {mainFoot.map((item) => (
                  <MenuItem
                    sx={{ color: item.color, fontSize: "1rem" }}
                    value={item.name}
                  >
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "left",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LabelBox width={"7rem"}>주포지션</LabelBox>
              <FormControl sx={{ width: "9.5rem", mr: 1 }} size="small">
                <Select
                  disabled={!basicInfo.isEditState}
                  defaultValue={basicInfo.mainPosition || "NotDefine"}
                  id="player-prefer-position-main"
                  value={basicInfo.mainPosition || "NotDefine"}
                  name={"preferPosition-main"}
                  onChange={onChangeSelect}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      fontSize: "1rem",
                    },
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 250,
                    },
                  }}
                >
                  <MenuItem sx={{ fontSize: "1rem" }} value={"NotDefine"}>
                    {"선택안함"}
                  </MenuItem>
                  {positionDetailEnum.map((item) => (
                    <MenuItem
                      sx={{ color: item.color, fontSize: "1rem" }}
                      value={item.name}
                    >
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LabelBox width={"7rem"}>세컨포지션</LabelBox>
              <FormControl sx={{ width: "9.5rem", mr: 1 }} size="small">
                <Select
                  disabled={!basicInfo.isEditState}
                  defaultValue={basicInfo.secondPosition || "NotDefine"}
                  id="player-prefer-position-second"
                  value={basicInfo.secondPosition || "NotDefine"}
                  name={"preferPosition-second"}
                  onChange={onChangeSelect}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      fontSize: "1rem",
                    },
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 250,
                    },
                  }}
                >
                  <MenuItem sx={{ fontSize: "1rem" }} value={"NotDefine"}>
                    {"선택안함"}
                  </MenuItem>
                  {positionDetailEnum.map((item) => (
                    <MenuItem
                      sx={{ color: item.color, fontSize: "1rem" }}
                      value={item.name}
                    >
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LabelBox width={"7rem"}>장점</LabelBox>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              {indexArr.map((item, index) => (
                <FormControl sx={{ width: "10rem" }} size="small">
                  <TextField
                    disabled={!basicInfo.isEditState}
                    size="small"
                    sx={{ mr: 1 }}
                    id="position-input"
                    variant="outlined"
                    name={index}
                    placeholder="ex)킥,패스,체력"
                    value={
                      basicInfo.strengths[index] === "NotDefine"
                        ? ""
                        : basicInfo.strengths[index]
                    }
                    defaultValue={
                      basicInfo.strengths[index] === "NotDefine"
                        ? ""
                        : basicInfo.strengths[index]
                    }
                    onChange={onChangeStrenth}
                    inputProps={{ style: { fontSize: "1rem" } }}
                  />
                </FormControl>
              ))}
            </Box>
          </Box>
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <LabelBox width={"7rem"}>관심리그</LabelBox>
            <FormControl sx={{ width: "9.5rem", mr: 1 }} size="small">
              <Select
                disabled={!basicInfo.isEditState}
                defaultValue={basicInfo.leagueName || "NotDefine"}
                id="player-prefer-league"
                name={"interesting-league"}
                value={basicInfo.leagueName || "NotDefine"}
                onChange={onChangeSelect}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    fontSize: "1rem",
                  },
                }}
                MenuProps={{
                  style: {
                    maxHeight: 250,
                  },
                }}
              >
                <MenuItem value={"NotDefine"} sx={{ fontSize: "1rem" }}>
                  {"선택안함"}
                </MenuItem>
                {appEnum.leagues.map((item) => (
                  <MenuItem
                    sx={{ color: item.color, fontSize: "1rem" }}
                    value={item.name}
                  >
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ fontSize: "1rem", color: "#056186", width: "6rem" }}>
              현재 소속이 없다면
            </Box>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
