import { Box } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

export default function TitleRowBox({ children, bgColor, padding, mode }) {
  return (
    <Box bgcolor={bgColor} padding={padding}>
      <Box
        sx={{
          padding: mode === "dark" ? "0 3.75rem" : "0",
          color: "white",
          width:
            mode === "dark"
              ? {
                  xs: "none",
                  md: "auto",
                  lg: "auto",
                  xl: "90%",
                  xxl: "90%",
                }
              : {
                  xs: "auto",
                  md: "auto",
                  lg: "auto",
                  xl: "1180px",
                  xxl: "1280px",
                },
          margin: "0 auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
