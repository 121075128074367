import { Box, Grid, Typography } from "@mui/material";

import styled from "styled-components";
import { API } from "../../config";
import { GenerateSNSURL } from "../common/ExtractYouTubeURL";
const StyledDiv = styled("div")({
  margin: "0.1em 0",
  alignItems: "center",
});
const StyledDiv2 = styled("div")({
  display: "flex",
  alignItems: "center",
});
const snsType = {
  INSTAGRAM:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png",
  FACEBOOK:
    "https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png",
  YOUTUBE:
    "https://w7.pngwing.com/pngs/936/468/png-transparent-youtube-logo-youtube-logo-computer-icons-subscribe-angle-rectangle-airplane-thumbnail.png",
};
export default function DetailTeamProfile({
  data,
  profileItem,
  comType,
  profileTypeText,
}) {
  return (
    <Box sx={{ borderRight: { xs: "none", md: "1px solid #999" } }}>
      <Grid container alignItems={"end"} justifyContent={"space-evenly"}>
        <Grid>
          <img
            style={{
              height: "20rem",
              background: "#eee",
              display: "inline-block",
              content: " ",
              maxWidth: "15rem",
              objectFit: "cover",
            }}
            src={
              data.logo
                ? `${API.BASE}${data.logo.fileUrl}`
                : "https://cdn-icons-png.flaticon.com/512/6388/6388000.png"
            }
            alt="profile-img"
          ></img>
        </Grid>
        <Grid item>
          <Box marginBottom={1}>
            <StyledDiv
              style={{
                width: "6rem",
                backgroundColor: "rgb(245,21,29)",
                textAlign: "center",
                color: "#fff",
              }}
            >
              {profileTypeText}
            </StyledDiv>
            <StyledDiv
              style={{
                fontSize: "2rem",
                fontWeight: "600",
                letterSpacing: data.name.length > 10 ? "0px" : "0.5rem",
              }}
            >
              {data.name}
            </StyledDiv>
          </Box>

          {profileItem.map((p, index) => (
            <StyledDiv2 key={index}>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "rgb(43,43,43)",
                  borderRadius: "50%",
                  marginRight: "1em",
                }}
              ></span>
              <p style={{ minWidth: "8rem" }}>
                <Typography variant="teamProfileDetail">{p.title}</Typography>
              </p>
              <Typography variant="teamProfileDetail">{p.content}</Typography>
            </StyledDiv2>
          ))}
          <StyledDiv2>
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: "rgb(43,43,43)",
                borderRadius: "50%",
                marginRight: "1em",
              }}
            ></span>
            <p style={{ minWidth: "8rem" }}>
              <Typography variant="teamProfileDetail">SNS</Typography>
            </p>
            <Typography variant="teamProfileDetail">
              {data.snsInformations.map((sns, index) =>
                snsType.hasOwnProperty(sns.type) ? (
                  <a
                    key={index}
                    href={GenerateSNSURL(sns.type, sns.url)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={snsType[sns.type]}
                      alt="sns-icon"
                      style={{
                        width: "2rem",
                        height: "2rem",
                        marginRight: "0.5rem",
                      }}
                    ></img>
                  </a>
                ) : null
              )}
            </Typography>
          </StyledDiv2>
        </Grid>
      </Grid>
    </Box>
  );
}
