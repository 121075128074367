export const coach_kor_title = {
  ASSISTANT_COACH: "코치",
  HEAD_COACH: "수석코치",
  MANAGER: "감독",
  GK_COACH: "골키퍼 코치",
  PHYSICAL_COACH: "피지컬 코치",
  ANALYST: "분석관",
};
export const leagueGrade = {
  U12_FC: [4, 5, 6],
  U15_FC: [1, 2, 3],
  U18_FC: [1, 2, 3],
  UNIVERSITY: [1, 2, 3, 4],
};
