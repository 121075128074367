import { Box } from "@mui/material";
import YearTitleListItem from "./YearTitleListItem";

export default function YearTitleList({
  comType,
  roleType,
  rows,
  setRows,
  setSnackbarOpen,
  setLastCRUD,
}) {
  return (
    <Box>
      {rows.map((item) => (
        <YearTitleListItem
          comType={comType}
          roleType={roleType}
          row={item}
          rows={rows}
          setRows={setRows}
          setSnackbarOpen={setSnackbarOpen}
          setLastCRUD={setLastCRUD}
        ></YearTitleListItem>
      ))}
    </Box>
  );
}
