import MPStaffManageStaffList from "./MPStaffManageStaff";
import MPStaffManageTeamMultimedia from "./MPStaffManageTeamSNS";
import MPStaffManageTeamProfileBasic from "./MPStaffManageTeamProfileBasic";
import TuitionFee from "./TuitionFee";
import MPStaffManageTeamSNS from "./MPStaffManageTeamSNS";
import { Box, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MPStaffManageTeamVideos from "./MPStaffManageTeamVideos";
import { useEffect, useState } from "react";
import { API } from "../../../../../config";
import MPImageList from "../../../../common/MPImageList";
import styled from "styled-components";
import { TitleBox } from "../../../common/MyPageComponents";
const ItemBox = styled(Box)`
  background-color: #fff;
  border: 1px solid rgb(64, 132, 75);
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 0.5rem/0.5rem;
`;

const basicInfo = [
  { keyType: "name", text: "팀명" },
  { keyType: "league", text: "리그" },
  { keyType: "name", text: "지역" },
  { keyType: "openingDate", text: "창단" },
  { keyType: "ceo", text: "대표이사" },
  { keyType: "leader", text: "단장" },
  { keyType: "phoneNumber", text: "전화" },
  { keyType: "email", text: "이메일" },
];
const basicInfoUpdatable = [];
export default function MPStaffManageTeamProfile({
  currentTeam,
  teamRequiredInfo,
  setTeamRequiredInfo,
  teamCommonInfo,
  setTeamCommonInfo,
  fee,
  setFee,
  SNS,
  setSNS,
  images,
  setImages,
  videos,
  setVideos,
  videoType,
}) {
  const [SNSSeq, setSNSSeq] = useState();
  const [imageSeq, setImageSeq] = useState();
  const [videoSeq, setVideoSeq] = useState();
  useEffect(() => {
    setSNSSeq((prev) => SNS.length);
    setImageSeq((prev) => images.length);
    setVideoSeq((prev) => videos.length);
  }, []);
  function onAddSNS() {
    setSNS((prev) => [
      ...prev,
      {
        teamId: currentTeam.id,
        fromServer: false,
        id: null,
        type: "INSTAGRAM",
        url: null,
        isEditState: true,
        seq: SNSSeq,
      },
    ]);
    setSNSSeq((prev) => prev + 1);
  }
  function onAddImage() {
    if (images.length > 6) {
      alert("최대 등록 개수를 초과했습니다.");
      return;
    }
    setImages((prev) => [
      ...prev,
      {
        teamId: currentTeam.id,
        isFromServer: false,
        id: null,
        title: null,
        imageFile: null,
        isEditState: true,
        seq: imageSeq,
      },
    ]);
    setImageSeq((prev) => prev + 1);
  }
  function onAddVideo() {
    if (videos.length > 4) {
      alert("최대 등록 개수를 초과했습니다.");
      return;
    }
    setVideos((prev) => [
      ...prev,
      {
        id: null, //video의 id
        title: null,
        url: null,
        hit: 0,
        teamId: currentTeam.id,
        fromServer: false,
        isEditState: true,
        seq: videoSeq,
      },
    ]);
    setVideoSeq((prev) => prev + 1);
  }
  return (
    <Box>
      <ItemBox>
        <TitleBox>팀프로필</TitleBox>
        <MPStaffManageTeamProfileBasic
          teamRequiredInfo={teamRequiredInfo}
          setTeamRequiredInfo={setTeamRequiredInfo}
          teamCommonInfo={teamCommonInfo}
          setTeamCommonInfo={setTeamCommonInfo}
        ></MPStaffManageTeamProfileBasic>
      </ItemBox>
      <ItemBox>
        <TitleBox>교육비용</TitleBox>
        <TuitionFee fee={fee} setFee={setFee}></TuitionFee>
      </ItemBox>
      <ItemBox className="mypage-body-item-wrapper">
        <TitleBox>
          SNS{" "}
          <Button
            id="player-career-add-btn"
            variant="text"
            sx={{ fontSize: "1rem" }}
            onClick={onAddSNS}
          >
            <FontAwesomeIcon
              className="add-btn-icon"
              color="#1976D2"
              icon={faPlus}
            />
            추가하기
          </Button>
        </TitleBox>

        <MPStaffManageTeamSNS
          roleType={API.RolyType.TEAM}
          comType={API.ComType.TEAM_SNS}
          SNS={SNS}
          setSNS={setSNS}
        ></MPStaffManageTeamSNS>
      </ItemBox>
      <ItemBox>
        <TitleBox>
          사진
          <Button
            id="player-career-add-btn"
            variant="text"
            sx={{ fontSize: "1rem" }}
            onClick={onAddImage}
          >
            <FontAwesomeIcon
              className="add-btn-icon"
              color="#1976D2"
              icon={faPlus}
            />
            추가하기
          </Button>
        </TitleBox>

        <MPImageList images={images} setImages={setImages}></MPImageList>
      </ItemBox>
      <ItemBox className="mypage-body-item-wrapper">
        <TitleBox>
          영상
          <Button
            id="player-career-add-btn"
            variant="text"
            sx={{ fontSize: "1rem" }}
            onClick={onAddVideo}
          >
            <FontAwesomeIcon
              className="add-btn-icon"
              color="#1976D2"
              icon={faPlus}
            />
            추가하기
          </Button>
        </TitleBox>

        <MPStaffManageTeamVideos
          comType={API.ComType.TEAM_VIDEO}
          roleType={API.RolyType.COACH}
          videos={videos}
          setVideos={setVideos}
          videoType={videoType}
        ></MPStaffManageTeamVideos>
      </ItemBox>
    </Box>
  );
}
