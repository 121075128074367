import { Box } from "@mui/material";
import styled from "styled-components";
import MPScouterProfile from "./MPScouterProfile";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../../config";

const ItemBox = styled(Box)`
  background-color: #fff;
  border: 1px solid rgb(64, 132, 75);
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 0.5rem/0.5rem;
`;

export default function MPScouterMain() {
  const [scouter, setScouter] = useState();
  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`${API.MP_SCOUTER}`);
      console.log("MPScouterMain", data);
      if (data.code === 200000) {
        setScouter(data.data);
      }
      try {
      } catch (error) {}
    }
    fetchData();
  }, []);
  return (
    <Box
      sx={{
        fontSize: "1.3rem",
        width: { sm: "auto", xl: "1200px" },
        margin: { sm: "0", xl: "0 auto" },
      }}
    >
      {scouter ? (
        <ItemBox>
          <MPScouterProfile
            basicInfo={scouter}
            setBasicInfo={setScouter}
          ></MPScouterProfile>
        </ItemBox>
      ) : null}
    </Box>
  );
}
