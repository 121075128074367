import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import { StyledLabel } from "../../common/MyPageComponents";
import styled from "styled-components";
import { useRef, useState } from "react";
import { API } from "../../../../config";
import axios from "axios";
import heic2any from "heic2any";
import Loading from "../../common/Loading";

const LabelP = styled("p")`
  display: inline-block;
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
  width: 6rem;
`;
function initPlayer() {
  return {
    playerId: null,
    name: null,
    kitNumber: null,
    clubName: null,
    profileImage: null,
  };
}
export default function MPAgencyPlayerAddManually({ setPlayers }) {
  const [isAddState, setIsAddState] = useState(false);
  const playerInputRef = useRef([]);
  const [newPlayer, setNewPlayer] = useState(initPlayer());
  const img_ref = useRef();
  const [loading, setLoading] = useState(false);

  async function changeImgText(event) {
    event.preventDefault();
    console.log("changed");
    const formData = new FormData();

    try {
      let uploadFile = event.target.files[0];
      if (uploadFile.name.split(".")[1] === "heic") {
        setLoading((prev) => true);
        let blob = uploadFile;
        const resultBlob = await heic2any({ blob: blob, toType: "image/jpeg" });
        uploadFile = new File(
          [resultBlob],
          uploadFile.name.split(".")[0] + ".jpg",
          {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          }
        );
        setLoading((prev) => false);
      }
      if (uploadFile.size > API.FILE_UPLOAD_MAX_SIZE) {
        alert(
          `변환된 파일크기가 제한 용량(5MB)를 초과합니다\n 파일크기:${Number(uploadFile.size / 1000000).toFixed(2)}MB`
        );
        return;
      }
      formData.append("file", uploadFile);
      const res = await axios.post(`${API.FILE_BASE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res);
      setNewPlayer((prev) => ({ ...prev, profileImage: res.data.data }));
    } catch (error) {
      console.log(error);
    }
  }
  async function onRemovePicture() {
    setNewPlayer((prev) => ({ ...prev, profileImage: null }));
  }
  async function onAddNewPlayer() {
    const name = playerInputRef.current[0].value.trim();
    const clubName = playerInputRef.current[1].value.trim();
    const kitNumber = playerInputRef.current[2].value.trim();

    if (!name) {
      alert("이름을 입력하세요");
      return;
    }
    const requestData = {
      playerId: null,
      name: name,
      kitNumber: parseInt(kitNumber),
      profileImageId: newPlayer.profileImage ? newPlayer.profileImage.id : null,
      clubName: clubName,
    };
    console.log("requestData", requestData);
    try {
      const {
        data: { data },
      } = await axios.post(API.MP_AGENCY_PLAYER, requestData);
      console.log(data);
      setPlayers((prev) => data); //소속 선수 전체 리스트 갱신
      setIsAddState((prev) => false);
      setNewPlayer((prev) => initPlayer());
      playerInputRef.current[0].value = "";
      playerInputRef.current[1].value = "";
      playerInputRef.current[2].value = "";
      alert("선수를 등록하였습니다");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Box>
      <Box>
        <button
          style={{
            fontSize: "1.2rem",
            backgroundColor: isAddState ? "#eee" : "rgb(64,132,75)",
            padding: "0.3rem 1rem",
            border: "none",
            color: isAddState ? "rgb(64,132,75)" : "#eee",
            margin: "0 0.2rem 0 0",
            cursor: "pointer",
          }}
          variant="outlined"
          onClick={() => setIsAddState((prev) => true)}
        >
          작성
        </button>
        <button
          style={{
            fontSize: "1.2rem",
            backgroundColor: isAddState ? "rgb(64,132,75)" : "#eee",
            padding: "0.3rem 1rem",
            border: "none",
            color: isAddState ? "#eee" : "#aaa",
            margin: "0 0.2rem 0 0",
            cursor: "pointer",
          }}
          onClick={onAddNewPlayer}
        >
          등록
        </button>
      </Box>
      <Divider sx={{ margin: "0.5rem 0" }}></Divider>
      <Grid container sx={{ alignItems: "center" }}>
        <Grid
          item
          xs={4}
          sm={4}
          sx={{ padding: "0.5rem", position: "relative" }}
        >
          {newPlayer ? (
            <Box sx={{ width: "100%", overflow: "hidden" }}>
              <img
                alt="profile-img"
                src={
                  newPlayer.profileImage
                    ? `${API.BASE}/${newPlayer.profileImage.fileUrl}`
                    : "https://cdn-icons-png.flaticon.com/512/6388/6388000.png"
                }
                style={{ width: "100%", objectFit: "contain" }}
              ></img>
            </Box>
          ) : (
            <Box sx={{ width: "100%", overflow: "hidden" }}>
              <img
                alt="profile-img"
                src={"https://cdn-icons-png.flaticon.com/512/6388/6388000.png"}
                style={{ width: "100%", objectFit: "contain" }}
              ></img>
            </Box>
          )}
          <Loading loading={loading}></Loading>
          <Box>
            <StyledLabel
              style={{
                display: "inline-block",
                width: "50%",
                margin: "0",
                backgroundColor: "#fff",
                color: isAddState ? "green" : "#777",
                border: isAddState ? "1px solid green" : "1px solid #777",
              }}
            >
              {newPlayer.profileImage ? "수정" : "등록"}
              <input
                type="file"
                className="file"
                ref={img_ref}
                accept="image/*,image/heic"
                onChange={changeImgText}
                disabled={!isAddState}
                style={{
                  display: "none",
                  margin: "0",
                  backgroundColor: "#fff",
                }}
              />
            </StyledLabel>
            <StyledLabel
              style={{
                display: "inline-block",
                width: "50%",
                margin: "0",
                backgroundColor: "#fff",
                color: isAddState ? "green" : "#777",
                border: isAddState ? "1px solid green" : "1px solid #777",
              }}
              onClick={onRemovePicture}
            >
              삭제
            </StyledLabel>
          </Box>
        </Grid>
        <Grid item xs={8} sm={8}>
          <Box>
            <LabelP>이름</LabelP>
            <TextField
              id="new-player-name-input"
              inputRef={(el) => (playerInputRef.current[0] = el)}
              size="small"
              variant="outlined"
              disabled={!isAddState}
              sx={{ m: "0.5rem", width: { xs: "95%" } }}
              inputProps={{
                style: { fontSize: "1.2rem", height: "1rem" },
              }}
            />
          </Box>
          <Box>
            <LabelP>소속</LabelP>
            <TextField
              id="new-player-team-input"
              inputRef={(el) => (playerInputRef.current[1] = el)}
              size="small"
              variant="outlined"
              disabled={!isAddState}
              sx={{ m: "0.5rem", width: { xs: "95%" } }}
              inputProps={{
                style: { fontSize: "1.2rem", height: "1rem" },
              }}
            />
          </Box>
          <Box>
            <LabelP>등넘버</LabelP>
            <TextField
              id="new-player-kitNumber-input"
              inputRef={(el) => (playerInputRef.current[2] = el)}
              size="small"
              variant="outlined"
              disabled={!isAddState}
              sx={{ m: "0.5rem", width: { xs: "95%" } }}
              inputProps={{
                style: { fontSize: "1.2rem", height: "1rem" },
              }}
            />
          </Box>
        </Grid>
        <Grid></Grid>
      </Grid>
    </Box>
  );
}
