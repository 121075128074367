import { useEffect } from "react";
import MainLayout from "../../LayoutContainers/MainLayout";
import RowBox from "../../LayoutContainers/RowBox";
import PlayerList from "../../components/person/PlayerList";
import axios from "axios";
import { API } from "../../config";
import { useRecoilState } from "recoil";
import { enumAtom } from "../../recoil/atoms/applicationEnum";

export default function PlayerListLayout() {
  const [enumData, setEnumData] = useRecoilState(enumAtom);
  useEffect(() => {
    async function fetchEnum() {
      try {
        const {
          data: { data },
        } = await axios.get(API.APPLICATION_ENUM);

        setEnumData(data);
      } catch (error) {}
    }
    fetchEnum();
  }, []);
  return (
    <MainLayout mode="light">
      <RowBox
        mode="light"
        bgColor="none"
        padding={"0 0 5rem 0"}
        isBorder={false}
      >
        {Object.keys(enumData).length !== 0 ? <PlayerList></PlayerList> : null}
      </RowBox>
    </MainLayout>
  );
}
