import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import "../../style/modal.css";
import ReactPlayer from "react-player";
import { width } from "@fortawesome/free-solid-svg-icons/faUniversalAccess";
export default function VideoModal({ setModalOpen, videoURL }) {
  const modalBackground = useRef();

  return (
    <Box
      className={"modal-container"}
      ref={modalBackground}
      onClick={(e) => {
        if (e.target === modalBackground.current) {
          setModalOpen(false);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          setModalOpen(false);
        }
      }}
    >
      <Box
        className={"modal-content"}
        sx={{
          width: {
            xs: "90%",
            sm: "90%",
            md: "70%",
            lg: "70%",
            xl: "50%",
            xxl: "50%",
          },
        }}
      >
        <Box
          sx={{
            textAlign: "end",
            backgroundColor: "#000",
            color: "#fff",
          }}
        >
          <FontAwesomeIcon
            icon={faClose}
            style={{ fontSize: "1.5rem" }}
            onClick={() => {
              setModalOpen(false);
            }}
          ></FontAwesomeIcon>
        </Box>
        <Box>
          <ReactPlayer
            width="100%"
            muted={true}
            controls={true}
            url={videoURL}
          />
        </Box>
      </Box>
    </Box>
  );
}
