import { useTheme } from "@emotion/react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import CoachCard from "./join/CoachCard";
import "swiper/css";
import "swiper/css/navigation";
import "../../style/myswiper.css";
export default function MainCoachList({ recentCoach, title }) {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Link to={"/coaches"}>
          <Typography
            variant="mainRowTitle"
            sx={{ fontSize: { xs: "1.3rem", md: "1.5rem" } }}
          >
            {title}
          </Typography>
          <Typography
            variant="mainRowTitle"
            sx={{
              borderRadius: "10%",
              display: "inline-block",
              transition: "0.5s",
              "&:hover": {
                transform: "translateX(10px)",
              },
            }}
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              color="rgb(64,132,75)"
              width="2rem"
            />
          </Typography>
        </Link>
      </Box>
      <Box width={"100%"} sx={{ position: "relative" }}>
        <i className="swiper-button-next-i review-swiper-button-next">&gt;</i>
        <i className="swiper-button-prev-i review-swiper-button-prev">&lt;</i>
        <Swiper
          spaceBetween={10}
          breakpoints={{
            480: {
              slidesPerView: "auto",
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 3,
            },
            1920: {
              slidesPerView: 3,
            },
          }}
          navigation={{
            nextEl: ".review-swiper-button-next",
            prevEl: ".review-swiper-button-prev",
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {recentCoach.map((rc, index) =>
            rc.profileImage ? (
              <SwiperSlide key={index}>
                <Link to={`/coach-detail/${rc.id}`}>
                  <CoachCard profile={rc}></CoachCard>
                </Link>
              </SwiperSlide>
            ) : null
          )}
        </Swiper>
      </Box>
    </Box>
  );
}
