import { Box } from "@mui/material";
import MultiLineText from "../../../common/MultiLineText";
import { SubTitleBox } from "../../../common/MyPageComponents";
export default function MPStaffProfilePhilosophy({
  comType,
  roleType,
  subTitle,
  philosophy,
  setPhilosophy,
}) {
  function onPhilosophyBtnClick() {
    setPhilosophy((prev) => ({ ...prev, isEditState: true }));
  }
  function onPhilosophyChange(e) {
    const newText = e.target.value;
    setPhilosophy((prev) => ({ ...prev, philosophy: e.target.value }));
  }
  return (
    <Box>
      <Box>
        <SubTitleBox>{subTitle}</SubTitleBox>
        <MultiLineText
          text={philosophy}
          setText={setPhilosophy}
        ></MultiLineText>
      </Box>
    </Box>
  );
}
