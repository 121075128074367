import { useEffect, useRef, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import {
  faCircleInfo,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "../../../../../config";
import { useRecoilValue } from "recoil";
import { enumAtom } from "../../../../../recoil/atoms/applicationEnum";
import { TitleBox } from "../../../common/MyPageComponents";

const leagueName = ["ELEMENTRY", "MIDDLE"];
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#F3F6F9",
    cursor: "pointer",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const ItemBox = styled(Box)`
  background-color: #fff;
  border: 1px solid rgb(64, 132, 75);
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 0.5rem/0.5rem;
`;
const Label = styled(Box)`
  font-size: 1.1rem;
  color: #001c48;
`;
const statusKorText = {
  PENDING: "검토",
  APPROVED: "승인",
  REJECTED: "거절",
};
export default function MPStaffCreateTeam() {
  const inputRef = useRef([]);
  const [searchTeamList, setsearchTeamList] = useState([]);
  const [mandataryWrite, setMandataryWrite] = useState(false);
  const [lastSelectedTeamId, setLastSelectedTeamId] = useState(false);
  const [teamActivationReuqstList, setTeamActivationReuqstList] = useState();
  const appEnum = useRecoilValue(enumAtom);
  useEffect(() => {
    const fetchContact = async () => {
      try {
        const res = await axios.get(API.MP_CONTACT);
        console.log(res);
        inputRef.current[4].value = res.data.data.phoneNumber;
        inputRef.current[5].value = res.data.data.email;
      } catch (error) {
        console.log(error);
      }
    };
    const fetchTeamActivationRequestHistory = async () => {
      try {
        const res = await axios.get(API.MP_COACH_TEAM_ACTIVATE_HISTORY);
        console.log(res);
        setTeamActivationReuqstList(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchContact();
    fetchTeamActivationRequestHistory();
  }, []);
  async function onschBtnClick(event) {
    const searchKeyword = inputRef.current[0].value;
    const data = {
      query: searchKeyword,
      isActive: null,
    };
    try {
      const res = await axios.post(
        `${API.SEARCH_TEAM_BY_NAME}?page=0&size=20`,
        data
      );
      const findTeam = res.data.data.content;
      if (findTeam.length === 0) {
        alert("조회한 팀이 없습니다. 다시 검색하거나 직접 입력해보세요.");
      }
      setsearchTeamList((prev) => findTeam);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }
  function onListClick(event) {
    setMandataryWrite((prev) => false);
    const teamId = Number(event.currentTarget.getAttribute("value"));
    setLastSelectedTeamId((prev) => teamId);
    const findObj = searchTeamList.find((item) => item.id === teamId);
    console.log("findObj", findObj);
    inputRef.current[1].value = findObj.league.name;
    inputRef.current[2].value = findObj.name;
    inputRef.current[3].value = appEnum.regions.find(
      (r) => r.name === findObj.region
    )
      ? appEnum.regions.find((r) => r.name === findObj.region).description
      : "-";
  }
  function onMandataryWrite() {
    setMandataryWrite((prev) => !prev);
  }
  async function requstBtnClick() {
    if (!lastSelectedTeamId) {
      alert(
        "팀을 먼저 선택해주세요. \n팀 조회가 되지 않는다면 직접 입력해주세요"
      );
      return;
    }
    if (
      teamActivationReuqstList.filter((tar) => tar.status === "PENDING")
        .length > 0
    ) {
      alert("이미 신청한 팀이 검토중입니다.");
      return;
    }
    const data = {
      email: inputRef.current[5].value,
      phoneNumber: inputRef.current[4].value,
    };
    try {
      const res = await axios.post(
        `${API.MP_COACH_TEAM_ACTIVATE}/${lastSelectedTeamId}/activate`,
        data
      );
      console.log(res);
      setTeamActivationReuqstList((prev) => res.data.data);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Box sx={{ padding: "0.5rem" }}>
      <ItemBox>
        <TitleBox>팀정보 관리 권한 신청</TitleBox>
        <Box>
          <div className="input-box">
            <Box>
              <FontAwesomeIcon icon={faCircleInfo} color="#1e8ffa" />
              <span style={{ fontSize: "1rem" }}>
                팀 사진 등록, 지도자 및 선수 관리 등 팀관리를 위해선 먼저 권한을
                신청해주세요
              </span>
            </Box>
            <p
              style={{
                fontSize: "1.1rem",
                color: "rgb(64,132,75)",
                fontWeight: "600",
              }}
            >
              등록된 팀을 조회해보세요
            </p>
            <div className="search-input">
              <TextField
                inputRef={(el) => (inputRef.current[0] = el)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") onschBtnClick();
                }}
                size="small"
                variant="outlined"
                placeholder="팀이름"
                inputProps={{ style: { fontSize: "1.2rem" } }}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="search-icon"
              />
            </div>
          </div>
          <Box>
            <TableContainer
              component={Paper}
              sx={{ margin: "1rem 0", maxHeight: 250, width: "100%" }}
            >
              <Table sx={{ width: "90%", margin: "1rem" }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#eee" }}>
                    <TableCell
                      sx={{ textAlign: "center", padding: "0.5rem 0" }}
                    >
                      팀명
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", padding: "0.5rem 0" }}
                    >
                      주소
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchTeamList
                    ? searchTeamList.map((row) => (
                        <StyledTableRow
                          onClick={onListClick}
                          value={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {/* <TableCell scope="row">
                            {appEnum.leagues.find(
                              (r) => r.name === row.league.name
                            )
                              ? appEnum.leagues.find(
                                  (r) => r.name === row.league.name
                                ).nameDescription
                              : "-"}
                          </TableCell> */}
                          <TableCell
                            sx={{ textAlign: "center", padding: "0.5rem 0" }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", padding: "0" }}>
                            {appEnum.regions.find((r) => r.name === row.region)
                              ? appEnum.regions.find(
                                  (r) => r.name === row.region
                                ).description
                              : "-"}
                          </TableCell>
                        </StyledTableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{ width: "49%", border: "1px solid #eee", padding: "0.5rem" }}
            >
              <Box
                sx={{
                  fontSize: "1.2rem",
                  color: "001c48",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                팀정보
              </Box>
              <Box>
                <Label>리그</Label>
                <TextField
                  type="text"
                  disabled={!mandataryWrite}
                  inputRef={(el) => (inputRef.current[1] = el)}
                  size="small"
                  variant="outlined"
                  placeholder="리그"
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
              <Box>
                <Label>팀명</Label>
                <TextField
                  type="text"
                  disabled={!mandataryWrite}
                  inputRef={(el) => (inputRef.current[2] = el)}
                  size="small"
                  variant="outlined"
                  placeholder="팀명"
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
              <Box>
                <Label>지역</Label>
                <TextField
                  type="text"
                  disabled={!mandataryWrite}
                  inputRef={(el) => (inputRef.current[3] = el)}
                  size="small"
                  variant="outlined"
                  placeholder="지역"
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
            </Box>
            <Box
              sx={{ width: "49%", border: "1px solid #eee", padding: "0.5rem" }}
            >
              <Box
                sx={{
                  fontSize: "1.2rem",
                  color: "001c48",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                신청자 정보
              </Box>
              <Box sx={{ marginBottom: "0.5rem", fontSize: "1rem" }}>
                <FontAwesomeIcon icon={faCircleInfo} color="#1e8ffa" />
                직접 작성 시, 신청자 확인을 위한 연락처가 필요합니다
              </Box>

              <Box>
                <Label>연락처</Label>
                <TextField
                  type="text"
                  inputRef={(el) => (inputRef.current[4] = el)}
                  size="small"
                  variant="outlined"
                  placeholder="-없이 숫자만 입력"
                  disabled={!mandataryWrite}
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
              <Box>
                <Label>이메일</Label>
                <TextField
                  type="text"
                  inputRef={(el) => (inputRef.current[5] = el)}
                  size="small"
                  variant="outlined"
                  placeholder="이메일"
                  disabled={!mandataryWrite}
                  sx={{ m: 0.5, width: { xs: "95%" } }}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box>
            <Button
              variant="contained"
              sx={{ fontSize: "1.1rem" }}
              onClick={requstBtnClick}
            >
              팀관리 권한신청
            </Button>
            <Box>
              <FontAwesomeIcon icon={faCircleInfo} color="#FF474C" />
              <span
                style={{ fontWeight: 600, color: "#FF474C", fontSize: "1rem" }}
              >
                팀이 조회되지 않나요? 아래에 직접작성 버튼을 누르세요
              </span>
              <Box></Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={mandataryWrite}
                    onChange={onMandataryWrite}
                  />
                }
                label="직접작성"
              />
            </Box>
          </Box>
        </Box>
      </ItemBox>

      <ItemBox>
        <div className="section-title">팀등록 신청현황</div>
        <div>
          <TableContainer
            component={Paper}
            sx={{ margin: "1rem 0", maxHeight: 500 }}
          >
            <Table
              sx={{ width: "90%", margin: "1rem" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#eee" }}>
                  <TableCell sx={{ textAlign: "center", padding: "0.5rem 0" }}>
                    팀이름
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "0.5rem 0" }}>
                    신청일자
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "0.5rem 0" }}>
                    상태
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamActivationReuqstList
                  ? teamActivationReuqstList.map((row) => (
                      <StyledTableRow
                        onClick={null}
                        value={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          scope="row"
                          sx={{ textAlign: "center", padding: "0.5rem 0" }}
                        >
                          {row.proClub.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ textAlign: "center", padding: "0.5rem 0" }}
                        >
                          {row.enrolledAt.slice(0, 10)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ textAlign: "center", padding: "0.5rem 0" }}
                        >
                          {statusKorText[row.status]}
                        </TableCell>
                      </StyledTableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </ItemBox>
    </Box>
  );
}
