import {
  faAddressCard,
  faListCheck,
  faPeopleGroup,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
export default function StaffSidebar({ selectIndex }) {
  const [selectedMenu, setSelectedMenu] = useState(selectIndex);

  return (
    <Box sx={{ display: "flex", "& a:visited": { color: "#000" } }}>
      <Box sx={{ color: "#000" }}>
        <Link to="/mypage/coaches/profile">
          <Box
            onClick={() => {
              setSelectedMenu((prev) => 0);
            }}
            className={
              selectedMenu === 0 ? "sidebar-menu selected-menu" : "sidebar-menu"
            }
          >
            프로필
          </Box>
        </Link>
      </Box>
      <Box>
        <Link to="/mypage/coaches/createTeam">
          <div
            onClick={() => {
              setSelectedMenu((prev) => 1);
            }}
            className={
              selectedMenu === 1 ? "sidebar-menu selected-menu" : "sidebar-menu"
            }
          >
            팀관리권한
          </div>
        </Link>
      </Box>
      <Box>
        <Link to="/mypage/coaches/teamManage">
          <div
            onClick={() => {
              setSelectedMenu((prev) => 2);
            }}
            className={
              selectedMenu === 2 ? "sidebar-menu selected-menu" : "sidebar-menu"
            }
          >
            팀관리
          </div>
        </Link>
      </Box>
    </Box>
  );
}
