import MainLayout from '../../LayoutContainers/MainLayout'
import TeamDetail from '../../components/team/TeamDetail'

export default function TeamDetailLayout() {
  return (
    <MainLayout>
      <div className="body-container">
        <TeamDetail></TeamDetail>
      </div>
    </MainLayout>
  )
}
