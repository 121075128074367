import { Box } from "@mui/material";
import MyPageMainLayout from "../../../LayoutContainers/MyPageLayout";
import MPPlayerProfile from "../../../components/mypage/body/player/profile/MPPlayerProfile";
import MPScouterMain from "../../../components/mypage/body/scouter/MPScouterMain";
export default function MyPageScouterLayout() {
  return (
    <MyPageMainLayout>
      <Box>
        <MPScouterMain></MPScouterMain>
      </Box>
    </MyPageMainLayout>
  );
}
