import { Box, Grid } from "@mui/material";
import GalleryItem from "./GalleryItem";
import { useState } from "react";
import ImageModal from "../modal/ImageModal";

export default function GalleryList({ imgList }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(null);
  return (
    <Box padding="0.5rem">
      <Grid container spacing={2}>
        {imgList.map((il, index) => (
          <Grid key={index} item xs={6} md={4}>
            <Box
              onClick={() => {
                setModalOpen(true);
                setImgIndex((prev) => parseInt(index));
              }}
            >
              <GalleryItem data={il}></GalleryItem>
            </Box>
          </Grid>
        ))}
      </Grid>
      {modalOpen && (
        <ImageModal
          setModalOpen={setModalOpen}
          imgURL={imgList[imgIndex].imageFile.fileUrl}
        ></ImageModal>
      )}
    </Box>
  );
}
