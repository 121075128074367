import Button from "@mui/material/Button";
import MPVideoList from "../../../common/MPVideoList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Box } from "@mui/material";
import { TitleBox } from "../../../common/MyPageComponents";
export default function MPPlayerVideo({
  comType,
  roleType,
  highlightVideos,
  setHighlightVideos,
  videoType,
  sectionTitle,
  setSnackbarOpen,
  setLastCRUD,
}) {
  const [seq, setSeq] = useState(highlightVideos.length);
  function addBtnClick(e) {
    const data = {
      seq: seq,
      hit: 0,
      id: null,
      url: null,
      fromServer: false,
      isEditState: true,
    };
    setHighlightVideos((prev) => [...prev, data]);
    setSeq((prev) => prev + 1);
  }
  return (
    <Box>
      <TitleBox>
        {sectionTitle}
        <Button
          id="player-career-add-btn"
          variant="text"
          sx={{ fontSize: "1rem" }}
          onClick={addBtnClick}
        >
          <FontAwesomeIcon
            className="add-btn-icon"
            color="#1976D2"
            icon={faPlus}
          />
          추가하기
        </Button>
      </TitleBox>
      <Box>
        <MPVideoList
          comType={comType}
          roleType={roleType}
          highlightVideos={highlightVideos}
          setHighlightVideos={setHighlightVideos}
          videoType={videoType}
          setSnackbarOpen={setSnackbarOpen}
          setLastCRUD={setLastCRUD}
        ></MPVideoList>
      </Box>
    </Box>
  );
}
