import "../../style/staff.css";
import { API } from "../../config";
import { useRecoilState, useRecoilValue } from "recoil";
// import { detailPositionSelector } from "../../recoil/atoms/detailPositionSelector";
import { Box, Grid, Typography } from "@mui/material";

import styled from "styled-components";
import DetailProfile from "./DetailProfile";
import { detailPositionSelector } from "../../recoil/atoms/detailPositionSelector";

const center = "45%";
const left = "30%";
const right = "59%";
const positionLoc = [
  { postion: "CF", top: "7%", left: center },
  { postion: "SS", top: "20%", left: center },
  { postion: "AMF", top: "35%", left: center },
  { postion: "DMF", top: "48%", left: center },
  { postion: "LW", top: "20%", left: left },
  { postion: "RW", top: "20%", left: right },
  { postion: "LB", top: "60%", left: left },
  { postion: "CB", top: "70%", left: "38%" },
  { postion: "CB", top: "70%", left: "52%" },
  { postion: "RB", top: "60%", left: right },
  { postion: "GK", top: "85%", left: center },
];

const ReSizeImg = styled.img`
  display: block;
  object-fit: cover;
  border-radius: 5px;
  width: 80%;
  margin: 0 auto;
`;

export default function PlayerProfile({ player }) {
  const positionDetailEnum = useRecoilValue(detailPositionSelector);

  function ListItemTranslate() {
    return [
      { title: "생년월일", content: player.birthDate },
      { title: "국적", content: "대한민국" },
      { title: "신장", content: player.height, unit: "cm" },
      { title: "몸무게", content: player.weight, unit: "kg" },
      {
        title: "메인 포지션",
        content: player.mainPosition || "",
      },
      {
        title: "세컨드 포지션",
        content: player.secondPosition || "",
      },
    ];
  }
  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          sx={{ padding: { xs: "0", md: "3rem 0" } }}
        >
          <DetailProfile
            data={player}
            profileItem={ListItemTranslate()}
            comType={API.ComType.PLAYER_PROFILE}
            profileTypeText="PLAYER"
          ></DetailProfile>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            textAlign: "center",
            padding: { xs: "0.5rem", md: "3rem 0" },
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="end"
            sx={{
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} md={12}>
              <Box mb={"1rem"} textAlign="center">
                <Typography color="rgb(245,21,29)" fontWeight="600">
                  MAIN POSITION
                </Typography>
                {positionDetailEnum ? (
                  <Typography>
                    -
                    {player.mainPosition
                      ? positionDetailEnum.find(
                          (pd) => pd.name === player.mainPosition
                        ).engDescription
                      : ""}
                    -
                  </Typography>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                }}
              >
                {/* <ReSizeImg src="https://canda23.com/files/field_board.jpg"></ReSizeImg> */}
                <ReSizeImg
                  src={
                    player.mainPosition
                      ? `/img/wh_position_${player.mainPosition}.png`
                      : `/img/wh_position.png`
                  }
                ></ReSizeImg>
                {/* {positionLoc.map((pl, index) => (
                  <div
                    key={index}
                    style={{
                      width: "3.5rem",
                      padding: "0.1rem 0.5rem",
                      textAlign: "center",
                      position: "absolute",
                      top: pl.top,
                      left: pl.left,
                      color: "#fff",
                      fontSize:
                        pl.postion === player.mainPosition ? "1rem" : "0.9rem",
                      background:
                        pl.postion === player.mainPosition ? "red" : "#000",
                      opacity: pl.postion === player.mainPosition ? 1 : "0.3",
                      borderRadius: "50%",
                    }}
                  >
                    {pl.postion}
                  </div>
                ))} */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
