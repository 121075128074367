import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Button,
  Box,
} from "@mui/material";
import { API } from "../../../../../config";
import axios from "axios";
//const SNSType = ["INSTAGRAM", "FACEBOOK", "YOUTUBE", "TWITTER", "TIKTOK"];
const SNSType = ["INSTAGRAM", "FACEBOOK", "YOUTUBE"];

function teamUrlParser(method, teamid, snsId) {
  //팀SNS수정은 팀엔티티가 아닌 제 3자인 코치가 제어하므로 URL 형태가 다름
  if (method === "POST") {
    ///mypage/pro-clubs/1/sns-infos
    return `${API.MP_COACH_MY_MANAGED_TEAM}/${teamid}/sns-infos`;
  } else if (method === "PUT") {
    // /mypage/pro-clubs/1/sns-infos/1
    return `${API.MP_COACH_MY_MANAGED_TEAM}/${teamid}/sns-infos/${snsId}`;
  } else if (method === "DELETE") {
    // /mypage/pro-clubs/1/sns-infos/2
    return `${API.MP_COACH_MY_MANAGED_TEAM}/${teamid}/sns-infos/${snsId}`;
  }
}
function personalUrlParser(method, roleType, snsId) {
  if (roleType === API.RolyType.PLAYER) {
    if (method === "POST") {
      return API.MP_PLAYER_SNS;
    } else if (method === "PUT") {
      return `${API.MP_PLAYER_SNS}/${snsId}`;
    } else if (method === "DELETE") {
      return `${API.MP_PLAYER_SNS}/${snsId}`;
    }
    //선수
    return API.MP_COACH_MY_MANAGED_TEAM;
  } else {
    if (method === "POST") {
      return API.MP_COACH_SNS;
    } else if (method === "PUT") {
      return `${API.MP_COACH_SNS}/${snsId}`;
    } else if (method === "DELETE") {
      return `${API.MP_COACH_SNS}/${snsId}`;
    }
  }
}

export default function SNSItem({
  roleType,
  comType,
  SNS,
  setSNS,
  currentRow,
}) {
  function changeSNS(event) {
    const changedSNSType = event.target.value;
    setSNS((prev) =>
      prev.map((item) =>
        item.seq === currentRow.seq ? { ...item, type: changedSNSType } : item
      )
    );
  }
  function onSNSUrlChange(event) {
    const changedSNSUrl = event.target.value;
    setSNS((prev) =>
      prev.map((item) =>
        item.seq === currentRow.seq ? { ...item, url: changedSNSUrl } : item
      )
    );
  }
  async function onCreateOrUpdate() {
    if (currentRow.isEditState) {
      const teamId = currentRow.teamId;
      const data = {
        type: currentRow.type,
        url: currentRow.url,
      };

      let res = null;
      try {
        if (currentRow.fromServer) {
          const updateURL =
            comType === API.ComType.TEAM_SNS
              ? teamUrlParser("PUT", teamId, currentRow.id)
              : personalUrlParser("PUT", roleType, currentRow.id);
          console.log("updateURL", updateURL);
          res = await axios.put(updateURL, data);
          alert("수정하였습니다.");
        } else {
          const createURL =
            comType === API.ComType.TEAM_SNS
              ? teamUrlParser("POST", teamId)
              : personalUrlParser("POST", roleType);
          res = await axios.post(createURL, data);
          alert("추가하였습니다.");
        }
        setSNS((prev) =>
          res.data.data.map((item, index) => ({
            ...item,
            seq: index,
            fromServer: true,
            isEditState: false,
            teamId: teamId,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      setSNS((prev) =>
        prev.map((item) =>
          item.seq === currentRow.seq ? { ...item, isEditState: true } : item
        )
      );
    }
  }
  async function removeBtnClick() {
    if (window.confirm("삭제하시겠습니까?")) {
      if (currentRow.fromServer) {
        const teamId = currentRow.teamId;
        const deleteURL =
          comType === API.ComType.TEAM_SNS
            ? teamUrlParser("DELETE", teamId, currentRow.id)
            : personalUrlParser("DELETE", roleType, currentRow.id);
        try {
          const res = await axios.delete(deleteURL);
          alert("삭제하였습니다.");
          setSNS((prev) =>
            res.data.data.map((item, index) => ({
              ...item,
              seq: index,
              isEditState: false,
              fromServer: true,
              teamId: teamId,
            }))
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        setSNS((prev) => prev.filter((item) => item.seq != currentRow.seq));
      }
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: {
          xs: "left",
          md: "center",
        },
        flexDirection: {
          xs: "column",
          md: "row",
        },
        justifyContent: "flex-start",
        border: {
          xs: "1px solid rgb(64,132,75)",
          md: "none",
        },
        padding: {
          xs: "0.5rem",
          md: "0",
        },
        margin: {
          xs: "0.5rem",
          md: "0",
        },
        borderRadius: "3%/5%",
      }}
    >
      <FormControl
        sx={{ width: { xs: "95%", md: "17rem" }, m: 1 }}
        size="small"
      >
        <Select
          disabled={!currentRow.isEditState}
          defaultValue={currentRow.type}
          value={currentRow.type}
          onChange={changeSNS}
          sx={{
            "& .MuiOutlinedInput-input": {
              fontSize: "1.1rem",
            },
          }}
          MenuProps={{
            style: {
              maxHeight: 250,
            },
          }}
        >
          {SNSType.map((item) => (
            <MenuItem sx={{ fontSize: "1rem" }} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id="team-sns-input-box"
        disabled={!currentRow.isEditState}
        onChange={onSNSUrlChange}
        size="small"
        sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
        label={"SNS URL"}
        variant="outlined"
        value={currentRow.url}
        defaultValue={currentRow.url ? currentRow.url : ""}
      />
      <Box sx={{ display: "flex" }}>
        <Button
          value={currentRow.seq}
          onClick={onCreateOrUpdate}
          variant="contained"
          sx={{
            backgroundColor: currentRow.isEditState
              ? "info.main"
              : "success.main",
            ":hover": {
              backgroundColor: currentRow.isEditState
                ? "info.main"
                : "success.main",
              color: "yellow",
            },
          }}
        >
          {currentRow.isEditState ? "저장" : "수정"}
        </Button>
        <Tooltip
          title={currentRow.isEditState ? "" : "수정버튼을 먼저 클릭하세요"}
        ></Tooltip>
        <Button
          disabled={!currentRow.isEditState}
          value={currentRow.seq}
          onClick={removeBtnClick}
          variant="contained"
          sx={{
            backgroundColor: "#9D0000",
            ":hover": {
              bgcolor: "#9D0000",
              color: "yellow",
            },
          }}
        >
          삭제
        </Button>
      </Box>
    </Box>
  );
}
