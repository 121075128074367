import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import SelectDialog from "../../../common/SelectDialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import dayjs from "dayjs";
import { faL } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API } from "../../../../../config";
import { useRecoilValue } from "recoil";
import { enumAtom } from "../../../../../recoil/atoms/applicationEnum";
import { detailPositionSelector } from "../../../../../recoil/atoms/detailPositionSelector";
import { StyledButton, TitleBox } from "../../../common/MyPageComponents";

export default function MPPlayerCurrentTeam({
  comType,
  roleType,
  sectionTitle,
  selectBoxType,
  career,
  setCareer,
}) {
  const [teamSelectOpen, setTeamSelectOpen] = useState(false);
  const positionEnum = useRecoilValue(enumAtom);
  const positionDetailEnum = useRecoilValue(detailPositionSelector);
  function setStartDate(e) {
    setCareer((prev) => ({ ...prev, joinedAt: e.$d }));
  }
  function inputValidation() {
    if (!career.club.name) {
      alert("팀명을 입력하세요");
      return false;
    }
    if (!career.joinedAt) {
      alert("시작일을 입력하세요");
      return false;
    }
    return true;
  }
  const changePosition = (e) => {
    const newPosition = e.target.value;
    setCareer((prev) => ({ ...prev, position: newPosition }));
  };
  const onChangeKitNumber = (e) => {
    const newKitNumber = e.target.value;
    setCareer((prev) => ({ ...prev, kitNumber: newKitNumber }));
  };
  async function onSave() {
    if (career.isEditState) {
      if (inputValidation()) {
        const requestData = {
          clubId: career.club.id || null,
          kitNumber: career.kitNumber ? career.kitNumber : null,
          position: career.position,
          joinedAt: career.joinedAt,
          etcClubName: career.club.id ? "" : career.club.name,
        };
        console.log("requestData", requestData);
        try {
          const {
            data: { data },
          } = await axios.put(API.MP_PLAYER_CURRENT_TEAM, requestData);
          console.log(data);
          setCareer((prev) => data);
          alert("현재소속을 저장하였습니다.");
        } catch (error) {
          console.log(error);
          alert("현재소속 저장 중 문제가 발생했습니다.");
        }
      }
    } else {
      setCareer((prev) => ({ ...prev, isEditState: true }));
    }
    console.log("career", career);
  }
  function findTeam() {
    setTeamSelectOpen(true);
  }
  return (
    <Box>
      <TitleBox>{sectionTitle}</TitleBox>
      <Box
        sx={{
          display: "flex",
          alignItems: {
            xs: "left",
            md: "center",
          },
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "flex-start",
        }}
      >
        <TextField
          label="팀명"
          disabled={!career.isEditState}
          onClick={findTeam}
          size="small"
          sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
          id="position-input"
          variant="outlined"
          value={career.club.name ? career.club.name : ""}
          defaultValue={career.club.name ? career.club.name : ""}
          inputProps={{ style: { fontSize: "1.2rem" } }}
        />
        <TextField
          label="등번호"
          disabled={!career.isEditState}
          size="small"
          sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
          id="kitNumber-input"
          variant="outlined"
          onChange={onChangeKitNumber}
          value={career.kitNumber ? career.kitNumber : ""}
          defaultValue={career.kitNumber ? career.kitNumber : ""}
          inputProps={{ style: { fontSize: "1.2rem" } }}
          //defaultValue={currentCareer.club ? currentCareer.club.name : ""}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
          <MobileDatePicker
            disabled={!career.isEditState}
            localeText={"ko"}
            defaultValue={dayjs(career.joinedAt)}
            value={dayjs(career.joinedAt)}
            onChange={setStartDate}
            sx={{
              m: 1,
              width: { xs: "95%", md: "17rem" },
              "& .MuiOutlinedInput-input": {
                border: 0,
                borderRadius: 3,
                fontSize: "1.2rem",
              },
            }}
            slotProps={{ textField: { size: "small" } }}
            views={["year", "month"]}
            format={"YYYY-MM"}
            label={"시작일"}
            data-pickerType="startDate"
            inputProps={{ style: { fontSize: "1.2rem" } }}
          />
        </LocalizationProvider>
        <FormControl
          sx={{
            width: { xs: "95%", md: "17rem" },
            ml: 1,
            m: 1,
            maxHeight: "100px",
          }}
          size="small"
        >
          <InputLabel id="demo-simple-select-label">포지션</InputLabel>
          <Select
            disabled={!career.isEditState}
            defaultValue={career.position}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Position"
            value={career.position}
            onChange={changePosition}
            sx={{
              "& .MuiOutlinedInput-input": {
                fontSize: "1rem",
              },
            }}
            MenuProps={{
              style: {
                maxHeight: 250,
              },
            }}
          >
            {comType === API.ComType.PLAYER_HISOTRY
              ? positionDetailEnum.map((item) => (
                  <MenuItem
                    sx={{ color: item.color, fontSize: "1rem" }}
                    value={item.name}
                  >
                    {item.description}
                  </MenuItem>
                ))
              : positionEnum.coachPositions.map((item) => (
                  <MenuItem value={item.name}>{item.description}</MenuItem>
                ))}
          </Select>
        </FormControl>

        <StyledButton
          onClick={onSave}
          variant="contained"
          sx={{
            backgroundColor: career.isEditState ? "info.main" : "success.main",
            ":hover": {
              backgroundColor: career.isEditState
                ? "info.main"
                : "success.main",
              color: "yellow",
            },
          }}
        >
          {career.isEditState ? "저장" : "수정"}
        </StyledButton>
      </Box>
      <SelectDialog
        selectBoxType={selectBoxType}
        open={teamSelectOpen}
        setOpen={setTeamSelectOpen}
        itemSeq={null}
        setCareer={setCareer}
      />
    </Box>
  );
}
