import { Box } from "@mui/material";
import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../../config";
import MPAgentProfile from "./MPAgentProfile";

const ItemBox = styled(Box)`
  background-color: #fff;
  border: 1px solid rgb(64, 132, 75);
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 0.5rem/0.5rem;
`;

export default function MPAgentMain() {
  const [agent, setAgent] = useState();
  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`${API.MP_AGENT}`);
      console.log("MPAgentMain", data);
      if (data.code === 200000) {
        setAgent(data.data);
      }
      try {
      } catch (error) {}
    }
    fetchData();
  }, []);
  return (
    <Box
      sx={{
        fontSize: "1.3rem",
        width: { sm: "auto", xl: "1200px" },
        margin: { sm: "0", xl: "0 auto" },
      }}
    >
      {agent ? <ItemBox></ItemBox> : null}
    </Box>
  );
}
