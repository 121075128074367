import { Box } from "@mui/material";
import { useState } from "react";
export default function MyTab({ tabs }) {
  const [currentTab, clickTab] = useState(0);
  const onMenuClick = (index) => {
    clickTab(index);
  };
  return (
    <Box sx={{ width: "100%", backgroundColor: "#fff", padding: "0.5rem" }}>
      <Box
        className="tab-menu-container"
        width={{ sx: "100%", md: "50%" }}
        margin="0 0 1rem 0"
      >
        {tabs.map((el, index) => (
          <div
            className={index === currentTab ? "tab-menu selected" : "tab-menu"}
            onClick={() => onMenuClick(index)}
          >
            {el.name}
          </div>
        ))}
      </Box>

      <div className="tab-content">
        <p>{tabs[currentTab].component}</p>
      </div>
    </Box>
  );
}
