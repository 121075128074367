import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Divider, TextField } from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { API } from "../../../../../config";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { enumAtom } from "../../../../../recoil/atoms/applicationEnum";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { StyledLabel } from "../../../common/MyPageComponents";
import heic2any from "heic2any";
import Loading from "../../../common/Loading";

const TeamProfileSubBox = styled(Box)`
  padding: 0.5rem;
  border: 1px solid #bbb;
  margin: 0 0 0.5rem 0;
`;

const LabelBox = styled("div")`
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
`;
const LabelP = styled("p")`
  display: inline-block;
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
  width: 7rem;
`;
export default function MPStaffManageTeamProfileBasic({
  teamRequiredInfo,
  setTeamRequiredInfo,
  teamCommonInfo,
  setTeamCommonInfo,
}) {
  const applicationEnum = useRecoilValue(enumAtom);
  const img_ref = useRef();
  const [loading, setLoading] = useState(false);
  function onTextChange(event) {
    const inputType = event.target.name;
    const inputValue = event.target.value;
    setTeamCommonInfo((prev) => ({ ...prev, [inputType]: inputValue }));
  }
  async function changeLogoImg(event) {
    event.preventDefault();
    const formData = new FormData();

    try {
      let uploadFile = event.target.files[0];
      if (uploadFile.name.split(".")[1] === "heic") {
        setLoading((prev) => true);
        let blob = uploadFile;
        const resultBlob = await heic2any({ blob: blob, toType: "image/jpeg" });
        uploadFile = new File(
          [resultBlob],
          uploadFile.name.split(".")[0] + ".jpg",
          {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          }
        );
        setLoading((prev) => false);
      }
      if (uploadFile.size > API.FILE_UPLOAD_MAX_SIZE) {
        alert(
          `변환된 파일크기가 제한 용량(5MB)를 초과합니다\n 파일크기:${Number(uploadFile.size / 1000000).toFixed(2)}MB`
        );
        return;
      }
      formData.append("file", uploadFile);
      var {
        data: { data },
      } = await axios.post(`${API.FILE_BASE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setTeamRequiredInfo((prev) => ({ ...prev, logo: data }));

      const sendData = {
        name: teamRequiredInfo.name,
        leagueId: teamRequiredInfo.league.id,
        region: teamRequiredInfo.region,
        logoImageId: data.id,
      };
      const url = `${API.MP_COACH_MY_MANAGED_TEAM}/${teamRequiredInfo.teamId}/required`;
      var {
        data: { data },
      } = await axios.put(url, sendData);
      alert("사진을 등록하였습니다");
    } catch (error) {
      console.log(error);
    }
  }
  async function onRemoveLogo() {
    console.log("teamRequiredInfo", teamRequiredInfo);
    console.log("teamCommonInfo", teamCommonInfo);
    const requestData = {
      name: teamRequiredInfo.name,
      leagueId: teamRequiredInfo.league ? teamRequiredInfo.league.id : null,
      region: teamRequiredInfo.region,
      logoImageId: null,
    };
    const {
      data: { data },
    } = await axios.put(
      `${API.MP_COACH_MY_MANAGED_TEAM}/${teamRequiredInfo.teamId}/required`,
      requestData
    );
    setTeamRequiredInfo((prev) => ({
      teamId: data.id,
      name: data.name,
      league: data.league ? data.league : null,
      region: data.region,
      logo: null,
      isEditState: false,
    }));
    setTeamCommonInfo((prev) => ({
      teamId: data.id,
      foundedDate: data.foundedDate,
      chiefOfficer: data.chiefOfficer,
      chiefManager: data.chiefManager,
      phoneNumber: data.phoneNumber,
      email: data.email,
      isEditState: false,
    }));
    alert("사진을 삭제했습니다");
  }
  function setEndDate(event) {
    setTeamCommonInfo((prev) => ({ ...prev, foundedDate: event.$d }));
  }
  async function onCommonInfoChange() {
    if (teamCommonInfo.isEditState) {
      const updateData = teamCommonInfo;
      const teamId = updateData.teamId;

      const changedDate = new Date(teamCommonInfo.foundedDate);
      changedDate.setDate(changedDate.getDate() + 1);
      updateData["foundedDate"] = changedDate.toISOString().split("T")[0];
      delete updateData.teamId;
      delete updateData.isEditState;

      try {
        const res = await axios.put(
          `${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/common`,
          updateData
        );
        alert("수정하였습니다.");
        setTeamCommonInfo((prev) => ({
          teamId: teamId,
          foundedDate: res.data.data.foundedDate,
          chiefOfficer: res.data.data.chiefOfficer,
          chiefManager: res.data.data.chiefManager,
          phoneNumber: res.data.data.phoneNumber,
          email: res.data.data.email,
          isEditState: false,
        }));
      } catch (error) {
        console.log(error);
      }
    } else {
      setTeamCommonInfo((prev) => ({ ...prev, isEditState: true }));
    }
  }
  return (
    <Box
      sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}
    >
      <TeamProfileSubBox sx={{ width: { xs: "100%", md: "33%" } }}>
        <Box>
          <LabelBox>팀명</LabelBox>
          <TextField
            disabled={!teamRequiredInfo.isEditState}
            value={teamRequiredInfo.name}
            size="small"
            id="position-input"
            variant="outlined"
            sx={{ m: 1, width: { xs: "95%" } }}
            inputProps={{ style: { fontSize: "1.1rem" } }}
          />
        </Box>
        <Box>
          <LabelBox>리그</LabelBox>
          <TextField
            disabled={!teamRequiredInfo.isEditState}
            value={teamRequiredInfo.league.description}
            size="small"
            id="position-input"
            variant="outlined"
            sx={{ m: 1, width: { xs: "95%" } }}
            inputProps={{ style: { fontSize: "1.1rem" } }}
          />
        </Box>
        <Box>
          <LabelBox>지역</LabelBox>
          <TextField
            disabled={!teamRequiredInfo.isEditState}
            value={
              applicationEnum.regions.find(
                (item) => item.name === teamRequiredInfo.region
              )
                ? applicationEnum.regions.find(
                    (item) => item.name === teamRequiredInfo.region
                  ).description
                : teamRequiredInfo.region
            }
            size="small"
            id="position-input"
            variant="outlined"
            sx={{ m: 1, width: { xs: "95%" } }}
            inputProps={{ style: { fontSize: "1.1rem" } }}
          />
        </Box>
        <Box>
          <div>
            <FontAwesomeIcon icon={faCircleInfo} color="#1e8ffa" />
            <span style={{ color: "#339AF0", fontSize: "1rem" }}>
              팀명,리그,지역을 수정할 수 없습니다.
            </span>
          </div>
        </Box>
      </TeamProfileSubBox>
      <TeamProfileSubBox sx={{ width: { xs: "100%", md: "33%" } }}>
        <Box className="file-upload-box">
          <StyledLabel sx={{ display: "inline-block" }}>
            {teamRequiredInfo.profileImage ? "수정" : "등록"}
            <input
              type="file"
              className="file"
              ref={img_ref}
              accept="image/*,image/heic"
              style={{ display: "none" }}
              onChange={changeLogoImg}
            />
          </StyledLabel>
          <StyledLabel onClick={onRemoveLogo}>삭제</StyledLabel>
        </Box>
        <Divider sx={{ margin: "0.5rem" }}></Divider>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="staff-serach-filter-item">
            {teamRequiredInfo.logo ? (
              <img
                src={`${API.BASE}${teamRequiredInfo.logo.fileUrl}`}
                style={{ width: "200px", margin: "0 auto" }}
                alt="logo-img"
              ></img>
            ) : (
              <div className="image-box"> No Image</div>
            )}
          </div>
          <Loading loading={loading}></Loading>
        </Box>
      </TeamProfileSubBox>
      <TeamProfileSubBox sx={{ width: { xs: "100%", md: "33%" } }}>
        <Box>
          <Button
            onClick={onCommonInfoChange}
            variant="contained"
            sx={{
              backgroundColor: teamCommonInfo.isEditState
                ? "info.main"
                : "success.main",
              ":hover": {
                backgroundColor: teamCommonInfo.isEditState
                  ? "info.main"
                  : "success.main",
                color: "yellow",
              },
            }}
          >
            {teamCommonInfo.isEditState ? "저장" : "수정"}
          </Button>
        </Box>
        <Divider sx={{ margin: "0.5rem" }}></Divider>
        <Box>
          <Box
            sx={{ display: "flex", alignItems: "center", margin: "0 0 0.2rem" }}
          >
            <LabelP>창단일</LabelP>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                disabled={!teamCommonInfo.isEditState}
                defaultValue={dayjs(teamCommonInfo.foundedDate)}
                value={dayjs(teamCommonInfo.foundedDate)}
                onChange={setEndDate}
                sx={{
                  m: 0,
                  width: { xs: "95%" }, //sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
                  "& .MuiOutlinedInput-input": {
                    border: 0,
                    borderRadius: 3,
                    fontSize: "1.1rem",
                  },
                }}
                slotProps={{ textField: { size: "small" } }}
                views={["year", "month", "day"]}
                format={"YYYY-MM-DD"}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", margin: "0 0 0.2rem" }}
          >
            <LabelP>구단주</LabelP>
            <TextField
              disabled={!teamCommonInfo.isEditState}
              value={teamCommonInfo.chiefOfficer}
              name="chiefOfficer"
              onChange={onTextChange}
              size="small"
              variant="outlined"
              sx={{ width: { xs: "95%" } }}
              inputProps={{ style: { fontSize: "1.1rem" } }}
            />
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", margin: "0 0 0.2rem" }}
          >
            <LabelP>대표이사</LabelP>
            <TextField
              disabled={!teamCommonInfo.isEditState}
              value={teamCommonInfo.chiefManager}
              name="chiefManager"
              onChange={onTextChange}
              size="small"
              variant="outlined"
              sx={{ width: { xs: "95%" } }}
              inputProps={{ style: { fontSize: "1.1rem" } }}
            />
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", margin: "0 0 0.2rem" }}
          >
            <LabelP>연락처</LabelP>
            <TextField
              disabled={!teamCommonInfo.isEditState}
              value={teamCommonInfo.phoneNumber}
              name="phoneNumber"
              onChange={onTextChange}
              size="small"
              variant="outlined"
              sx={{ width: { xs: "95%" } }}
              inputProps={{ style: { fontSize: "1.1rem" } }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LabelP>이메일</LabelP>
            <TextField
              disabled={!teamCommonInfo.isEditState}
              value={teamCommonInfo.email}
              name="email"
              onChange={onTextChange}
              size="small"
              variant="outlined"
              sx={{ width: { xs: "95%" } }}
              inputProps={{ style: { fontSize: "1.1rem" } }}
            />
          </Box>
        </Box>
      </TeamProfileSubBox>
    </Box>
  );
}
