import { Box, Grid } from "@mui/material";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

const StyledLink = styled(Link)`
  box-sizing: border-box;
  display: block;
  padding: 0.6em;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s;
  color: rgb(150, 150, 150);
  &:hover {
    color: rgb(84, 162, 105);
    font-weight: 600;
    transition: 0.3s;
  }
`;

const StyledLi = styled("span")`
  height: 1.5rem;
`;
const liMenu = [
  { text: "ANTIOPING", url: "#" },
  { text: "선수처리 고충센터", url: "#" },
  { text: "클린센터", url: "#" },
  { text: "개인정보처리방침", url: "#" },
  { text: "이용약관", url: "#" },
  { text: "이메일무단수집거부", url: "#" },
  { text: "오시는 길", url: "#" },
  { text: "통합 SNS", url: "#" },
];
const liCompany = [
  "법인명 (주)캔다",
  " 주소 경기 남양주시 경춘로 498 다산지금데이앙스튜디오 4층 449호",
  "대표자(서명) 이용섭",
];
const liContact = [
  "사업자등록번호 308-81-47456",
  "고객센터 0507-1431-7250",
  "E-MAIL canda.lys76@gmail.com",
  "FAX:0504-467-4939",
];
export default function Footer() {
  return (
    <Grid
      container
      color="rgb(150, 150, 150)"
      alignItems="center"
      justifyContent="left"
      alignContent="left"
      direction={{
        xs: "row",
        sm: "row",
        md: "row",
        lg: "row",
        xl: "row",
        xxl: "row",
      }}
      fontSize="1rem"
    >
      <Grid sx={{ justifyContent: "left" }}>
        <img
          src="https://canda23.com/files/footer_logo.png"
          alt="logo"
          style={{ width: "15rem", marginRight: "3rem" }}
        ></img>
      </Grid>
      <Grid item>
        {/* <Box padding={"1.5rem 0"}>
          <Grid container>
            {liMenu.map((li, index) => (
              <Grid item key={index}>
                <StyledLink>{li.text}</StyledLink>
              </Grid>
            ))}
          </Grid>
        </Box> */}
        <Box padding={"0 0 1rem 0"}>
          <Grid
            container
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
              xxl: "row",
            }}
            justifyContent="left"
          >
            {liCompany.map((li, index) => (
              <Grid
                key={index}
                item
                sx={{
                  borderRight: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "1px solid rgb(150, 150, 150)",
                  },
                  "&:last-child": { borderRight: "none" },
                  justifyContent: "center",
                  textAlign: "left",
                  padding: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "0 2rem",
                  },
                  "&:first-child": { padding: "0 2rem 0 0" },
                }}
              >
                <StyledLi key={index}>{li}</StyledLi>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
              xxl: "row",
            }}
          >
            {liContact.map((li, index) => (
              <Grid
                key={index}
                item
                sx={{
                  borderRight: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "1px solid rgb(150, 150, 150)",
                  },
                  "&:last-child": { borderRight: "none" },
                  justifyContent: "center",
                  textAlign: "left",
                  padding: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "0 2rem",
                  },
                  "&:first-child": { padding: "0 2rem 0 0" },
                }}
              >
                <StyledLi key={index}>{li}</StyledLi>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box>
          <p style={{ width: "100%" }}>
            Canda 소유의 사진, 초상, 영상, 캐릭터 및 기타 Canda의 지적
            재산권등을 무단으로 사용하는 것을 상표법 및 저작권법에 의해
            금지됩니다.
          </p>
          <p>Copyright ©CAN-DA ALL RIGHT RESERVED</p>
        </Box>
      </Grid>
    </Grid>
  );
}
