import { Box } from "@mui/material";
import MPVideoListItem from "./MPVideoListItem";

export default function MPVideoList({
  comType,
  roleType,
  highlightVideos,
  setHighlightVideos,
  videoType,
  setSnackbarOpen,
  setLastCRUD,
}) {
  return (
    <Box>
      {highlightVideos.map((item) => (
        <MPVideoListItem
          comType={comType}
          roleType={roleType}
          highlightVideos={highlightVideos}
          setHighlightVideos={setHighlightVideos}
          videoType={videoType}
          currentVideo={item}
          setSnackbarOpen={setSnackbarOpen}
          setLastCRUD={setLastCRUD}
        ></MPVideoListItem>
      ))}
    </Box>
  );
}
