import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CoachAwardRecord from "./CoachAwardRecord";
import CoachCoachingCareer from "./CoachCoachingCareer";
import CoachPhilosophy from "./CoachPhilosophy";
import CoachPlayerCareer from "./CoachPlayerCareer";
import CoachPositionPreference from "./CoachPositionPreference";
import CoachProfile from "./CoachProfile";
import CoachTeamHistory from "./CoachTeamHistory";
import axios from "axios";
import { API } from "../../config";
import CoachCurrentTeam from "./CoachCurrentTeam";
import PlayerSNS from "./PlayerSNS";
import PlayerVideo from "./PlayerVideo";
import { Box, Typography } from "@mui/material";
import TitleBox from "../../LayoutContainers/TitleBox";
import RowBox from "../../LayoutContainers/RowBox";
import PlayerProfile from "./PlayerProfile";
import TitleRowBox from "../../LayoutContainers/TitleRowBox";
import ItemBox from "./ItemBox";
import { coach_kor_title } from "../../recoil/commonEnum";
const snsType = {
  INSTAGRAM:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png",
  FACEBOOK:
    "https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png",
  YOUTUBE:
    "https://w7.pngwing.com/pngs/936/468/png-transparent-youtube-logo-youtube-logo-computer-icons-subscribe-angle-rectangle-airplane-thumbnail.png",
};
const testCoachVideos = [
  {
    id: 1,
    title: "기본영상1",
    url: "https://youtu.be/kSJe-2jD2-I?si=MRebj12LGNHke27J",
    hit: 10,
  },
  {
    id: 2,
    title: "기본영상2",
    url: "https://youtu.be/WLK51YE_z9k?si=n2o1akDl33hg5b8w",
    hit: 10,
  },
  {
    id: 3,
    title: "기본영상3",
    url: "https://youtu.be/NjsPXsxp2T8?si=G3UoLJxqSUED_cwX",
    hit: 10,
  },
];

export default function StaffDetail() {
  const [coach, setCoach] = useState(null);
  const { coachId } = useParams();
  useEffect(() => {
    async function fetchdata() {
      const { data } = await axios.get(`${API.COACHE_DETAIL}/${coachId}`);
      setCoach((prev) => data.data);
    }
    fetchdata();
  }, []);
  function MakeList(type) {
    if (type === "playerHistories") {
      return coach.playerHistories.map((ph) => ({
        title: `${ph.joinedAt.substring(0, 4)}-${ph.leftAt.substring(0, 4)}`,
        content: `${ph.club.name}(${ph.position})`,
      }));
    } else if (type === "coachHistory") {
      return coach.coachHistories.map((ch) => ({
        title: `${ch.joinedAt.substring(0, 4)}-${ch.leftAt.substring(0, 4)}`,
        content: `${ch.club.name}(${coach_kor_title[ch.position]})`,
      }));
    } else if (type === "personalAwardHistories") {
      return coach.personalAwardHistories.map((pah) => ({
        title: pah.date ? pah.date.substring(0, 4) : "",
        content: pah.name,
      }));
    } else if (type === "teamAwardHistories") {
      return coach.teamAwardHistories.map((pah) => ({
        title: pah.date ? pah.date.substring(0, 4) : "",
        content: pah.name,
      }));
    }
  }
  return (
    <Box>
      {coach ? (
        <Box>
          <TitleBox title="COACH"></TitleBox>
          <RowBox padding="0 0 3rem" isBorder={true}>
            <CoachProfile coach={coach}></CoachProfile>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              하이라이트
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="2rem"
            margin="0 0 1rem 0"
            isBorder={true}
          >
            <PlayerVideo
              playerVideo={coach.highlightVideos}
              btnClassName={"player-highlight"}
            ></PlayerVideo>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              이력
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="2rem"
            margin="0 0 1rem 0"
            isBorder={true}
          >
            <ItemBox
              data={MakeList("playerHistories")}
              labelWidth={"8rem"}
              twoLine={"true"}
            ></ItemBox>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              지도 경력
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="2rem"
            margin="0 0 1rem 0"
            isBorder={true}
          >
            <ItemBox
              data={MakeList("coachHistory")}
              labelWidth={"8rem"}
            ></ItemBox>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              개인 수상경력
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="2rem"
            margin="0 0 1rem 0"
            isBorder={true}
          >
            <ItemBox data={MakeList("personalAwardHistories")}></ItemBox>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              팀 수상경력
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="2rem"
            margin="0 0 1rem 0"
            isBorder={true}
          >
            <ItemBox data={MakeList("teamAwardHistories")}></ItemBox>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              지도철학
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="2rem"
            margin="0 0 1rem 0"
            isBorder={true}
          >
            <ItemBox singleText={coach.coachingPhilosophy}></ItemBox>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              포지션별 선호스타일
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="0rem"
            margin="0 0 1rem 0"
            isBorder={true}
          >
            <CoachPositionPreference
              coach={coach}
              margin="0.5rem"
            ></CoachPositionPreference>
          </RowBox>
        </Box>
      ) : null}
    </Box>
  );
}
