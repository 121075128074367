import { Box, Button, TextField } from "@mui/material";
import axios from "axios";
import { API } from "../../../../../config";
import { StyledButton, TitleBox } from "../../../common/MyPageComponents";

export default function MPPlayerAgent({ sectionTitle, agent, setAgent }) {
  async function onSave() {
    if (agent.isEditState) {
      const requestData = {
        name: agent.name,
        phoneNumber: agent.phoneNumber,
      };
      console.log("requestData", requestData);
      try {
        const {
          data: { data },
        } = await axios.put(API.MP_PLAYER_AGENT, requestData);
        console.log("data", data);
        setAgent((prev) => ({
          ...data,
          isEditState: false,
        }));
        alert("성공적으로 저장하였습니다.");
      } catch (error) {
        console.log(error);
        alert("서버에 문제가 발생했습니다.");
      }
    } else {
      setAgent((prev) => ({ ...prev, isEditState: true }));
    }
  }
  function onChange(e) {
    const { name, value } = e.target;
    if (name === "agent-name-input") {
      setAgent((prev) => ({ ...prev, name: value }));
    } else {
      setAgent((prev) => ({ ...prev, phoneNumber: value }));
    }
  }
  return (
    <Box>
      <TitleBox>{sectionTitle}</TitleBox>
      <Box
        sx={{
          display: "flex",
          alignItems: {
            xs: "left",
            md: "center",
          },
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "flex-start",
        }}
      >
        <Box>
          <TextField
            label="에이전트명"
            disabled={!agent.isEditState}
            size="small"
            sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
            name="agent-name-input"
            variant="outlined"
            onChange={onChange}
            value={agent.name || ""}
            defaultValue={agent.name || ""}
            inputProps={{ style: { fontSize: "1.2" } }}
          />
        </Box>
        <Box>
          <TextField
            label="전화번호"
            disabled={!agent.isEditState}
            size="small"
            sx={{ m: 1, width: { xs: "95%", md: "17rem" } }}
            name="agent-phone-input"
            variant="outlined"
            onChange={onChange}
            value={agent.phoneNumber || ""}
            defaultValue={agent.phoneNumber || ""}
            inputProps={{ style: { fontSize: "1.2rem" } }}
          />
        </Box>
        <Box>
          <StyledButton
            onClick={onSave}
            variant="contained"
            sx={{
              backgroundColor: agent.isEditState ? "info.main" : "success.main",
              ":hover": {
                backgroundColor: agent.isEditState
                  ? "info.main"
                  : "success.main",
                color: "yellow",
              },
            }}
          >
            {agent.isEditState ? "저장" : "수정"}
          </StyledButton>
        </Box>
      </Box>
    </Box>
  );
}
