import MainLayout from "../../LayoutContainers/MainLayout";
import RowBox from "../../LayoutContainers/RowBox";
import ScouterList from "../../components/Scouter/ScouterList";
import PlayerList from "../../components/person/PlayerList";

export default function ScouterListLayout() {
  return (
    <MainLayout mode="light">
      <RowBox
        mode="light"
        bgColor="none"
        padding={"0 0 6rem 0"}
        isBorder={false}
      >
        <ScouterList></ScouterList>
      </RowBox>
    </MainLayout>
  );
}
