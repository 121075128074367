import { API } from "../../../config";
export default function URLCreator(roleType, comType, teamId, method) {
  if (roleType === API.RolyType.COACH) {
    //코치
    if (comType === API.ComType.PLAYER_HISOTRY) {
      return API.MP_COACH_PLAYER_HISTORY;
    } else if (comType === API.ComType.COACH_HISOTRY) {
      return API.MP_COACH_COACH_HISTORY;
    } else if (comType === API.ComType.COACH_AWARDS_PERSONAL) {
      return API.MP_COACH_AWARDS_PERSONAL;
    } else if (comType === API.ComType.COACH_AWARDS_TEAM) {
      return API.MP_COACH_AWARDS_TEAM;
    } else if (comType === API.ComType.COACH_VIDEO) {
      return API.MP_COACH_VIDEO;
    } else if (comType === API.ComType.TEAM_VIDEO) {
      if (method === "DELETE") {
        return `${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/videos`;
      } else if (method === "PUT") {
        return `${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/videos`;
      }
      return `${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/videos`; //id->teamId
    } else if (comType === API.ComType.COACH_CERT) {
      if (method === "DELETE") {
        return API.MP_COACH_CERT;
      } else if (method === "PUT" || method === "POST") {
        return API.MP_COACH_CERT;
      }
    } else if (comType === API.ComType.TEAM_COACH) {
      if (method === "DELETE") {
        return `${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/coaches`;
      } else if (method === "PUT") {
        return `${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/coaches`;
      }
    } else if (comType === API.ComType.TEAM_PLAYER) {
      if (method === "DELETE") {
        return `${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/pro-players`;
      } else if (method === "PUT") {
        return `${API.MP_COACH_MY_MANAGED_TEAM}/${teamId}/pro-players`;
      }
    }
  } else if (roleType === API.RolyType.PLAYER) {
    if (comType === API.ComType.PLAYER_HISOTRY) {
      return API.MP_PLAYER_PLAYER_HISTORY;
    }
    if (comType === API.ComType.PLAYER_AWARDS_PERSONAL) {
      return API.MP_PLAYER_AWARDS_PERSONAL;
    }
    if (comType === API.ComType.PLAYER_AWARDS_TEAM) {
      return API.MP_PLAYER_AWARDS_TEAM;
    }
    if (comType === API.ComType.PLAYER_VIDEO) {
      return API.MP_PLAYER_VIDEO;
    }
  }
}
