import { Box, Typography } from "@mui/material";
import { API } from "../../config";

export default function GalleryItem({ data }) {
  return (
    <Box sx={{ backgroundColor: "#eee", padding: "1rem", borderRadius: "5%" }}>
      <Box sx={{ textAlign: "center", fontWeight: "600", fontSize: "1.1rem" }}>
        {data.title}
      </Box>
      <Box overflow="hidden">
        <img
          src={`${API.BASE}${data.imageFile.fileUrl}`}
          alt="team-gallery-img"
          style={{ objectFit: "cover", width: "100%", borderRadius: "10px" }}
        ></img>
      </Box>
    </Box>
  );
}
