import {
  faCircleInfo,
  faMagnifyingGlass,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import axios from "axios";
import { useRef, useState } from "react";
import styled from "styled-components";
import { API } from "../../../../config";
import { StyledLabel, TitleBox } from "../../common/MyPageComponents";
import { width } from "@fortawesome/free-solid-svg-icons/faUniversalAccess";
import MPAgencyPlayerAddManually from "./MPAgencyPlayerAddManually";
import heic2any from "heic2any";
import Loading from "../../common/Loading";

const StyledTable = styled.table`
  width: 100%;
  margin: 0 auto 1rem;
  border-collapse: collapse;
  caption-side: bottom;
  max-height: 100px;
  border:1px solid rgb(64,132,75);
  td,
  th {
    border: none;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 0.7rem 10px;
    text-align: center;
    vertical-align: middle;
  }

  tbody > tr {
    border-bottom: 1px solid #bbb;
    vertical-align: top
    :nth-of-type(odd) {
    }
    &:hover {
      background-color: #eee;
    }
  }
  thead > tr {
    background-color: rgb(64, 132, 75);
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
  }
  th {
    padding: 10px 0;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

const LabelBox = styled("div")`
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
`;
const LabelP = styled("p")`
  display: inline-block;
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
  width: 6rem;
`;
const LabelContent = styled("span")`
  display: inline-block;
  font-size: 1.1rem;
`;
const hHeaders = ["프로필", "이름", "소속", "등넘버"];
export default function MPAgencyPlayer({ players, setAgencyPlayers }) {
  const inputRef = useRef();
  const [searchedPlayer, setSearchedPlayer] = useState();
  const [clickedNewPlayer, setClickedNewPlayer] = useState();
  const [clickedCurrentPlayer, setClickedCurrentPlayer] = useState();
  const [isCurrentPlayerEditable, setIsCurrentPlayerEditable] = useState(false);
  const img_ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const clickedCurrentPlayerInputRef = useRef([]);
  function onClickPlayerTableRow(event) {
    const clickedPlayerId = parseInt(event.currentTarget.getAttribute("value"));
    const player = players.find((p) => p.id === clickedPlayerId);
    setClickedCurrentPlayer((prev) => player);
    console.log("player", player);
  }
  async function changeImgText(event) {
    event.preventDefault();
    const formData = new FormData();

    try {
      let uploadFile = event.target.files[0];
      if (uploadFile.name.split(".")[1] === "heic") {
        setLoading((prev) => true);
        let blob = uploadFile;
        const resultBlob = await heic2any({ blob: blob, toType: "image/jpeg" });
        uploadFile = new File(
          [resultBlob],
          uploadFile.name.split(".")[0] + ".jpg",
          {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          }
        );
        setLoading((prev) => false);
      }
      if (uploadFile.size > API.FILE_UPLOAD_MAX_SIZE) {
        alert(
          `변환된 파일크기가 제한 용량(5MB)를 초과합니다\n 파일크기:${Number(uploadFile.size / 1000000).toFixed(2)}MB`
        );
        return;
      }
      formData.append("file", uploadFile);
      const res = await axios.post(`${API.FILE_BASE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("update image", res.data.data);
      setClickedCurrentPlayer((prev) => ({
        ...prev,
        profileImage: res.data.data,
      }));
    } catch (error) {
      console.log(error);
    }
  }
  async function onRemovePicture() {
    if (!clickedCurrentPlayer.profileImage) {
      alert("등록된 사진이 없습니다");
      return;
    }
    try {
      const requestData = {
        kitNumber: clickedCurrentPlayer.kitNumber,
        profileImageId: null,
        name: clickedCurrentPlayer.name,
        clubName: clickedCurrentPlayer.clubName,
      };
      const {
        data: { data },
      } = await axios.put(
        `${API.MP_AGENCY_PLAYER}/${clickedCurrentPlayer.id}`,
        requestData
      );
      setAgencyPlayers((prev) => data);
      setClickedCurrentPlayer((prev) => ({ ...prev, profileImage: null }));
      alert("사진을 삭제했습니다");
    } catch (error) {
      alert("사진삭제에 실패했습니다");
    }
  }
  function clickedCurrentPlayerInputChange(event) {
    const changedField = event.target.name;
    const changedValue = event.target.value;
    setClickedCurrentPlayer((prev) => ({
      ...prev,
      [changedField]: changedValue,
    }));
    console.log("changedField", changedField);
    console.log("changedValue", changedValue);
  }
  async function onChangeCurrentPlayerInfo() {
    if (isCurrentPlayerEditable) {
      try {
        const requestData = {
          kitNumber: clickedCurrentPlayer.kitNumber,
          profileImageId: clickedCurrentPlayer.profileImage
            ? clickedCurrentPlayer.profileImage.id
            : null,
          name: clickedCurrentPlayer.name,
          clubName: clickedCurrentPlayer.clubName,
        };
        console.log("requestData", requestData);
        const {
          data: { data },
        } = await axios.put(
          `${API.MP_AGENCY_PLAYER}/${clickedCurrentPlayer.id}`,
          requestData
        );
        setAgencyPlayers((prev) => data);
        setClickedCurrentPlayer((prev) => null);
        setIsCurrentPlayerEditable((prev) => false);
        alert("선수 정보를 수정했습니다");
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsCurrentPlayerEditable((prev) => true);
    }
  }
  async function onschBtnClick(event) {
    const searchKeyword = inputRef.current.value.trim();
    if (searchKeyword.length <= 1) {
      alert("최소 두글자 검색어가 필요합니다");
      return;
    }
    try {
      const requestData = {
        query: searchKeyword,
        position: null,
        strength: null,
        mainFoot: null,
        league: null,
        address: {
          province: null,
          city: null,
        },
        page: 0,
        size: 10,
      };
      const {
        data: {
          data: { content },
        },
      } = await axios.post(API.PLAYER_SEARCH, requestData);
      setSearchedPlayer((prev) => content);
    } catch (error) {}
  }
  async function onAddPlayer() {
    if (!clickedNewPlayer) {
      alert("등록할 선수를 먼저 선택하세요");
      return;
    }
    console.log(players.find((p) => p.playerId === clickedNewPlayer.playerId));
    console.log("players", players);
    console.log("clickedNewPlayer", clickedNewPlayer);
    if (players.find((p) => p.playerId === clickedNewPlayer.id)) {
      alert(`${clickedNewPlayer.name} (은/는)이미 등록된 선수입니다.`);
      console.log("clickedNewPlayer", clickedNewPlayer);
      console.log("players", players);
      return;
    }
    try {
      const requsetData = {
        playerId: clickedNewPlayer.id,
        name: clickedNewPlayer.name,
        kitNumber: clickedNewPlayer.kitNumber,
        profileImageId: null,
      };
      const {
        data: { data },
      } = await axios.post(API.MP_AGENCY_PLAYER, requsetData);
      setAgencyPlayers((prev) => data);
      setClickedNewPlayer((prev) => null);
      searchedPlayer((prev) => null);
      alert("선수를 등록했습니다");
    } catch (error) {}
  }
  async function onDeletePlayer() {
    console.log("onDeletePlayer");
    if (!clickedCurrentPlayer) {
      alert("삭제할 선수를 먼저 선택하세요");
      return;
    }
    if (window.confirm("선수를 삭제하시겠습니까?")) {
      try {
        const {
          data: { data },
        } = await axios.delete(
          `${API.MP_AGENCY_PLAYER}/${clickedCurrentPlayer.id}`
        );
        setAgencyPlayers((prev) => data);
        setClickedCurrentPlayer((prev) => null);
        setIsCurrentPlayerEditable((prev) => false);
        alert("선수를 삭제했습니다");
      } catch (error) {
        console.log(error);
        alert("삭제가 실패했습니다.");
      }
    } else {
    }
  }
  return (
    <Box>
      <Box>
        소속선수
        <StyledTable>
          <thead>
            <tr>
              {hHeaders.map((h, hindex) => (
                <th key={hindex}>{h}</th>
              ))}
            </tr>
          </thead>
          {players ? (
            <tbody>
              {players.map((row) => (
                <tr
                  key={row.id}
                  value={row.id}
                  onClick={onClickPlayerTableRow}
                  style={{ fontSize: "1rem" }}
                >
                  {row.profileImage ? (
                    <td>
                      <div
                        style={{
                          width: "3rem",
                          height: "3rem",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "50%",
                          overflow: "hidden",
                          margin: "0 auto",
                        }}
                      >
                        <img
                          src={`${API.BASE}/${row.profileImage.fileUrl}`}
                          alt="profile-img"
                          style={{ width: "100%", objectFit: "cover" }}
                        ></img>
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div
                        style={{
                          width: "3rem",
                          height: "3rem",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "50%",
                          overflow: "hidden",
                          margin: "0 auto",
                          backgroundColor: "#eee",
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ display: "inline-block", margin: "0 auto" }}
                          icon={faUser}
                          size="3x"
                          color="#81cdc6"
                        />
                      </div>
                    </td>
                  )}

                  <td>{row.name}</td>
                  <td>{row.clubName}</td>
                  <td>{row.kitNumber}</td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </StyledTable>
        {clickedCurrentPlayer ? (
          <Box sx={{ border: "1px solid rgb(64,132,75)", padding: "0.5rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <LabelP
                  style={{ color: "#000", fontSize: "1.2rem", width: "100%" }}
                >
                  선택한 선수
                </LabelP>
              </Box>
              <Box>
                <button
                  style={{
                    border: "none",
                    backgroundColor: isCurrentPlayerEditable
                      ? "#8B0000"
                      : "#1a73e8",
                    padding: "0.3rem",
                    fontSize: "1.1rem",
                    width: "5rem",
                    borderRadius: "4%/8%",
                    color: "#fff",
                    cursor: "pointer",
                    margin: "0 0.2rem 0 0",
                  }}
                  onClick={onChangeCurrentPlayerInfo}
                >
                  {isCurrentPlayerEditable ? "저장" : "수정"}
                </button>
                <button
                  style={{
                    border: "none",
                    backgroundColor: "#1a73e8",
                    padding: "0.3rem",
                    fontSize: "1.1rem",
                    width: "5rem",
                    borderRadius: "4%/8%",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={onDeletePlayer}
                >
                  삭제
                </button>
              </Box>
            </Box>
            <Divider sx={{ margin: "0.5rem 0" }}></Divider>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item xs={4} sm={3}>
                <Box
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  {clickedCurrentPlayer ? (
                    <img
                      alt="profile-img"
                      src={
                        clickedCurrentPlayer.profileImage
                          ? `${API.BASE}/${clickedCurrentPlayer.profileImage.fileUrl}`
                          : "https://cdn-icons-png.flaticon.com/512/6388/6388000.png"
                      }
                      style={{ width: "100%", objectFit: "contain" }}
                    ></img>
                  ) : (
                    <img
                      alt="profile-img"
                      src={
                        "https://cdn-icons-png.flaticon.com/512/6388/6388000.png"
                      }
                      style={{ width: "100%", objectFit: "contain" }}
                    ></img>
                  )}
                  <Loading loading={loading}></Loading>
                </Box>
                <Box>
                  <StyledLabel
                    style={{
                      display: "inline-block",
                      width: "50%",
                      margin: "0",
                      backgroundColor: "#fff",
                      color: isCurrentPlayerEditable ? "green" : "#777",
                      border: isCurrentPlayerEditable
                        ? "1px solid green"
                        : "1px solid #777",
                    }}
                  >
                    {clickedCurrentPlayer
                      ? clickedCurrentPlayer.profileImage
                        ? "수정"
                        : "등록"
                      : "등록"}
                    <input
                      type="file"
                      className="file"
                      ref={img_ref}
                      accept="image/*,image/heic"
                      onChange={changeImgText}
                      disabled={!isCurrentPlayerEditable}
                      style={{
                        display: "none",
                        margin: "0",
                        backgroundColor: "#fff",
                      }}
                    />
                  </StyledLabel>
                  <StyledLabel
                    style={{
                      display: "inline-block",
                      width: "50%",
                      margin: "0",
                      backgroundColor: "#fff",
                      color: isCurrentPlayerEditable ? "green" : "#777",
                      border: isCurrentPlayerEditable
                        ? "1px solid green"
                        : "1px solid #777",
                    }}
                    onClick={onRemovePicture}
                  >
                    삭제
                  </StyledLabel>
                </Box>
              </Grid>
              <Grid item xs={8} sm={9}>
                <Box>
                  <LabelP>이름</LabelP>
                  <TextField
                    value={
                      clickedCurrentPlayer ? clickedCurrentPlayer.name : ""
                    }
                    name="name"
                    size="small"
                    variant="outlined"
                    disabled={!isCurrentPlayerEditable}
                    sx={{ m: "0.5rem", width: { xs: "95%" } }}
                    inputProps={{
                      style: { fontSize: "1.2rem", height: "1rem" },
                    }}
                    onChange={clickedCurrentPlayerInputChange}
                  />
                </Box>
                <Box>
                  <LabelP>소속</LabelP>
                  <TextField
                    value={
                      clickedCurrentPlayer ? clickedCurrentPlayer.clubName : ""
                    }
                    name="clubName"
                    size="small"
                    variant="outlined"
                    disabled={!isCurrentPlayerEditable}
                    sx={{ m: "0.5rem", width: { xs: "95%" } }}
                    inputProps={{
                      style: { fontSize: "1.2rem", height: "1rem" },
                    }}
                    onChange={clickedCurrentPlayerInputChange}
                  />
                </Box>
                <Box>
                  <LabelP>등넘버</LabelP>
                  <TextField
                    value={clickedCurrentPlayer.kitNumber}
                    name="kitNumber"
                    size="small"
                    variant="outlined"
                    disabled={!isCurrentPlayerEditable}
                    sx={{ m: "0.5rem", width: { xs: "95%" } }}
                    inputProps={{
                      style: { fontSize: "1.2rem", height: "1rem" },
                    }}
                    onChange={clickedCurrentPlayerInputChange}
                  />
                </Box>
              </Grid>
              <Grid></Grid>
            </Grid>
          </Box>
        ) : null}
      </Box>
      <Divider sx={{ margin: "2rem 0", background: "#999" }}></Divider>
      <Box>
        <Box sx={{ border: "1px solid #aaa", padding: "0.5rem" }}>
          <TitleBox style={{ fontSize: "1.3rem" }}>
            회원가입된 선수등록
          </TitleBox>
          <Box sx={{ fontSize: "1rem" }}>
            <FontAwesomeIcon icon={faCircleInfo} color="#1e8ffa" />
            <span>{"에이전시에 등록할 선수를 검색 후 클릭하세요"}</span>
          </Box>
          <Box sx={{ fontSize: "1rem" }}>
            <FontAwesomeIcon icon={faCircleInfo} color="#1e8ffa" />
            <span>
              {"회원가입된 선수를 등록할 경우, 선수페이지와 연동됩니다"}
            </span>
          </Box>
          <div className="search-input">
            <TextField
              id="player-search-input"
              inputRef={inputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") onschBtnClick();
              }}
              size="small"
              variant="outlined"
              placeholder="이름"
              sx={{ m: 0.5, width: { xs: "95%", md: "20rem" } }}
              inputProps={{ style: { fontSize: "1.1rem" } }}
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
          </div>
          <StyledTable>
            <thead>
              <tr>
                {hHeaders.map((h, hindex) =>
                  h === "생년월일" ? (
                    window.innerHeight > 600 ? null : (
                      <th key={hindex}>{h}</th>
                    )
                  ) : (
                    <th key={hindex}>{h}</th>
                  )
                )}
              </tr>
            </thead>
            {searchedPlayer ? (
              <tbody>
                {searchedPlayer.map((row) => (
                  <tr
                    key={row.id}
                    value={row.id}
                    onClick={() => setClickedNewPlayer(row)}
                    style={{ fontSize: "1rem" }}
                  >
                    {row.profileImage ? (
                      <td>
                        <div
                          style={{
                            width: "4rem",
                            height: "4rem",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "50%",
                            overflow: "hidden",
                            margin: "0 auto",
                          }}
                        >
                          <img
                            src={`${API.BASE}/${row.profileImage.fileUrl}`}
                            alt="profile-img"
                            style={{ width: "100%", objectFit: "cover" }}
                          ></img>
                        </div>
                      </td>
                    ) : (
                      <td>
                        <div
                          style={{
                            width: "4rem",
                            height: "4rem",
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              display: "inline-block",
                              margin: "0 auto",
                            }}
                            icon={faUser}
                            size="3x"
                            color="#81cdc6"
                          />
                        </div>
                      </td>
                    )}

                    <td>{row.name}</td>
                    <td>{row.club ? row.club.name : null}</td>
                    <td>{row.club ? row.kitNumber : null}</td>
                  </tr>
                ))}
              </tbody>
            ) : null}
          </StyledTable>
          {clickedNewPlayer ? (
            <Box
              sx={{
                border: "1px solid #eee",
                margin: "1rem 0 0 0",
                padding: "0.5rem",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ margin: "0 1rem 0 0 " }}>
                  {clickedNewPlayer.profileImage ? (
                    <td>
                      <div
                        style={{
                          width: "10rem",
                          height: "10rem",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "5%",
                          overflow: "hidden",
                          margin: "0 auto",
                        }}
                      >
                        <img
                          src={`${API.BASE}/${clickedNewPlayer.profileImage.fileUrl}`}
                          alt="profile-img"
                          style={{ width: "100%", objectFit: "cover" }}
                        ></img>
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div
                        style={{
                          width: "4rem",
                          height: "4rem",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ display: "inline-block", margin: "0 auto" }}
                          icon={faUser}
                          size="3x"
                          color="#81cdc6"
                        />
                      </div>
                    </td>
                  )}
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "#1a73e8",
                      padding: "0.5rem 1rem",
                      fontSize: "1.1rem",
                      width: "100%",
                      margin: "0.5rem 0 0 0",
                      borderRadius: "4%/8%",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={onAddPlayer}
                  >
                    신규등록
                  </button>
                </Box>
                <Box>
                  <Box>
                    <LabelP>이름</LabelP>
                    <LabelContent>{clickedNewPlayer.name}</LabelContent>
                  </Box>
                  <Box>
                    <LabelP>생년월일</LabelP>{" "}
                    <LabelContent>{clickedNewPlayer.birthDate}</LabelContent>
                  </Box>
                  <Box>
                    <LabelP>소속</LabelP>{" "}
                    <LabelContent>
                      {clickedNewPlayer.club ? clickedNewPlayer.club.name : ""}
                    </LabelContent>
                  </Box>
                  <Box>
                    <LabelP>등넘버</LabelP>{" "}
                    <LabelContent>{clickedNewPlayer.kitNumber}</LabelContent>
                  </Box>
                  <Box>
                    <LabelP>주포지션</LabelP>
                    <LabelContent>{clickedNewPlayer.mainPosition}</LabelContent>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            border: "1px solid #aaa",
            padding: "0.5rem",
            margin: "1rem 0 0 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <TitleBox style={{ fontSize: "1.3rem" }}>
                직접 입력하여 선수등록
              </TitleBox>
            </Box>
          </Box>
          <Box sx={{ border: "1px solid rgb(64,132,75)", padding: "0.5rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <MPAgencyPlayerAddManually
                setPlayers={setAgencyPlayers}
              ></MPAgencyPlayerAddManually>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
