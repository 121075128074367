import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CareerList from "../../../common/CareerList";
import Button from "@mui/material/Button";
import { coachCarrerAtom } from "../../../../../recoil/atoms/coachCarrer";
import { coachCareerSeqAtom } from "../../../../../recoil/atoms/coachCareerSeq";
import { useRecoilState } from "recoil";
import { useState } from "react";
import axios from "axios";
import { API } from "../../../../../config";
import { Box } from "@mui/material";
import { TitleBox } from "../../../common/MyPageComponents";

export default function MPStaffProfilePlayerCareer({
  comType,
  roleType,
  selectBoxType,
  sectionTitle,
  career,
  setCareer,
  selectBoxText,
  setSnackbarOpen,
  setLastCRUD,
}) {
  const [seq, setSeq] = useState(career.length);
  function addBtnClick(e) {
    const data = {
      seq: seq,
      joinedAt: null,
      leftAt: null,
      club: null,
      kitNumber: null,
      teamPosition: selectBoxText[0].text,
      fromServer: false,
      isEditState: true,
    };
    setCareer((prev) => [...prev, data]);
    setSeq((prev) => prev + 1);
  }
  return (
    <Box>
      <TitleBox>
        {sectionTitle}
        <Button
          id="player-career-add-btn"
          variant="text"
          sx={{ fontSize: "1rem" }}
          onClick={addBtnClick}
        >
          <FontAwesomeIcon
            className="add-btn-icon"
            color="#1976D2"
            icon={faPlus}
          />
          추가하기
        </Button>
      </TitleBox>
      <CareerList
        comType={comType}
        roleType={roleType}
        selectBoxType={selectBoxType}
        career={career}
        setCareer={setCareer}
        roleText={selectBoxText}
        setSnackbarOpen={setSnackbarOpen}
        setLastCRUD={setLastCRUD}
      ></CareerList>
    </Box>
  );
}
