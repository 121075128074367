import { Box, Button } from "@mui/material";
import SNSItem from "../../staff/teamManage/SNSItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { API } from "../../../../../config";
import { TitleBox } from "../../../common/MyPageComponents";

export default function MPPlayerSNS({
  roleType,
  comType,
  sectionTitle,
  SNS,
  setSNS,
}) {
  const [seq, setSeq] = useState(SNS.length);
  function onAddSNS() {
    const data = {
      id: null,
      type: null,
      url: null,
      seq: seq,
      fromServer: false,
      isEditState: true,
    };
    setSNS((prev) => [...prev, data]);
    setSeq((prev) => prev + 1);
  }
  return (
    <Box>
      <TitleBox>
        {sectionTitle}
        <Button
          id="player-career-add-btn"
          variant="text"
          sx={{ fontSize: "1rem" }}
          onClick={onAddSNS}
        >
          <FontAwesomeIcon
            className="add-btn-icon"
            color="#1976D2"
            icon={faPlus}
          />
          추가하기
        </Button>
      </TitleBox>
      <span
        style={{
          color: "rgb(64,132,75)",
          fontSize: "1.2rem",
          display: "inline-block",
          width: "70%",
          marginLeft: "1rem",
        }}
      >
        SNS의 id만 입력하세요
      </span>

      <Box>
        {SNS.map((item) => (
          <SNSItem
            roleType={roleType}
            comType={comType}
            SNS={SNS}
            setSNS={setSNS}
            currentRow={item}
          ></SNSItem>
        ))}
      </Box>
    </Box>
  );
}
